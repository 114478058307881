import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import * as Actions from "../actions";
import {IPool, IManageEntranceRequest, IManagePrestationRequest, IPrestation, IPrestationModel} from "../reducers";
import { getNetworksListActive } from "../../networks/actions";
import { INetwork, IPostTypes } from "../../networks/reducers";
import PoolResumeComponent from "../components/PoolResumeComponent";
import { removePost } from "../../posts/actions";

const mapStateToProps = (state: RootState) => {

    const listPrestationModel: IPrestationModel[] = state.dashboardReducer.listPrestationModel;
    const listPrestationScenario: IPrestationModel[] = state.dashboardReducer.listPrestationScenario;

    return {
        listPrestationModel,
        listPrestationScenario,
        networksActive: state.networksReducer.networksActive as INetwork[],
        postTypesAvailables: state.networksReducer.postTypesAvailables as IPostTypes
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get: (id: number, subtype: string, category: string) => {
            return new Promise((resolve: (event: IPool) => void, reject: any) => {
                dispatch(Actions.get(id, subtype, category, resolve, reject));
            });
        },
        publishCancel: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.publishCancel(id, resolve, reject));
            });
        },
        ticketsDownload: (event_id: number) => {
            return new Promise((resolve: ({status, pdf}: {status: string, pdf: string}) => void, reject: any) => {
                dispatch(Actions.ticketsDownload(event_id, resolve, reject));
            });
        },
        getNetworksListActive: () => {
            dispatch(getNetworksListActive());
        },
        removePost: (post_id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(removePost(post_id, resolve, reject));
            });
        },
        manageEntrances: (infos: IManageEntranceRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.manageEntrances(infos, resolve, reject));
            });
        },
        getPrestation: (id: number) => {
            return new Promise((resolve: ({status, prestation}: {status: string; prestation: IPrestation}) => void, reject: any) => {
                dispatch(Actions.getPrestation(id, resolve, reject));
            });
        },
        managePrestation: (infos: IManagePrestationRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.managePrestation(infos, resolve, reject));
            });
        },
        resumeDownload: (event_id: number) => {
            return new Promise((resolve: ({status, pdf}: {status: string, pdf: string}) => void, reject: any) => {
                dispatch(Actions.resumeDownload(event_id, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PoolResumeComponent);