import { Breadcrumb } from 'react-bootstrap'
import { HouseDoorFill } from 'react-bootstrap-icons'
import { useLocation } from 'react-router-dom'
import { LOGIN, REGISTER, FORGOT_PASSWORD } from '../../modules/auth/routes/auth'
import { paths } from './BreadCrumbPaths'
import { useNavigate } from "react-router-dom";

const BreadcrumbComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const noShow = [LOGIN, REGISTER, FORGOT_PASSWORD]

    if (noShow.includes(location.pathname)) return null

    let p2 = location.pathname.split('/').slice(0, 3).join('/');

    const breadCrumbPaths = paths.find(path => path.path.startsWith(p2))?.breadcrumb

    const createBreadCrumb = () => {
        if (!breadCrumbPaths) {
            return <Breadcrumb.Item href='/' className='white' active>Tableau de bord</Breadcrumb.Item>
        } else {
            let crumbs: JSX.Element[] = []
            for (const p of breadCrumbPaths) {
                if (!!p.path && !!p.title) crumbs.push(<Breadcrumb.Item key={p.path} href={p.path} className=''>{p.title}</Breadcrumb.Item>)
            }
            crumbs.push(<Breadcrumb.Item key={'active'} className='fw-bold' active>{paths.find(path => path.path.startsWith(p2))?.title}</Breadcrumb.Item>)
            return crumbs
        }
    }
    return (
        <div>
            <Breadcrumb className='d-flex'>
                <Breadcrumb.Item onClick={() => navigate('/')}><HouseDoorFill className="bi bi-house-door-fill mx-lg-3 white " /></Breadcrumb.Item>
                {createBreadCrumb()}
            </Breadcrumb>
        </div>
    )
}

export default BreadcrumbComponent