import { ISupport, IUserCredential, IUserPassword } from '../reducers';
import {
    LOGIN,
    LOGOUT,
    REGISTER,
    FORGOT_PASSWORD,
    RECOVER_PASSWORD,
    CHANGE_PASSWORD,
    SUPPORT,
} from '../constants'

export function logIn(credentials: IUserCredential, resolve: any, reject: any) {
    return {
        type: LOGIN, credentials, resolve, reject
    };
}

export function logOut(resolve: any, reject: any) {
    return {
        type: LOGOUT, resolve, reject
    };
}

export function register(credentials: IUserCredential, resolve: any, reject: any) {
    return {
        type: REGISTER, credentials, resolve, reject
    };
}

export function forgotPassword(email: string, resolve: any, reject: any) {
    return {
        type: FORGOT_PASSWORD, email, resolve, reject
    };
}

export function recoverPassword(code: string, email: string, password: string, resolve: any, reject: any) {
    return {
        type: RECOVER_PASSWORD, code, email, password, resolve, reject
    };
}

export function changePassword(passwordData: IUserPassword, resolve: any, reject: any) {
    return {
        type: CHANGE_PASSWORD, passwordData, resolve, reject
    };
}

export function support(supportData: ISupport, resolve: any, reject: any) {
    return {
        type: SUPPORT, supportData, resolve, reject
    };
}
