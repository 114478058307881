import { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Overlay, Tooltip, NavLink } from 'react-bootstrap'
import { Gear, List, Power } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { PropsFromReduxTopMenu } from '../containers/TopMenuContainer'
import BreadcrumbComponent from './BreadcrumbComponent'
import toast from 'react-hot-toast';

const TopMenuComponent = (props: PropsFromReduxTopMenu) => {

  const configMenuButton = useRef<any>();
  const navigationMenuButton = useRef<any>();
  const navigate = useNavigate()
  const [showConfigMenu, setShowConfigMenu] = useState(false)
  const [showNavigationMenu, setShowNavigationMenu] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event:Event) => {
        if (configMenuButton.current && !configMenuButton.current.contains(event.target)) {
          setShowConfigMenu(false)
        }
        if (navigationMenuButton.current && !navigationMenuButton.current.contains(event.target)) {
          setShowNavigationMenu(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [configMenuButton]);

  const handleLogOut = () => {
    props.logOut()
    .catch(error => toast.error(error.message.fr))
  }

  const navigateFromMenu = (destination:string) => {
    setShowConfigMenu(false)
    setShowNavigationMenu(false)
    navigate(destination)
  }

  return (
    <Row className='d-flex flex-wrap-reverse'>
      <Col className='col-12 col-lg-7 mt-2'>
        <BreadcrumbComponent />
      </Col>
      <Col className='col-12 col-lg-5 text-end'>
        <Overlay
          target={navigationMenuButton.current}
          show={showNavigationMenu}
          placement='bottom-start'
          rootClose
          rootCloseEvent='click'>
            <Tooltip placement='top'>
              <Row className='profilmenu' >
              <NavLink onClick={ () => navigateFromMenu('/')} className='mt-1 mb-1 nav-link fw-bolder'>Tableau de bord</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/party')} className='mt-1 mb-1 nav-link fw-bolder'>Soirées</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/pool')} className='mt-1 mb-1 nav-link fw-bolder'>Pool party</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/transat')} className='mt-1 mb-1 nav-link fw-bolder'>Transat</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/spa')} className='mt-1 mb-1 nav-link fw-bolder'>SPA</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/restaurant')} className='mt-1 mb-1 nav-link fw-bolder'>Restaurant</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/hostel')} className='mt-1 mb-1 nav-link fw-bolder'>Hébergement</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/pack')} className='mt-1 mb-1 nav-link fw-bolder'>Pack</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/business-record')} className='mt-1 mb-1 nav-link fw-bolder'>Fiche établissement</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/scanners')} className='mt-1 mb-1 nav-link fw-bolder'>Scanners</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/networks')} className='mt-1 mb-1 nav-link fw-bolder'>Réseaux</NavLink>
              <NavLink onClick={ () => navigateFromMenu('/bank-info')} className='mt-1 mb-1 nav-link fw-bolder'>Données bancaires</NavLink>
              </Row>
            </Tooltip>
        </Overlay>
        <Overlay
          target={configMenuButton.current}
          show={showConfigMenu}
          placement='bottom'
          rootClose
          rootCloseEvent='click'>
            <Tooltip placement='top'>
              <Row className='profilmenu' >
                <NavLink className='mt-1 mb-1 nav-link fw-bolder' onClick={ () => navigateFromMenu('change-password')}>Mot de passe</NavLink>
                <NavLink className='mt-1 mb-1 nav-link fw-bolder' onClick={ () => navigateFromMenu('support')}>Support</NavLink>
                <a href='https://lib-events.com/cgu-cgv' rel="noreferrer" target={'_blank'} className='mt-1 mb-1 nav-link fw-bolder' >CGV / CGU</a>
              </Row>
            </Tooltip>
        </Overlay>
        <Button ref={navigationMenuButton} className='d-lg-none' style={{float: 'left', marginLeft: -20}} variant='link'onClick={() => setShowNavigationMenu(!showNavigationMenu)}>
          <List size={30} className="white mx-2" />
        </Button>
        <Button ref={configMenuButton} variant='link'onClick={() => setShowConfigMenu(!showConfigMenu)}>
          <Gear size={25} className="white mx-2"  />
        </Button>
        <Button variant='link' onClick={handleLogOut}>
          <Power size={25} className="white mx-2" />
        </Button>
      </Col>
    </Row>
  )
}

export default TopMenuComponent