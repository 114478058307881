import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import HostelPage from "../pages/HostelPage";
import HostelNewContainer from "../containers/HostelNewContainer";
import HostelNewModelsContainer from "../containers/HostelNewModelsContainer";
import HostelCreateContainer from "../containers/HostelCreateContainer";
import HostelEditContainer from '../containers/HostelEditContainer';
import HostelResumeContainer from "../containers/HostelResumeContainer";

export const HostelRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/hostel',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <HostelPage /> },
                { path: '/hostel/new', element: <HostelNewContainer /> },
                { path: '/hostel/models', element: <HostelNewModelsContainer /> },
                {
                    path: '/hostel/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/hostel/create/:id',
                            element: <HostelCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/hostel/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/hostel/resume/:id',
                            element: <HostelResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/hostel/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/hostel/edit/:id',
                            element: <HostelEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}