import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Overlay, Popover, Row } from 'react-bootstrap';
import { PropsFromReduxBusinessRecord } from '../containers/BusinessRecordContainer';
import { toast } from 'react-hot-toast';
import Geocode from '../../../utils/geocode';
import Slim, { blobToBase64 } from '../../../common/lib/slim/slim.react';
import { Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { IOrganizerInfos } from '../reducers';

const BusinessRecordComponent = (props: PropsFromReduxBusinessRecord) => {
    
    const [showPopOver, setShowPopOver] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [place_lat, setLatitude] = useState<number | undefined>();
    const [place_lng, setLongitude] = useState<number | undefined>();
    const [place, setPlace] = useState("");
    const [error, setError] = useState("");
    const [coverImage, setCoverImage] = useState("");;
    const [baselineText, setBaselineText] = useState("");
    const [baselineHtml, setBaselineHtml] = useState("");

    const [disabledButtons, setDisabledButtons] = useState(false);

    var cropper: any = undefined;
    var cropperActive: boolean = true;
    useEffect(() => {
        props.getInfos().then((organizerInfos: IOrganizerInfos) => {

            if(organizerInfos) {                
                setName(organizerInfos.name)
                setDescription(organizerInfos.description)
                setLatitude(organizerInfos.place_lat)
                setLongitude(organizerInfos.place_lng)
                setPlace(organizerInfos.place)
                setCoverImage(organizerInfos.img_cover)
    
                if(organizerInfos.img_cover) {
                    cropperActive = false;                
    
                    cropper.load(organizerInfos.img_cover, function(){
                        cropperActive = true;                
                    });
                }
    
                setBaselineText(organizerInfos.baseline_text)
                setBaselineHtml(organizerInfos.baseline_html)
            }

        }).catch((error: any) => {
            if(error.message.fr) {
                toast.error(error.message.fr)
            }
        })
    }, [])
    
    useEffect(() => {
        if (!!showPopOver) {
            setTimeout(() => {
                setShowPopOver(false)
            }, 1000)
        }
    }, [showPopOver])
    
    const addToClipboard = (content: string, event: any) => {
        navigator.clipboard.writeText(content)
        .then(function () {
            setShowPopOver(true);
            setTarget(event.target);
        }, 
        function (err) {
            console.error(err)
        });
    }

    const onChangeLocation = async (text: string) => {
        setLatitude(undefined)
        setLongitude(undefined)
        setPlace(text)
        
        try {
            const response = await Geocode.fromAddress(text)
            /* @ts-ignore */
            if (response !== {}) {
                setError("")
                const { lat, lng } = response.results[0].geometry.location;
                setLatitude(lat);
                setLongitude(lng);
                return
            }
        } catch (e) {
            if (text === "") setError("")
            else setError("Ce lieu est introuvable, merci de préciser votre adresse.")
        }
    }

    const saveInfos = () => {
        if (place_lat !== undefined && place_lng !== undefined && place !== "") {
            setDisabledButtons(true)
            props.updateInfos({ name, description, place, place_lat, place_lng })
            .then(status => {
                setDisabledButtons(false)
                toast.success("Informations modifiées.")
            })
            .catch(error => {
                setDisabledButtons(false)
                toast.error(error.message.fr)
            })
        } else toast.error("Ce lieu est introuvable, merci de préciser votre adresse.")
    }
    
    /* @ts-ignore */
    const slimInit = (data, slim) => { 
        cropper = slim;
    }

    /* @ts-ignore */
    const slimService = async (blob, progress, success, failure, slim) => {

        if(cropperActive) {
            if(blob[0] !== undefined) {
                const base64 = await blobToBase64(blob[0]);
    
                props.updateCover({ base64: base64 })
                .then(status => {
                    toast.success("Image de couverture enregistrée.")
                    success(); //Fin du process slim
                })
                .catch(error => {
                    toast.error(error.message.fr)
                    success(); //Fin du process slim
                })
    
                return;
            }
            else {
                toast.error("Une erreur est survenue, merci de réessayer.")
                success(); //Fin du process slim
            }
        }
        else {
            success(); //Fin du process slim
        }

    }

    return (
        <>
        
            <Row className='mt-2 g-2 flex-wrap'>
                        
                {/* -----------------------------  LIEN DE PARTAGE  ----------------------------- */}

                <Col ref={ref} className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Lien de partage</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text id='cover'>Pour que vos clients réservent, rajoutez le lien vers votre fiche événement Lib-Events dans vos communications:</Card.Text>
                            <pre className='baseline-pre'>{baselineText}</pre>
                            <div className='d-flex gap-2 ms-2'>
                                <Button variant='secondary' onClick={(e) => addToClipboard(baselineText, e)}><Clipboard /> Copier le texte</Button>
                                <Button variant='secondary' onClick={(e) => addToClipboard(baselineHtml, e)}><Clipboard /> Copier le HTML</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Informations</div>
                                <div className="align-items-right d-none d-md-block">
                                    <Button className="topButton" variant="primary" disabled={disabledButtons} onClick={() => saveInfos()}>
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>Nom de l'établissement</Form.Label>
                                <Form.Control type="text" placeholder="Lib Club" value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mb-3 d-flex w-100' controlId='formEventDates'>
                                <div className='w-100'>
                                    <Form.Label>Adresse de l'établissement</Form.Label>
                                    <Form.Control type="text" placeholder="10 avenue des coquins, La ville du plaisir" value={place} onChange={(e) => { onChangeLocation(e.target.value) }} />
                                    {error !== "" && <p className="text-danger" style={{marginTop: 10, marginLeft: 5}}>{error}</p>}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={6} placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="d-flex d-md-none align-items-center ">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder"></div>
                                <div className="align-items-right">
                                    <Button className="topButton" variant="primary" disabled={disabledButtons} onClick={() => saveInfos()}>
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Card.Title>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Image de couverture</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text id='cover'>L'image de couverture sera affichée au format paysage en en-tête de la fiche de votre établissement.</Card.Text>
                            <div className='w-100 overflow-hidden'>
                            <Slim ratio="2:1"
                                id="cropper-cover"
                                initialImage={coverImage ? coverImage : ''}
                                minSize={ { width:380, height:190 } }
                                push={true}
                                service={ slimService }
                                didInit={ slimInit }
                                serviceFormat="file"
                                style={ { aspectRatio: '2/1' } }
                                label="Charger une image"
                                buttonCancelLabel= "Annuler"
                                buttonCancelTitle= "Annuler"
                                buttonConfirmLabel= "Valider"
                                buttonConfirmTitle= "Valider"
                                buttonRotateTitle= "Rotation"
                                buttonEditTitle= "Modifier"
                                buttonRemoveTitle= "Supprimer"
                                buttonUploadTitle= "Sauvegarder"
                                statusUploadSuccess="">
                                <input type="file" name="cover"/>
                            </Slim>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            

            <Overlay
                show={showPopOver}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Body>
                        <ClipboardCheck /> Copié !
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    )
}

export default BusinessRecordComponent