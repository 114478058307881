import { SyntheticEvent, useRef, useState } from "react";
import { Badge, Button, ButtonGroup, Col, FloatingLabel, Form, Image, InputGroup, Modal, Ratio, Row, Tab, Tabs } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { IPrestation, IPrestationRequest } from "../../reducers";
import { toast } from "react-hot-toast";
import Slim, { blobToBase64 } from '../../../../common/lib/slim/slim.react';
import { useEffect } from "react";
import { getBase64FromUrl } from "../../../../common/helpers/getBase64FromUrl";

interface EditPrestationComponentProps {
    show: boolean;
    handleHide: () => void;
    handleSave: () => void;

    prestation: IPrestation | undefined;
    isNewPrestation: boolean;
    
    removePrestation: (id: number) => Promise<string>;
    updatePrestation: (prestationRequest: IPrestationRequest) => Promise<string>;
}

const EditPrestationComponent = (props: EditPrestationComponentProps) => {
    
    const [prestaName, setPrestaName] = useState("");
    const [prestaPrice, setPrestaPrice] = useState("");
    const [prestaDesc, setPrestaDesc] = useState("");
    const [prestaQty, setPrestaQty] = useState("");
    const [prestaChoice, setPrestaChoice] = useState("");
    const [prestaChoices, setPrestaChoices] = useState<string[]>([]);
    const [prestaType, setPrestaType] = useState<"QUANTITY" | "CHOICES">("QUANTITY");
    const [prestaBase64, setPrestaBase64] = useState("");

    useEffect(() => {
        setPrestaName(props.prestation && props.prestation.name ? props.prestation.name : "");
        setPrestaPrice(props.prestation && props.prestation.price_public ? props.prestation.price_public.toString() : "");
        setPrestaDesc(props.prestation && props.prestation.description ? props.prestation.description : "");
        setPrestaQty(props.prestation && props.prestation.quantity_available ? props.prestation.quantity_available.toString() : "");
        setPrestaChoices(props.prestation && props.prestation.choices_available ? props.prestation.choices_available : []);
        setPrestaType(props.prestation && props.prestation.type ? props.prestation.type : "QUANTITY");
        
        if(props.prestation && props.prestation.img_cover) {
            getBase64FromUrl(props.prestation.img_cover, function(base64 : string) {
                setPrestaBase64(base64);
            })
        }
    }, [props])

    const formatPrice = (price: string) => {
        if (price.includes(",")) return Number(price.replace(",", "."));
        else return Number(price);
    };

    const handleAddChoice = (e: SyntheticEvent) => {
        if (!prestaChoice) return;
        setPrestaChoices([...prestaChoices, prestaChoice]);
        setPrestaChoice("");
    };

    const handleRemovePrestationChoice = (index: number) => {
        const choices = prestaChoices;
        choices.splice(index, 1);
        setPrestaChoices([...choices]);
    };

    const handleRemovePrestation = () => {
        if(props.prestation) {
            props.removePrestation(props.prestation.id)
            .then((status) => {
                props.handleSave();
                toast.error("Prestation supprimée.")
            })
            .catch((error: any) => toast.error(error.message.fr))
        }
    };    
    

    const removeCover = async () => {
        setPrestaBase64('')
    }
    
    /* @ts-ignore */
    const saveCover = async (blob, progress, success, failure, slim) => {
        if(blob[0] !== undefined) {
            const base64 = await blobToBase64(blob[0]);
            setPrestaBase64(base64)
            success(); //Fin du process slim
        }
        else {
            toast.error("Une erreur est survenue, merci de réessayer.")
            success(); //Fin du process slim
        }
    }

    const handleUpdatePresta = () => {
        if(props.prestation) {
            
            if (!prestaPrice.match(/\d+(?:[.,]\d{0,2})?/)) return toast.error("Le prix n'est pas un nombre.")

            let request: IPrestationRequest = {
                id: props.prestation.id, 
                name: prestaName,
                description: prestaDesc,
                price_public: formatPrice(prestaPrice),
                type: prestaType,
            }

            if(prestaType === "CHOICES") {
                request.choices = prestaChoices
            }
            else {                
                request.quantity = parseInt(prestaQty)
            }
            
            if(prestaBase64) {
                request.base64 = prestaBase64
            }
            
            props.updatePrestation(request)
            .then((status) => {
                props.handleSave()
                toast.success("Prestation mise à jour.");
            })
            .catch((error: any) => toast.error(error.message.fr))
        }
    };

    return (
        <Modal show={props.show} onHide={props.handleHide} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Prestation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="col-12 col-lg-8 mb-3">
                        <Form.Label>Nom</Form.Label>   
                        <Form.Control
                            type="text"
                            value={prestaName}
                            onChange={(e) => setPrestaName(e.target.value)}
                            placeholder="Nom de la prestation"
                        />
                        <Form.Text>Affiché sur la fiche de l'événement.</Form.Text>
                    </Col>
                    <Col className="gap-2 col-12 col-lg-4 mb-3">
                        <Form.Label>Prix public TTC en €</Form.Label>   
                        <Form.Control
                            type="text"
                            value={prestaPrice}
                            onChange={(e) => setPrestaPrice(e.target.value)}
                            placeholder="Prix"
                        />
                        <Form.Text>A payer par le client.</Form.Text>
                    </Col>
                    <Col className="gap-2 col-12 mb-3">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Contenu de la prestation</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={prestaDesc}
                                onChange={(e) => setPrestaDesc(e.target.value)}
                                placeholder="Description"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-2 mt-2">Type de prestation (ne remplir qu'un des deux choix)</div>
                        <ButtonGroup size="sm" className="w-100 justify-content-center d-flex">
                            <Button
                                className="w-100"
                                onClick={(e) => setPrestaType("QUANTITY")}
                                variant={`${prestaType !== "QUANTITY" ? "outline-" : ""}secondary`}
                            >
                                Par quantité uniquement
                            </Button>
                            <Button
                                className="w-100"
                                onClick={(e) => setPrestaType("CHOICES")}
                                variant={`${prestaType !== "CHOICES" ? "outline-" : ""}secondary`}
                            >
                                Par créneaux horaires (ou numéro de table, ..)
                            </Button>
                        </ButtonGroup>
                        {prestaType === "QUANTITY" && (
                            <div className="w-100 mt-2">
                                <Form.Control
                                    type="text"
                                    value={prestaQty}
                                    onChange={(e) => setPrestaQty(e.target.value)}
                                    placeholder="Quantité"
                                />
                                <Form.Text>Introduisez le nombre de prestations disponibles lors de votre événement.</Form.Text>
                            </div>
                        )}
                        {prestaType === "CHOICES" && (
                            <>
                                <InputGroup className=" mt-2">
                                    <Form.Control
                                        type="text"
                                        value={prestaChoice}
                                        onChange={(e) => setPrestaChoice(e.target.value)}
                                        placeholder="20h30"
                                    />
                                    <InputGroup.Text
                                        id="prestaChoice"
                                        onClick={handleAddChoice}
                                        style={prestaChoice.length > 0 ? { cursor: "pointer" } : {}}
                                    >
                                        Ajouter
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text className=" mt-2">Saisissez les créneaux horaires (ou autres) ou votre prestation est résevable puis cliquez sur Ajouter.</Form.Text>
                                <div className="d-flex gap-2 flex-wrap mt-2">
                                    {prestaChoices.map((choice, i) => (
                                        <Badge key={i} bg="light" style={{ color: "#454545", fontSize: "1rem" }}>
                                            {choice} <X onClick={() => handleRemovePrestationChoice(i)} />{" "}
                                        </Badge>
                                    ))}
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{marginBottom: 10, marginTop: 20}}>Image de couverture</div>
                        <div className="w-100 overflow-hidden">
                            <Slim ratio="2:1"
                                initialImage={props.prestation && props.prestation.img_cover ? props.prestation.img_cover : ''}
                                minSize={{ width:380, height:190 }}
                                push={true}
                                service={ saveCover }
                                //didInit={ slimInit }
                                didRemove={ removeCover }
                                serviceFormat="file"
                                style={ { aspectRatio: '2/1' } }
                                label="Charger une image"
                                buttonCancelLabel= "Annuler"
                                buttonCancelTitle= "Annuler"
                                buttonConfirmLabel= "Valider"
                                buttonConfirmTitle= "Valider"
                                buttonRotateTitle= "Rotation"
                                buttonEditTitle= "Modifier"
                                buttonRemoveTitle= "Supprimer"
                                buttonUploadTitle= "Sauvegarder"
                                statusUploadSuccess="">
                                <input type="file" name="cover"/>
                            </Slim>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button className="fw-bolder" style={{marginRight: 10, marginBottom: 10}} variant='outline-secondary' type="button" onClick={() => props.handleHide()}>Retour</Button>
                    {!props.isNewPrestation && 
                        <Button className="fw-bolder" style={{marginBottom: 10}} variant='outline-danger' onClick={() => handleRemovePrestation()}>Supprimer</Button>
                    }
                </Col>
                <Col className="text-end">
                    <Button className="fw-bolder" style={{marginBottom: 10}} variant='primary' onClick={handleUpdatePresta}>Sauvegarder</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default EditPrestationComponent;
