import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import RestaurantTransfersComponent from "../components/RestaurantTransfersComponent";
import * as Actions from '../actions'

const mapStateToProps = (state: RootState) => {
    return {
        listTransfers: state.restaurantReducer.listTransfers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        listTransfer: () => {
            dispatch(Actions.listTransfer());
        },
        downloadResume: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.downloadResume(id, resolve, reject));
            })
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxRestaurantTransfers = ConnectedProps<typeof connector>;

export default connector(RestaurantTransfersComponent);