import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PoolTemplateCard from "./common/PoolTemplateCard";
import toast from "react-hot-toast";
import { IPoolModel } from "../reducers";
import { PropsFromRedux } from "../containers/PoolNewModelsContainer";
import history from "../../../routes/history";

const PoolNewModelsComponent = (props: PropsFromRedux) => {
    const navigate = useNavigate()

    useEffect(() => {
        props.listModel('UNIQUE', 'POOL');
    }, []); 

    const [selectedId, setSelectedId] = useState(-1);

    const handleValid = async () => {
        if (selectedId === -1) return;

        props.createModel(selectedId)
        .then((eventId: number) => {            
            navigate('/pool/create/'+eventId);
            return
        })
        .catch(error => { toast.error(error.message.fr) })
    };

    const handlePrevious = () => {
        history.back()
    };

    return (
        <Row className="mt-2 g-2">
            <Col className="">
                <Card className="blocCard">
                    <Card.Header className="d-flex align-items-center mb-2">
                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                            <div className="w-100 fw-bolder">À partir d'une précédente</div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Choisissez la pool party modèle parmi vos précédentes. Les champs de la nouvelle pool seront pré-remplis.</Card.Text>
                        {props.eventsModel && (
                            <Row className="my-4">
                                <Col className="d-flex justify-content-center flex-wrap">
                                    {props.eventsModel.map((model: IPoolModel) => (
                                        <PoolTemplateCard
                                            key={model.id}
                                            modelId={model.id}
                                            selectedId={selectedId}
                                            name={model.name}
                                            started={model.started}
                                            imgFlyer={model.img_flyer}
                                            onClick={setSelectedId}
                                        />
                                    ))}
                                </Col>
                            </Row>
                        )}
                        <Row className="mx-0 p-0 mt-3">
                            <Col className="d-flex justify-content-between p-0">
                                <Button className="w-25" variant="secondary" onClick={handlePrevious}>
                                    Précédent
                                </Button>
                                <Button className="w-25" variant="success" disabled={selectedId === -1} onClick={handleValid}>
                                    Valider
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default PoolNewModelsComponent;
