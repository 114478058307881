import { Outlet, RouteObject } from "react-router-dom";
import * as ROUTES from './restaurant'
import RestaurantPage from "../pages/RestaurantPage";
import withPermissions from "../../../routes/withPermissions";
import RestaurantTransfersContainer from "../containers/RestaurantTransfersContainer";
import RestaurantResumeContainer from "../containers/RestaurantResumeContainer";
import RestaurantConfigureContainer from "../containers/RestaurantConfigureContainer";

export const RestaurantRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: ROUTES.RESTAURANT,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <RestaurantPage /> },
                { path: ROUTES.TRANSFERS, element: <RestaurantTransfersContainer /> },
                { path: ROUTES.CONFIGURE, element: <RestaurantConfigureContainer /> },
                {
                    path: ROUTES.RESUME,
                    element: <Outlet />,
                    children: [
                        {
                            path: ROUTES.RESUMEID,
                            element: <RestaurantResumeContainer />
                        }
                    ]
                }
            ]
        }
    ]
}