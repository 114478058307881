import { Outlet } from "react-router-dom"
import RestaurantContainer from "../containers/RestaurantContainer"

const RestaurantPage = () => {
    return <>
        <RestaurantContainer />
        <Outlet />
    </>
}

export default RestaurantPage