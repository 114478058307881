
import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IResponseStatus } from "../reducers";


function* list(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.list, apikey);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_LIST_FAIL : Constants.HOSTEL_LIST_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.HOSTEL_LIST_FAIL, e });
    }
}

function* listModel(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listModel, apikey);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_LIST_MODEL_GOOD_FAIL : Constants.HOSTEL_LIST_MODEL_GOOD_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.HOSTEL_LIST_MODEL_GOOD_FAIL, e });
    }
}

function* createModel(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createModel, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_CREATE_MODEL_GOOD_FAIL : Constants.HOSTEL_CREATE_MODEL_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.id);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_CREATE_MODEL_GOOD_FAIL, e });
    }
}

function* createEmpty(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createEmpty, apikey);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_CREATE_EMPTY_GOOD_FAIL : Constants.HOSTEL_CREATE_EMPTY_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.id);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_CREATE_EMPTY_GOOD_FAIL, e });
    }
}

function* get(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.get, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_GET_GOOD_FAIL : Constants.HOSTEL_GET_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_GET_GOOD_FAIL, e });
    }
}

function* publishCheck(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.publishCheck, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_PUBLISH_CHECK_GOOD_FAIL : Constants.HOSTEL_PUBLISH_CHECK_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_PUBLISH_CHECK_GOOD_FAIL, e });
    }
}

function* publish(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.publish, apikey, action.id, action.scheduled_at);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_PUBLISH_GOOD_FAIL : Constants.HOSTEL_PUBLISH_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_PUBLISH_GOOD_FAIL, e });
    }
}

function* remove(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.remove, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_REMOVE_GOOD_FAIL : Constants.HOSTEL_REMOVE_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_REMOVE_GOOD_FAIL, e });
    }
}

function* updateInfos(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateInfos, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_UPDATE_INFOS_GOOD_FAIL : Constants.HOSTEL_UPDATE_INFOS_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_UPDATE_INFOS_GOOD_FAIL, e });
    }
}

function* updateCover(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateCover, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_UPDATE_COVER_GOOD_FAIL : Constants.HOSTEL_UPDATE_COVER_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_UPDATE_COVER_GOOD_FAIL, e });
    }
}

function* manageInfos(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.manageInfos, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_MANAGE_INFOS_GOOD_FAIL : Constants.HOSTEL_MANAGE_INFOS_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_MANAGE_INFOS_GOOD_FAIL, e });
    }
}

function* availabilitiesCreate(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.availabilitiesCreate, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_CREATE_AVAILABILITIES_GOOD_FAIL : Constants.HOSTEL_CREATE_AVAILABILITIES_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_CREATE_AVAILABILITIES_GOOD_FAIL, e });
    }
}

function* availabilityUpdate(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.availabilityUpdate, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.HOSTEL_UPDATE_AVAILABILITY_GOOD_FAIL : Constants.HOSTEL_UPDATE_AVAILABILITY_GOOD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.HOSTEL_UPDATE_AVAILABILITY_GOOD_FAIL, e });
    }
}

export const hostelSagas = [
    takeLatest(Constants.HOSTEL_LIST, list),
    takeLatest(Constants.HOSTEL_LIST_MODEL_GOOD, listModel),
    takeLatest(Constants.HOSTEL_CREATE_MODEL_GOOD, createModel),
    takeLatest(Constants.HOSTEL_CREATE_EMPTY_GOOD, createEmpty),
    takeLatest(Constants.HOSTEL_GET_GOOD, get),
    takeLatest(Constants.HOSTEL_PUBLISH_CHECK_GOOD, publishCheck),
    takeLatest(Constants.HOSTEL_PUBLISH_GOOD, publish),
    takeLatest(Constants.HOSTEL_REMOVE_GOOD, remove),
    takeLatest(Constants.HOSTEL_UPDATE_INFOS_GOOD, updateInfos),
    takeLatest(Constants.HOSTEL_UPDATE_COVER_GOOD, updateCover),
    takeLatest(Constants.HOSTEL_MANAGE_INFOS_GOOD, manageInfos),
    takeLatest(Constants.HOSTEL_CREATE_AVAILABILITIES_GOOD, availabilitiesCreate),
    takeLatest(Constants.HOSTEL_UPDATE_AVAILABILITY_GOOD, availabilityUpdate),

];
