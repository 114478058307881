import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import DashboardComponent from "../components/DashboardComponent";
import * as Actions from "../actions";
import { IDashboardResume } from "../reducers";

const mapStateToProps = (state: RootState) => {
    const resume: IDashboardResume = state.dashboardReducer.resume;

    return {
        resume,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        dashboardResume: () => {
            dispatch(Actions.dashboardResume());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxDashboard = ConnectedProps<typeof connector>;

export default connector(DashboardComponent);
