
import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IResponseStatus } from "../reducers";

function* get(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.get, apikey, action.id, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_GET_EVENT_FAIL : Constants.PARTY_GET_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.PARTY_GET_EVENT_FAIL, e });
    }
}

function* listModel(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listModel, apikey, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_LIST_MODEL_EVENT_FAIL : Constants.PARTY_LIST_MODEL_EVENT_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.PARTY_LIST_MODEL_EVENT_FAIL, e });
    }
}

function* listFinished(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listFinished, apikey, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_LIST_FINISHED_EVENT_FAIL : Constants.PARTY_LIST_FINISHED_EVENT_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.PARTY_LIST_FINISHED_EVENT_FAIL, e });
    }
}

function* listFuture(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listFuture, apikey, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_LIST_FUTURE_EVENT_FAIL : Constants.PARTY_LIST_FUTURE_EVENT_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.PARTY_LIST_FUTURE_EVENT_FAIL, e });
    }
}

function* createEmpty(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createEmpty, apikey, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_CREATE_EMPTY_EVENT_FAIL : Constants.PARTY_CREATE_EMPTY_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.id);
    } catch (e) {
        yield put({ type: Constants.PARTY_CREATE_EMPTY_EVENT_FAIL, e });
    }
}

function* createModel(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createModel, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_CREATE_MODEL_EVENT_FAIL : Constants.PARTY_CREATE_MODEL_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.id);
    } catch (e) {
        yield put({ type: Constants.PARTY_CREATE_MODEL_EVENT_FAIL, e });
    }
}

function* publishCheck(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.publishCheck, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_PUBLISH_CHECK_EVENT_FAIL : Constants.PARTY_PUBLISH_CHECK_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_PUBLISH_CHECK_EVENT_FAIL, e });
    }
}

function* publish(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.publish, apikey, action.id, action.scheduled_at);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_PUBLISH_EVENT_FAIL : Constants.PARTY_PUBLISH_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_PUBLISH_EVENT_FAIL, e });
    }
}

function* publishCancel(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.publishCancel, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_PUBLISH_CANCEL_EVENT_FAIL : Constants.PARTY_PUBLISH_CANCEL_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_PUBLISH_CANCEL_EVENT_FAIL, e });
    }
}

function* ticketsDownload(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.ticketsDownload, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_TICKETS_DOWNLOAD_FAIL : Constants.PARTY_TICKETS_DOWNLOAD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.PARTY_TICKETS_DOWNLOAD_FAIL, e });
    }
}

function* remove(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.remove, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_REMOVE_EVENT_FAIL : Constants.PARTY_REMOVE_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_REMOVE_EVENT_FAIL, e });
    }
}

function* updateInfos(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateInfos, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_UPDATE_INFOS_EVENT_FAIL : Constants.PARTY_UPDATE_INFOS_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_UPDATE_INFOS_EVENT_FAIL, e });
    }
}

function* updateCover(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateCover, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_UPDATE_COVER_EVENT_FAIL : Constants.PARTY_UPDATE_COVER_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_UPDATE_COVER_EVENT_FAIL, e });
    }
}

function* updateFlyer(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateFlyer, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_UPDATE_FLYER_EVENT_FAIL : Constants.PARTY_UPDATE_FLYER_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.url);
    } catch (e) {
        yield put({ type: Constants.PARTY_UPDATE_FLYER_EVENT_FAIL, e });
    }
}

function* saveTmpImage(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.saveTmpImage, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_SAVE_TMP_IMAGE_FAIL : Constants.PARTY_SAVE_TMP_IMAGE_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.url);
    } catch (e) {
        yield put({ type: Constants.PARTY_SAVE_TMP_IMAGE_FAIL, e });
    }
}

function* updateEntrances(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateEntrances, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_UPDATE_ENTRANCES_EVENT_FAIL : Constants.PARTY_UPDATE_ENTRANCES_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_UPDATE_ENTRANCES_EVENT_FAIL, e });
    }
}

function* manageInfos(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.manageInfos, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_MANAGE_INFOS_EVENT_FAIL : Constants.PARTY_MANAGE_INFOS_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_MANAGE_INFOS_EVENT_FAIL, e });
    }
}

function* manageEntrances(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.manageEntrances, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_MANAGE_ENTRANCES_EVENT_FAIL : Constants.PARTY_MANAGE_ENTRANCES_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_MANAGE_ENTRANCES_EVENT_FAIL, e });
    }
}

function* createEmptyPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createEmptyPrestation, apikey, action.event_id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_CREATE_EMPTY_PRESTATION_FAIL : Constants.PARTY_CREATE_EMPTY_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve({ status: response.status, id: response.data.id });
    } catch (e) {
        yield put({ type: Constants.PARTY_CREATE_EMPTY_PRESTATION_FAIL, e });
    }
}

function* getPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getPrestation, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_GET_PRESTATION_FAIL : Constants.PARTY_LIST_SCENARIO_PRESTATION,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve({ status: response.status, prestation: response.data });
    } catch (e) {
        yield put({ type: Constants.PARTY_GET_PRESTATION_FAIL, e });
    }
}

function* listModelPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listModelPrestation, apikey, action.subtype, action.category);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_LIST_MODEL_PRESTATION_FAIL : Constants.PARTY_LIST_MODEL_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.prestations);
    } catch (e) {
        yield put({ type: Constants.PARTY_LIST_MODEL_PRESTATION_FAIL, e });
    }
}

function* listScenarioPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.listScenarioPrestation, apikey);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_LIST_SCENARIO_PRESTATION_FAIL : Constants.PARTY_LIST_SCENARIO_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data.scenarios);
    } catch (e) {
        yield put({ type: Constants.PARTY_LIST_SCENARIO_PRESTATION_FAIL, e });
    }
}

function* createModelPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createModelPrestation, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_CREATE_MODEL_PRESTATION_FAIL : Constants.PARTY_CREATE_MODEL_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve({ status: response.status, id: response.data.id });
    } catch (e) {
        yield put({ type: Constants.PARTY_CREATE_MODEL_PRESTATION_FAIL, e });
    }
}

function* createScenarioPrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createScenarioPrestation, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_CREATE_SCENARIO_PRESTATION_FAIL : Constants.PARTY_CREATE_SCENARIO_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve({ status: response.status, id: response.data.id });
    } catch (e) {
        yield put({ type: Constants.PARTY_CREATE_SCENARIO_PRESTATION_FAIL, e });
    }
}

function* removePrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.removePrestation, apikey, action.id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_REMOVE_PRESTATION_FAIL : Constants.PARTY_REMOVE_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_REMOVE_PRESTATION_FAIL, e });
    }
}

function* updatePrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updatePrestation, apikey, action.prestationInfos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_UPDATE_PRESTATION_FAIL : Constants.PARTY_UPDATE_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_UPDATE_PRESTATION_FAIL, e });
    }
}

function* resumeDownload(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.resumeDownload, apikey, action.event_id);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_RESUME_DOWNLOAD_FAIL : Constants.PARTY_RESUME_DOWNLOAD_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.PARTY_RESUME_DOWNLOAD_FAIL, e });
    }
}

function* managePrestation(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.managePrestation, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.PARTY_MANAGE_PRESTATION_FAIL : Constants.PARTY_MANAGE_PRESTATION_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.PARTY_MANAGE_PRESTATION_FAIL, e });
    }
}

export const partySagas = [
    takeLatest(Constants.PARTY_GET_EVENT, get),
    takeLatest(Constants.PARTY_LIST_MODEL_EVENT, listModel),
    takeLatest(Constants.PARTY_LIST_FINISHED_EVENT, listFinished),
    takeLatest(Constants.PARTY_LIST_FUTURE_EVENT, listFuture),
    takeLatest(Constants.PARTY_CREATE_EMPTY_EVENT, createEmpty),
    takeLatest(Constants.PARTY_CREATE_MODEL_EVENT, createModel),
    takeLatest(Constants.PARTY_PUBLISH_CHECK_EVENT, publishCheck),
    takeLatest(Constants.PARTY_PUBLISH_EVENT, publish),
    takeLatest(Constants.PARTY_PUBLISH_CANCEL_EVENT, publishCancel),
    takeLatest(Constants.PARTY_TICKETS_DOWNLOAD, ticketsDownload),
    takeLatest(Constants.PARTY_REMOVE_EVENT, remove),
    takeLatest(Constants.PARTY_UPDATE_INFOS_EVENT, updateInfos),
    takeLatest(Constants.PARTY_UPDATE_COVER_EVENT, updateCover),
    takeLatest(Constants.PARTY_UPDATE_FLYER_EVENT, updateFlyer),
    takeLatest(Constants.PARTY_SAVE_TMP_IMAGE, saveTmpImage),
    takeLatest(Constants.PARTY_UPDATE_ENTRANCES_EVENT, updateEntrances),
    takeLatest(Constants.PARTY_MANAGE_INFOS_EVENT, manageInfos),
    takeLatest(Constants.PARTY_MANAGE_ENTRANCES_EVENT, manageEntrances),
    takeLatest(Constants.PARTY_CREATE_EMPTY_PRESTATION, createEmptyPrestation),
    takeLatest(Constants.PARTY_GET_PRESTATION, getPrestation),
    takeLatest(Constants.PARTY_LIST_MODEL_PRESTATION, listModelPrestation),
    takeLatest(Constants.PARTY_LIST_SCENARIO_PRESTATION, listScenarioPrestation),
    takeLatest(Constants.PARTY_CREATE_MODEL_PRESTATION, createModelPrestation),
    takeLatest(Constants.PARTY_CREATE_SCENARIO_PRESTATION, createScenarioPrestation),
    takeLatest(Constants.PARTY_REMOVE_PRESTATION, removePrestation),
    takeLatest(Constants.PARTY_UPDATE_PRESTATION, updatePrestation),
    takeLatest(Constants.PARTY_MANAGE_PRESTATION, managePrestation),
    takeLatest(Constants.PARTY_RESUME_DOWNLOAD, resumeDownload),
];
