import {Outlet, RouteObject} from "react-router-dom";
import * as ROUTES from './businessRecord'
import withPermissions from "../../../routes/withPermissions";
import BusinessRecordPage from "../pages/BusinessRecordPage";

export const BusinessRecordRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: ROUTES.BUSINESS_RECORD,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <BusinessRecordPage />}
            ]
        }
    ]
}