import { ChangeEvent, useState } from "react";
import { Row, Col, Card, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { ClipboardPlus, PlusCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PropsFromRedux } from "../containers/HostelNewContainer";

const HostelNewComponent = (props: PropsFromRedux) => {
    const navigate = useNavigate()

    const [choice, setChoice] = useState('');

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChoice(e.currentTarget.value)
    };

    const handleNext = async () => {
        if (choice === "EMPTY") {            
            props.createEmpty()
            .then((eventId: number) => {            
                navigate('/hostel/create/'+eventId);
                return
            })
            .catch(error => { toast.error(error.message.fr) })            
        } else {
            navigate('/hostel/models');
        }
    };

    return (
        <Row className="mt-2 g-2">
            <Col className="col-12">
                <Card className="blocCard">
                    <Card.Header className="d-flex align-items-center mb-2">
                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                            <div className="w-100 fw-bolder">Créer une chambre</div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Choisissez la base de départ de votre chambre.</Card.Text>
                
                        <Row className="my-4">
                            <ButtonGroup className="w-100 row row-inputs-event">
                                <div className="col-12 col-md-4 offset-md-2 p-2">
                                    <ToggleButton
                                        type="radio"
                                        variant="secondary"
                                        value="EMPTY"
                                        id="EMPTY"
                                        name="radio"
                                        onChange={(e) => handleChange(e)}
                                        checked={choice === "EMPTY"}
                                        className="d-flex flex-column iconButton"
                                    >
                                        <PlusCircle size={32} className="flex-grow-1" />
                                        <div className="flex-grow-1" style={{ marginTop: '10px' }}>Vierge</div>
                                    </ToggleButton>
                                </div>

                                <div className="col-12 col-md-4 p-2">
                                    <ToggleButton
                                        type="radio"
                                        variant="secondary"
                                        value="MODELS"
                                        id="MODELS"
                                        name="radio"
                                        onChange={(e) => handleChange(e)}
                                        checked={choice === "MODELS"}
                                        className="d-flex flex-column iconButton"
                                    >
                                        <ClipboardPlus size={32} className="flex-grow-1" />
                                        <div className="flex-grow-1" style={{ marginTop: '10px' }}>À partir d'une précédente</div>
                                    </ToggleButton>
                                </div>
                            </ButtonGroup>
                        </Row>
                        <Row className="mx-0 p-0 d-md-block">
                            <Col className="d-flex justify-content-end p-0 ">
                                <Button  variant="success" disabled={!choice} onClick={handleNext} style={{minWidth: 150}}>
                                    Suivant
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row >
    );
};

export default HostelNewComponent;
