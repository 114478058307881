import { Alert, Card, Col, Row, Button, Image } from 'react-bootstrap'
import { Gear, Trash } from 'react-bootstrap-icons'
import { INetwork } from '../reducers'

interface Props {
    network: INetwork
    onEdit: () => void
    onDelete: () => void
}

const NetworkCard = (props: Props) => {

    const styleImg = {
        width: '60px',
        height: '60px',
        display: 'flex',
    };

    const styleAlert = {
        padding: '2px 5px',
        width: '120px',
        marginBottom: '5px'
    };

    return (
        <>
            <Card className='mt-4 networkCard border'>
                <div>
                    {props.network.status !== 'CONNECTED' ?
                        <Button onClick={() => props.onEdit()} className='overlayCardButton'>
                            <Gear />
                        </Button>
                        :
                        <Button onClick={() => props.onDelete()} variant='danger' className='overlayCardButton'>
                            <Trash />
                        </Button>
                    }
                    <Card.Body className='mt-3'>
                        <Row>
                            <Col xs={4}>
                                {props.network.type_icon ?
                                    <Image src={props.network.type_icon} style={styleImg} />
                                    :
                                    <></>
                                }
                            </Col>
                            {props.network.status !== 'CONNECTED' ?
                                <Col xs={8}>
                                    <Alert variant='danger' className='justify-content-center d-flex' style={styleAlert}>Erreur</Alert>
                                    <p style={{color: "#842029", fontWeight: "bold"}}>{props.network.error}</p>
                                </Col>
                                :
                                <Col xs={8}>
                                    <Alert variant='success' className='justify-content-center d-flex' style={styleAlert}>Connecté</Alert>
                                    <p>{props.network.login}</p>
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                </div>
            </Card>
        </>
    )
}

export default NetworkCard