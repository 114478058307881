import { API_URL } from "../constants";
import { ISupport, IUserCredential, IUserPassword } from "../reducers";

export const registerUserService = (request:IUserCredential) => {
    const ENDPOINT:RequestInfo = `${API_URL}/subscribe`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};

export const loginUserService = (request:IUserCredential) => {
    const ENDPOINT:RequestInfo = `${API_URL}/login`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};

export const logoutUserService = (apiKey:string) => {
    const ENDPOINT:RequestInfo = `${API_URL}/logout`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": apiKey
      },
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};
  
export const forgotPasswordService = (email:string) => {
    const ENDPOINT:RequestInfo = `${API_URL}/password/forgot`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({email}),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};
  
export const recoverPasswordService = (info:any) => {
    const ENDPOINT:RequestInfo = `${API_URL}/password/recover`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(info),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};

export const changePasswordService = (apiKey:string, passwordData:IUserPassword) => {
    const ENDPOINT:RequestInfo = `${API_URL}/password/change`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": apiKey
      },
      body: JSON.stringify(passwordData),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};

export const supportService = (apiKey:string, supportData:ISupport) => {
    const ENDPOINT:RequestInfo = `${API_URL}/support`;
    
    const parameters:RequestInit = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": apiKey
      },
      body: JSON.stringify(supportData),
      mode:'cors'
    };
  
    return fetch(ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      }).catch(e => {
        console.error(e)
    });
};
