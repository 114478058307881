import { IGoodAvailabilitiesRequest, IGoodAvailabilityRequest, IManageInfosRequest, IPrestationModelRequest } from '../reducers';
import * as Constants from '../constants'
import { IGoodInfos, IGoodImage } from '../reducers';


export function list() {
    return {
        type: Constants.HOSTEL_LIST
    };
}

export function listModel() {
    return {
        type: Constants.HOSTEL_LIST_MODEL_GOOD
    };
}

export function createModel(id: number, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_CREATE_MODEL_GOOD,
        id,
        resolve,
        reject,
    };
}

export function createEmpty(resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_CREATE_EMPTY_GOOD,
        resolve,
        reject,
    };
}

export function get(id: number, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_GET_GOOD,
        id,
        resolve,
        reject,
    };
}

export function publishCheck(id: number, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_PUBLISH_CHECK_GOOD,
        id,
        resolve,
        reject,
    };
}

export function publish(id: number, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_PUBLISH_GOOD,
        id,
        resolve,
        reject,
    };
}

export function remove(id: number, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_REMOVE_GOOD,
        id,
        resolve,
        reject,
    };
}

export function updateInfos(infos: IGoodInfos, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_UPDATE_INFOS_GOOD,
        infos,
        resolve,
        reject,
    };
}

export function updateCover(infos: IGoodImage, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_UPDATE_COVER_GOOD,
        infos,
        resolve,
        reject,
    };
}

export function manageInfos(infos: IManageInfosRequest, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_MANAGE_INFOS_GOOD,
        infos,
        resolve,
        reject,
    };
}

export function availabilitiesCreate(infos: IGoodAvailabilitiesRequest, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_CREATE_AVAILABILITIES_GOOD,
        infos,
        resolve,
        reject,
    };
}

export function availabilityUpdate(infos: IGoodAvailabilityRequest, resolve: any, reject: any) {
    return {
        type: Constants.HOSTEL_UPDATE_AVAILABILITY_GOOD,
        infos,
        resolve,
        reject,
    };
}
