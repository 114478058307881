import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { FileEarmarkPdf, GearFill } from 'react-bootstrap-icons'
import TicketCard from './common/TicketCard';
import { PropsFromReduxRestaurantResume } from '../containers/RestaurantResumeContainer';
import { Key, useEffect, useState } from 'react';
import { IService, ITicket } from '../reducers';
import toast from 'react-hot-toast';

const RestaurantResumeComponent = (props: PropsFromReduxRestaurantResume) => {
    
    const [showModalQuantity, setShowModalQuantity] = useState(false);
    const [showModalInternInfo, setShowModalInternInfo] = useState(false);
    const [service, setService] = useState<IService | undefined>()
    
    const [quantityNew, setQuantityNew] = useState<number>(0)
    
    const [currentTicket, setCurrentTicket] = useState<ITicket | undefined>()
    const [internInfo, setInternInfo] = useState<string>("")

    const location = useLocation();
    const navigate = useNavigate();
    const occurenceId = parseInt(location.pathname.split("/")[3]);
    
    useEffect(() => {
        loadService()
    }, [])

    const loadService = () => {
        props.getService(occurenceId)
        .then((s: IService) => {
            setService(s);
            setQuantityNew(s.quantity_available)
        })
        .catch(() => navigate('/restaurant'))
    }

    const handleChangeQuantityNew = () => {
        if(service && service.occurence_id) {
            props.manageService(service.occurence_id, quantityNew)
            .then((data: any) => {            
                toast.success("Quantité réservables mise à jour.")
                loadService()
                setShowModalQuantity(false)
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    }

    const startModalInterInfo = (ticket: ITicket) => {
        setCurrentTicket(ticket)
        setInternInfo(ticket.intern_info)
        setShowModalInternInfo(true)
    }

    const handleChangeInternInfo = () => {
        if(currentTicket && currentTicket.id) {
            props.manageTableService(currentTicket.id, internInfo)
            .then((data: any) => {            
                toast.success("Ticket mis à jour.")
                loadService()
                setCurrentTicket(undefined)
                setInternInfo("")
                setShowModalInternInfo(false)
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    }
    
    const download = () => {
        if(service && service.occurence_id) {
            props.downloadService(service.occurence_id)
            .then((data: any) => {            
                window.open(data.pdf, '_blank', 'noopener,noreferrer');
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    }

    return (
        <>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    {
                        service &&
                        <Card>
                            <Card.Header className="d-flex align-items-center">
                                <Card.Title className="d-flex w-100 align-items-center pt-2">
                                    <div className="w-100 fw-bolder">Service du {service.name}</div>
                                    <div className="align-items-right">
                                        <Button className="topButton" variant="outline-secondary" onClick={() => download()}>
                                        <FileEarmarkPdf />&nbsp;&nbsp;<span className="d-none d-none d-md-inline-block">Récapitulatif</span>
                                        </Button>
                                    </div>
                                </Card.Title>
                            </Card.Header>
                        </Card>
                    }
                </Col>
            </Row>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    {
                        service &&
                        <Card className='blocCard'>
                            <Card.Header className="d-flex align-items-center mb-2">
                                <Card.Title className="d-flex w-100 align-items-center pt-2">
                                    <div className="w-100 fw-bolder">Résumé</div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={4} className='justify-content-center align-items-center mt-2 mb-2'>
                                        <Card.Title className='d-flex justify-content-center align-items-center ms-2 mb-0'>Couverts réservés</Card.Title>
                                        <Card.Text className='d-flex justify-content-center align-items-center mt-2 text-result'>{(service.quantity - service.quantity_available)}</Card.Text>
                                    </Col>
                                    <Col xs={4} className=' justify-content-center align-items-center mt-2 mb-2'>
                                        <Card.Title className='d-flex justify-content-center align-items-center ms-2 mb-0'>Restant réservables</Card.Title>
                                        <Card.Text className='d-flex justify-content-center align-items-center mt-2 text-result'>
                                            {service.quantity_available}
                                            {service.status === 'PUBLISHED' &&
                                                <Button onClick={() => setShowModalQuantity(true)} variant='outline-secondary' style={{ fontSize: 20, padding: 5, paddingRight: 10, paddingLeft: 10 }} className='ms-3'><GearFill /></Button>
                                            }
                                        </Card.Text>
                                    </Col>
                                    <Col xs={4} className='justify-content-center align-items-center mt-2 mb-2'>
                                        <Card.Title className='d-flex justify-content-center align-items-center ms-2 mb-0'>Montant encaissé €</Card.Title>
                                        <Card.Text className='d-flex justify-content-center align-items-center mt-2 text-result'>{service.ca_ttc}</Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    {
                        service &&
                        <Card className='blocCard'>
                            <Card.Header className="d-flex align-items-center mb-2">
                                <Card.Title className="d-flex w-100 align-items-center pt-2">
                                    <div className="w-100 fw-bolder">Tables</div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    {service.tickets && service.tickets.length > 0 && service.tickets.map((ticket: ITicket, index: Key) => {
                                        return <Col xs={6} key={index}><TicketCard ticket={ticket} status={service.status} startModalInterInfo={startModalInterInfo} /></Col>
                                    })}
                                    {service.tickets && service.tickets.length === 0 && <p className='mt-3'>Aucune table réservée.</p>}
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
            <Modal
                size="lg"
                show={showModalQuantity}
                onHide={() => { setShowModalQuantity(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Restant réservables</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div>Vous pouvez modifier le nombre de couverts restants réservables à la baisse ou à la hausse.<br />Les couverts en train d'être réservés (panier client de 15 minutes non expiré) ne sont pas décomptés.</div>
                    <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Nouvelle quantité</Form.Label>
                    <Form.Control type="number" value={quantityNew} onChange={(e) => { setQuantityNew(Number(e.target.value)) }} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalQuantity(false)}>Annuler</Button>
                    </Col>
                    <Col className="text-end">
                        <Button variant='primary' className='btn-modal-device me-1' onClick={handleChangeQuantityNew}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                show={showModalInternInfo}
                onHide={() => { setShowModalInternInfo(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Numéro de table</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div>Vous pouvez définir un numéro de table pour y place plus facilement vos clients.</div>
                    <div className='mt-2'>Nombre de personnes à cette table: <b>{(currentTicket ? currentTicket.quantity : '')}</b></div>
                    <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Numéro</Form.Label>
                    <Form.Control type="string" value={internInfo} onChange={(e) => { setInternInfo(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalInternInfo(false)}>Annuler</Button>
                    </Col>
                    <Col className="text-end">
                        <Button variant='primary' className='btn-modal-device me-1' onClick={handleChangeInternInfo}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RestaurantResumeComponent