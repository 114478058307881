import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_EVENTS = CORE_URL + "/events";
export const API_URL_PRESTATIONS = CORE_URL + "/prestations";

// ------------------------------  EVENT  ------------------------------

export const POOL_GET_EVENT = "POOL_GET_EVENT";
export const POOL_GET_EVENT_SUCCESS = "POOL_GET_EVENT_SUCCESS";
export const POOL_GET_EVENT_FAIL = "POOL_GET_EVENT_FAIL";

export const POOL_LIST_FINISHED_EVENT = "POOL_LIST_FINISHED_EVENT";
export const POOL_LIST_FINISHED_EVENT_SUCCESS = "POOL_LIST_FINISHED_EVENT_SUCCESS";
export const POOL_LIST_FINISHED_EVENT_FAIL = "POOL_LIST_FINISHED_EVENT_FAIL";

export const POOL_LIST_FUTURE_EVENT = "POOL_LIST_FUTURE_EVENT";
export const POOL_LIST_FUTURE_EVENT_SUCCESS = "POOL_LIST_FUTURE_EVENT_SUCCESS";
export const POOL_LIST_FUTURE_EVENT_FAIL = "POOL_LIST_FUTURE_EVENT_FAIL";

export const POOL_CREATE_EMPTY_EVENT = "POOL_CREATE_EMPTY_EVENT";
export const POOL_CREATE_EMPTY_EVENT_SUCCESS = "POOL_CREATE_EMPTY_EVENT_SUCCESS";
export const POOL_CREATE_EMPTY_EVENT_FAIL = "POOL_CREATE_EMPTY_EVENT_FAIL";

export const POOL_CREATE_MODEL_EVENT = "POOL_CREATE_MODEL_EVENT";
export const POOL_CREATE_MODEL_EVENT_SUCCESS = "POOL_CREATE_MODEL_EVENT_SUCCESS";
export const POOL_CREATE_MODEL_EVENT_FAIL = "POOL_CREATE_MODEL_EVENT_FAIL";

export const POOL_UPDATE_INFOS_EVENT = "POOL_UPDATE_INFOS_EVENT";
export const POOL_UPDATE_INFOS_EVENT_SUCCESS = "POOL_UPDATE_INFOS_EVENT_SUCCESS";
export const POOL_UPDATE_INFOS_EVENT_FAIL = "POOL_UPDATE_INFOS_EVENT_FAIL";

export const POOL_UPDATE_COVER_EVENT = "POOL_UPDATE_COVER_EVENT";
export const POOL_UPDATE_COVER_EVENT_SUCCESS = "POOL_UPDATE_COVER_EVENT_SUCCESS";
export const POOL_UPDATE_COVER_EVENT_FAIL = "POOL_UPDATE_COVER_EVENT_FAIL";

export const POOL_UPDATE_FLYER_EVENT = "POOL_UPDATE_FLYER_EVENT";
export const POOL_UPDATE_FLYER_EVENT_SUCCESS = "POOL_UPDATE_FLYER_EVENT_SUCCESS";
export const POOL_UPDATE_FLYER_EVENT_FAIL = "POOL_UPDATE_FLYER_EVENT_FAIL";

export const POOL_SAVE_TMP_IMAGE = "POOL_SAVE_TMP_IMAGE";
export const POOL_SAVE_TMP_IMAGE_SUCCESS = "POOL_SAVE_TMP_IMAGE_SUCCESS";
export const POOL_SAVE_TMP_IMAGE_FAIL = "POOL_SAVE_TMP_IMAGE_FAIL";

export const POOL_UPDATE_ENTRANCES_EVENT = "POOL_UPDATE_ENTRANCES_EVENT";
export const POOL_UPDATE_ENTRANCES_EVENT_SUCCESS = "POOL_UPDATE_ENTRANCES_EVENT_SUCCESS";
export const POOL_UPDATE_ENTRANCES_EVENT_FAIL = "POOL_UPDATE_ENTRANCES_EVENT_FAIL";

export const POOL_LIST_MODEL_EVENT = "POOL_LIST_MODEL_EVENT";
export const POOL_LIST_MODEL_EVENT_SUCCESS = "POOL_LIST_MODEL_EVENT_SUCCESS";
export const POOL_LIST_MODEL_EVENT_FAIL = "POOL_LIST_MODEL_EVENT_FAIL";

export const POOL_PUBLISH_CHECK_EVENT = "POOL_PUBLISH_CHECK_EVENT";
export const POOL_PUBLISH_CHECK_EVENT_SUCCESS = "POOL_PUBLISH_CHECK_EVENT_SUCCESS";
export const POOL_PUBLISH_CHECK_EVENT_FAIL = "POOL_PUBLISH_CHECK_EVENT_FAIL";

export const POOL_PUBLISH_EVENT = "POOL_PUBLISH_EVENT";
export const POOL_PUBLISH_EVENT_SUCCESS = "POOL_PUBLISH_EVENT_SUCCESS";
export const POOL_PUBLISH_EVENT_FAIL = "POOL_PUBLISH_EVENT_FAIL";

export const POOL_PUBLISH_CANCEL_EVENT = "POOL_PUBLISH_CANCEL_EVENT";
export const POOL_PUBLISH_CANCEL_EVENT_SUCCESS = "POOL_PUBLISH_CANCEL_EVENT_SUCCESS";
export const POOL_PUBLISH_CANCEL_EVENT_FAIL = "POOL_PUBLISH_CANCEL_EVENT_FAIL";

export const POOL_TICKETS_DOWNLOAD = "POOL_TICKETS_DOWNLOAD";
export const POOL_TICKETS_DOWNLOAD_SUCCESS = "POOL_TICKETS_DOWNLOAD_SUCCESS";
export const POOL_TICKETS_DOWNLOAD_FAIL = "POOL_TICKETS_DOWNLOAD_FAIL";

export const POOL_REMOVE_EVENT = "POOL_REMOVE_EVENT";
export const POOL_REMOVE_EVENT_SUCCESS = "POOL_REMOVE_EVENT_SUCCESS";
export const POOL_REMOVE_EVENT_FAIL = "POOL_REMOVE_EVENT_FAIL";

export const POOL_MANAGE_ENTRANCES_EVENT = "POOL_MANAGE_ENTRANCES_EVENT";
export const POOL_MANAGE_ENTRANCES_EVENT_SUCCESS = "POOL_MANAGE_ENTRANCES_EVENT_SUCCESS";
export const POOL_MANAGE_ENTRANCES_EVENT_FAIL = "POOL_MANAGE_ENTRANCES_EVENT_FAIL";

export const POOL_RESUME_DOWNLOAD = "POOL_RESUME_DOWNLOAD";
export const POOL_RESUME_DOWNLOAD_SUCCESS = "POOL_RESUME_DOWNLOAD_SUCCESS";
export const POOL_RESUME_DOWNLOAD_FAIL = "POOL_RESUME_DOWNLOAD_FAIL";

export const POOL_MANAGE_INFOS_EVENT = "POOL_MANAGE_INFOS_EVENT";
export const POOL_MANAGE_INFOS_EVENT_SUCCESS = "POOL_MANAGE_INFOS_EVENT_SUCCESS";
export const POOL_MANAGE_INFOS_EVENT_FAIL = "POOL_MANAGE_INFOS_EVENT_FAIL";

// ------------------------------  PRESTATION  ------------------------------

export const POOL_CREATE_EMPTY_PRESTATION = "POOL_CREATE_EMPTY_PRESTATION";
export const POOL_CREATE_EMPTY_PRESTATION_SUCCESS = "POOL_CREATE_EMPTY_PRESTATION_SUCCESS";
export const POOL_CREATE_EMPTY_PRESTATION_FAIL = "POOL_CREATE_EMPTY_PRESTATION_FAIL";

export const POOL_GET_PRESTATION = "POOL_GET_PRESTATION";
export const POOL_GET_PRESTATION_SUCCESS = "POOL_GET_PRESTATION_SUCCESS";
export const POOL_GET_PRESTATION_FAIL = "POOL_GET_PRESTATION_FAIL";

export const POOL_REMOVE_PRESTATION = "POOL_REMOVE_PRESTATION";
export const POOL_REMOVE_PRESTATION_SUCCESS = "POOL_REMOVE_PRESTATION_SUCCESS";
export const POOL_REMOVE_PRESTATION_FAIL = "POOL_REMOVE_PRESTATION_FAIL";

export const POOL_UPDATE_PRESTATION = "POOL_UPDATE_PRESTATION";
export const POOL_UPDATE_PRESTATION_SUCCESS = "POOL_UPDATE_PRESTATION_SUCCESS";
export const POOL_UPDATE_PRESTATION_FAIL = "POOL_UPDATE_PRESTATION_FAIL";

export const POOL_LIST_MODEL_PRESTATION = "POOL_LIST_MODEL_PRESTATION";
export const POOL_LIST_MODEL_PRESTATION_SUCCESS = "POOL_LIST_MODEL_PRESTATION_SUCCESS";
export const POOL_LIST_MODEL_PRESTATION_FAIL = "POOL_LIST_MODEL_PRESTATION_FAIL";

export const POOL_LIST_SCENARIO_PRESTATION = "POOL_LIST_SCENARIO_PRESTATION";
export const POOL_LIST_SCENARIO_PRESTATION_SUCCESS = "POOL_LIST_SCENARIO_PRESTATION_SUCCESS";
export const POOL_LIST_SCENARIO_PRESTATION_FAIL = "POOL_LIST_SCENARIO_PRESTATION_FAIL";

export const POOL_CREATE_MODEL_PRESTATION = "POOL_CREATE_MODEL_PRESTATION";
export const POOL_CREATE_MODEL_PRESTATION_SUCCESS = "POOL_CREATE_MODEL_PRESTATION_SUCCESS";
export const POOL_CREATE_MODEL_PRESTATION_FAIL = "POOL_CREATE_MODEL_PRESTATION_FAIL";

export const POOL_CREATE_SCENARIO_PRESTATION = "POOL_CREATE_SCENARIO_PRESTATION";
export const POOL_CREATE_SCENARIO_PRESTATION_SUCCESS = "POOL_CREATE_SCENARIO_PRESTATION_SUCCESS";
export const POOL_CREATE_SCENARIO_PRESTATION_FAIL = "POOL_CREATE_SCENARIO_PRESTATION_FAIL";

export const POOL_MANAGE_PRESTATION = "POOL_MANAGE_PRESTATION";
export const POOL_MANAGE_PRESTATION_SUCCESS = "POOL_MANAGE_PRESTATION_SUCCESS";
export const POOL_MANAGE_PRESTATION_FAIL = "POOL_MANAGE_PRESTATION_FAIL";
