import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import HostelComponent from "../components/HostelComponent";

const mapStateToProps = (state: RootState) => {
    return {
        goods: state.hostelReducer.goods,
        eventsFuture: state.hostelReducer.eventsFuture,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        list: () => {
            dispatch(Actions.list());
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HostelComponent);