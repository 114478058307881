import { Outlet } from "react-router-dom"
import HostelContainer from "../containers/HostelContainer"

const HostelPage = () => {
    return <>
        <HostelContainer />
        <Outlet />
    </>
}

export default HostelPage