import React from "react";
import {Card, Col, Button, Row} from "react-bootstrap";
import {Pencil} from "react-bootstrap-icons";
import { formatDatetime } from "../../../../utils/formattage";
import { IGoodAvailability, IGoodTicket } from "../../reducers";

interface GoodTicketCardProps {
    ticket: IGoodTicket;
}

const GoodTicketCard = (props: GoodTicketCardProps) => {
    return (
        <>
            {props.ticket &&
                <div>
                    <Card className={ props.ticket.scanned_at ? 'col-12 mt-2 ticketCard-disabled border' : 'col-12 mt-2 ticketCard ticketCard border'}>
                        <Card.Header className='d-flex'>
                            <Card.Text className='d-flex w-100 fw-bolder justify-content-start align-items-center mb-0'>
                                N° {props.ticket.code ? props.ticket.code : '--'}
                            </Card.Text>
                            <Card.Text className='d-flex w-100 fw-bolder justify-content-end align-items-center' > Qty: {props.ticket.quantity}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text style={{marginBottom: 5, fontWeight: "bold"}}>{props.ticket.label}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Référence: {props.ticket.reference}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Arrivée: {props.ticket.start}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Départ: {props.ticket.end}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Montant payé: {props.ticket.total_format}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Email: {props.ticket.user_email}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Téléphone: {props.ticket.user_phone}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Participants: {(props.ticket.peoples ? props.ticket.peoples.join(', ') : '')}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>Commentaire: {(props.ticket.user_requests ? props.ticket.user_requests : '')}</Card.Text>
                            {
                                props.ticket.scanned_at && 
                                <Card.Text style={{marginBottom: 5, fontWeight: "bold"}}>Scanné le {formatDatetime(props.ticket.scanned_at)}</Card.Text>
                            }
                        </Card.Body>
                    </Card>
                </div>
            }
        </>
    )
};

export default GoodTicketCard;