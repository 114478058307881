import { AnyAction } from "redux";
import { produce } from "immer";

export interface IPost {
    event_id: number;
    id: number;
    link: string;
    network_id: number;
    status: "SCHEDULED" | "IN_PROGRESS" | "FAILED" | "POSTED";
    scheduled_at: string;
    network?: {
        icon: string,
        id: number,
        login: string,
        type: string
        name?: string,
    }
}

export interface IInfosPost {
    name: string,
    img_flyer: string,
    img_cover: string,
    started: string,
    ended: string,
    description_html: string,
    description_text: string
    id: number;
    status: "PUBLISHED" | "DRAFT" | "FINISHED";
    type: string
    user_id: number
}

export interface IManualPostRequest {
    link: string;
    id: number;
}

export interface IAutoPostRequest {
    id: number;
    network: number;
    delay: 'NOW' | 'PLANNED';
    planned_at?: string;
    url?: string;
}

export interface IPostNewsRequest {
    id: number;
    type: string
    network_id?: number;
    text: string
    delay: 'NOW' | 'PLANNED';
    planned_at?: string;
}

export interface IResponseStatus {
    data: {
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

const initState = {}

const postsReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        default:
            return draft;
    }
})

export default postsReducer