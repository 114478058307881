import React, { ChangeEvent, Key, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Modal, Row, ToggleButton } from "react-bootstrap";
import { ClipboardPlus, EmojiWinkFill, PlusCircle } from "react-bootstrap-icons";
import PrestationTemplateCard from "./PrestationTemplateCard";
import toast from "react-hot-toast";
import { IPrestationModel } from '../../reducers';
import { IPrestationModelRequest } from "../../reducers";

interface AddPrestationComponentProps {
    show: boolean;
    handleHide: () => void;
    handleSave: (id: number) => void;
    
    eventId: number;

    createEmptyPrestation: (event_id: number) => Promise<{ status: string; id: number; }>;
    createModelPrestation: (infos: IPrestationModelRequest) => Promise<{ status: string; id: number; }>;
    createScenarioPrestation: (infos: IPrestationModelRequest) => Promise<{ status: string; id: number; }>;

    listModelPrestation: (subtype: string, category: string) => Promise<IPrestationModel[]>;
    listScenarioPrestation: () => Promise<IPrestationModel[]>;
}

interface ProcessStepProps {
    next: (jump: number) => void;
    previous?: (jump: number) => void;
}

const AddPrestationComponent = (props: AddPrestationComponentProps) => {

    const [step, setStep] = useState(1);
    const [step1, setStep1] = useState("");
    
    const [prestationId, setPrestationId] = useState<number>(0);
    const [list, setList] = useState<IPrestationModel[]>();

    //eslint-disable-next-line
    const [templateId, setTemplateId] = useState<number | undefined>();

    useEffect(() => {
        if (step === 3) {
            setStep(1);
            props.handleSave(prestationId);
        }
        //eslint-disable-next-line
    }, [step]);

    const displayStep = () => {
        switch (step) {
            case 1:
                return (
                    <ChooseBasePrestation
                        radioValue={(value: string) => setStep1(value)}
                        next={(jump: number) => setStep(step + jump)}
                        createEmptyPrestation={props.createEmptyPrestation}
                        handleHide={props.handleHide}
                        eventId={props.eventId}
                        setPrestationId={setPrestationId}
                        setList={setList}
                        listModelPrestation={props.listModelPrestation}
                        listScenarioPrestation={props.listScenarioPrestation}
                    />
                );
            case 2:
                return (
                    <CreateFrom
                        templateSource={step1}
                        selectedTemplate={(id: number | undefined) => setTemplateId(id)}
                        previous={(jump: number) => setStep(step - jump)}
                        next={(jump: number) => setStep(step + jump)}
                        eventId={props.eventId}
                        setPrestationId={setPrestationId}
                        list={list}
                        createModelPrestation={props.createModelPrestation}
                        createScenarioPrestation={props.createScenarioPrestation}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal show={props.show} onHide={props.handleHide} size="lg" backdrop="static" className="">
            <Modal.Header>
                <Modal.Title>Ajouter une prestation</Modal.Title>
            </Modal.Header>
            {displayStep()}
        </Modal>
    );
};

const ChooseBasePrestation = (
    props: ProcessStepProps & { radioValue: (value: string) => void } 
    & { handleHide: () => void }
    & { createEmptyPrestation: (event_id: number) => Promise<{ status: string; id: number }>; } 
    & { eventId: number } 
    & { setPrestationId: React.Dispatch<React.SetStateAction<number>> } 
    & { setList: React.Dispatch<React.SetStateAction<IPrestationModel[] | undefined>> } 
    & { listModelPrestation: (subtype: string, category: string) => Promise<IPrestationModel[]> } 
    & { listScenarioPrestation: () => Promise<IPrestationModel[]> }
) => {
    const [radioValue, setRadioValue] = useState("");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRadioValue(e.currentTarget.value);
        props.radioValue(e.currentTarget.value);
    };

    const handleNext = () => {
        if (!radioValue) return;

        const jump = radioValue === "EMPTY" ? 2 : 1;

        if (radioValue === "EMPTY") {
            props.createEmptyPrestation(props.eventId)
            .then(({ status, id }: { status: string; id: number }) => {
                props.setPrestationId(id);
                props.radioValue(radioValue);
                props.next(jump);
            })
            .catch(error => toast.error(error.message.fr))
        } 
        
        else if (radioValue === "MODELS") {

            props.listModelPrestation("UNIQUE", "PARTY")
            .then((prestations: IPrestationModel[]) => {
                props.setList(prestations)
                props.radioValue(radioValue);
                props.next(jump);
            })
            .catch(error => toast.error(error.message.fr))
        } 
        
        else if (radioValue === "SCENARIOS") {

            props.listScenarioPrestation()
            .then((prestations: IPrestationModel[]) => {
                props.setList(prestations)
                props.radioValue(radioValue);
                props.next(jump);
            })
            .catch(error => toast.error(error.message.fr))

        }
    };

    return (
        <>        
        <Modal.Body className="align--self-center w-100">
            <Card.Text style={{marginLeft: 20, marginTop: 10}}>Choisissez la base de départ de votre prestation.</Card.Text>
            <div className="d-flex justify-content-around w-100 my-4">
                <ButtonGroup className="w-100 row row-inputs-event">
                    <div className="col-12 col-md-4 p-2">
                        <ToggleButton
                            type="radio"
                            variant="secondary"
                            value="EMPTY"
                            id="EMPTY"
                            name="radio"
                            onChange={(e) => handleChange(e)}
                            checked={radioValue === "EMPTY"}
                            className="d-flex flex-column iconButton"
                        >
                            <PlusCircle size={32} className="flex-grow-1" />
                            <div className="flex-grow-1" style={{marginTop: '10px'}}>Vierge</div>
                        </ToggleButton>
                    </div>

                    <div className="col-12 col-md-4 p-2">
                        <ToggleButton
                            type="radio"
                            variant="secondary"
                            value="MODELS"
                            id="MODELS"
                            name="radio"
                            onChange={(e) => handleChange(e)}
                            checked={radioValue === "MODELS"}
                            className="d-flex flex-column iconButton col-12 col-md-4"
                        >
                            <ClipboardPlus size={32} className="flex-grow-1" />
                            <div className="flex-grow-1" style={{marginTop: '10px'}}>À partir d'un précédent</div>
                        </ToggleButton>
                    </div>

                    <div className="col-12 col-md-4 p-2">
                        <ToggleButton
                            type="radio"
                            variant="secondary"
                            value="SCENARIOS"
                            id="SCENARIOS"
                            name="radio"
                            onChange={(e) => handleChange(e)}
                            checked={radioValue === "SCENARIOS"}
                            className="d-flex flex-column iconButton col-12 col-md-4"
                        >
                            <EmojiWinkFill size={32} className="flex-grow-1" />
                            <div className="flex-grow-1" style={{marginTop: '10px'}}>Conseils Lib-Events</div>
                        </ToggleButton>
                    </div>
                </ButtonGroup>
            </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center w-100">
            <Col className="text-start">
                <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => props.handleHide()}>Retour</Button>
            </Col>
            <Col className="text-end">
                <Button className="fw-bolder" variant='primary' disabled={!radioValue} onClick={() => handleNext()}>Suivant</Button>
            </Col>
        </Modal.Footer>
        </>
    );
};

const CreateFrom = (
    props: ProcessStepProps 
    & { templateSource: string; selectedTemplate: (id: number | undefined) => void }
    & { eventId: number } 
    & { setPrestationId: React.Dispatch<React.SetStateAction<number>> } 
    & { list: IPrestationModel[] | undefined }
    & { createModelPrestation: (infos: IPrestationModelRequest) => Promise<{ status: string; id: number; }> }
    & { createScenarioPrestation: (infos: IPrestationModelRequest) => Promise<{ status: string; id: number; }> } 
) => {

    const [selectedId, setSelectedId] = useState(-1);

    useEffect(() => {
        props.selectedTemplate(selectedId);

        //eslint-disable-next-line
    }, [props.selectedTemplate]);

    const handleNext = () => {

        if (!selectedId) return;

        props.selectedTemplate(selectedId);
        if (props.templateSource === "MODELS") {

            props.createModelPrestation({ event_id: props.eventId, prestation_id: selectedId })
            .then(({ status, id }: { status: string, id: number }) => {
                props.setPrestationId(id);
                props.next(1);
            })
            .catch((error: any) => toast.error(error.message.fr))

        } 
        else if (props.templateSource === "SCENARIOS") { 

            props.createScenarioPrestation({ event_id: props.eventId, prestation_id: selectedId })
            .then(({ status, id }: { status: string, id: number }) => {
                props.setPrestationId(id);
                props.next(1);
            }).catch((error: any) => toast.error(error.message.fr))

        }
    };

    const handlePrevious = () => {
        if (props.previous) props.previous(1);
    };

    return (
        <>
        <Modal.Body className="align--self-center w-100">
            <Card.Text style={{marginLeft: 20, marginTop: 10}}>
                Choisissez la prestation modèle parmi{" "}
                {props.templateSource === "MODELS" ? "vos événements précédents" : "les propositions de scénario Lib-Events"}.
                <br />Les champs de la nouvelle prestation seront pré-remplis.
            </Card.Text>
            <Row className="my-4">
                <Col className="d-flex justify-content-start flex-wrap">
                    {props.list && props.list.map((prestation: IPrestationModel, index: Key) => {
                        return <PrestationTemplateCard
                            key={index}
                            templateId={prestation.id}
                            title={prestation.name}
                            description={(prestation.description !== undefined  ? prestation.description : '')}
                            price={(prestation.price_public !== undefined  ? prestation.price_public : 0)}
                            type={prestation.type}
                            hasCover={prestation.have_cover}
                            cover={prestation.img_cover}
                            onClick={setSelectedId}
                            selectedId={selectedId}
                        />
                    })}
                    {(props.list === undefined || props.list.length) == 0 && 
                        <p><i>Aucun modèle trouvé.</i></p>
                    }
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center w-100">
            <Col className="text-start">
                <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => handlePrevious()}>Retour</Button>
            </Col>
            <Col className="text-end">
                <Button className="fw-bolder" variant='primary' disabled={selectedId === -1} onClick={handleNext}>Valider</Button>
            </Col>
        </Modal.Footer>
        </>
    );
};

export default AddPrestationComponent;
