import React from 'react'
import { Modal, Button, Col } from 'react-bootstrap'
import { toast } from 'react-hot-toast';

interface IProps {
    configurationId: number | undefined,
    showModalRemove: boolean,
    closeModal: () => void,
    setShowModalRemove: React.Dispatch<React.SetStateAction<boolean>>,
    removeConfiguration: (cId: number) => Promise<string>
}

const RemoveConfigurationModal = (props: IProps) => {

    const handleRemove = () => {
        if(props.configurationId) {
            props.removeConfiguration(props.configurationId)
            .then(() => {
                props.setShowModalRemove(false);
                props.closeModal();
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    };

    return (
        <Modal
            show={props.showModalRemove}
            onHide={() => props.setShowModalRemove(false)}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
            centered
        >
            <Modal.Header>
                <Modal.Title className='w-100'>Supprimer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-4">
                <div>Confirmez-vous la suppression de cette configuration ?<br />Les services déjà générés ne seront pas supprimés.</div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => props.setShowModalRemove(false)}>Annuler</Button>
                </Col>
                <Col className="text-end">
                    <Button variant='primary' className='btn-modal-device me-1' onClick={handleRemove}>Valider</Button>
                </Col>
            </Modal.Footer>
        </Modal>

    )
}

export default RemoveConfigurationModal