import {produce} from "immer";
import * as Constants from "../constants";
import {AnyAction} from "@reduxjs/toolkit";

export interface IScannerState {
    codeInfos: ICodeInfos | null;
    devices: IDevice[] | null;
}

export interface ICodeInfos {
    sync_code?: string;
    sync_code_expire_at: string;
    scanner_android_store: string;
    scanner_apple_store: string;
}

export interface IDevice {
    id: number;
    designation: string;
    notifications_active: boolean;
}

export interface IChangeNotifRequest {
    id: number;
    active: boolean;
}

export interface IResponseStatus {
    data: {
        id?: number;
        pdf?: string;
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    };
    status: "fail" | "success";
}

const initState = {
    codeInfos: null,
    devices: null
} as IScannerState;

const scannersReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case Constants.GET_CODE_SUCCESS:
            draft.codeInfos = action.payload.data
            break;
        case Constants.GET_DEVICES_SUCCESS:
            draft.devices = action.payload.data
            break;
        default:
            return draft;
    }
});

export default scannersReducer;
