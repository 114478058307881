import {CORE_URL} from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/users";

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const REGISTER = 'SIGNUP';
export const REGISTER_SUCCESS = 'SIGNUP_SUCCESS';
export const REGISTER_FAILED = 'SIGNUP_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED"

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS"
export const RECOVER_PASSWORD_FAILED = "RECOVER_PASSWORD_FAILED"

export const SUPPORT = 'SUPPORT';
export const SUPPORT_SUCCESS = 'SUPPORT_SUCCESS';
export const SUPPORT_FAILED = 'SUPPORT_FAILED';

