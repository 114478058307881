import { RouteObject } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import withPermissions from '../../../routes/withPermissions';

const DashRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            index: true,
            element: withPermissions(isLogged, <DashboardPage />),
        },
        
    ]
}

export default DashRoutes
