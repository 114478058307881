import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import { IAutoPostRequest, IInfosPost } from "../reducers";
import PostEventCreateAutoModalComponent from "../components/PostEventCreateAutoModalComponent";

const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createInfoPost: (event_id: number) => {
            return new Promise((resolve: (p: IInfosPost) => void, reject: any) => {
                dispatch(Actions.createInfoPost(event_id, resolve, reject));
            });
        },
        createAutoPost: (infos: IAutoPostRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.createAutoPost(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PostEventCreateAutoModalComponent);