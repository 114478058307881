import React, { useState, useEffect, ChangeEvent } from 'react'
import { Button, ButtonGroup, Card, Col, Form, Modal, Row, ToggleButton, Image } from 'react-bootstrap';
import NetworkCard from './NetworkCard';
import { PropsFromReduxNetworks } from '../containers/NetworksContainer';
import { toast } from 'react-hot-toast';
import { IFacebookConfirm, IFacebookPage, IInstagramPage, INetwork, INetworkCredentials, INetworkReference, INetworkType } from '../reducers';
import { useLocation } from 'react-router-dom';

const NetworksComponent = (props: PropsFromReduxNetworks) => {
    
    const location = useLocation();

    const [showModalCreate, setShowModalCreate] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)
    const [id, setId] = useState<number| undefined>(0)
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState(false)
    const [type, setType] = useState<string>('')
    const [nouslibName, setNouslibName] = useState<string | undefined>('')
    const [nouslibCountry, setNouslibCountry] = useState<string | undefined>('')
    const [nouslibRegion, setNouslibRegion] = useState<string | undefined>('')
    const [nouslibDepartment, setNouslibDepartment] = useState<string | undefined>('')

    const [typeName, setTypeName] = useState<string>('')
    const [urlPage, setUrlPage] = useState<string | undefined>('')

    const [networkId, setNeworkId] = useState<number>(0)

    const [facebookPages, setFacebookPages] = useState<IFacebookPage[]>([])
    const [showModalFacebookConfirm, setShowModalFacebookConfirm] = useState(false)
    const [facebookPageChoosed, setFacebookPageChoosed] = useState<string>('')

    const [instagramPages, setInstagramPages] = useState<IInstagramPage[]>([])
    const [showModalInstagramConfirm, setShowModalInstagramConfirm] = useState(false)
    const [instagramPageChoosed, setInstagramPageChoosed] = useState<string>('')
    
    
    useEffect(() => {
        props.getNetworksList()
        props.getNetworksReferences()
        
        const queryParams = new URLSearchParams(location.search);

        if(queryParams && queryParams.get('error')) {
            toast.error(queryParams.get('error'))
        }

        if(queryParams && queryParams.get('type') && queryParams.get('id')) {

            if(queryParams.get('type') == 'FACEBOOK_PAGE_SYNC') {
                // @ts-ignore
                const id = parseInt((queryParams && queryParams.get('id') !== null ? queryParams.get('id').toString() : '0'))
                props.facebookPageList(id).then(data => {
                    if(data.pages.length == 0) {
                        toast.error('Aucune page est rattachée à votre compte')
                    }
                    else {
                        setNeworkId(id);
                        setFacebookPages(data.pages as IFacebookPage[])
                        setFacebookPageChoosed(data.pages[0].id)
                        setShowModalFacebookConfirm(true)
                    }
                })
                .catch(error => { toast.error(error.message.fr) })
            }
            else if(queryParams.get('type') == 'INSTAGRAM_SYNC') {
                // @ts-ignore
                const id = parseInt((queryParams && queryParams.get('id') !== null ? queryParams.get('id').toString() : '0'))
                props.instagramPageList(id).then(data => {
                    if(data.pages.length == 0) {
                        toast.error('Aucune page est rattachée à votre compte')
                    }
                    else {
                        setNeworkId(id);
                        setInstagramPages(data.pages as IInstagramPage[])
                        setInstagramPageChoosed(data.pages[0].id)
                        setShowModalInstagramConfirm(true)
                    }
                })
                .catch(error => { toast.error(error.message.fr) })
            }

        }
    }, [])

    const resetForms = () => {
        setId(undefined)
        setLogin('')
        setPassword('')
        setShowPassword(false)
        setType('')
        setTypeName('')
        setNouslibName('')
        setNouslibCountry('')
        setNouslibRegion('')
        setNouslibDepartment('')
        setUrlPage('')
    }

    const handleTypeValue = (e: ChangeEvent<HTMLInputElement>) => {
        setType(e.currentTarget.value);
        
        if(props.networksReferences && props.networksReferences.length) {
            props.networksReferences.map((network: INetworkReference, index: number) => {
                if(network.id === e.currentTarget.value) {
                    setTypeName(network.name)
                }
            })
        }
        
        setShowModalCreate(true)
    };

    const handleCreateNetwork = () => {

        if(type === 'FACEBOOK_PAGE_SYNC') {
            const credentials: INetworkType = {
                type: type
            }
    
            props.facebookLoginUrl(credentials).then(data => {
                window.location.href = data.url;
            })
            .catch(error => { toast.error(error.message.fr) })
        }
        else if(type === 'INSTAGRAM_SYNC') {
            const credentials: INetworkType = {
                type: type
            }
    
            props.instagramLoginUrl(credentials).then(data => {
                window.location.href = data.url;
            })
            .catch(error => { toast.error(error.message.fr) })
        }
        else {
            const credentials: INetworkCredentials = {
                type: type as INetworkCredentials["type"],
                login: login, 
                password: password,
                url_page: (urlPage ? urlPage: ''),
                nouslib_name: (nouslibName ? nouslibName: ''), 
                nouslib_country: (nouslibCountry ? nouslibCountry: ''), 
                nouslib_region: (nouslibRegion ? nouslibRegion: ''), 
                nouslib_department: (nouslibDepartment ? nouslibDepartment: ''),
            }
    
            props.createNetwork(credentials).then(status => {
                props.getNetworksList()
                toast.success("Réseau ajouté")
    
                setShowModalCreate(false)
                resetForms()
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    }

    const startEditNetwork = (network: INetwork) => {

        setShowModalEdit(true);
        
        setId(network.id)
        setLogin(network.login)
        setPassword('')
        setShowPassword(false)
        setType(network.type)
        setUrlPage((network.page ? network.page : ''))
        if(network.type === 'NOUSLIB') {
            setNouslibName(network.nouslib_name)
            setNouslibCountry(network.nouslib_country)
            setNouslibRegion(network.nouslib_region)
            setNouslibDepartment(network.nouslib_department)
        }
        else {
            setNouslibName('')
            setNouslibCountry('')
            setNouslibRegion('')
            setNouslibDepartment('')
        }
    }

    const handleEditNetwork = () => {

        if(type === 'FACEBOOK_PAGE_SYNC') {
            toast.error('Pour résoudre le problème de connexion, merci de contacter le support.') 
        }
        else if(type === 'INSTAGRAM_SYNC') {
            toast.error('Pour résoudre le problème de connexion, merci de contacter le support.') 
        }
        else {
            const credentials: INetworkCredentials = {
                id: id,
                login: login, 
                password: password,
                url_page: (urlPage ? urlPage: ''),
                nouslib_name: (nouslibName ? nouslibName: ''), 
                nouslib_country: (nouslibCountry ? nouslibCountry: ''), 
                nouslib_region: (nouslibRegion ? nouslibRegion: ''), 
                nouslib_department: (nouslibDepartment ? nouslibDepartment: ''),
            }

            props.updateNetwork(credentials).then(status => {
                props.getNetworksList()
                toast.success("Réseau modifié")

                setShowModalEdit(false)
                resetForms()
            })
            .catch(error => { toast.error(error.message.fr) })
        }
    }

    const startRemoveNetwork = (id: number) => {

        setShowModalRemove(true);
        setId(id)

    }

    const handleRemoveNetwork = () => {

        props.deleteNetwork((id ? id : 0)).then(status => {
            setShowModalRemove(false)
            props.getNetworksList()
            toast.success("Réseau supprimé")
        })
        .catch(error => { toast.error(error.message.fr) })
        
    }
    
    const handleFacebookConfirm = () => {

        const credentials: IFacebookConfirm = {
            id: networkId,
            page_id: facebookPageChoosed
        }

        props.facebookLoginConfirm(credentials).then(status => {
            props.getNetworksList()
            toast.success("Réseau ajouté")

            setShowModalFacebookConfirm(false)
        })
        .catch(error => { toast.error(error.message.fr) })
        
    }
    
    const handleInstagramConfirm = () => {

        const credentials: IFacebookConfirm = {
            id: networkId,
            page_id: instagramPageChoosed
        }

        props.instagramLoginConfirm(credentials).then(status => {
            props.getNetworksList()
            toast.success("Réseau ajouté")

            setShowModalInstagramConfirm(false)
        })
        .catch(error => { toast.error(error.message.fr) })
        
    }

    return (
        <>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Réseaux</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Text>Connectez vos réseaux favoris pour diffuser automatiquement vos événements à votre communautée.</Card.Text>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-2">
                                <div className="d-flex justify-content-around w-100 my-2">
                                    <ButtonGroup className="w-100 row row-inputs-event">
                                        {props.networksReferences && props.networksReferences.length > 0 ? <>
                                            {props.networksReferences.map((network: INetworkReference, index: number) =>                                                                
                                                <div className="col-12 col-sm-6 col-md-4 p-2" key={index}>
                                                    <ToggleButton
                                                        type="radio"
                                                        variant="light"
                                                        value={network.id}
                                                        id={network.id}
                                                        name="radio"
                                                        onChange={(e) => handleTypeValue(e)}
                                                        className="d-flex flex-column iconButton"
                                                    >
                                                        <Image src={network.icon} style={{width: 60, height: 60, display: 'flex'}} />
                                                        <div className="flex-grow-1" style={{marginTop: '10px'}}>{network.name}</div>
                                                    </ToggleButton>
                                                </div>
                                            )}
                                            </>
                                            :
                                            <></>
                                        }
                                    </ButtonGroup>
                                </div>
                            </Row>
                            <hr className="border-bottom-custom" />
                            <Row>
                                <Col>
                                    <Card.Text>Vos réseaux connectés</Card.Text>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-2">
                                <>
                                    {
                                        props.networks && props.networks.map((network: INetwork, i: number) => {
                                            return <Col key={i} md={6} className='mb-0  d-flex align-items-center'>
                                                <NetworkCard onEdit={() => startEditNetwork(network)} onDelete={() => startRemoveNetwork(network.id)} network={network} />
                                            </Col>
                                        })
                                    }
                                </>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal
                size="lg"
                show={showModalCreate}
                onHide={() => { setShowModalCreate(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Réseau {typeName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    {(type === 'WYYLDE_PAGE' || type === 'FACEBOOK_PAGE_SYNC' || type === 'INSTAGRAM_SYNC' || type === 'NOUSLIB') ?
                       <Card.Text style={{ fontSize: 17 }} className='mb-3 text-muted'>Pour pouvoir diffuser en automatique vos événements sur votre réseau nous avons besoins des informations de connexion.<br /> Ces informations sont stockées de manière sécurisée sur notre serveur. </Card.Text>
                       :
                       <Card.Text style={{ fontSize: 17 }} className='mb-3 text-muted'>Pour pouvoir diffuser vos événements sur votre réseau, un texte préformaté vous sera proposé lors de la promotion de vos événements, contenant le lien public vers votre page de vente. </Card.Text>
                    }
                    {(type === 'INSTAGRAM_SYNC') && 
                        <>
                       <Card.Text style={{ fontSize: 17 }} className='mt-3 mb-3 text-muted'>Afin de connecter votre compte instagram, vous devez :
                       <ul>
                           <li>Avoir un compte Instagram professionnel</li>
                           <li>Avoir une page facebook lié à votre compte Instagram</li>
                       </ul>
                       </Card.Text>
                       </>                      
                    }
                    {(type === 'WYYLDE_PAGE' || type === 'NOUSLIB') &&
                        <Row className='mt-3'>
                            <Col>
                                <Form.Label> Identifiant</Form.Label>
                                <Form.Control required type="text"
                                    placeholder='Identifiant / Mail'
                                    value={login} onChange={(e) => setLogin(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control required type={(showPassword ? "text" : "password")}
                                    placeholder='Mot de passe'
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Col>
                        </Row>
                    }
                    {(type === 'WYYLDE_PAGE') &&
                        <>
                            <Row>
                                <div>
                                    <Form.Label> URL de la page</Form.Label>
                                    <Form.Control required type="text"
                                        placeholder="URL de la page"
                                        value={urlPage} onChange={(e) => setUrlPage(e.target.value)}
                                    />
                                </div>
                            </Row>
                        </>
                    }
                    {type === 'NOUSLIB' &&
                        <>
                            <Row className='mt-3 d-flex'>
                                <div style={{ flex: 1 }}>
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Select value={nouslibCountry} onChange={(e) => { setNouslibCountry(e.target.value); setNouslibRegion(''); setNouslibDepartment('') }}>
                                        <option value=''>Choisissez un pays</option>
                                        {props.nousLibData && Object.keys(props.nousLibData).map((ref: string, i: number) => {
                                            return <option key={i} value={ref}>{ref}</option>
                                        })}
                                    </Form.Select>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Label>Région</Form.Label>
                                    <Form.Select value={nouslibRegion} onChange={(e) => setNouslibRegion(e.target.value)}>
                                        <option value=''>Choisissez une région</option>
                                        {!!nouslibCountry &&
                                            <>
                                                {nouslibCountry !== 'France' ?
                                                    props.nousLibData[nouslibCountry].map((region: string, i: number) => {
                                                        return <option key={i} value={region}>{region}</option>
                                                    })
                                                    :
                                                    Object.entries(props.nousLibData[nouslibCountry]).map((region: any[], i: number) => {
                                                        return <option key={i} value={region[0]}>{region[0]}</option>
                                                    })
                                                }
                                            </>
                                        }
                                    </Form.Select>
                                </div>
                                {nouslibCountry === 'France' && !!nouslibRegion &&
                                    <div style={{ flex: 1 }}>
                                        <Form.Label>Département</Form.Label>
                                        <Form.Select value={nouslibDepartment} onChange={(e) => setNouslibDepartment(e.target.value)}>
                                            <option value=''>Choisissez un département</option>
                                            {
                                                Object.entries(props.nousLibData[nouslibCountry][nouslibRegion]).map((dept: any, i: number) => {
                                                    return <option key={i} value={dept[1]}>{dept[1]}</option>
                                                })
                                            }
                                        </Form.Select>
                                        <>
                                        </>
                                    </div>
                                }
                            </Row>
                            <Row className='mt-3'>
                                <div>
                                    <Form.Label> Nom de l'établissement</Form.Label>
                                    <Form.Control required type="text"
                                        placeholder="Nom de l'établissement"
                                        value={nouslibName} onChange={(e) => setNouslibName(e.target.value)}
                                    />
                                </div>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => setShowModalCreate(false)}>Retour</Button>
                    </Col>
                    {(type === 'WYYLDE_PAGE' || type === 'FACEBOOK_PAGE_SYNC' || type === 'INSTAGRAM_SYNC' || type === 'NOUSLIB') &&
                        <Col className="text-end">
                            <Button className="fw-bolder" style={{display: (type !== 'FACEBOOK_PAGE_SYNC' && type !== 'INSTAGRAM_SYNC' ? 'inline-block' : 'none')}} variant='primary' type="submit" onClick={() => handleCreateNetwork()}>Valider</Button>
                            <Button className="fw-bolder btn-continue-facebook"  style={{display: (type === 'FACEBOOK_PAGE_SYNC' ? 'inline-block' : 'none')}} variant='primary' type="submit" onClick={() => handleCreateNetwork()}>Continuer avec Facebook</Button>
                            <Button className="fw-bolder btn-continue-instagram"  style={{display: (type === 'INSTAGRAM_SYNC' ? 'inline-block' : 'none')}} variant='primary' type="submit" onClick={() => handleCreateNetwork()}>Continuer avec Instagram</Button>
                        </Col>
                    }
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showModalEdit}
                onHide={() => { setShowModalEdit(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Modifier un réseau {typeName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <Card.Text style={{ fontSize: 17 }} className='mb-3 text-muted'>Pour pouvoir diffuser en automatique vos événements sur votre réseau nous avons besoins des informations de connexion.<br /> Ces informations sont stockées de manière sécurisée sur notre serveur. </Card.Text>
                    {(type === 'WYYLDE_PAGE' || type === 'NOUSLIB') &&
                        <Row className='mt-3'>
                            <Col>
                                <Form.Label> Identifiant</Form.Label>
                                <Form.Control required type="text"
                                    placeholder='Identifiant / Mail'
                                    value={login} onChange={(e) => setLogin(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control required type={(showPassword ? "text" : "password")}
                                    placeholder='Mot de passe'
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Col>
                        </Row>
                    }
                    {(type === 'WYYLDE_PAGE') &&
                        <>
                            <Row>
                                <div>
                                    <Form.Label> URL de la page</Form.Label>
                                    <Form.Control required type="text"
                                        placeholder="URL de la page"
                                        value={urlPage} onChange={(e) => setUrlPage(e.target.value)}
                                    />
                                </div>
                            </Row>
                        </>
                    }
                    {type === 'NOUSLIB' &&
                        <>
                            <Row className='mt-3 d-flex'>
                                <div style={{ flex: 1 }}>
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Select value={nouslibCountry} onChange={(e) => { setNouslibCountry(e.target.value); setNouslibRegion(''); setNouslibDepartment('') }}>
                                        <option value=''>Choisissez un pays</option>
                                        {props.nousLibData && Object.keys(props.nousLibData).map((ref: string, i: number) => {
                                            return <option key={i} value={ref}>{ref}</option>
                                        })}
                                    </Form.Select>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Label>Région</Form.Label>
                                    <Form.Select value={nouslibRegion} onChange={(e) => setNouslibRegion(e.target.value)}>
                                        <option value=''>Choisissez une région</option>
                                        {!!nouslibCountry &&
                                            <>
                                                {nouslibCountry !== 'France' ?
                                                    props.nousLibData[nouslibCountry].map((region: string, i: number) => {
                                                        return <option key={i} value={region}>{region}</option>
                                                    })
                                                    :
                                                    Object.entries(props.nousLibData[nouslibCountry]).map((region: any[], i: number) => {
                                                        return <option key={i} value={region[0]}>{region[0]}</option>
                                                    })
                                                }
                                            </>
                                        }
                                    </Form.Select>
                                </div>
                                {nouslibCountry === 'France' && !!nouslibRegion &&
                                    <div style={{ flex: 1 }}>
                                        <Form.Label>Département</Form.Label>
                                        <Form.Select value={nouslibDepartment} onChange={(e) => setNouslibDepartment(e.target.value)}>
                                            <option value=''>Choisissez un département</option>
                                            {
                                                Object.entries(props.nousLibData[nouslibCountry][nouslibRegion]).map((dept: any, i: number) => {
                                                    return <option key={i} value={dept[1]}>{dept[1]}</option>
                                                })
                                            }
                                        </Form.Select>
                                        <>
                                        </>
                                    </div>
                                }
                            </Row>
                            <Row className='mt-3'>
                                <div>
                                    <Form.Label> Nom de l'établissement</Form.Label>
                                    <Form.Control required type="text"
                                        placeholder="Nom de l'établissement"
                                        value={nouslibName} onChange={(e) => setNouslibName(e.target.value)}
                                    />
                                </div>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => setShowModalEdit(false)}>Retour</Button>
                    </Col>
                    <Col className="text-end">
                        <Button className="fw-bolder" variant='primary' type="submit" onClick={() => handleEditNetwork()}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
          
            <Modal 
                show={showModalRemove} 
                onHide={() => setShowModalRemove(false)} 
                className='w-100' 
                backdrop="static" 
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Supprimer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div>Confirmez-vous la suppression de ce réseau ?</div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalRemove(false)}>Annuler</Button>
                    </Col>
                    <Col className="text-end">
                        <Button variant='primary' className='btn-modal-device me-1' onClick={handleRemoveNetwork}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        
            <Modal
                size="lg"
                show={showModalFacebookConfirm}
                onHide={() => { setShowModalFacebookConfirm(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Choix de la page</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <Card.Text style={{ fontSize: 17 }} className='mb-3 text-muted'>Pour confirmer l'ajout du réseau, confirmez la page sur laquelle vous voulez publier en automatique.</Card.Text>
                    
                    <Form.Label>Pages</Form.Label>
                    <Form.Select value={facebookPageChoosed} onChange={(e) => setFacebookPageChoosed(e.target.value)}>
                        {facebookPages && facebookPages.map((ref: IFacebookPage, i: number) => {
                            return <option key={i} value={ref.id}>{ref.name}</option>
                        })}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => setShowModalFacebookConfirm(false)}>Retour</Button>
                    </Col>
                    <Col className="text-end">
                        <Button className="fw-bolder" variant='primary' type="submit" onClick={() => handleFacebookConfirm()}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
            
            <Modal
                size="lg"
                show={showModalInstagramConfirm}
                onHide={() => { setShowModalInstagramConfirm(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='w-100'>Choix de la page</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <Card.Text style={{ fontSize: 17 }} className='mb-3 text-muted'>Pour confirmer l'ajout du réseau, confirmez la page liée à votre Instagram Business sur lequel vous voulez publier en automatique.</Card.Text>
                    
                    <Form.Label>Pages</Form.Label>
                    <Form.Select value={instagramPageChoosed} onChange={(e) => setInstagramPageChoosed(e.target.value)}>
                        {instagramPages && instagramPages.map((ref: IInstagramPage, i: number) => {
                            return <option key={i} value={ref.id}>{ref.name}</option>
                        })}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button className="fw-bolder" variant='outline-secondary' type="button" onClick={() => setShowModalInstagramConfirm(false)}>Retour</Button>
                    </Col>
                    <Col className="text-end">
                        <Button className="fw-bolder" variant='primary' type="submit" onClick={() => handleInstagramConfirm()}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NetworksComponent