import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FooterComponent from '../../../common/components/FooterComponent'
import { PropsFromReduxRegister } from '../containers/RegisterContainer'
import Logo from '../../../assets/RectBlanc.png'
import toast from "react-hot-toast";

const RegisterComponent = (props: PropsFromReduxRegister) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [valueCheckboxGCU, setValueCheckboxGCU] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (!valueCheckboxGCU) {
            toast.error("Vous devez accepter les conditions générales d'utilisations pour créer un compte organisateur.")
            event.stopPropagation();
            return;
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        setValidated(true);

        props.register({ name, email, password })
        .catch(error => toast.error(error.message.fr))
    };

    const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setName(e.target.value);
    };

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value);
    };

    const handlePasswordInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.target.value);
    };

    const handleCheckboxGCU = () => {
        setValueCheckboxGCU(!valueCheckboxGCU);
    };

    const CGU = () => {
        return <div onClick={(e) => { e.preventDefault() }}>J'accepte les <span onClick={(e) => {
            e.preventDefault();
            window.open("https://lib-events.com/cgu-cgv/", "_blank")
        }} className="text-decoration-underline" role="button"> conditions générales d'utilisations</span></div>
    };

    return (
        <div className='w-100'>
            <Container className='d-flex align-content-center justify-content-center flex-wrap '>
                <Row className='py-4 align-items-center justify-content-center bg-white-up' style={{zIndex:50}}>
                    <Col className='d-flex flex-wrap align-items-center justify-content-center ' >
                        <Row>
                            <Col className="text text-center">
                                <img src={Logo} width={'70%'} height={'100%'} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text text-center mt-2 mb-5">
                                <h3>S'inscrire</h3>
                            </Col>
                        </Row>
                        <Form className='register-form' noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 w-75 " controlId="name">
                                <Form.Label>Nom / Établissement</Form.Label>
                                <Form.Control type="name" placeholder="Nom" onChange={handleNameInputChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3 w-75 " controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="E-mail" onChange={handleEmailInputChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3 w-75" controlId="password">
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control type={(showPassword ? "text" : "password")} placeholder="Mot de passe" onChange={handlePasswordInputChange} required />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Form.Group>
                            <Form.Group className="mb-3 w-75 " controlId="radio-rules">
                                <Form.Check type={'checkbox'}
                                    label={CGU()}
                                    id={"radio-rules"}
                                    role="button"
                                    checked={valueCheckboxGCU} onChange={handleCheckboxGCU} className="position-relative" />
                            </Form.Group>
                            <div className='text-center'>
                            </div>
                            <Row>
                                <Col className="text text-center">
                                    <Button className="mt-4 mb-5 btn-register" variant="primary" type="submit">
                                        Valider
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text text-center mt-2">
                                    <p className=''>Vous avez déjà un compte ?
                                        <Link to='/login' className="formlink fw-bold"> Connectez-vous</Link>
                                    </p>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <FooterComponent />
        </div>
    )
}

export default RegisterComponent