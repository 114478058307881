import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/organizers";

export const GET_BANK_INFO = "GET_BANK_INFO"
export const GET_BANK_INFO_SUCCESS = "GET_BANK_INFO_SUCCESS"
export const GET_BANK_INFO_FAILED = "GET_BANK_INFO_FAILED"

export const UPDATE_BANK_INFO = "UPDATE_BANK_INFO"
export const UPDATE_BANK_INFO_SUCCESS = "UPDATE_BANK_INFO_SUCCESS"
export const UPDATE_BANK_INFO_FAILED = "UPDATE_BANK_INFO_FAILED"