import { useState, useEffect } from 'react'
import { Button, Form, Modal, Col, Row } from 'react-bootstrap'
import { IGoodAvailabilitiesRequest, IGoodAvailability, IGoodAvailabilityRequest } from '../../reducers'
import { toast } from 'react-hot-toast';

interface AddAvailabilityComponentProps {
    show: boolean
    handleHide: () => void
    handleSave: () => void

    goodId: number

    availabilitiesCreate: (infos: IGoodAvailabilitiesRequest) => Promise<string>
}

const AddAvailabilityComponent = (props: AddAvailabilityComponentProps) => {

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [quantity, setQuantity] = useState<number>(2)
    const [price, setPrice] = useState<number>(100)

    const handleSaveAvail = () => {

        if (new Date(start) < new Date() || new Date(end) < new Date()) {
            toast.error("Vous devez planifier dans le futur !")
            return
        }

        let infos: IGoodAvailabilitiesRequest = { 
            id: props.goodId,
            start: start,
            end: end,
            quantity: quantity,
            price_public: price,
        }
        props.availabilitiesCreate(infos)
        .then(status => {
            toast.success('Disponibilités ajoutées')
            props.handleSave()
        })
        .catch(err => { toast.error(err.message.fr) })
        
    }
    return (
        <Modal show={props.show} onHide={props.handleHide} backdrop="static" size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Ajout de disponibilités</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="formInfos">
                    <Row className='w-100 row-inputs-event'>
                        <Col xs={12} md={6} className='pe-md-2'>
                            <Form.Label>Quantité</Form.Label>
                            <Form.Control type="number" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} placeholder="Quantité" />
                        </Col>
                        <Col xs={12} md={6} className='pe-md-2'>
                            <Form.Label>Prix public TTC en €</Form.Label>
                            <Form.Control type="number" value={price} onChange={(e) => setPrice(Number(e.target.value))} placeholder="Prix" />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className='mb-3' controlId='formDates'>
                    <Row className='w-100 row-inputs-event'>
                        <Col xs={12} md={6} className='pe-md-2'>
                            <Form.Label>Date de début</Form.Label>
                            <Form.Control type='date' value={start} onChange={(e) => setStart(e.target.value)} />
                        </Col>
                        <Col xs={12} md={6} className='ps-md-2'>
                            <Form.Label>Date de fin</Form.Label>
                            <Form.Control type='date' value={end} onChange={(e) => setEnd(e.target.value)} />
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button className="fw-bolder" variant='outline-secondary' onClick={props.handleHide}>Retour</Button>
                </Col>
                <Col className="text-end">
                    <Button className="fw-bolder" variant='primary' onClick={handleSaveAvail}>Enregistrer</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}

export default AddAvailabilityComponent