import React from "react";
import {Card, Col, Button, Row} from "react-bootstrap";
import {Pencil} from "react-bootstrap-icons";
import { IGoodAvailability } from "../../reducers";

interface AvailabilityCardProps {
    availability: IGoodAvailability;
    showAvailabilityEdit: (availability: IGoodAvailability) => void ;
}

const AvailabilityCard = (props: AvailabilityCardProps) => {
    return (
        <div className="col-12 p-1 mt-3">
            <Card className="position-relative prestaCard">
                <Card.Body>
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <div className="col-12 col-lg-4">
                                    <Card.Text className="fw-bolder">{props.availability.day}</Card.Text>
                                </div>
                                <div className="col-12 col-lg-4">
                                    Quantité: <span className="fw-bolder">{props.availability.quantity_available}</span>
                                </div>
                                <div className="col-12 col-lg-4">
                                    Prix: <span className="fw-bolder">{props.availability.price_public}</span> €
                                </div>
                                <Button onClick={() => props.showAvailabilityEdit(props.availability)} className="position-absolute px-1 pt-0 pb-2" variant="outline-secondary" size="sm">
                                    <Pencil size={18} />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default AvailabilityCard;