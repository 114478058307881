import {useSelector} from 'react-redux';
import {RootState} from './store';
import {useLocation, useRoutes} from 'react-router-dom';
import routes from './routes/routes';
import NavbarContainer from './common/containers/NavbarContainer';
import {FORGOT_PASSWORD, LOGIN, REGISTER} from './modules/auth/routes/auth';
import {Container} from 'react-bootstrap';
import TopMenuContainer from './common/containers/TopMenuContainer';
import HeaderComponent from './common/components/HeaderComponent';
import HeaderBackground from './assets/header-background.jpg';
import {Toaster} from 'react-hot-toast'

const App = () => {
  const isLogged = useSelector((state: RootState) => state.authReducer.isLogged)
  const location = useLocation()

  const noShow = [LOGIN, REGISTER, FORGOT_PASSWORD]

  let elements = useRoutes([...routes(isLogged)])

  return (
    <div id="app" className="pb-4">
      <Toaster position="top-center" />
      <HeaderComponent bgImage={HeaderBackground} />
      <div className='d-flex container'>
        <NavbarContainer />
        <div className='w-100'>
          <Container fluid className='mt-4'>
            {(!noShow.includes(location.pathname)) && <TopMenuContainer />}
            {elements}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;