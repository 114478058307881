import {AnyAction} from "redux";
import {RootState} from "../../../store";
import {takeLatest, put, call, select} from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import {IResponseStatus} from "../reducers";

function* getInfos (action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getInfos, apikey);
        yield put({type: response.status === "fail" ? Constants.GET_INFOS_FAIL : Constants.GET_INFOS_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({type: Constants.GET_INFOS_FAIL, e});
    }
}

function* updateInfos (action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateInfos, apikey, action.infos);
        yield put({type: response.status === "fail" ? Constants.UPDATE_INFOS_FAIL : Constants.UPDATE_INFOS_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({type: Constants.UPDATE_INFOS_FAIL, e});
    }
}

function* updateCover (action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateCover, apikey, action.infos);
        yield put({type: response.status === "fail" ? Constants.UPDATE_COVER_FAIL : Constants.UPDATE_COVER_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({type: Constants.UPDATE_COVER_FAIL, e});
    }
}

export const businessRecordSagas = [
    takeLatest(Constants.GET_INFOS, getInfos),
    takeLatest(Constants.UPDATE_INFOS, updateInfos),
    takeLatest(Constants.UPDATE_COVER, updateCover),
];