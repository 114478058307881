import { AnyAction } from "redux";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { RootState } from "../../../store";
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILED,
    SUPPORT,
    SUPPORT_FAILED,
    SUPPORT_SUCCESS
} from "../constants";

import { changePasswordService, loginUserService, logoutUserService, registerUserService, forgotPasswordService, recoverPasswordService, supportService } from "../services";
import { IResponseStatus, IUserCredential, IUserPassword } from "../reducers";

function* logIn(action: AnyAction) {
    try {
        const userCredential: IUserCredential = {
            email: action.credentials.email,
            password: action.credentials.password
        }
        const response: IResponseStatus = yield call(loginUserService, userCredential);
        yield put({ type: (response.status === "fail" ? LOGIN_FAILED : LOGIN_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: LOGIN_FAILED, e })
    }
}

function* logOut(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(logoutUserService, apikey);
        yield put({ type: (response.status === "fail" ? LOGOUT_FAILED : LOGOUT_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: LOGOUT_FAILED, e })
    }
}

function* register(action: AnyAction) {
    try {
        const userCredentials: IUserCredential = {
            name: action.credentials.name,
            email: action.credentials.email,
            password: action.credentials.password
        }
        const response: IResponseStatus = yield call(registerUserService, userCredentials)
        yield put({ type: (response.status === "fail" ? REGISTER_FAILED : REGISTER_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: REGISTER_FAILED, e })
    }
}

function* forgotPassword(action: AnyAction) {
    try {
        const response: IResponseStatus = yield call(forgotPasswordService, action.email);
        yield put({ type: (response.status === "fail" ? FORGOT_PASSWORD_FAILED : FORGOT_PASSWORD_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: FORGOT_PASSWORD_FAILED, e })
    }
}

function* recoverPassword(action: AnyAction) {
    try {
        const info: any = {
            code: action.code,
            email: action.email,
            password: action.password
        }
        const response: IResponseStatus = yield call(recoverPasswordService, info);
        yield put({ type: (response.status === "fail" ? RECOVER_PASSWORD_FAILED : RECOVER_PASSWORD_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: RECOVER_PASSWORD_FAILED, e })
    }
}

function* changePassword(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;
    try {
        const passwordData: IUserPassword = {
            old_password: action.passwordData.old_password,
            new_password: action.passwordData.new_password,
            check_password: action.passwordData.check_password
        }
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(changePasswordService, apikey, passwordData);
        yield put({ type: (response.status === "fail" ? CHANGE_PASSWORD_FAILED : CHANGE_PASSWORD_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: CHANGE_PASSWORD_FAILED, e })
    }
}

function* support(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;
    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(supportService, apikey, action.supportData);
        yield put({ type: (response.status === "fail" ? SUPPORT_FAILED : SUPPORT_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: SUPPORT_FAILED, e })
    }
}

export const authSagas = [
    takeLatest(LOGIN, logIn),
    takeLatest(REGISTER, register),
    takeLatest(LOGOUT, logOut),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(RECOVER_PASSWORD, recoverPassword),
    takeLatest(CHANGE_PASSWORD, changePassword),
    takeLatest(SUPPORT, support),
];