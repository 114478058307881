import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import { ITransat, ITransatImage, IManageInfosRequest } from "../reducers";
import TransatEditComponent from "../components/TransatEditComponent";

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get: (id: number, subtype: string, category: string) => {
            return new Promise((resolve: (event: ITransat) => void, reject: any) => {
                dispatch(Actions.get(id, subtype, category, resolve, reject));
            });
        },
        manageInfos: (infos: IManageInfosRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.manageInfos(infos, resolve, reject));
            });
        },
        saveTmpImage: (infos: ITransatImage) => {
            return new Promise((resolve: (url: string) => void, reject: any) => {
                dispatch(Actions.saveTmpImage(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransatEditComponent);