import React, { Key, useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import EditConfigurationModalComponent from './EditConfigurationModalComponent'
import ConfigurationComponent from './ConfigurationComponent'
import { PropsFromReduxRestaurantConfigure } from '../containers/RestaurantConfigureContainer'
import { IConfiguration } from '../reducers'

const RestaurantConfigureComponent = (props: PropsFromReduxRestaurantConfigure) => {

    const [showModal, setShowModal] = useState(false)
    const [configuration, setConfiguration] = useState<IConfiguration | undefined>()

    const startModal = (c?: IConfiguration) => {

        if (c !== undefined) {
            setConfiguration(c)
        }
        else {
            setConfiguration(undefined)
        }

        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
        setConfiguration(undefined)
        props.listConfiguration();
    }

    useEffect(() => {
        props.listConfiguration();
    }, [])

    return (
        <>
            <Card className='blocCard'>
                <Card.Header className="d-flex align-items-center mb-2">
                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                        <div className="w-100 fw-bolder">
                            Configuration
                        </div>
                        <div className="align-items-right">
                            <Button className="topButton" variant="primary" onClick={() => startModal()}>
                                Ajouter
                            </Button>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body className='d-flex flex-wrap2'>
                    <Card.Text style={{paddingLeft: 10, paddingRight: 10}}>Pour confirmer sa réservation, le client devra s'acquitter de la somme de 30€, qui sera à déduire de son addition.</Card.Text>
                </Card.Body>
                <Card.Body className='d-flex flex-wrap flex-row gap-2'>
                    {props.listConfigurations && props.listConfigurations.length > 0 && props.listConfigurations.map((c: IConfiguration, key: Key) => {
                        return <ConfigurationComponent key={key} onEdit={startModal} configuration={c} />
                    })}
                </Card.Body>
            </Card>
            <EditConfigurationModalComponent 
                configuration={configuration} 
                show={showModal} 
                closeModal={closeModal}
                createConfiguration={props.createConfiguration} 
                updateConfiguration={props.updateConfiguration} 
                removeConfiguration={props.removeConfiguration} 
            />
        </>
    )
}

export default RestaurantConfigureComponent
