import React from 'react'
import {Card} from 'react-bootstrap'
import { formatDatetime } from '../../../../utils/formattage'

interface TransatTemplateCardProps {
    modelId: number
    selectedId: number
    name: string
    started: string
    imgFlyer: string
    onClick: React.Dispatch<React.SetStateAction<number>>
}

const TransatTemplateCard = (props: TransatTemplateCardProps) => {

    const isSelected = (props.selectedId === props.modelId)

    return (
        <Card 
            onClick={() => props.onClick(props.modelId)}
            style={{textDecoration: "none", color: "inherit", cursor: "pointer", margin: 10}}
            className={`position-relative templateCard ${(isSelected ? 'selected' : '')}`} 
        >
            <div className="cardDisplay">
                <Card.Img className="rounded-top eventCardImg" src={props.imgFlyer} alt="événement" />
            </div>
            <Card.Body>
                <Card.Title>{props.name}</Card.Title>
                <Card.Text>{formatDatetime(props.started)}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default TransatTemplateCard