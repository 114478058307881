import { API_URL_EVENTS } from "../constants";
import { IAutoPostRequest, IManualPostRequest, IPostNewsRequest } from "../reducers";

export const createInfoPost = (apiKey: string, event_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/post/event/create/info`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id: event_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createManualPost = (apiKey: string, infos: IManualPostRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/post/event/create/manual`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createAutoPost = (apiKey: string, infos: IAutoPostRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/post/event/create/auto`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createPostNews = (apiKey: string, infos: IPostNewsRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/post/news/create`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const removePost = (apiKey: string, post_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/post/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id: post_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};