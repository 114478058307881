import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../../utils/formattage'

interface ServiceCardProps {
    occurence_id: number
    started: string
    choice: string
    quantity: number
    quantity_available: number
}

const ServiceCard = (props: ServiceCardProps) => {
    return (
        <Link to={'/restaurant/resume/' + props.occurence_id} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Card className='mt-2 serviceCard border'>
                <Card.Header className='d-flex'>
                    <Card.Text className='w-100 fw-bolder'>{formatDate(props.started)} à {props.choice?.replace(':', 'h')}</Card.Text>
                </Card.Header>
                <Card.Body>
                    <Card.Text style={{marginBottom: 5}}>Réservés : <span className=''> <b>{(props.quantity - props.quantity_available)} couverts</b> sur <b>{props.quantity_available}</b></span></Card.Text>
                </Card.Body>
            </Card>
        </Link>
    )
}

export default ServiceCard