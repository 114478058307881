import { produce } from "immer";
import { AnyAction } from "@reduxjs/toolkit";

export interface IBankInfoState {
    bankInfo: IBankInfo;
}

export interface IBankInfo {
    retrocession_rate: number,
    society_name: string,
    society_siren: string,
    society_iban: string,
    society_bic: string,
    society_iban_good: string,
    society_bic_good: string,
    check_status: string,
    check_at: string
}

export interface postBankInfo {
    society_name: string,
    society_siren: string,
    society_iban: string,
    society_bic: string,
    society_iban_good: string,
    society_bic_good: string
}

export interface IResponseStatus {
    data: {
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

const initState = {};

const bankInfoReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        default:
            return draft;
    }
});

export default bankInfoReducer;
