import { useState, useEffect, Key, ChangeEvent } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row, InputGroup, ToggleButton, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddPrestationComponent from "./common/AddPrestationComponent";
import EditPrestationComponent from "./common/EditPrestationComponent";
import toast from "react-hot-toast";
import { ISpa, IPrestation } from "../reducers";
import { Trash, } from "react-bootstrap-icons";
import { PropsFromRedux } from "../containers/SpaCreateContainer";
import CoverImageEditorModalTUI from "../../../common/components/ImageEditorModalTUI";
import { convertDatetimeLocalToUtc, formatDateEdit } from "../../../utils/formattage";
import Slim, { blobToBase64 } from '../../../common/lib/slim/slim.react';
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import '../../../common/lib/trumbowyg/plugins/colors/ui/trumbowyg.colors.css';
import '../../../common/lib/trumbowyg/plugins/colors/trumbowyg.colors.js';
import moment from "moment";
import PrestationCard from "./common/PrestationCard";

const SpaCreateComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const eventId = parseInt(location.pathname.split("/")[3]);

    const [event, setEvent] = useState<ISpa | undefined>();
    const [eventName, setEventName] = useState('');
    const [eventStartDate, setEventStartDate] = useState('');
    const [eventEndDate, setEventEndDate] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventFlyerImage, setEventFlyerImage] = useState('');
    
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalPublish, setShowModalPublish] = useState(false);
    const [showFlyerImageCustomize, setShowFlyerImageCustomize] = useState(false);
    
    const [showPrestationAdd, setShowPrestationAdd] = useState(false);
    const [showPrestationEdit, setShowPrestationEdit] = useState(false);
    const [isNewPrestation, setIsNewPrestation] = useState(false);
    const [prestationEdit, setPrestationEdit] = useState<IPrestation>();

    const [publishDate, setPublishDate] = useState('')
    const [publishChoice, setPublishChoice] = useState<'NOW' | 'PLANNED'>('NOW')
    
    useEffect(() => {
        fetchEvent()
    }, [])
        
    const fetchEvent = () => {
        props.get(eventId, 'UNIQUE', 'SPA')
        .then(ev => {
            if(ev.status !== 'DRAFT') {
                navigate("/spa/resume/"+eventId)
                return
            }

            setEvent(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/spa")
        });
    };

    useEffect(() => {
        if (event) {

            setEventName(event.name ? event.name : '')
            setEventStartDate(event.started ? formatDateEdit(event.started, 'YYYY-MM-DD HH:mm:ss') : '')
            setEventEndDate(event.ended ? formatDateEdit(event.ended, 'YYYY-MM-DD HH:mm:ss') : '')
            setEventDescription(event.description ? event.description : '')

            if(event.img_flyer) {
                setEventFlyerImage(event.img_flyer);
            }
            
        }
    }, [event]);

    const handleDelete = () => {
        props.remove(eventId)
        .then(() => {
            setShowModalDelete(false);
            toast.success(`Événement supprimé.`);
            navigate("/spa");
        })
        .catch(error => { toast.error(error.message.fr) })
    };

    const handleDescription = (e: any) => {
        /* @ts-ignore */
        var content = $(e.target).trumbowyg('html');
        setEventDescription(content);
    };

    const saveInfos = () => {
        if (new Date(eventStartDate) < new Date() || new Date(eventEndDate) < new Date()) {
            toast.error("Vous devez planifier un événement dans le futur !")
            return
        }
        const started = convertDatetimeLocalToUtc(eventStartDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
        const ended = convertDatetimeLocalToUtc(eventEndDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');

        props.updateInfos({ id: eventId, name: eventName, started, ended, description: eventDescription })
        .then((status) => {
            toast.success("Informations enregistrées.");
        })
        .catch(error => toast.error(error.message.fr))
    };
    
    /* @ts-ignore */
    const loadCover = (file, image, meta) => {

        let showToast = true
        if(event && event.img_cover) {
            if(event.img_cover.endsWith(file.name)) {
                showToast = false
            }
        }

        if(showToast) {
            toast.success("Vous pouvez cropper votre image à la bonne dimension.", {duration: 5000})
        }

        return true;
    }
    
    /* @ts-ignore */
    const saveCover = async (blob, progress, success, failure, slim) => {
        if(blob[0] !== undefined) {
            const base64 = await blobToBase64(blob[0]);

            props.updateCover({ id: eventId, base64: base64 })
            .then(status => {
                toast.success("Image de couverture enregistrée.")
                success(); //Fin du process slim
            })
            .catch(error => {
                toast.error(error.message.fr)
                success(); //Fin du process slim
            })

            return;
        }
        else {
            toast.error("Une erreur est survenue, merci de réessayer.")
            success(); //Fin du process slim
        }
    }
     
    /* @ts-ignore */
    const removeFlyer = async () => {
        setEventFlyerImage('')
    }
    
    /* @ts-ignore */
    const loadFlyer = (file, image, meta) => {
        let showToast = true
        if(event && event.img_flyer) {
            if(event.img_flyer.endsWith(file.name)) {
                showToast = false
            }
        }

        if(showToast) {
            toast.success("Vous pouvez cropper votre flyer à la bonne dimension.", {duration: 5000})
        }

        return true;
    }

    /* @ts-ignore */
    const saveFlyer = async (blob, progress, success, failure, slim) => {
        if(blob[0] !== undefined) {
            const base64 = await blobToBase64(blob[0]);

            props.updateFlyer({ id: eventId, base64: base64 })
            .then(url => {
                setEventFlyerImage(url);
                
                toast.success("Flyer enregistré.")
                success(); //Fin du process slim
            })
            .catch(error => {
                toast.error(error.message.fr)
                success(); //Fin du process slim
            })

            return;
        }
        else {
            toast.error("Une erreur est survenue, merci de réessayer.")
            success(); //Fin du process slim
        }
    }
    
    const saveFlyerCustomized = async (base64: string) => {
        if(base64) {
            props.updateFlyer({ id: eventId, base64: base64 })
            .then(url => {
                setEventFlyerImage(url);
                toast.success("Flyer enregistré.")

                window.location.reload();                
            })
            .catch(error => {
                toast.error(error.message.fr)
            })

            return;
        }
    }

    const formatPrice = (price: string) => {
        if (price.includes(",")) return Number(price.replace(",", "."));
        else return Number(price);
    };

    const handleHideFlyerImageCustomize = () => setShowFlyerImageCustomize(false);    
    const handleHidePrestationAdd = () => {
        setIsNewPrestation(false)
        setShowPrestationAdd(false)
    }

    const handleSavePrestationAdd = (id: number) => {
        setShowPrestationAdd(false);
        
        props.getPrestation(id)
        .then(({ status, prestation }: { status: string, prestation: IPrestation }) => {
            setIsNewPrestation(true)
            setPrestationEdit(prestation)
            setShowPrestationEdit(true)
        })
        .catch(error => toast.error(error.message.fr));
    };

    const handleShowPrestationEdit = (id: number) => {
        props.getPrestation(id)
        .then(({ status, prestation }: { status: string, prestation: IPrestation }) => {
            setIsNewPrestation(false)
            setPrestationEdit(prestation)
            setShowPrestationEdit(true)
        })
        .catch(error => toast.error(error.message.fr));
    }

    const handleShowPrestationManage = (id: number, quantity?: number) => {}

    const handleHidePrestationEdit = () => {
        setIsNewPrestation(false)
        setShowPrestationEdit(false)
    }

    const handleSavePrestationEdit = () => {
        setIsNewPrestation(false)
        setShowPrestationEdit(false);
        fetchEvent();
    };

    const handlePublishCheck = () => {
        let toastId = toast.loading('Vérification de la complétude de l\'événement..')

        setTimeout(() => {
            
            //Republication des INFOS
            if (new Date(eventStartDate) < new Date() || new Date(eventEndDate) < new Date()) {
                toast.error("Vous devez planifier un événement dans le futur !")
                return
            }
            const started = convertDatetimeLocalToUtc(eventStartDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
            const ended = convertDatetimeLocalToUtc(eventEndDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
            props.updateInfos({ id: eventId, name: eventName, started, ended, description: eventDescription })
            .then((status) => {

                //Si OK: Check
                props.publishCheck(eventId)
                .then((status) => {
                    toast.dismiss(toastId)
                    
                    setPublishDate(moment.utc().local().add('1', 'hour').format('YYYY-MM-DD HH:mm').replace(' ', 'T'));
                    setShowModalPublish(true);
                })
                .catch(error => {
                    toast.dismiss(toastId)

                    let msgError = error.message.fr;
                    if(error.complement !== undefined && error.complement.fr !== undefined) {
                        msgError += '. '+error.complement.fr+'.'
                    }
                    toast.error(msgError, {duration: 6000})
                });
            })            
            .catch(error => {
                toast.dismiss(toastId)
                toast.error(error.message.fr); 
            });

        }, 1000);
    }

    const handlePublishChoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublishChoice(e.currentTarget.value as 'NOW' | 'PLANNED')
    }

    const handlePublish = () => {

        let d = '';
        if(publishChoice === 'PLANNED') {
            d = convertDatetimeLocalToUtc(publishDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
        }

        props.publish(eventId, d)
        .then((status) => {
            window.location.reload();
        })
        .catch((error) => toast.error(error.message.fr))
    }
    
    return (
        <>
            {event ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`eventTitle m-0 align-self-center h-50`}>Événement</Card.Title>
                                    <div className="d-flex gap-2 mt-2 mt-md-0 mb-2 mb-md-0">
                                        <Button onClick={() => setShowModalDelete(true)} variant="danger">
                                            <Trash />
                                        </Button>
                                        <Button onClick={handlePublishCheck} variant='success'>Publier</Button>
                                    </div>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>                                
                    
                    <Row className='mt-2 g-2 flex-wrap'>

                        {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Informations</div>
                                        <div className="align-items-right d-none d-md-block">
                                            <Button className="topButton" variant="primary" onClick={() => saveInfos()}>
                                                Sauvegarder
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formEventName">
                                        <Row className='w-100 row-inputs-event'>
                                            <Col xs={12} md={12} className='pe-md-2'>
                                                <Form.Label>Nom de l'événement</Form.Label>
                                                <Form.Control type="text" value={eventName} onChange={(e) => setEventName(e.target.value)} placeholder="Nom de l'événement" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formEventDates'>
                                        <Row className='w-100 row-inputs-event'>
                                            <Col xs={12} md={6} className='pe-md-2'>
                                                <Form.Label>Date de début</Form.Label>
                                                <Form.Control type='datetime-local' value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
                                            </Col>
                                            <Col xs={12} md={6} className='ps-md-2'>
                                                <Form.Label>Date de fin</Form.Label>
                                                <Form.Control type='datetime-local' value={eventEndDate} onChange={(e) => setEventEndDate(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEventName">
                                        <Form.Label>Description</Form.Label>
                                        <Trumbowyg
                                            id='react-trumbowyg'
                                            buttons={
                                                [
                                                    ['formatting'],
                                                    ['foreColor', 'backColor'],
                                                    ['strong', 'em', 'underline', 'del'],
                                                    ['insertImage'],
                                                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                                                    ['unorderedList', 'orderedList'],
                                                    ['horizontalRule'],
                                                    ['link'],
                                                    ['viewHTML'],
                                                ]
                                            }
                                            data={event.description}
                                            placeholder='Décrit ton événement en quelques lignes..'
                                            onChange={(e: any) => handleDescription(e)} 
                                        />
                                    </Form.Group>
                                </Card.Body>
                                <Card.Footer className="d-flex d-md-none align-items-center ">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder"></div>
                                        <div className="align-items-right">
                                            <Button className="topButton" variant="primary" onClick={() => saveInfos()}>
                                                Sauvegarder
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Footer>
                            </Card>
                        </Col>
                        
                        {/* -----------------------------  IMAGE DE COUVERTURE  ----------------------------- */ }

                        <Col className='d-flex gap-2 flex-column col-12 col-md-7'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Image de couverture</div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text id='cover'>L'image de couverture sera affichée au format paysage en en-tête de la fiche de votre événement.</Card.Text>
                                    <div className='w-100 overflow-hidden'>
                                    <Slim ratio="2:1"
                                        initialImage={event && event.img_cover ? event.img_cover : ''}
                                        minSize={ { width:380, height:190 } }
                                        push={true}
                                        service={ saveCover }
                                        //didInit={ slimInit }
                                        didLoad={ loadCover }
                                        serviceFormat="file"
                                        style={ { aspectRatio: '2/1' } }
                                        label="Charger une image"
                                        buttonCancelLabel= "Annuler"
                                        buttonCancelTitle= "Annuler"
                                        buttonConfirmLabel= "Valider"
                                        buttonConfirmTitle= "Valider"
                                        buttonRotateTitle= "Rotation"
                                        buttonEditTitle= "Modifier"
                                        buttonRemoveTitle= "Supprimer"
                                        statusUploadSuccess="">
                                        <input type="file" name="cover"/>
                                    </Slim>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        

                        {/* -----------------------------  FLYER  ----------------------------- */}
                    
                        <Col className='d-flex gap-2 flex-column col-12 col-md-5'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Flyer</div>
                                        <div className="align-items-right">
                                            <Button className="topButton" variant="outline-secondary" disabled={!eventFlyerImage} onClick={() => setShowFlyerImageCustomize(true)}>
                                                Customiser
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text id='flyer'>Le flyer sera affichée au format portrait pour présenter votre événement.</Card.Text>
                                    <div className='w-100 overflow-hidden align-self-center'>
                                    <Slim 
                                        //ratio="2:3"
                                        initialImage={event && event.img_flyer ? event.img_flyer : ''}
                                        minSize={ { width:200, height:300 } }
                                        push={true}
                                        service={ saveFlyer }
                                        //didInit={ initFlyer }
                                        didLoad={ loadFlyer }
                                        didRemove={ removeFlyer }
                                        serviceFormat="file"
                                        style={ { aspectRatio: '2/1' } }
                                        label="Charger une image"
                                        buttonCancelLabel= "Annuler"
                                        buttonCancelTitle= "Annuler"
                                        buttonConfirmLabel= "Valider"
                                        buttonConfirmTitle= "Valider"
                                        buttonRotateTitle= "Rotation"
                                        buttonEditTitle= "Modifier"
                                        buttonRemoveTitle= "Supprimer"
                                        statusUploadSuccess="">
                                        <input type="file" name="flyer"/>
                                    </Slim>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <CoverImageEditorModalTUI
                            show={showFlyerImageCustomize}
                            handleHide={handleHideFlyerImageCustomize}
                            image={eventFlyerImage}
                            updatedImage={(base64: string) => saveFlyerCustomized(base64) }
                        />

                        {/* -----------------------------  PRESTATIONS  ----------------------------- */}

                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Prestations</div>
                                        <div className="align-items-right">
                                            <Button className="topButton" variant="success" onClick={() => setShowPrestationAdd(true)}>
                                                Ajouter
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>Ajouter des prestations supplémentaires à votre événement.</Card.Text>
                                     {<div className="d-flex justify-content-start flex-wrap mb-4">
                                        {event.prestations &&
                                            event.prestations.length > 0 &&
                                            event.prestations.map((presta: IPrestation, index: Key) => {
                                                return (
                                                   <PrestationCard
                                                        key={index}
                                                        id={presta.id}
                                                        title={presta.name}
                                                        content={presta.description}
                                                        cover={presta.img_cover}
                                                        price={presta.price_public}
                                                        type={presta.type}
                                                        quantityAvailable={presta.quantity_available}
                                                        choices={presta.choices_available}
                                                        eventStatus={event.status}

                                                        showPrestationEdit={handleShowPrestationEdit}
                                                        showPrestationManage={handleShowPrestationManage}
                                                    />
                                                );
                                            })}
                                        {!(event.prestations && event.prestations.length > 0) && (
                                            <Card.Text className="text-muted text-center">
                                                Il n'y a pas encore de prestations enregistrées pour cet événement
                                            </Card.Text>
                                        )}
                                    </div>}
                                </Card.Body>
                            </Card >
                        </Col >
                    </Row >
                    
                    <Modal
                        size="lg"
                        show={showModalPublish}
                        onHide={() => { setShowModalPublish(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Publier</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <div>Vous allez programmer la publication de votre évenemnt sur Lib-Events.<br />Une fois la publication effectué, vous pourrez désormais diffuser sur tous vos autres réseaux.</div>
                            <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Date de publication</Form.Label>
                            
                            <InputGroup className="mb-3">
                                <ToggleButton type='radio' variant="outline-primary"
                                    value='NOW' id='NOW' name='radio'
                                    onChange={(e) => handlePublishChoiceChange(e)}
                                    checked={publishChoice === 'NOW'}
                                >
                                    Immédiatement
                                </ToggleButton>
                                <ToggleButton type='radio' variant="outline-primary"
                                    value='PLANNED' id='PLANNED' name='radio'
                                    onChange={(e) => handlePublishChoiceChange(e)}
                                    checked={publishChoice === 'PLANNED'}
                                >
                                    Plus tard
                                </ToggleButton>
                                {publishChoice === 'PLANNED' && <Form.Control className='ms-3' type='datetime-local' value={publishDate} onChange={(e) => setPublishDate(e.target.value)} />}
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalPublish(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handlePublish}>Valider</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>
                    
                    <Modal
                        show={showModalDelete}
                        onHide={() => { setShowModalDelete(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Supprimer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <Card.Text>Êtes-vous sûr de vouloir supprimer ce brouillon ?<br />Cette action est irréversible.</Card.Text>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalDelete(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handleDelete}>Valider</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>

                    <AddPrestationComponent
                        show={showPrestationAdd}
                        handleHide={handleHidePrestationAdd}
                        handleSave={handleSavePrestationAdd}
                        
                        eventId={eventId}
                        
                        createEmptyPrestation={props.createEmptyPrestation}
                        createModelPrestation={props.createModelPrestation}

                        listModelPrestation={props.listModelPrestation}
                    />
                    
                    <EditPrestationComponent
                        show={showPrestationEdit}
                        handleHide={handleHidePrestationEdit}
                        handleSave={handleSavePrestationEdit}
                        
                        prestation={prestationEdit}
                        isNewPrestation={isNewPrestation}

                        removePrestation={props.removePrestation}
                        updatePrestation={props.updatePrestation}
                    />
                    
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default SpaCreateComponent;