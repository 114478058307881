import { SyntheticEvent, useState, useEffect } from 'react'
import { Badge, Button, Form, InputGroup, Modal, Col } from 'react-bootstrap'
import { Plus, X } from 'react-bootstrap-icons'
import { IPrestation } from '../../reducers'
import { IManagePrestationRequest } from '../../reducers'
import { toast } from 'react-hot-toast';

interface ManagePrestationComponentProps {
    show: boolean
    handleHide: () => void
    handleSave: () => void
    prestation: IPrestation | undefined
    managePrestation: (infos: IManagePrestationRequest) => Promise<string>
}

const ManagePrestationComponent = (props: ManagePrestationComponentProps) => {

    useEffect(() => {
        setPrestaQty(props.prestation && props.prestation.quantity_available ? props.prestation.quantity_available : 0)
        setPrestaChoices(props.prestation && props.prestation.choices_available ? props.prestation.choices_available : [])
    }, [props.prestation])

    const [prestaQty, setPrestaQty] = useState<number>(props.prestation && props.prestation.quantity_available ? props.prestation.quantity_available : 0)
    const [prestaChoice, setPrestaChoice] = useState('')
    const [prestaChoices, setPrestaChoices] = useState<string[]>([])

    const handleAddChoice = (e: SyntheticEvent) => {
        if (!prestaChoice) return
        setPrestaChoices([...prestaChoices, prestaChoice])
        setPrestaChoice('')
    }

    const handleRemovePrestaChoice = (index: number) => {
        const choices = prestaChoices
        choices.splice(index, 1)
        setPrestaChoices([...choices])
    }

    const handleSavePresta = () => {
        if(props.prestation) {
            let infos: IManagePrestationRequest = { id: props.prestation.id }
            if (props.prestation && props.prestation.type === 'QUANTITY') infos = { ...infos, quantity: prestaQty }
            if (props.prestation && props.prestation.type === 'CHOICES') infos = { ...infos, choices: prestaChoices }
            props.managePrestation(infos)
            .then(status => {
                toast.success('Prestation mise  à jour')
                props.handleSave()
            })
            .catch(err => { toast.error(err.message.fr) })
        }
    }
    return (
        <Modal show={props.show} onHide={props.handleHide} backdrop="static" size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Prestation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.prestation && props.prestation.type === 'QUANTITY' &&
                    <>
                        <div>Vous pouvez modifier la quantité restante à la baisse ou à la hausse.</div>
                        <div>Les billets en cours d'achat (panier client de 15 minutes non expiré) ne sont pas repris dans cette liste.</div>
                        <div className='w-100 mt-2' >
                            <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Nouvelle quantité</Form.Label>
                            <Form.Control type="number" value={prestaQty} onChange={(e) => setPrestaQty(Number(e.target.value))} placeholder="Quantité" />
                        </div>
                    </>
                }
                {props.prestation && props.prestation.type === 'CHOICES' &&
                    <>
                        <div>Vous pouvez ajouter ou enlever des choix non encore réservés.</div>
                        <div>Les choix en cours d'achat (panier client de 15 minutes non expiré) ne sont pas repris dans cette liste.</div>
                        <InputGroup className="my-3">
                            <Form.Control type="text" value={prestaChoice} onChange={(e) => setPrestaChoice(e.target.value)} placeholder="Choix" />
                            <InputGroup.Text id="prestaChoice" onClick={handleAddChoice}><Plus /></InputGroup.Text>
                        </InputGroup>
                        <div className='d-flex gap-2 flex-wrap'>
                            {
                                prestaChoices.map((choice, i) => (
                                    <Badge key={i} bg='light' style={{ color: '#454545', fontSize: '1rem' }}>{choice} <X onClick={() => handleRemovePrestaChoice(i)} /> </Badge>
                                ))
                            }
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button className="fw-bolder" variant='outline-secondary' onClick={props.handleHide}>Retour</Button>
                </Col>
                <Col className="text-end">
                    <Button className="fw-bolder" variant='primary' onClick={handleSavePresta}>Enregistrer</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}

export default ManagePrestationComponent