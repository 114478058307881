import { produce } from "immer";
import * as Constants from "../constants";
import { AnyAction } from "@reduxjs/toolkit";
import { IEventLight } from "../../../common/reducers";

export interface IDashboardState {
    resume: IDashboardResume | null;
}

export interface IDashboardResume {
    event_ephemeral_last_id: number;
    event_ephemeral_last_ca_ttc: number;
    event_ephemeral_last_subtype: string;
    event_ephemeral_last_category: string;
    event_ephemeral_next_count: number;
    event_ephemeral_next: IEventLight;
    devices_active_count: number;
    event_eat_next: [];
}

export interface IResponseStatus {
    data: {
        id?: number;
        pdf?: string;
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    };
    status: "fail" | "success";
}

const initState = {
    resume: null
} as IDashboardState;

const dashboardReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case Constants.DASHBOARD_RESUME_SUCCESS:
            draft.resume = action.payload.data;
            break;
        default:
            return draft;
    }
});

export default dashboardReducer;
