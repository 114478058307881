import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import { IPartyModel } from "../reducers";
import PartyNewModelsComponent from "../components/PartyNewModelsComponent";

const mapStateToProps = (state: RootState) => {
    return {
        eventsModel: state.partyReducer.eventsModel
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        listModel: (subtype: string, category: string) => {
            dispatch(Actions.listModel(subtype, category));
        },
        createModel: (id: number) => {
            return new Promise((resolve: (id: number) => void, reject: any) => {
                dispatch(Actions.createModel(id, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PartyNewModelsComponent);