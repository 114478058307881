import {produce} from "immer";
import {AnyAction} from "@reduxjs/toolkit";

export interface IOrganizerInfos {
    name: string;
    place: string;
    place_lat: number;
    place_lng: number;
    img_cover: string;
    description: string;
    baseline_text: string;
    baseline_html: string;
}

export interface ICoverRequest {
    base64: string;
}

export interface IInfosRequest {
    name: string;
    description: string;
    place: string;
    place_lat: number | undefined;
    place_lng: number | undefined;
}

export interface IResponseStatus {
    data: {
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    };
    status: "fail" | "success";
}

const initState = {};

const businessRecordReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        default:
            return draft;
    }
});

export default businessRecordReducer;
