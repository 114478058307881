import React from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";

import App from './App';
import './styles/Navbar.css';
import './styles/Dashboard.css';
import './styles/Button.css';
import './index.css'

import './index.css';
import './styles/Heading.css';
import './styles/Button.css';
import './styles/Form.css';
import './styles/Footer.css';

import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './common/Errors/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
