import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import * as Actions from "../actions";
import {ITransat, ITransatImage, ITransatInfos, IPrestation, IPrestationRequest, IPrestationModel} from "../reducers";
import {IPrestationModelRequest} from "../reducers";
import TransatCreateComponent from "../components/TransatCreateComponent";

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get: (id: number, subtype: string, category: string) => {
            return new Promise((resolve: (event: ITransat) => void, reject: any) => {
                dispatch(Actions.get(id, subtype, category, resolve, reject));
            });
        },
        updateInfos: (infos: ITransatInfos) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateInfos(infos, resolve, reject));
            });
        },
        updateCover: (infos: ITransatImage) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateCover(infos, resolve, reject));
            });
        },
        updateFlyer: (infos: ITransatImage) => {
            return new Promise((resolve: (url: string) => void, reject: any) => {
                dispatch(Actions.updateFlyer(infos, resolve, reject));
            });
        },
        removePrestation: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.removePrestation(id, resolve, reject));
            });
        },
        updatePrestation: (prestationInfos: IPrestationRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updatePrestation(prestationInfos, resolve, reject));
            });
        },
        createEmptyPrestation: (event_id: number) => {
            return new Promise((resolve: ({status, id}: {status: string; id: number}) => void, reject: any) => {
                dispatch(Actions.createEmptyPrestation(event_id, resolve, reject));
            });
        },
        getPrestation: (id: number) => {
            return new Promise((resolve: ({status, prestation}: {status: string; prestation: IPrestation}) => void, reject: any) => {
                dispatch(Actions.getPrestation(id, resolve, reject));
            });
        },
        listModelPrestation: (subtype: string, category: string) => {
            return new Promise((resolve: (prestations: IPrestationModel[]) => void, reject: any) => {
                dispatch(Actions.listModelPrestation(subtype, category, resolve, reject));
            });
        },
        createModelPrestation: (infos: IPrestationModelRequest) => {
            return new Promise((resolve: ({status, id}: {status: string, id: number}) => void, reject: any) => {
                dispatch(Actions.createModelPrestation(infos, resolve, reject));
            });
        },
        publishCheck: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.publishCheck(id, resolve, reject));
            });
        },
        publish: (id: number, scheduled_at:string) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.publish(id, scheduled_at, resolve, reject));
            });
        },
        remove: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.remove(id, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransatCreateComponent);