import { AnyAction } from "redux";
import { produce } from "immer";
import * as Constants from "../constants";

export interface IGoodState {
    goods: IGoodLight[] | null;
    goodsModel: IGoodModel[] | null;
}

export interface IGoodLight {
    id: number;
    status: "DRAFT" | "ACTIVATED" | "ARCHIVED";
    name: string;
    img_cover: string;
}

export interface IGoodModel {
    id: number;
    status: "DRAFT" | "ACTIVATED" | "ARCHIVED";
    name: string;
    img_cover: string;
}

export interface IGood {
    id: number;
    user_id: number;
    status: "DRAFT" | "ACTIVATED" | "ARCHIVED";
    name: string;
    img_cover: string;
    description: string;
    place: string;
    availabilities: IGoodAvailability[];
    tickets: IGoodTicket[];
    /*
    resume: {
        entrances_sold: number,
        prestations_sold: number,
        ca_ttc: number
    };
    transfer: {
        id: number,
        status: string,
        amount: number,
        error: string,
        executed_at: string
    };*/
}

export interface IGoodTicket {
    id: number;
    label: string;
    start: string;
    end: string;
    quantity: number;
    total: number;
    scanned_at: string;
    code: string;
    reference: string;
    total_format: string;
    user_email: string;
    user_phone: string;
    peoples: string[];
    user_requests: string;
}

export interface IGoodInfos {
    id: number;
    name: string;
    description: string;
}

export interface IGoodImage {
    id: number;
    base64: string;
}

export interface IManageInfosRequest {
    id: number,
    description: string,
    base64?: string
}

export interface IGoodAvailability {
    id: number,
    day: string,
    quantity: number,
    quantity_available: number,
    price_public: number,
}

export interface IGoodAvailabilitiesRequest {
    id: number,
    start: string;
    end: string;
    quantity: number,
    price_public: number,
}

export interface IGoodAvailabilityRequest {
    id: number;
    quantity: number;
    price_public: number;
}

export interface IPrestationModelRequest {
    event_id: number,
    prestation_id: number
}

export interface IResponseStatus {
    data: {
        id?: number;
        pdf?: string;
        url?: string;
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

const initState = {
    goods: null,
    goodsModel: null,
} as IGoodState

const hostelReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case Constants.HOSTEL_LIST_SUCCESS:
            draft.goods = action.payload.data.goods;
            break;
        case Constants.HOSTEL_LIST_MODEL_GOOD_SUCCESS:
            draft.goodsModel = action.payload.data.goods;
            break;
        default:
            return draft;
    }
})

export default hostelReducer