import { API_URL } from "../constants";
import { IConfiguration } from "../reducers";

export const listFutureService = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/list/future`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listFinishedService = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/list/finished`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listConfiguration = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/configuration/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createConfiguration = (apiKey: string, value: IConfiguration) => {
    const ENDPOINT: RequestInfo = `${API_URL}/configuration/create`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(value),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const removeConfiguration = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/configuration/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateConfiguration = (apiKey: string, value: IConfiguration) => {
    const ENDPOINT: RequestInfo = `${API_URL}/configuration/update`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(value),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const getService = (apiKey: string, occurence_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/get`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ occurence_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const removeService = (apiKey: string, occurence_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ occurence_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const manageService = (apiKey: string, occurence_id: number, quantity: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/manage`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ occurence_id, quantity }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const manageTableService = (apiKey: string, ticket_id: number, info: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/manage/table`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ ticket_id, info }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listTransfer = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/transfers/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const downloadResume = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/resume/download`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const downloadService = (apiKey: string, occurence_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/download`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ occurence_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};