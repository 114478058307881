import { useRef, useState } from 'react';
import { Button, Card, Col, Image, Row, Overlay, Popover } from 'react-bootstrap';
import { ArrowClockwise, Files, ClipboardCheck, Download } from 'react-bootstrap-icons';
import Apple from '../../../assets/AppStore.png'
import Google from '../../../assets/GooglePlay.png'
import ConnectedDevice from './ConnectedDevice';
import { useEffect } from 'react';
import { PropsFromReduxScanners } from '../containers/ScannersContainer';
import { formatHours } from './../../../utils/formattage';
import { toast } from 'react-hot-toast';


const ScannerComponent = (props: PropsFromReduxScanners) => {

    const [target, setTarget] = useState(null);
    const [showPopOver, setShowPopOver] = useState(false);

    useEffect(() => {
        props.getCode()
        props.getDevices()
    }, [])

    const copyToClipboard = (content: string, event: any) => {
        navigator.clipboard.writeText(content).then(function () {
            setShowPopOver(true);
            setTimeout(() => { setShowPopOver(false) }, 2000)
            setTarget(event.target);
        }, function () {
            toast.error('Erreur lors de la copie dans le presse-papier.')
        });
    }

    return (
        <>
            {<>
                <Row className='mt-2 g-2 flex-wrap'>
                    <Col className='d-flex gap-2 flex-column col-12'>
                        <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                                <Card.Title className="d-flex w-100 align-items-center pt-2">
                                    <div className="w-100 fw-bolder">Synchronisation</div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>Pour utiliser un scanner, téléchargez sur l'appareil souhaité l'application <b>Lib-Events scanner</b>. <br /> Une fois démarée, saisissez le code de synchronisation affiché ci-dessous.</Card.Text>
                                <Row className='mb-4 mt-4'>
                                    <Col xs={6} className='d-flex justify-content-center align-items-center'>
                                        <p className='mb-0 fw-bold' style={{ fontSize: 35 }}>{props.codeInfos ? props.codeInfos.sync_code : '--'}</p>
                                    </Col>
                                    <Col xs={6} className='d-flex justify-content-center align-items-center'>
                                        <p className='mb-0 me-3 d-none d-md-block'>Valide jusqu'à {formatHours(props.codeInfos ? props.codeInfos.sync_code_expire_at : '')}</p>
                                        <Button variant='outline-secondary' onClick={props.getCode}><ArrowClockwise style={{ fontSize: 18 }} /></Button>
                                    </Col>
                                    <Col xs={12} className='d-flex justify-content-center align-items-center my-3 d-md-none'>
                                        <p className='mb-0 me-3'>Valide jusqu'à {formatHours(props.codeInfos ? props.codeInfos.sync_code_expire_at : '')}</p>
                                    </Col>
                                </Row>
                                <Card.Text className='mt-5'>L'application est disponible sur l'Apple Store et le Google Play store.</Card.Text>
                                <Row>
                                    <Col xs={6} className='d-flex justify-content-center flex-column align-items-center p-2'>
                                        <Image src={Apple} className='available-on w-100' style={{cursor: 'pointer'}} alt='Disponible sur Apple Store' onClick={(e) => window.open(props.codeInfos ? props.codeInfos.scanner_apple_store : '', '_blank')} />
                                        <Button variant='outline-secondary' className="available-on w-100 mt-2 px-1" onClick={(e) => window.open(props.codeInfos ? props.codeInfos.scanner_apple_store : '', '_blank')}>
                                            <Download style={{ fontSize: 18 }} className="me-2" />
                                            Télécharger
                                        </Button>
                                        <Button variant='outline-secondary' className="available-on w-100 mt-2 px-1" onClick={(e) => copyToClipboard(props.codeInfos ? props.codeInfos.scanner_apple_store : '', e)}>
                                            <Files style={{ fontSize: 18 }} className="me-2" />
                                            Copier le lien
                                        </Button>
                                    </Col>
                                    <Col xs={6} className='d-flex flex-column justify-content-center align-items-center p-2'>
                                        <Image src={Google} className='available-on w-100' style={{cursor: 'pointer'}} alt='Disponible sur Google Play' onClick={(e) => window.open(props.codeInfos ? props.codeInfos.scanner_android_store : '', '_blank')} />
                                        <Button variant='outline-secondary' className="available-on w-100 mt-2 px-1" onClick={(e) => window.open(props.codeInfos ? props.codeInfos.scanner_android_store : '', '_blank')}>
                                            <Download style={{ fontSize: 18 }} className="me-2" />
                                            Télécharger
                                        </Button>
                                        <Button variant='outline-secondary' className="available-on w-100 mt-2 px-1" onClick={(e) => copyToClipboard(props.codeInfos ? props.codeInfos.scanner_android_store : '', e)}>
                                            <Files style={{ fontSize: 18 }} className="me-2" />
                                            Copier le lien
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Overlay
                    show={showPopOver}
                    target={target}
                    placement="bottom"
                    containerPadding={20}
                >
                    <Popover id="popover-contained" style={{ width: '110px' }}>
                        <Popover.Body className="d-flex align-items-center">
                            <ClipboardCheck size={15} className='me-2' /> Copié !
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </>
            }
        {<>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Appareils</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text id='app'>Vous pouvez activer ou désactiver les notifications liées à vos événements pour chaque appareil.</Card.Text>
                            <div className='w-100 overflow-hidden'>
                                {props.devices && props.devices.length > 0 ? <>
                                    {props.devices.map(device => {
                                        return <ConnectedDevice key={device.id} device={device} removeDevice={props.removeDevice} getDevices={props.getDevices} changeNotifications={props.changeNotifications} />
                                    })}
                                </>
                                    :
                                    <p><i>Aucun appareil connecté</i></p>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
        }
        </>
    )
}

export default ScannerComponent