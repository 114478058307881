import { Outlet } from "react-router-dom"
import PackContainer from "../containers/PackContainer"

const PackPage = () => {
    return <>
        <PackContainer />
        <Outlet />
    </>
}

export default PackPage