
export const getEventInternPath = (
  subtype: string,
  category: string,
) => {

  if(subtype == 'PACK') {
    return 'pack';
  }
  else {
    if(category == 'PARTY') {
      return 'party';
    }
    else if(category == 'POOL') {
      return 'pool';
    }
    else if(category == 'TRANSAT') {
      return 'transat';
    }
    else if(category == 'SPA') {
      return 'spa';
    }
    else if(category == 'RESTAURANT') {
      return 'restaurant';
    }
  }

  return '';
}
