import { Outlet } from "react-router-dom"
import PartyContainer from "../containers/PartyContainer"

const PartyPage = () => {
    return <>
        <PartyContainer />
        <Outlet />
    </>
}

export default PartyPage