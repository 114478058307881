import * as Constants from '../constants'
import { IConfiguration, IService } from '../reducers'

export function listFutureService() {
    return {
        type: Constants.LIST_FUTURE_SERVICE
    }
}

export function listFinishedService() {
    return {
        type: Constants.LIST_FINISHED_SERVICE
    }
}


export function listConfiguration() {
    return {
        type: Constants.LIST_CONFIGURATION
    }
}

export function createConfiguration(value: IConfiguration, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.CREATE_CONFIGURATION, value, resolve, reject
    }
}

export function removeConfiguration(id: number | undefined, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.REMOVE_CONFIGURATION, id, resolve, reject
    }
}

export function updateConfiguration(value: IConfiguration, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.UPDATE_CONFIGURATION, value, resolve, reject
    }
}

export function getService(occurence_id: number, resolve: (service: IService) => void, reject: any) {
    return {
        type: Constants.GET_SERVICE, occurence_id, resolve, reject
    }
}

export function removeService(occurence_id: number, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.REMOVE_SERVICE, occurence_id, resolve, reject
    }
}

export function manageService(occurence_id: number, quantity: number, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.MANAGE_SERVICE, occurence_id, quantity, resolve, reject
    }
}

export function manageTableService(ticket_id: number, info: string, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.MANAGE_TABLE_SERVICE, ticket_id, info, resolve, reject
    }
}

export function listTransfer() {
    return {
        type: Constants.LIST_TRANSFER
    }
}

export function downloadResume(id: number, resolve: (pdf: string) => void, reject: any) {
    return {
        type: Constants.DOWNLOAD_EAT_RESUME, id, resolve, reject
    }
}

export function downloadService(occurence_id: number, resolve: (pdf: string) => void, reject: any) {
    return {
        type: Constants.DOWNLOAD_SERVICE, occurence_id, resolve, reject
    }
}