import { createRef } from 'react'
import { Button, Modal, Col, Card } from 'react-bootstrap'

import 'tui-image-editor/dist/tui-image-editor.css';

// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';

interface CoverImageEditorModalTUIProps {
    show: boolean
    handleHide: () => void
    image?: string
    updatedImage: (base64: string) => void
}

const CoverImageEditorModalTUI = (props: CoverImageEditorModalTUIProps) => {

    const TUIEditor = createRef()

    const saveImage = () => {

        /* @ts-ignore */
        const imageEditorInst = TUIEditor.current.imageEditorInst;
        const data = imageEditorInst.toDataURL();

        if (!!data) {
            const extension = data.split(";")[0].split("/")[1];
            props.updatedImage(data)
        }
    }

    const handleCancel = () => {
        props.handleHide()
    }

    var whiteTheme = {
        'common.bi.image': 'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
        'common.bisize.width': '251px',
        'common.bisize.height': '21px',
        'common.backgroundImage': './img/bg.png',
        'common.backgroundColor': '#fff',
        'common.border': '0',
      
        // header
        'header.display': 'none',
        'header.backgroundImage': 'none',
        'header.backgroundColor': 'transparent',
        'header.border': '0px',
      
        // load button
        'loadButton.backgroundColor': '#fff',
        'loadButton.border': '1px solid #ddd',
        'loadButton.color': '#222',
        'loadButton.fontFamily': "'Noto Sans', sans-serif",
        'loadButton.fontSize': '12px',
      
        // download button
        'downloadButton.backgroundColor': '#fdba3b',
        'downloadButton.border': '1px solid #fdba3b',
        'downloadButton.color': '#fff',
        'downloadButton.fontFamily': "'Noto Sans', sans-serif",
        'downloadButton.fontSize': '12px',
      
        // main icons
        'menu.normalIcon.color': '#8a8a8a',
        'menu.activeIcon.color': '#555555',
        'menu.disabledIcon.color': '#434343',
        'menu.hoverIcon.color': '#e9e9e9',
        'menu.iconSize.width': '24px',
        'menu.iconSize.height': '24px',
      
        // submenu icons
        'submenu.normalIcon.color': '#8a8a8a',
        'submenu.activeIcon.color': '#555555',
        'submenu.iconSize.width': '32px',
        'submenu.iconSize.height': '32px',
      
        // submenu primary color
        'submenu.backgroundColor': 'transparent',
        'submenu.partition.color': '#e5e5e5',
      
        // submenu labels
        'submenu.normalLabel.color': '#858585',
        'submenu.normalLabel.fontWeight': 'normal',
        'submenu.activeLabel.color': '#000',
        'submenu.activeLabel.fontWeight': 'normal',
      
        // checkbox style
        'checkbox.border': '1px solid #ccc',
        'checkbox.backgroundColor': '#fff',
      
        // rango style
        'range.pointer.color': '#333',
        'range.bar.color': '#ccc',
        'range.subbar.color': '#606060',
      
        'range.disabledPointer.color': '#d3d3d3',
        'range.disabledBar.color': 'rgba(85,85,85,0.06)',
        'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',
      
        'range.value.color': '#000',
        'range.value.fontWeight': 'normal',
        'range.value.fontSize': '11px',
        'range.value.border': '0',
        'range.value.backgroundColor': '#f5f5f5',
        'range.title.color': '#000',
        'range.title.fontWeight': 'lighter',
      
        // colorpicker style
        'colorpicker.button.border': '0px',
        'colorpicker.title.color': '#000',
    };

    return (
        <Modal enforceFocus={false} show={props.show} className="modal-full" onHide={props.handleHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Customisez votre image</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-block d-lg-none">
                <Card.Text>Pour utiliser l'éditeur, merci d'utiliser un grand écran.</Card.Text>
            </Modal.Body>
            <Modal.Body className="d-none d-lg-block">
                <ImageEditor
                    ref={TUIEditor}
                    includeUI={{
                        loadImage: {
                            path: props.image,
                            name: "Customize"
                        },
                        //locale: locale_fr_FR, 
                        theme: whiteTheme,
                        menu: [
                            //"crop",
                            "draw",
                            "text",
                            "mask",
                            "icon",
                            "shape",
                            "filter",
                            "flip",
                            "rotate"
                        ],
                        initMenu: "draw",
                        uiSize: {
                            width: "100%",
                            height: "700px"
                        },
                        menuBarPosition: "left"
                    }}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70
                    }}
                    usageStatistics={false}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => handleCancel()}>Retour</Button>
                </Col>
                <Col className="text-end">
                    <Button variant='primary' className='btn-modal-device me-1' onClick={saveImage}>Sauvegarder</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}

export default CoverImageEditorModalTUI
