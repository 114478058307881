import {Navigate, Outlet, RouteObject} from "react-router-dom";
import NotFound from "../common/components/NotFound";
import AuthRoutes from "../modules/auth/routes";
import DashRoutes from "../modules/dashboard/routes";
import {PartyRoutes} from "../modules/party/routes";
import {PoolRoutes} from "../modules/pool/routes";
import {TransatRoutes} from "../modules/transat/routes";
import {SpaRoutes} from "../modules/spa/routes";
import {RestaurantRoutes} from "../modules/restaurant/routes";
import {HostelRoutes} from "../modules/hostel/routes";
import {PackRoutes} from "../modules/pack/routes";
import {BusinessRecordRoutes} from './../modules/business-record/routes';
import {ScannerRoutes} from './../modules/scanners/routes';
import {NetworksRoutes} from './../modules/networks/routes';
import {BankInfoRoutes} from './../modules/bank-info/routes';

const Routes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/',
            element: <Outlet />,
            children: [
                ...AuthRoutes(isLogged),
                ...DashRoutes(isLogged),
                
                ...PartyRoutes(isLogged),
                ...PoolRoutes(isLogged),
                ...TransatRoutes(isLogged),
                ...SpaRoutes(isLogged),
                ...RestaurantRoutes(isLogged),
                ...HostelRoutes(isLogged),
                ...PackRoutes(isLogged),

                ...BusinessRecordRoutes(isLogged),
                ...ScannerRoutes(isLogged),
                ...NetworksRoutes(isLogged),
                ...BankInfoRoutes(isLogged),
                {path: '*', element: <Navigate to='/404' replace />},
                {path: '404', element: <NotFound />},
            ],
        }
    ]
}

export default Routes

