import { useEffect } from 'react'
import { Badge, Button, Card, Col } from 'react-bootstrap'
import { FilePdf } from 'react-bootstrap-icons'
import { ITransfer } from '../reducers'
import { toast } from 'react-hot-toast';
import { formatDate } from '../../../utils/formattage';

export interface TransferCardComponentProps {
    transfer: ITransfer,
    downloadResume: (id: number) => Promise<string>
}

const TransferCardComponent = (props: TransferCardComponentProps) => {
    useEffect(() => {
    }, [])
    
    const download = () => {
        props.downloadResume(props.transfer.event_id)
        .then((data: any) => {            
            window.open(data.pdf, '_blank', 'noopener,noreferrer');
        })
        .catch(error => { toast.error(error.message.fr) })
    }

    let statusTag: string
    let statusInfo: string

    switch (props.transfer.status) {
        case 'EXECUTED': statusTag = 'success'; statusInfo = 'Exécuté'; break;
        case 'ERROR': statusTag = 'danger'; statusInfo = 'Erreur'; break;
        default: statusTag = 'info'; statusInfo = 'En cours'; break;
    }

    return (
        <Col xs={12} md={6}>
            <Card className='flex-grow-1' style={{ marginTop: 20 }}>
                <Card.Header className='d-flex justify-content-between'>
                    <div className={`m-0 align-self-center h-50`}>{props.transfer.label}</div>
                </Card.Header>
                <Card.Body style={{ position: 'relative' }}>
                    <div className='d-flex gap-2'>
                        <div className="align-items-left">
                            <div className="w-100 fw-bolder"><h4>{props.transfer.amount} €</h4></div>
                        </div>
                        <div style={{textAlign: 'right', flex: 1}}>
                            {props.transfer.status === 'EXECUTED' && <div style={{fontSize: 18}}>{formatDate(props.transfer.executed_at)}</div>}
                            {props.transfer.status === 'ERROR' && <div className='text-danger' style={{fontSize: 18}}>{props.transfer.error}</div>}
                        </div>
                    </div>
                    <div className='d-flex gap-2 mt-2'>
                        <div className="align-items-left">
                            <Button variant='outline-secondary' style={{padding: 6, paddingRight: 15, paddingLeft: 15, fontSize: 16}} onClick={() => download()}><FilePdf /> Télécharger</Button>
                        </div>
                        <div style={{textAlign: 'right', flex: 1}}>
                            <Badge bg={statusTag} style={{padding: 10, paddingRight: 15, paddingLeft: 15, fontSize: 16}}>{statusInfo}</Badge>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default TransferCardComponent