import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import SupportComponent from '../components/SupportComponent';
import { ISupport } from '../reducers';
import { support } from '../actions';


const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        support: (supportData: ISupport) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(support(supportData, resolve, reject))
            })
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxSupport = ConnectedProps<typeof connector>

export default connector(SupportComponent)