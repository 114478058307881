export interface BreadCrumbPath {
    path: string
    title: string
    breadcrumb?: {
        title: string
        path: string
    }[]
}

export const paths: BreadCrumbPath[] = [
    { path: '/', title: 'Tableau de bord', breadcrumb: [{ title: '', path: '' }] },
    
    { path: '/404', title: 'Contenu inexistant', breadcrumb: [{ title: '', path: '' }] },

    { path: '/party', title: 'Soirées', breadcrumb: [{ title: '', path: '' }] },
    { path: '/party/new', title: 'Créer', breadcrumb: [{ title: 'Soirées', path: '/party' }] },
    { path: '/party/models', title: 'A partir de..', breadcrumb: [{ title: 'Soirées', path: '/party' }, { title: 'Créer', path: '/party/new'}] },
    { path: '/party/create', title: 'Brouillon', breadcrumb: [{ title: 'Soirées', path: '/party' }] },
    { path: '/party/edit', title: 'Modifier', breadcrumb: [{ title: 'Soirées', path: '/party' }] },
    { path: '/party/resume', title: 'Détail', breadcrumb: [{ title: 'Soirées', path: '/party' }] },

    { path: '/pool', title: 'Pool party', breadcrumb: [{ title: '', path: '' }] },
    { path: '/pool/new', title: 'Créer', breadcrumb: [{ title: 'Pool party', path: '/pool' }] },
    { path: '/pool/models', title: 'A partir de..', breadcrumb: [{ title: 'Pool party', path: '/pool' }, { title: 'Créer', path: '/pool/new'}] },
    { path: '/pool/create', title: 'Brouillon', breadcrumb: [{ title: 'Pool party', path: '/pool' }] },
    { path: '/pool/edit', title: 'Modifier', breadcrumb: [{ title: 'Pool party', path: '/pool' }] },
    { path: '/pool/resume', title: 'Détail', breadcrumb: [{ title: 'Pool party', path: '/pool' }] },

    { path: '/transat', title: 'Transat', breadcrumb: [{ title: '', path: '' }] },
    { path: '/transat/new', title: 'Créer', breadcrumb: [{ title: 'Transat', path: '/transat' }] },
    { path: '/transat/models', title: 'A partir de..', breadcrumb: [{ title: 'Transat', path: '/transat' }, { title: 'Créer', path: '/transat/new'}] },
    { path: '/transat/create', title: 'Brouillon', breadcrumb: [{ title: 'Transat', path: '/transat' }] },
    { path: '/transat/edit', title: 'Modifier', breadcrumb: [{ title: 'Transat', path: '/transat' }] },
    { path: '/transat/resume', title: 'Détail', breadcrumb: [{ title: 'Transat', path: '/transat' }] },

    { path: '/spa', title: 'SPA', breadcrumb: [{ title: '', path: '' }] },
    { path: '/spa/new', title: 'Créer', breadcrumb: [{ title: 'SPA', path: '/spa' }] },
    { path: '/spa/models', title: 'A partir de..', breadcrumb: [{ title: 'SPA', path: '/spa' }, { title: 'Créer', path: '/spa/new'}] },
    { path: '/spa/create', title: 'Brouillon', breadcrumb: [{ title: 'SPA', path: '/spa' }] },
    { path: '/spa/edit', title: 'Modifier', breadcrumb: [{ title: 'SPA', path: '/spa' }] },
    { path: '/spa/resume', title: 'Détail', breadcrumb: [{ title: 'SPA', path: '/spa' }] },

    { path: '/restaurant', title: 'Restaurant', breadcrumb: [{ title: '', path: '' }] },
    { path: '/restaurant/resume', title: 'Service', breadcrumb: [{ title: 'Restaurant', path: '/restaurant' }] },
    { path: '/restaurant/configure', title: 'Configuration', breadcrumb: [{ title: 'Restaurant', path: '/restaurant' }] },
    { path: '/restaurant/transfers', title: 'Virements', breadcrumb: [{ title: 'Restaurant', path: '/restaurant' }] },

    { path: '/hostel', title: 'Hébergement', breadcrumb: [{ title: '', path: '' }] },
    { path: '/hostel/new', title: 'Créer', breadcrumb: [{ title: 'Hébergement', path: '/hostel' }] },
    { path: '/hostel/models', title: 'A partir de..', breadcrumb: [{ title: 'Hébergement', path: '/hostel' }, { title: 'Créer', path: '/hostel/new'}] },
    { path: '/hostel/create', title: 'Brouillon', breadcrumb: [{ title: 'Hébergement', path: '/hostel' }] },
    { path: '/hostel/edit', title: 'Modifier', breadcrumb: [{ title: 'Hébergement', path: '/hostel' }] },
    { path: '/hostel/resume', title: 'Détail', breadcrumb: [{ title: 'Hébergement', path: '/hostel' }] },

    { path: '/pack', title: 'Pack', breadcrumb: [{ title: '', path: '' }] },
    { path: '/pack/new', title: 'Créer', breadcrumb: [{ title: 'Pack', path: '/pack' }] },
    { path: '/pack/models', title: 'A partir de..', breadcrumb: [{ title: 'Pack', path: '/pack' }, { title: 'Créer', path: '/pack/new'}] },
    { path: '/pack/create', title: 'Brouillon', breadcrumb: [{ title: 'Pack', path: '/pack' }] },
    { path: '/pack/edit', title: 'Modifier', breadcrumb: [{ title: 'Pack', path: '/pack' }] },
    { path: '/pack/resume', title: 'Détail', breadcrumb: [{ title: 'Pack', path: '/pack' }] },
    
    { path: '/business-record', title: 'Fiche établissement', breadcrumb: [{ title: '', path: '' }] },

    { path: '/networks', title: 'Réseaux', breadcrumb: [{ title: '', path: '' }] },
    
    { path: '/scanners', title: 'Scanners', breadcrumb: [{ title: '', path: '' }] },

    { path: '/bank-info', title: 'Données bancaires', breadcrumb: [{ title: '', path: '' }] },

    { path: '/change-password', title: 'Mot de passe', breadcrumb: [{ title: '', path: '' }] },
    
    { path: '/support', title: 'Support', breadcrumb: [{ title: '', path: '' }] },
]