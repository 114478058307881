import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import PostEventCreateManualModalComponent from '../components/PostEventCreateManualModalComponent'
import * as Actions from "../actions";
import { IManualPostRequest } from "../reducers";

const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createManualPost: (infos: IManualPostRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.createManualPost(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PostEventCreateManualModalComponent);