import {Outlet, RouteObject} from "react-router-dom";
import * as ROUTES from './scanners'
import withPermissions from "../../../routes/withPermissions";
import ScannersPage from "../pages/ScannersPage";

export const ScannerRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: ROUTES.SCANNERS,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <ScannersPage />}
            ]
        }
    ]
}