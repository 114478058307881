import {Button, Card, Form, Row, Col, Modal} from 'react-bootstrap'
import {Trash} from 'react-bootstrap-icons'
import {IChangeNotifRequest, IDevice} from '../reducers'
import {toast} from 'react-hot-toast';
import {useState} from 'react';


interface Props {
    device: IDevice
    removeDevice: (id: number) => Promise<string>
    getDevices: () => void
    changeNotifications: (infos: IChangeNotifRequest) => Promise<string>
}

const ConnectedDevice = (props: Props) => {

    const [showModal, setShowModal] = useState(false);

    const handleRemove = () => {
        props.removeDevice(props.device.id).then(status => {
            props.getDevices()
            toast.success('Appareil supprimé.')
        }).catch(error => toast.error(error.message.fr))
    }

    const handleChange = () => {
        props.changeNotifications({id: props.device.id, active: !props.device.notifications_active}).then(status => {
            props.getDevices()
            toast.success('Notifications changées.')
        }).catch(error => toast.error(error.message.fr))
    }

    return (
        <>
            <Card className='deviceCard m-2 px-2'>
                <Card.Body className='align-items-center'>
                    <Row>
                        <Col xs={10} md={5} className='mb-0 d-flex align-items-center'>
                            <Card.Title className='small mb-0 text-muted fw-bolder' style={{fontSize: 18}}>{props.device.designation}</Card.Title>
                        </Col>
                        <Col xs={2} md={2}className="d-flex justify-content-end d-md-none">
                            <Button onClick={() => setShowModal(true)} variant='danger'><Trash /></Button>
                        </Col>
                        <Col xs={12} md={5} className='mb-0 d-flex mt-2 mt-md-0 align-items-center'>
                            <Form.Check
                                checked={props.device.notifications_active}
                                id={`checkbox`}
                                label={'Notifications activées'}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={2} className="flex-row justify-content-end d-none d-md-flex">
                            <Button onClick={() => setShowModal(true)} variant='danger'><Trash /></Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(false)} className='w-100' backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title className='w-100'>Supprimer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div>Confirmez-vous la suppression de l'appareil {props.device.designation} ?</div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModal(false)}>Annuler</Button>
                    </Col>
                    <Col className="text-end">
                        <Button variant='primary' className='btn-modal-device me-1' onClick={handleRemove}>Valider</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConnectedDevice