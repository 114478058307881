import { API_URL_GOODS } from "../constants";
import { IGoodAvailabilitiesRequest, IGoodAvailabilityRequest, IGoodImage, IGoodInfos, IManageInfosRequest, } from "../reducers";

export const list = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listModel = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/list/models`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createModel = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/create/model`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createEmpty = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/create/empty`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const get = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/get`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const publishCheck = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/publish/check`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const publish = (apiKey: string, id: number, scheduled_at:string) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/publish`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id, scheduled_at }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const remove = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateInfos = (apiKey: string, infos: IGoodInfos) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/update/infos`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateCover = (apiKey: string, infos: IGoodImage) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/update/cover`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const manageInfos = (apiKey: string, infos: IManageInfosRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/manage/infos`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const availabilitiesCreate = (apiKey: string, infos: IGoodAvailabilitiesRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/availabilities/create`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const availabilityUpdate = (apiKey: string, infos: IGoodAvailabilityRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_GOODS}/availabilities/update`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};
