import React from 'react'
import { Image } from 'react-bootstrap'

interface HeaderComponentProps {
    bgImage: string | undefined
}

const HeaderComponent = (props:HeaderComponentProps) => {
  return (
    <div className="jumbotron">
        <div className="d-flex w-100">
            <Image className='jumbotronImage' src={props.bgImage}/>
        </div>
    </div>
  )
}

export default HeaderComponent