import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/services";

export const LIST_CONFIGURATION = "LIST_CONFIGURATION";
export const LIST_CONFIGURATION_SUCCESS = "LIST_CONFIGURATION_SUCCESS";
export const LIST_CONFIGURATION_FAILED = "LIST_CONFIGURATION_FAILED";

export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const CREATE_CONFIGURATION_SUCCESS = "CREATE_CONFIGURATION_SUCCESS";
export const CREATE_CONFIGURATION_FAILED = "CREATE_CONFIGURATION_FAILED";

export const REMOVE_CONFIGURATION = "REMOVE_CONFIGURATION";
export const REMOVE_CONFIGURATION_SUCCESS = "REMOVE_CONFIGURATION_SUCCESS";
export const REMOVE_CONFIGURATION_FAILED = "REMOVE_CONFIGURATION_FAILED";

export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const UPDATE_CONFIGURATION_SUCCESS = "UPDATE_CONFIGURATION_SUCCESS";
export const UPDATE_CONFIGURATION_FAILED = "UPDATE_CONFIGURATION_FAILED";

export const LIST_FUTURE_SERVICE = "LIST_FUTURE_SERVICE";
export const LIST_FUTURE_SERVICE_SUCCESS = "LIST_FUTURE_SERVICE_SUCCESS";
export const LIST_FUTURE_SERVICE_FAILED = "LIST_FUTURE_SERVICE_FAILED";

export const LIST_FINISHED_SERVICE = "LIST_FINISHED_SERVICE";
export const LIST_FINISHED_SERVICE_SUCCESS = "LIST_FINISHED_SERVICE_SUCCESS";
export const LIST_FINISHED_SERVICE_FAILED = "LIST_FINISHED_SERVICE_FAILED";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILED = "GET_SERVICE_FAILED";

export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const REMOVE_SERVICE_SUCCESS = "REMOVE_SERVICE_SUCCESS";
export const REMOVE_SERVICE_FAILED = "REMOVE_SERVICE_FAILED";

export const MANAGE_SERVICE = "MANAGE_SERVICE";
export const MANAGE_SERVICE_SUCCESS = "MANAGE_SERVICE_SUCCESS";
export const MANAGE_SERVICE_FAILED = "MANAGE_SERVICE_FAILED";

export const MANAGE_TABLE_SERVICE = "MANAGE_TABLE_SERVICE";
export const MANAGE_TABLE_SERVICE_SUCCESS = "MANAGE_TABLE_SERVICE_SUCCESS";
export const MANAGE_TABLE_SERVICE_FAILED = "MANAGE_TABLE_SERVICE_FAILED";

export const LIST_TRANSFER = "LIST_TRANSFER";
export const LIST_TRANSFER_SUCCESS = "LIST_TRANSFER_SUCCESS";
export const LIST_TRANSFER_FAILED = "LIST_TRANSFER_FAILED";

export const DOWNLOAD_EAT_RESUME = "DOWNLOAD_EAT_RESUME";
export const DOWNLOAD_EAT_RESUME_SUCCESS = "DOWNLOAD_EAT_RESUME_SUCCESS";
export const DOWNLOAD_EAT_RESUME_FAILED = "DOWNLOAD_EAT_RESUME_FAILED";

export const DOWNLOAD_SERVICE = "DOWNLOAD_SERVICE";
export const DOWNLOAD_SERVICE_SUCCESS = "DOWNLOAD_SERVICE_SUCCESS";
export const DOWNLOAD_SERVICE_FAILED = "DOWNLOAD_SERVICE_FAILED";