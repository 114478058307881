import { Navigate, RouteObject, Outlet } from "react-router-dom"
import LoginPage from "../pages/LoginPage"
import RegisterPage from "../pages/RegisterPage"
import ForgotPasswordPage from "../pages/ForgotPasswordPage"
import ChangePasswordPage from "../pages/ChangePasswordPage"
import SupportPage from "../pages/SupportPage"
import withPermissions from "../../../routes/withPermissions";
import *  as authRoutes from "./auth"

let AuthRoutes = (isLogged: boolean):RouteObject[] => {
    return [
        {
            path: authRoutes.LOGIN,
            element: !isLogged ? <LoginPage /> : <Navigate to='/' replace/>,
        },
        {
            path: authRoutes.REGISTER,
            element: !isLogged ? <RegisterPage /> : <Navigate to='/' replace/>,
        },
        {
            path: authRoutes.FORGOT_PASSWORD,
            element: !isLogged ? <ForgotPasswordPage /> : <Navigate to='/' replace/>,
        },
        {
            path: authRoutes.CHANGE_PASSWORD,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <ChangePasswordPage />}
            ]
        },
        {
            path: authRoutes.SUPPORT,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <SupportPage />}
            ]
        }
    ]
}

export default AuthRoutes