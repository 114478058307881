import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import { IGood, IManageInfosRequest } from "../reducers";
import HostelEditComponent from "../components/HostelEditComponent";

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get: (id: number) => {
            return new Promise((resolve: (good: IGood) => void, reject: any) => {
                dispatch(Actions.get(id, resolve, reject));
            });
        },
        manageInfos: (infos: IManageInfosRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.manageInfos(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HostelEditComponent);