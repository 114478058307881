import {Outlet} from "react-router-dom"
import BankInfoContainer from "../containers/BankInfoContainer"

const BankInfoPage = () => {
    return <>
        <BankInfoContainer />
        <Outlet />
    </>
}

export default BankInfoPage