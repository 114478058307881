import * as Constants from "../constants";
import { IFacebookConfirm, INetworkCredentials, INetworkType } from "../reducers";

export function getNetworksList() {
    return {
        type: Constants.GET_NETWORKS_LIST,
    };
}

export function getNetworksListActive() {
    return {
        type: Constants.GET_NETWORKS_LIST_ACTIVE,
    };
}

export function getNetworksReferences() {
    return {
        type: Constants.GET_NETWORKS_REFERENCES
    };
}

export function createNetwork(data: INetworkCredentials, resolve: any, reject: any) {
    return {
        type: Constants.CREATE_NETWORK,
        data,
        resolve,
        reject
    };
}

export function updateNetwork(data: INetworkCredentials, resolve: any, reject: any) {
    return {
        type: Constants.UPDATE_NETWORK,
        data,
        resolve,
        reject
    };
}

export function deleteNetwork(id: number, resolve: any, reject: any) {
    return {
        type: Constants.DELETE_NETWORK,
        id,
        resolve,
        reject
    };
}

export function facebookLoginUrl(data: INetworkType, resolve: any, reject: any) {
    return {
        type: Constants.FACEBOOK_LOGIN_URL,
        data,
        resolve,
        reject
    };
}

export function facebookPageList(id: number, resolve: any, reject: any) {
    return {
        type: Constants.FACEBOOK_PAGE_LIST,
        id,
        resolve,
        reject
    };
}

export function facebookLoginConfirm(data: IFacebookConfirm, resolve: any, reject: any) {
    return {
        type: Constants.FACEBOOK_LOGIN_CONFIRM,
        data,
        resolve,
        reject
    };
}

export function instagramLoginUrl(data: INetworkType, resolve: any, reject: any) {
    return {
        type: Constants.INSTAGRAM_LOGIN_URL,
        data,
        resolve,
        reject
    };
}

export function instagramPageList(id: number, resolve: any, reject: any) {
    return {
        type: Constants.INSTAGRAM_PAGE_LIST,
        id,
        resolve,
        reject
    };
}

export function instagramLoginConfirm(data: IFacebookConfirm, resolve: any, reject: any) {
    return {
        type: Constants.INSTAGRAM_LOGIN_CONFIRM,
        data,
        resolve,
        reject
    };
}
