import {Outlet} from "react-router-dom"
import ScannersContainer from "../containers/ScannersContainer"

const ScannersPage = () => {
    return <>
        <ScannersContainer />
        <Outlet />
    </>
}

export default ScannersPage