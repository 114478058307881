import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/networks";

export const GET_NETWORKS_LIST = "GET_NETWORKS_LIST";
export const GET_NETWORKS_LIST_SUCCESS = "GET_NETWORKS_LIST_SUCCESS";
export const GET_NETWORKS_LIST_FAIL = "GET_NETWORKS_LIST_FAIL";

export const GET_NETWORKS_LIST_ACTIVE = "GET_NETWORKS_LIST_ACTIVE";
export const GET_NETWORKS_LIST_ACTIVE_SUCCESS = "GET_NETWORKS_LIST_ACTIVE_SUCCESS";
export const GET_NETWORKS_LIST_ACTIVE_FAIL = "GET_NETWORKS_LIST_ACTIVE_FAIL";

export const GET_NETWORKS_REFERENCES = "GET_NETWORKS_REFERENCES";
export const GET_NETWORKS_REFERENCES_SUCCESS = "GET_NETWORKS_REFERENCES_SUCCESS";
export const GET_NETWORKS_REFERENCES_FAIL = "GET_NETWORKS_REFERENCES_FAIL";

export const CREATE_NETWORK = "CREATE_NETWORK";
export const CREATE_NETWORK_SUCCESS = "CREATE_NETWORK_SUCCESS";
export const CREATE_NETWORK_FAIL = "CREATE_NETWORK_FAIL";

export const UPDATE_NETWORK = "UPDATE_NETWORK";
export const UPDATE_NETWORK_SUCCESS = "UPDATE_NETWORK_SUCCESS";
export const UPDATE_NETWORK_FAIL = "UPDATE_NETWORK_FAIL";

export const DELETE_NETWORK = "DELETE_NETWORK";
export const DELETE_NETWORK_SUCCESS = "DELETE_NETWORK_SUCCESS";
export const DELETE_NETWORK_FAIL = "DELETE_NETWORK_FAIL";

export const FACEBOOK_LOGIN_URL = "FACEBOOK_LOGIN_URL";
export const FACEBOOK_LOGIN_URL_SUCCESS = "FACEBOOK_LOGIN_URL_SUCCESS";
export const FACEBOOK_LOGIN_URL_FAIL = "FACEBOOK_LOGIN_URL_FAIL";

export const FACEBOOK_PAGE_LIST = "FACEBOOK_PAGE_LIST";
export const FACEBOOK_PAGE_LIST_SUCCESS = "FACEBOOK_PAGE_LIST_SUCCESS";
export const FACEBOOK_PAGE_LIST_FAIL = "FACEBOOK_PAGE_LIST_FAIL";

export const FACEBOOK_LOGIN_CONFIRM = "FACEBOOK_LOGIN_CONFIRM";
export const FACEBOOK_LOGIN_CONFIRM_SUCCESS = "FACEBOOK_LOGIN_CONFIRM_SUCCESS";
export const FACEBOOK_LOGIN_CONFIRM_FAIL = "FACEBOOK_LOGIN_CONFIRM_FAIL";

export const INSTAGRAM_LOGIN_URL = "INSTAGRAM_LOGIN_URL";
export const INSTAGRAM_LOGIN_URL_SUCCESS = "INSTAGRAM_LOGIN_URL_SUCCESS";
export const INSTAGRAM_LOGIN_URL_FAIL = "INSTAGRAM_LOGIN_URL_FAIL";

export const INSTAGRAM_PAGE_LIST = "INSTAGRAM_PAGE_LIST";
export const INSTAGRAM_PAGE_LIST_SUCCESS = "INSTAGRAM_PAGE_LIST_SUCCESS";
export const INSTAGRAM_PAGE_LIST_FAIL = "INSTAGRAM_PAGE_LIST_FAIL";

export const INSTAGRAM_LOGIN_CONFIRM = "INSTAGRAM_LOGIN_CONFIRM";
export const INSTAGRAM_LOGIN_CONFIRM_SUCCESS = "INSTAGRAM_LOGIN_CONFIRM_SUCCESS";
export const INSTAGRAM_LOGIN_CONFIRM_FAIL = "INSTAGRAM_LOGIN_CONFIRM_FAIL";
