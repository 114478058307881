import { IAutoPostRequest, IManualPostRequest, IPostNewsRequest } from '../reducers';
import * as Constants from '../constants'

export function createInfoPost(event_id: number, resolve: any, reject: any) {
    return {
        type: Constants.CREATE_INFO_POST_EVENT,
        event_id,
        resolve,
        reject
    };
}

export function createManualPost(infos: IManualPostRequest, resolve: any, reject: any) {
    return {
        type: Constants.CREATE_MANUAL_POST_EVENT,
        infos,
        resolve,
        reject
    };
}

export function createAutoPost(infos: IAutoPostRequest, resolve: any, reject: any) {
    return {
        type: Constants.CREATE_AUTO_POST_EVENT,
        infos,
        resolve,
        reject
    };
}

export function createPostNews(infos: IPostNewsRequest, resolve: any, reject: any) {
    return {
        type: Constants.CREATE_POST_NEWS,
        infos,
        resolve,
        reject
    };
}

export function removePost(post_id: number, resolve: any, reject: any) {
    return {
        type: Constants.REMOVE_POST,
        post_id,
        resolve,
        reject
    };
}
