import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IResponseStatus } from "../reducers";

function* getNetworksList(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getNetworksList, apikey);
        yield put({ type: response.status === "fail" ? Constants.GET_NETWORKS_LIST_FAIL : Constants.GET_NETWORKS_LIST_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.GET_NETWORKS_LIST_FAIL, e });
    }
}

function* getNetworksReferences(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getNetworksReferences, apikey);
        yield put({ type: response.status === "fail" ? Constants.GET_NETWORKS_REFERENCES_FAIL : Constants.GET_NETWORKS_REFERENCES_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.GET_NETWORKS_REFERENCES_FAIL, e });
    }
}

function* getNetworksListActive(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getNetworksListActive, apikey);
        yield put({ type: response.status === "fail" ? Constants.GET_NETWORKS_LIST_ACTIVE_FAIL : Constants.GET_NETWORKS_LIST_ACTIVE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.GET_NETWORKS_LIST_ACTIVE_FAIL, e });
    }
}

function* createNetwork(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createNetwork, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.CREATE_NETWORK_FAIL : Constants.CREATE_NETWORK_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.CREATE_NETWORK_FAIL, e });
    }
}

function* updateNetwork(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateNetwork, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.UPDATE_NETWORK_FAIL : Constants.UPDATE_NETWORK_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.UPDATE_NETWORK_FAIL, e });
    }
}

function* deleteNetwork(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.deleteNetwork, apikey, action.id);
        yield put({ type: (response.status === "fail" ? Constants.DELETE_NETWORK_FAIL : Constants.DELETE_NETWORK_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.DELETE_NETWORK_FAIL, e });
    }
}

function* facebookLoginUrl(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.facebookLoginUrl, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.FACEBOOK_LOGIN_URL_FAIL : Constants.FACEBOOK_LOGIN_URL_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.FACEBOOK_LOGIN_URL_FAIL, e });
    }
}

function* facebookPageList(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.facebookPageList, apikey, action.id);
        yield put({ type: (response.status === "fail" ? Constants.FACEBOOK_PAGE_LIST_FAIL : Constants.FACEBOOK_PAGE_LIST_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.FACEBOOK_PAGE_LIST_FAIL, e });
    }
}

function* facebookLoginConfirm(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.facebookLoginConfirm, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.FACEBOOK_LOGIN_CONFIRM_FAIL : Constants.FACEBOOK_LOGIN_CONFIRM_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.FACEBOOK_LOGIN_CONFIRM_FAIL, e });
    }
}

function* instagramLoginUrl(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.instagramLoginUrl, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.INSTAGRAM_LOGIN_URL_FAIL : Constants.INSTAGRAM_LOGIN_URL_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.INSTAGRAM_LOGIN_URL_FAIL, e });
    }
}

function* instagramPageList(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.instagramPageList, apikey, action.id);
        yield put({ type: (response.status === "fail" ? Constants.INSTAGRAM_PAGE_LIST_FAIL : Constants.INSTAGRAM_PAGE_LIST_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.INSTAGRAM_PAGE_LIST_FAIL, e });
    }
}

function* instagramLoginConfirm(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.instagramLoginConfirm, apikey, action.data);
        yield put({ type: (response.status === "fail" ? Constants.INSTAGRAM_LOGIN_CONFIRM_FAIL : Constants.INSTAGRAM_LOGIN_CONFIRM_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.INSTAGRAM_LOGIN_CONFIRM_FAIL, e });
    }
}

export const networksSagas = [
    takeLatest(Constants.GET_NETWORKS_REFERENCES, getNetworksReferences),
    takeLatest(Constants.GET_NETWORKS_LIST, getNetworksList),
    takeLatest(Constants.GET_NETWORKS_LIST_ACTIVE, getNetworksListActive),
    takeLatest(Constants.CREATE_NETWORK, createNetwork),
    takeLatest(Constants.UPDATE_NETWORK, updateNetwork),
    takeLatest(Constants.DELETE_NETWORK, deleteNetwork),
    takeLatest(Constants.FACEBOOK_LOGIN_URL, facebookLoginUrl),
    takeLatest(Constants.FACEBOOK_PAGE_LIST, facebookPageList),
    takeLatest(Constants.FACEBOOK_LOGIN_CONFIRM, facebookLoginConfirm),
    takeLatest(Constants.INSTAGRAM_LOGIN_URL, instagramLoginUrl),
    takeLatest(Constants.INSTAGRAM_PAGE_LIST, instagramPageList),
    takeLatest(Constants.INSTAGRAM_LOGIN_CONFIRM, instagramLoginConfirm)
];