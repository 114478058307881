
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store';
import NavbarComponent from '../components/NavbarComponent';

const mapStateToProps = (state: RootState) => {
    return {
        isLogged: state.authReducer.isLogged,
        email: (state.authReducer.user ? state.authReducer.user.email : ''),
        name: (state.authReducer.user ? state.authReducer.user.name : '')
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxNavbar = ConnectedProps<typeof connector>


export default connector(NavbarComponent)