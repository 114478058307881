import { Key, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { PropsFromReduxRestaurantTransfers } from '../containers/RestaurantTransfersContainer'
import { ITransfer } from '../reducers'
import TransferCardComponent from './TransferCardComponent'

const RestaurantTransfersComponent = (props: PropsFromReduxRestaurantTransfers) => {

    useEffect(() => {
        props.listTransfer();
    }, [])
    
    return (
        <Card className='blocCard'>
            <Card.Header className="d-flex align-items-center mb-2">
                <Card.Title className="d-flex w-100 align-items-center pt-2">
                    <div className="w-100 fw-bolder">Virements</div>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Card.Text>Dès 72h ouvrables après la fin des services du jour, un virement SEPA sera émis vers votre compte bancaire.</Card.Text>
                    </Col>
                </Row>
                <Row>
                    {props.listTransfers && props.listTransfers.length > 0 && props.listTransfers.map((transfer: ITransfer, index: Key) => {
                        return <TransferCardComponent key={index} downloadResume={props.downloadResume} transfer={transfer} />
                    })}
                </Row>
            </Card.Body>
        </Card>
    )
}

export default RestaurantTransfersComponent