import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import PartyPage from "../pages/PartyPage";
import PartyNewContainer from "../containers/PartyNewContainer";
import PartyNewModelsContainer from "../containers/PartyNewModelsContainer";
import PartyCreateContainer from "../containers/PartyCreateContainer";
import PartyEditContainer from '../containers/PartyEditContainer';
import PartyResumeContainer from "../containers/PartyResumeContainer";

export const PartyRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/party',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <PartyPage /> },
                { path: '/party/new', element: <PartyNewContainer /> },
                { path: '/party/models', element: <PartyNewModelsContainer /> },
                {
                    path: '/party/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/party/create/:id',
                            element: <PartyCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/party/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/party/resume/:id',
                            element: <PartyResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/party/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/party/edit/:id',
                            element: <PartyEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}