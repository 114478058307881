import { PropsFromRedux } from "../containers/HostelContainer";
import { useEffect, Key } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IGoodLight } from "../reducers";
import GoodCard from "./common/GoodCard";

const HostelComponent = (props: PropsFromRedux) => {

    useEffect(() => {
        props.list();
    }, []); 

    return (
        <>
            <Row className="mt-2 g-2">
                <Col className="">
                    <Card className="blocCard">
                        <Card.Header className="d-flex align-items-center">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Chambres</div>
                                <div className="align-items-right">
                                    <Link to="/hostel/new">
                                        <Button className="topButton" variant="primary">
                                            Créer <span className="d-none d-none d-md-inline-block">une chambre</span>
                                        </Button>
                                    </Link>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center flex-wrap">
                                {props.goods && props.goods.length > 0 ? (
                                    <>
                                        {props.goods.map((good: IGoodLight, key: Key) => {
                                            return (
                                                <GoodCard
                                                    key={key}
                                                    id={good.id}
                                                    status={good.status}
                                                    name={good.name}
                                                    img_cover={good.img_cover}
                                                />
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p className="mt-2">Pas de chambres renseignées</p>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default HostelComponent