import { useState } from "react"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import FooterComponent from "../../../common/components/FooterComponent"
import { PropsFromReduxForgotPassword } from "../containers/ForgotPasswordContainer"
import Logo from '../../../assets/RectBlanc.png'
import toast from 'react-hot-toast';

export default function ForgotPassword(props: PropsFromReduxForgotPassword) {

    const [validatedBegin, setValidatedBegin] = useState(false);
    const [validatedRecoverPassword] = useState(false);

    const [email, setEmail] = useState("");

    const [step, setStep] = useState(1);

    const handleSubmitBegin = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }
        props.forgotPassword(form.email.value).then(() => {
            setEmail(form.email.value);
            setStep(2);
            setValidatedBegin(true);
        })
        .catch(error => toast.error(error.message.fr))
    };

    const Mail = () => {
        return (
            <div className="w-100">
                <Container className='d-flex align-content-center justify-content-center flex-wrap '>
                    <Row className='py-4 align-items-center justify-content-center bg-white-up' style={{zIndex:50}}>
                        <Col className='d-flex flex-wrap align-items-center justify-content-center ' >
                            <Row>
                                <Col className="text text-center">
                                    <img src={Logo} width={'70%'} height={'100%'} alt="" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text text-center mt-2 mb-5">
                                    <h3>Mot de passe oublié</h3>
                                </Col>
                            </Row>
                            <Form className='register-form' noValidate validated={validatedBegin} onSubmit={handleSubmitBegin}>
                                <Form.Group className="mb-4 w-75 " controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="E-mail" required />
                                </Form.Group>
                                <div className='text-center'>
                                    <Button className="mt-4 mb-5 btn-register" variant="primary" type="submit">
                                        Valider
                                    </Button>
                                </div>
                                <Row>
                                    <Col className="text text-center mt-2">
                                        <p className=''>Vous l'avez retrouvé ?
                                            <Link to='/login' className="formlink fw-bold"> Connectez-vous</Link>
                                        </p>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }

    const handleSubmitRecoverPassword = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }
        props.recoverPassword(form.code.value, form.email.value, form.password.value)
        .catch(error => toast.error(error.message.fr))
    };

    const SetRecoverPassword = () => {
        return (
            <>
                <Container className='d-flex align-content-center justify-content-center flex-wrap '>
                    <Row className='py-4 align-items-center justify-content-center bg-white-up' style={{zIndex:50}}>
                        <Col className='d-flex flex-wrap align-items-center justify-content-center ' >
                            <Row>
                                <Col className="text text-center">
                                    <img src={Logo} width={'70%'} height={'100%'} alt="" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text text-center mt-2">
                                    <h3 className="mb-3">Récupérer le mot de passe</h3>
                                    <p className="text-center mb-4">Saisissez le code unique reçu par email, <br />votre adrresse email ainsi que votre nouveau mot de passe.</p>
                                </Col>
                            </Row>
                            <Form className='register-form' noValidate validated={validatedRecoverPassword} onSubmit={handleSubmitRecoverPassword}>
                                <Form.Group className="mb-4 w-75 " controlId="code">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="number" placeholder="Code" required />
                                </Form.Group>
                                <Form.Group className="mb-4 w-75 " controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="E-mail" defaultValue={email} required />
                                </Form.Group>
                                <Form.Group className="mb-2 w-75" controlId="password">
                                    <Form.Label>Mot de passe</Form.Label>
                                    <Form.Control type="password" placeholder="Mot de passe" required />
                                </Form.Group>
                                <div className='text-center'>
                                    <Button className="mt-4 mb-5 btn-register" variant="primary" type="submit">
                                        Valider
                                    </Button>
                                </div>
                                <Row>
                                    <Col className="text text-center mt-2">
                                        <p className=''>Vous l'avez retrouvé?
                                            <Link to='/login' className="formlink fw-bold"> Connectez-vous</Link>
                                        </p>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </>
        )
    }

    const handleStep = () => {
        switch (step) {
            case 1:
                return <Mail />
            case 2:
                return <SetRecoverPassword />
        }
    }
    return (
        <>
            {handleStep()}
        </>
    )
}
