import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_GOODS = CORE_URL + "/goods";

// ------------------------------  EVENT  ------------------------------

export const HOSTEL_LIST = "HOSTEL_LIST";
export const HOSTEL_LIST_SUCCESS = "HOSTEL_LIST_SUCCESS";
export const HOSTEL_LIST_FAIL = "HOSTEL_LIST_FAIL";

export const HOSTEL_CREATE_EMPTY_GOOD = "HOSTEL_CREATE_EMPTY_GOOD";
export const HOSTEL_CREATE_EMPTY_GOOD_SUCCESS = "HOSTEL_CREATE_EMPTY_GOOD_SUCCESS";
export const HOSTEL_CREATE_EMPTY_GOOD_FAIL = "HOSTEL_CREATE_EMPTY_GOOD_FAIL";

export const HOSTEL_CREATE_MODEL_GOOD = "HOSTEL_CREATE_MODEL_GOOD";
export const HOSTEL_CREATE_MODEL_GOOD_SUCCESS = "HOSTEL_CREATE_MODEL_GOOD_SUCCESS";
export const HOSTEL_CREATE_MODEL_GOOD_FAIL = "HOSTEL_CREATE_MODEL_GOOD_FAIL";

export const HOSTEL_LIST_MODEL_GOOD = "HOSTEL_LIST_MODEL_GOOD";
export const HOSTEL_LIST_MODEL_GOOD_SUCCESS = "HOSTEL_LIST_MODEL_GOOD_SUCCESS";
export const HOSTEL_LIST_MODEL_GOOD_FAIL = "HOSTEL_LIST_MODEL_GOOD_FAIL";

export const HOSTEL_GET_GOOD = "HOSTEL_GET_GOOD";
export const HOSTEL_GET_GOOD_SUCCESS = "HOSTEL_GET_GOOD_SUCCESS";
export const HOSTEL_GET_GOOD_FAIL = "HOSTEL_GET_GOOD_FAIL";

export const HOSTEL_PUBLISH_CHECK_GOOD = "HOSTEL_PUBLISH_CHECK_GOOD";
export const HOSTEL_PUBLISH_CHECK_GOOD_SUCCESS = "HOSTEL_PUBLISH_CHECK_GOOD_SUCCESS";
export const HOSTEL_PUBLISH_CHECK_GOOD_FAIL = "HOSTEL_PUBLISH_CHECK_GOOD_FAIL";

export const HOSTEL_PUBLISH_GOOD = "HOSTEL_PUBLISH_GOOD";
export const HOSTEL_PUBLISH_GOOD_SUCCESS = "HOSTEL_PUBLISH_GOOD_SUCCESS";
export const HOSTEL_PUBLISH_GOOD_FAIL = "HOSTEL_PUBLISH_GOOD_FAIL";

export const HOSTEL_UPDATE_INFOS_GOOD = "HOSTEL_UPDATE_INFOS_GOOD";
export const HOSTEL_UPDATE_INFOS_GOOD_SUCCESS = "HOSTEL_UPDATE_INFOS_GOOD_SUCCESS";
export const HOSTEL_UPDATE_INFOS_GOOD_FAIL = "HOSTEL_UPDATE_INFOS_GOOD_FAIL";

export const HOSTEL_UPDATE_COVER_GOOD = "HOSTEL_UPDATE_COVER_GOOD";
export const HOSTEL_UPDATE_COVER_GOOD_SUCCESS = "HOSTEL_UPDATE_COVER_GOOD_SUCCESS";
export const HOSTEL_UPDATE_COVER_GOOD_FAIL = "HOSTEL_UPDATE_COVER_GOOD_FAIL";

export const HOSTEL_REMOVE_GOOD = "HOSTEL_REMOVE_GOOD";
export const HOSTEL_REMOVE_GOOD_SUCCESS = "HOSTEL_REMOVE_GOOD_SUCCESS";
export const HOSTEL_REMOVE_GOOD_FAIL = "HOSTEL_REMOVE_GOOD_FAIL";

export const HOSTEL_MANAGE_INFOS_GOOD = "HOSTEL_MANAGE_INFOS_GOOD";
export const HOSTEL_MANAGE_INFOS_GOOD_SUCCESS = "HOSTEL_MANAGE_INFOS_GOOD_SUCCESS";
export const HOSTEL_MANAGE_INFOS_GOOD_FAIL = "HOSTEL_MANAGE_INFOS_GOOD_FAIL";

export const HOSTEL_CREATE_AVAILABILITIES_GOOD = "HOSTEL_CREATE_AVAILABILITIES_GOOD";
export const HOSTEL_CREATE_AVAILABILITIES_GOOD_SUCCESS = "HOSTEL_CREATE_AVAILABILITIES_GOOD_SUCCESS";
export const HOSTEL_CREATE_AVAILABILITIES_GOOD_FAIL = "HOSTEL_CREATE_AVAILABILITIES_GOOD_FAIL";

export const HOSTEL_UPDATE_AVAILABILITY_GOOD = "HOSTEL_UPDATE_AVAILABILITY_GOOD";
export const HOSTEL_UPDATE_AVAILABILITY_GOOD_SUCCESS = "HOSTEL_UPDATE_AVAILABILITY_GOOD_SUCCESS";
export const HOSTEL_UPDATE_AVAILABILITY_GOOD_FAIL = "HOSTEL_UPDATE_AVAILABILITY_GOOD_FAIL";
