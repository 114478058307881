import { PropsFromRedux } from "../containers/PartyContainer";
import { useEffect, Key } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IEventLight } from "../../../common/reducers";
import EventCard from "../../../common/components/EventCard";

const PartyComponent = (props: PropsFromRedux) => {

    useEffect(() => {
        props.listFinished('UNIQUE', 'PARTY');
        props.listFuture('UNIQUE', 'PARTY');
    }, []); 

    return (
        <>
            <Row className="mt-2 g-2">
                <Col className="">
                    <Card className="blocCard">
                        <Card.Header className="d-flex align-items-center">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Soirées à venir</div>
                                <div className="align-items-right">
                                    <Link to="/party/new">
                                        <Button className="topButton" variant="primary">
                                            Créer <span className="d-none d-none d-md-inline-block">une soirée</span>
                                        </Button>
                                    </Link>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center flex-wrap">
                                {props.eventsFuture && props.eventsFuture.length > 0 ? (
                                    <>
                                        {props.eventsFuture.map((event: IEventLight, key: Key) => {
                                            return (
                                                <EventCard
                                                    key={key}
                                                    id={event.id}
                                                    status={event.status}
                                                    subtype={event.subtype}
                                                    category={event.category}
                                                    name={event.name}
                                                    img_flyer={event.img_flyer}
                                                    started={event.started}
                                                />
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p>Pas de soirée en cours</p>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="my-2 g-2">
                <Col className="">
                    <Card className="blocCard">
                        <Card.Header className="d-flex align-items-center">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Soirées terminées</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center flex-wrap">
                                {props.eventsFinished && props.eventsFinished.length > 0 ? (
                                    <>
                                        {props.eventsFinished.map((event: IEventLight, key: Key) => {
                                            return (
                                                <EventCard
                                                    key={key}
                                                    id={event.id}
                                                    status={event.status}
                                                    subtype={event.subtype}
                                                    category={event.category}
                                                    name={event.name}
                                                    img_flyer={event.img_flyer}
                                                    started={event.started}
                                                />
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p>Pas de soirée terminée</p>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default PartyComponent