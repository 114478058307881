import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import RegisterComponent from '../components/RegisterComponent';
import { register } from '../actions';
import { IUserCredential } from '../reducers';

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.authReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        register: (credentials: IUserCredential) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(register(credentials, resolve, reject))
            })
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxRegister = ConnectedProps<typeof connector>

export default connector(RegisterComponent)