import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import history from "../../routes/history";
import authReducer from "../../modules/auth/reducers";
import dashboardReducer from "../../modules/dashboard/reducers";
import partyReducer from "../../modules/party/reducers";
import poolReducer from "../../modules/pool/reducers";
import transatReducer from "../../modules/transat/reducers";
import spaReducer from "../../modules/spa/reducers";
import restaurantReducer from "../../modules/restaurant/reducers";
import hostelReducer from "../../modules/hostel/reducers";
import packReducer from "../../modules/pack/reducers";
import businessRecordReducer from './../../modules/business-record/reducers/index';
import scannersReducer from "../../modules/scanners/reducers";
import networksReducer from './../../modules/networks/reducers/index';
import bankInfoReducer from './../../modules/bank-info/reducers/index';
import postsReducer from "../../modules/posts/reducers";

export default combineReducers({
    authReducer,
    dashboardReducer,

    partyReducer,
    poolReducer,
    spaReducer,
    transatReducer,
    restaurantReducer,
    hostelReducer,
    packReducer,
    
    businessRecordReducer,
    scannersReducer,
    networksReducer,
    bankInfoReducer,

    postsReducer,

    router: connectRouter(history)
})