import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import RestaurantResumeComponent from "../components/RestaurantResumeComponent";
import * as Actions from '../actions'
import { IService } from "../reducers";

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getService: (occurence_id: number) => {
            return new Promise((resolve: (service: IService) => void, reject: any) => {
                dispatch(Actions.getService(occurence_id, resolve, reject));
            })
        },
        removeService: (occurence_id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.removeService(occurence_id, resolve, reject));
            })
        },
        downloadService: (occurence_id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.downloadService(occurence_id, resolve, reject));
            })
        },
        manageService: (occurence_id: number, quantity: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.manageService(occurence_id, quantity, resolve, reject));
            })
        },
        manageTableService: (ticket_id: number, info: string) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.manageTableService(ticket_id, info, resolve, reject));
            })
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxRestaurantResume = ConnectedProps<typeof connector>;

export default connector(RestaurantResumeComponent);