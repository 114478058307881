import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Image, InputGroup, Modal, Overlay, Popover, Ratio, Row, ToggleButton } from 'react-bootstrap'
import { Clipboard, ClipboardCheck, Download, Plus } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import { PropsFromRedux } from '../containers/PostEventCreateAutoModalContainer';
import { IAutoPostRequest, IInfosPost } from '../reducers';
import moment from 'moment';
import { convertDatetimeLocalToUtc } from '../../../utils/formattage';
import { INetwork } from '../../networks/reducers';

interface PostEventCreateAutoModalComponentProps {
    eventId: number
    show: boolean
    postType: string
    networksActive: INetwork[]
    handleHide: (refresh: boolean) => void
}

const PostEventCreateAutoModalComponent = (props: PostEventCreateAutoModalComponentProps & PropsFromRedux) => {

    const navigate = useNavigate()

    const [publishAuto, setPublishAuto] = useState(true)

    const [networkChoice, setNetworkChoice] = useState('')
    const [publishChoice, setPublishChoice] = useState<'NOW' | 'PLANNED'>('NOW')
    const [publishDate, setPublishDate] = useState('')
    const [networks, setNetworks] = useState<INetwork[]>([])
    const [url, setUrl] = useState('')
    
    const [showPopOver, setShowPopOver] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const [postInfos, setPostInfos] = useState<IInfosPost>();

    useEffect(() => {
        if(props.postType === 'WYYLDE_PAGE' || props.postType === 'NOUSLIB') {
            setPublishAuto(true)
        }
        else {
            setPublishAuto(false)
        }

        setPublishDate(moment.utc().local().add('1', 'hour').format('YYYY-MM-DD HH:mm').replace(' ', 'T'));

        var n = [] as INetwork[];
        if(props.networksActive && props.networksActive.length) {
            props.networksActive.map(network => {
                if(network.type == props.postType) {
                    n.push(network);
                }
            })
        }
        setNetworks(n);

    }, [props.postType])

    useEffect(() => {

        props.createInfoPost(props.eventId)
        .then((p: IInfosPost) => {
            setPostInfos(p)
        })
        .catch((error: any) => toast.error(error.message.fr))

    }, [props.eventId])

    useEffect(() => {
        if (!!showPopOver) {
            setTimeout(() => {
                setShowPopOver(false)
            }, 1000)
        }
    }, [showPopOver])
    
    const handleClose = () => {
        props.handleHide(false)
    }

    const handlePublishChoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublishChoice(e.currentTarget.value as 'NOW' | 'PLANNED')
    }

    const savePostAuto = () => {
        let request: IAutoPostRequest = {
            id: props.eventId,
            network: parseInt(networkChoice),
            delay: publishChoice
        }
        if(props.postType === 'WYYLDE_PAGE' && publishAuto) {
            request.url = url;
        }
        if(publishChoice === "PLANNED") {
            request.planned_at = convertDatetimeLocalToUtc(publishDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
        }
        
        props.createAutoPost(request)
        .then((status: string) => {
            toast.success("Publication enregistré.")
                
            props.handleHide(true)
        })
        .catch((error: any) => toast.error(error.message.fr))
    }

    const addToClipboard = (content: string, event: any) => {
        navigator.clipboard.writeText(content)
        .then(function () {
            setShowPopOver(true);
            setTarget(event.target);
        }, 
        function (err) {
            console.error(err)
        });
    }

    const downloadCover = () => {
        if(postInfos !== undefined && postInfos.img_cover) {
            var filenameArr = postInfos.img_cover.split('/');
            var filename = filenameArr[filenameArr.length - 1];

            var element = document.createElement("a");
            element.href = postInfos.img_cover 
            element.download = filename;
            element.target = '_blank';
            element.click();
        }
    }

    const downloadFlyer = () => {
        if(postInfos !== undefined && postInfos.img_flyer) {
            var filenameArr = postInfos.img_flyer.split('/');
            var filename = filenameArr[filenameArr.length - 1];

            var element = document.createElement("a");
            element.href = postInfos.img_flyer 
            element.download = filename;
            element.target = '_blank';
            element.click();
        }
    }

    return (
        <>
            <Modal show={props.show} size='lg' backdrop="static" className="">
                <Modal.Header>
                    <Modal.Title>Publier {(publishAuto ? 'en automatique' : 'manuellement')}</Modal.Title>
                </Modal.Header>

                {publishAuto ? <>
                    <Modal.Body>
                        
                        {props.postType === 'WYYLDE_PAGE' && <>
                            <Row>
                                <div>
                                    <Form.Label> URL de l'évenement (si déjà publié)</Form.Label>
                                    <Form.Control required type="text"
                                        placeholder="URL de l'évenement"
                                        value={url} onChange={(e) => setUrl(e.target.value)}
                                    />
                                </div>
                                <Form.Text className="mt-3 mb-2">Si votre événement est déjà publié, saisissez l'url pour que le système rajoute automatiquement le lien de réservation en en-tête de la description.</Form.Text>
                            
                            </Row>
                            <hr />
                        </>
                        }
                        <InputGroup className="my-3">
                            <InputGroup.Text id="prestaChoice">Réseau :</InputGroup.Text>
                            <Form.Select value={networkChoice} onChange={(e) => setNetworkChoice(e.target.value)}>
                                <option value=''>Choisissez un réseau...</option>
                                {networks && networks.length > 0 && <>
                                    {networks.map(network => {
                                        if (network.status === "CONNECTED" && !network.error) return <option key={network.id} value={network.id}>{network.type}: {network.login}</option>
                                        else return <></>
                                    })}
                                </>
                                }
                            </Form.Select>
                            <Button style={{ cursor: 'pointer' }} variant='secondary' onClick={() => navigate('/networks')} ><Plus className='d-block d-md-none' /> <span className='d-none d-md-block'>Ajouter un réseau</span></Button>
                        </InputGroup>
                        <InputGroup className="my-3">
                            <InputGroup.Text id="prestaChoice">Publier</InputGroup.Text>
                            <ToggleButton type='radio' variant="outline-secondary"
                                value='NOW' id='NOW' name='radio'
                                onChange={(e) => handlePublishChoiceChange(e)}
                                checked={publishChoice === 'NOW'}
                            >
                                Immédiatement
                            </ToggleButton>
                            <ToggleButton type='radio' variant="outline-secondary"
                                value='PLANNED' id='PLANNED' name='radio'
                                onChange={(e) => handlePublishChoiceChange(e)}
                                checked={publishChoice === 'PLANNED'}
                            >
                                Plus tard
                            </ToggleButton>
                            {publishChoice === 'PLANNED' && <Form.Control className='ms-3' type='datetime-local' value={publishDate} onChange={(e) => setPublishDate(e.target.value)} />}
                        </InputGroup>
                        <Form.Text>Immédiatement: si votre événement n'est pas encore publié, la publication sur les réseaux aura lieu une fois l'événement publié.</Form.Text>
                        
                    </Modal.Body>

                    <Modal.Footer className='justify-content-between'>
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={handleClose}>Retour</Button>
                        <Button variant='primary' className='btn-modal-device ms-1' onClick={savePostAuto}>Valider</Button>
                    </Modal.Footer>
                </>
                :
                <>
                    <Modal.Body>
                        <div ref={ref} className="my-3 mt-1">
                            <div>Connectez vous sur votre compte et publiez votre événement à l'aider des informations de votre événement (à copier-coller). Une fois publié, récupérez le lien (URL) et ajoutez le manuellement à votre fiche Lib-Events.</div>
                            <InputGroup className="my-3">
                                <Form.Control type="text" value={(postInfos ? postInfos.name : '')} disabled />
                                <Button variant='secondary' onClick={(e) => addToClipboard((postInfos !== undefined ? postInfos.name : ''), e)}><Clipboard /> Copier</Button>
                            </InputGroup>
                            <InputGroup className="my-3">
                                <Form.Control as="textarea" rows={8} value={(postInfos !== undefined ? postInfos.description_text : '')} disabled />
                                <div className='d-flex flex-column gap-2 ms-2'>
                                    <Button variant='secondary' onClick={(e) => addToClipboard((postInfos !== undefined ? postInfos.description_text : ''), e)}><Clipboard /> Copier le texte</Button>
                                    <Button variant='secondary' onClick={(e) => addToClipboard((postInfos !== undefined ? postInfos.description_html : ''), e)}><Clipboard /> Copier le HTML</Button>
                                </div>
                            </InputGroup>
                            <Row>
                                {postInfos && postInfos.img_cover &&
                                    <Col style={{ position: 'relative' }}>
                                        <Card.Text className="mb-1">Couverture</Card.Text>
                                        <Ratio aspectRatio={13 / 28}>
                                            <Image src={(postInfos !== undefined ? postInfos.img_cover : '')} style={{ minHeight: '100%', minWidth: '100%', objectFit: 'cover' }} />
                                        </Ratio>
                                        <Button as='a' variant='light' className='dlImageButton' onClick={downloadCover}><Download /></Button>
                                    </Col>
                                }
                                {postInfos && postInfos.img_flyer &&
                                    <Col style={{ position: 'relative' }}>
                                        <Card.Text className="mb-1">Flyer</Card.Text>
                                        <Ratio aspectRatio={13 / 28}>
                                            <Image src={(postInfos !== undefined ? postInfos.img_flyer : '')} style={{ minHeight: '100%', minWidth: '100%', objectFit: 'cover' }} />
                                        </Ratio>
                                        <Button as='a' variant='light' className='dlImageButton' onClick={downloadFlyer}><Download /></Button>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className='justify-content-between'>
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={handleClose}>Retour</Button>
                    </Modal.Footer>
                </>
                }

            </Modal>
            <Overlay
                show={showPopOver}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Body>
                        <ClipboardCheck /> Copié !
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    )
}

export default PostEventCreateAutoModalComponent