import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import RestaurantComponent from "../components/RestaurantComponents";
import * as Actions from "../actions";

const mapStateToProps = (state: RootState) => {
    return {
        listFuture: state.restaurantReducer.listFuture,
        listFinished: state.restaurantReducer.listFinished,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        listFutureService: () => {
            dispatch(Actions.listFutureService());
        },
        listFinishedService: () => {
            dispatch(Actions.listFinishedService());
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxRestaurant = ConnectedProps<typeof connector>;

export default connector(RestaurantComponent);