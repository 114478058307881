import { useState, useEffect, Key, useRef, ChangeEvent } from "react";
import { Button, Card, Col, Form, Badge, Image, Row, Modal, Tabs, Tab, Overlay, Popover, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PrestationCard from "./common/PrestationCard";
import toast from "react-hot-toast";
import { ISpa, IPrestation } from "../reducers";
import { Clipboard, ClipboardCheck, FilePdf, Trash, Link, Triangle } from "react-bootstrap-icons";
import { PropsFromRedux } from "../containers/SpaResumeContainer";
import { formatDate, formatDatetime, formatPrice } from "../../../utils/formattage";
import parse from "html-react-parser";
import EventStatusBadge from "../../../common/components/EventStatusBadge";
import ManagePrestationComponent from "./common/ManagePrestationComponent";
import history from "../../../routes/history";
import queryString from 'query-string';
import { IPostType } from "../../networks/reducers";
import PostEventCreateAutoModalContainer from "../../posts/containers/PostEventCreateAutoModalContainer";
import PostEventCreateManualModalContainer from "../../posts/containers/PostEventCreateManualModalContainer";
import PostNewsCreateModalContainer from "../../posts/containers/PostNewsCreateModalContainer";
import PostStatusBadge from "../../posts/components/common/PostStatusBadge";
import { IPost } from "../../posts/reducers";

const SpaResumeComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const eventId = parseInt(location.pathname.split("/")[3]);

    const queryParams = queryString.parse(location.search);
    const [tabsKey, setTabsKey] = useState((queryParams && queryParams.tab ? queryParams.tab.toString() : 'resume'));

    const [event, setEvent] = useState<ISpa | undefined>()

    const [prestationManage, setPrestationManage] = useState<IPrestation>();
    
    const [showModalPublishCancel, setShowModalPublishCancel] = useState(false);
    const [showModalPostEventCreateAuto, setShowModalPostEventCreateAuto] = useState(false);
    const [showModalPostEventCreateManual, setShowModalPostEventCreateManual] = useState(false);
    const [showModalPostNewsCreate, setShowModalPostNewsCreate] = useState(false);
    const [showPrestationManage, setShowPrestationManage] = useState(false);

    const [showPopOver, setShowPopOver] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const [postEventValue, setPostEventValue] = useState("");
    const [postNewsValue, setPostNewsValue] = useState("");    

    useEffect(() => {
        props.getNetworksListActive()

        fetchEvent()
    }, [])
    
    useEffect(() => {
        if (!!showPopOver) {
            setTimeout(() => {
                setShowPopOver(false)
            }, 1000)
        }
    }, [showPopOver])

    const fetchEvent = () => {
        props.get(eventId, 'UNIQUE', 'SPA')
        .then(ev => {
            if(ev.status === 'DRAFT') {
                navigate("/spa/create/"+eventId)
                return
            }

            setEvent(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/spa")
        });
    };

    const handleTabsKey = (key: string) => {
        setTabsKey(key)
        history.push('/spa/resume/'+eventId+'?tab='+key)
    }

    const addToClipboard = (content: string, event: any) => {
        navigator.clipboard.writeText(content)
        .then(function () {
            setShowPopOver(true);
            setTarget(event.target);
        }, 
        function (err) {
            console.error(err)
        });
    }
    
    const handlePublishCancel = () => {
        props.publishCancel(eventId)
        .then(() => {
            setShowModalPublishCancel(false);
            toast.success(`Publication annulée.`);
            navigate("/spa/create/"+eventId);
        })
        .catch(error => { toast.error(error.message.fr) })
    };

    const handleTicketsDownload = () => {
        props.ticketsDownload(eventId)
        .then((data: any) => {            
            window.open(data.pdf, '_blank', 'noopener,noreferrer');
        })
        .catch(error => { toast.error(error.message.fr) })
    }

    const handleResumeDownload = () => {
        props.resumeDownload(eventId)
        .then((data: any) => {            
            window.open(data.pdf, '_blank', 'noopener,noreferrer');
        })
        .catch(error => { toast.error(error.message.fr) })
    }

    const handleHideModalPostEventCreateAuto = (refresh: boolean) => {
        setShowModalPostEventCreateAuto(false);
        if(refresh) {
            fetchEvent()
        }
    }

    const handleHideModalPostEventCreateManual = (refresh: boolean) => {
        setShowModalPostEventCreateManual(false);
        if(refresh) {
            fetchEvent()
        }
    }

    const handleHideModalPostNewsCreate = (refresh: boolean) => {
        setShowModalPostNewsCreate(false);
        if(refresh) {
            fetchEvent()
        }
    }
    
    const handlePostLink = (link: string) => {
        window.open(link, '_blank');
    }
            
    const handlePostFailed = (networkId: number) => {
        navigate("/networks")
    }
        
    const handlePostRemove = (id: number) => {
        props.removePost(id)
        .then(status => {
            toast.success("Publication supprimée.")
            fetchEvent()
        })
        .catch((error) => toast.error(error.message.fr))
    }

    const handleShowPrestationEdit = (id: number, quantity?: number) => {}

    const handleShowPrestationManage = (id: number) => {
        props.getPrestation(id)
        .then(({ status, prestation }: { status: string, prestation: IPrestation }) => {
            setPrestationManage(prestation)
            setShowPrestationManage(true)
        })
        .catch(error => toast.error(error.message.fr));
    }

    const handleHidePrestationManage = () => setShowPrestationManage(false)

    const handleSavePrestationManage = () => {
        setShowPrestationManage(false);
        fetchEvent();
    };

    const handlePostEventValue = (e: ChangeEvent<HTMLInputElement>) => {
        setPostEventValue(e.currentTarget.value);
        setShowModalPostEventCreateAuto(true)
    };

    const handlePostNewsValue = (e: ChangeEvent<HTMLInputElement>) => {
        setPostNewsValue(e.currentTarget.value);
        setShowModalPostNewsCreate(true)
    };

    let statusTag: string = ''
    let statusInfo: string = ''
    if(event && event.transfer) {
        switch (event.transfer.status) {
            case 'EXECUTED': statusTag = 'success'; statusInfo = 'Exécuté'; break;
            case 'ERROR': statusTag = 'danger'; statusInfo = 'Erreur'; break;
            default: statusTag = 'info'; statusInfo = 'En cours'; break;
        }
    }

    return (
        <>
            {event ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`eventTitle m-0 align-self-center h-50`}>
                                        {event.name} 
                                        <EventStatusBadge status={event.status} position="INLINE" />
                                    </Card.Title>
                                    {
                                        event.status === 'SCHEDULED' &&
                                        <>
                                            <div className="d-flex gap-2 mt-2 mt-md-0 mb-2 mb-md-0">
                                                <Card.Text style={{marginTop: 15, marginRight: 10}}>Le {formatDatetime(event.scheduled_at)}</Card.Text>
                                                <Button onClick={() => setShowModalPublishCancel(true)} variant="danger">
                                                    Annuler
                                                </Button>
                                            </div>
                                        </>
                                    }
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row> 

                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className='d-flex gap-2 flex-column col-12'>
                    
                            <Tabs activeKey={tabsKey} onSelect={(k) => handleTabsKey(k ? k : 'resume')} id="event-tab" className="nav-fill nav-custom">

                                {/* -----------------------------  Mes ventes  ----------------------------- */}
                                <Tab eventKey="resume" title="Mes ventes">
                                    <Row className='g-2 flex-wrap'>
                                    
                                        {/* -----------------------------  RECAPITULATIF  ----------------------------- */}

                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Résumé</div>
                                                        <div className="align-items-right">
                                                            {
                                                                (event.status === 'PUBLISHED') &&
                                                                <>
                                                                    <Button className="topButton" variant='outline-secondary' onClick={handleTicketsDownload}><FilePdf /> Liste billets</Button>
                                                                </>
                                                            }
                                                            {
                                                                event.status === 'FINISHED' &&
                                                                <>
                                                                    <Button className="topButton" variant='outline-secondary' onClick={handleResumeDownload}><FilePdf /> Synthèse détaillée</Button>
                                                                </>
                                                            }                                        
                                                            </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row className=''>
                                                        <Col className="text-center">
                                                            <div className='small text-muted mb-2'>Prestations vendues</div>
                                                            <div className='h3'>{event.resume && event.resume.prestations_sold ? event.resume.prestations_sold : 0}</div>
                                                        </Col>
                                                        <Col className="text-center">
                                                            <div className='small text-muted mb-2'>Montant encaissé €</div>
                                                            <div className='h3'>{event.resume ? formatPrice(event.resume.ca_ttc) : 0}</div>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        {/* -----------------------------  VIREMENT  ----------------------------- */}

                                        {event.transfer &&
                                            <Col className='d-flex gap-2 flex-column col-12'>
                                                <Card className='blocCard'>
                                                    <Card.Header className="d-flex align-items-center mb-2">
                                                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                            <div className="w-100 fw-bolder">Virement</div>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                            <div className='d-flex gap-2'>
                                                                <div className="align-items-left">
                                                                    <div className="w-100 fw-bolder mt-2"><h4>{event.transfer.amount} €</h4></div>
                                                                </div>
                                                                <div style={{textAlign: 'right', flex: 1}}>
                                                                    <Badge bg={statusTag} style={{padding: 10, paddingRight: 15, paddingLeft: 15, fontSize: 16, borderRadius: 20}}>{statusInfo}</Badge>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 mt-2'>
                                                                <div style={{textAlign: 'right', flex: 1, marginTop: 5}}>
                                                                    {event.transfer.status === 'EXECUTED' && <div style={{fontSize: 18}}>{formatDate(event.transfer.executed_at)}</div>}
                                                                    {event.transfer.status === 'ERROR' && <div className='text-danger' style={{fontSize: 18}}>{event.transfer.error}</div>}
                                                                </div>
                                                            </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        }

                                        {/* -----------------------------  PRESTATIONS  ----------------------------- */}

                                        {event.prestations && event.prestations.length > 0 &&
                                            <Col className='d-flex gap-2 flex-column col-12'>
                                                <Card className='blocCard'>
                                                    <Card.Header className="d-flex align-items-center mb-2">
                                                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                            <div className="w-100 fw-bolder">Prestations</div>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                            {<div className="d-flex justify-content-start flex-wrap mb-4">
                                                            {event.prestations.map((presta: IPrestation, index: Key) => {
                                                                return (
                                                                    <PrestationCard
                                                                        key={index}
                                                                        id={presta.id}
                                                                        title={presta.name}
                                                                        content={presta.description}
                                                                        cover={presta.img_cover}
                                                                        price={presta.price_public}
                                                                        type={presta.type}
                                                                        quantityAvailable={presta.quantity_available}
                                                                        choices={presta.choices_available}
                                                                        eventStatus={event.status}

                                                                        showPrestationEdit={handleShowPrestationEdit}
                                                                        showPrestationManage={handleShowPrestationManage}
                                                                    />
                                                                );
                                                            })}
                                                        </div>}
                                                    </Card.Body>
                                                </Card >
                                                <ManagePrestationComponent
                                                    show={showPrestationManage} 
                                                    handleHide={handleHidePrestationManage}
                                                    handleSave={handleSavePrestationManage}
                                                    
                                                    prestation={prestationManage}

                                                    managePrestation={props.managePrestation}
                                                />
                                            </Col >
                                        }    

                                    </Row>
                                </Tab>

                                {/* -----------------------------  Contenu  ----------------------------- */}
                                <Tab eventKey="informations" title="Contenu">
                                    <Row className='g-2 flex-wrap'>
                                    
                                        {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Informations</div>
                                                        <div className="align-items-right">
                                                            {event.status === 'PUBLISHED' &&
                                                                <Button className="topButton" onClick={() => navigate("/spa/edit/"+eventId)} variant="primary">
                                                                    Modifier 
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3" controlId="formEventName">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Nom de l'événement</Form.Label>
                                                        <Card.Text>{event.name}</Card.Text>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEventPlace">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Adresse</Form.Label>
                                                        <Card.Text>{event.place}</Card.Text>
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='formEventDates'>
                                                        <Row className='w-100 row-inputs-event'>
                                                            <Col xs={12} md={6} className='pe-md-2'>
                                                                <Form.Label style={{fontWeight: 'bold'}}>Date de début</Form.Label>
                                                                <Card.Text>{formatDatetime(event.started)}</Card.Text>
                                                            </Col>
                                                            <Col xs={12} md={6} className='ps-md-2'>
                                                                <Form.Label style={{fontWeight: 'bold'}}>Date de fin</Form.Label>
                                                                <Card.Text>{formatDatetime(event.ended)}</Card.Text>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEventName">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Description</Form.Label>
                                                        <Card.Text className="textEventDescription">{parse(event.description)}</Card.Text>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        
                                        {/* -----------------------------  IMAGE DE COUVERTURE  ----------------------------- */ }

                                        <Col className='d-flex gap-2 flex-column col-12 col-md-7'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Image de couverture</div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {event.img_cover ? (<>
                                                            <div className="align-items-center d-flex justify-content-center overflow-hidden">
                                                                <Image
                                                                    src={event.img_cover}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                        </>)
                                                        :
                                                        (<>
                                                            <p className="mt-3">Aucune image ajouté</p>
                                                        </>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        
                                        {/* -----------------------------  FLYER  ----------------------------- */}
                                    
                                        <Col className='d-flex gap-2 flex-column col-12 col-md-5'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Flyer</div>
                                                        <div className="align-items-right">
                                                            {/*event.status === 'PUBLISHED' &&
                                                                <Button className="topButton"  onClick={() => navigate("/spa/edit/"+eventId)} variant="primary">
                                                                    Modifier 
                                                                </Button>
                                                            */}
                                                        </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {event.img_flyer ? (<>
                                                            <div className="align-items-center d-flex justify-content-center overflow-hidden">
                                                                <Image
                                                                    src={event.img_flyer}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                        </>)
                                                        :
                                                        (<>
                                                            <p className="mt-3">Aucune image ajouté</p>
                                                        </>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                    </Row>
                                </Tab>
                                
                                {/* -----------------------------  Promotion réseaux ----------------------------- */}
                                <Tab eventKey="promotion" title="Promotion réseaux">
                                    <Row className='g-2 flex-wrap'>

                                        {/* -----------------------------  Publications promotionnelles en automatique  ----------------------------- */}

                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                    <div className="w-100 fw-bolder">Publications promotionnelles en automatique</div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {event.status !== 'FINISHED' && event.status !== 'CANCELED' && 
                                                        <>
                                                        <Card.Text id='cover'>Publiez votre événement et programmez vos relances à votre communauté jusqu'au jour de l'événement.</Card.Text>
                                                        <div className="d-flex justify-content-around w-100 my-2">
                                                            <ButtonGroup className="w-100 row row-inputs-event">
                                                                <div className="col-12 col-sm-6 col-md-4 p-2" key="0">
                                                                    <ToggleButton
                                                                        type="radio"
                                                                        variant="light"
                                                                        value="WYYLDE_PAGE"
                                                                        id="EVENT_WYYLDE_PAGE"
                                                                        name="radio"
                                                                        onChange={(e) => handlePostEventValue(e)}
                                                                        className="d-flex flex-column iconButton"
                                                                    >
                                                                        <Image src="https://assets.libevents.net/img/public/icon_WYYLDE_PAGE.png" style={{width: 60, height: 60, display: 'flex'}} />
                                                                        <div className="flex-grow-1" style={{marginTop: '20px'}}>Section "Evénement" sur "Page" Wyylde</div>
                                                                    </ToggleButton>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-4 p-2" key="1">
                                                                    <ToggleButton
                                                                        type="radio"
                                                                        variant="light"
                                                                        value="WYYLDE_PAGE"
                                                                        id="NEWS_WYYLDE_PAGE"
                                                                        name="radio"
                                                                        onChange={(e) => handlePostNewsValue(e)}
                                                                        className="d-flex flex-column iconButton"
                                                                    >
                                                                        <Image src="https://assets.libevents.net/img/public/icon_WYYLDE_PAGE.png" style={{width: 60, height: 60, display: 'flex'}} />
                                                                        <div className="flex-grow-1" style={{marginTop: '20px'}}>Publication sur "Accueil" de "Page" Wyylde</div>
                                                                    </ToggleButton>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-4 p-2" key="2">
                                                                    <ToggleButton
                                                                        type="radio"
                                                                        variant="light"
                                                                        value="LIB_EVENTS_NOTIFS"
                                                                        id="NEWS_LIB_EVENTS_NOTIFS"
                                                                        name="radio"
                                                                        onChange={(e) => handlePostNewsValue(e)}
                                                                        className="d-flex flex-column iconButton"
                                                                    >
                                                                        <Image src="https://assets.libevents.net/img/public/icon_LIB_EVENTS.png" style={{width: 60, height: 60, display: 'flex'}} />
                                                                        <div className="flex-grow-1" style={{marginTop: '20px'}}>Notification sur Lib-Events</div>
                                                                    </ToggleButton>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-4 p-2" key="3">
                                                                    <ToggleButton
                                                                        type="radio"
                                                                        variant="light"
                                                                        value="FACEBOOK_PAGE_SYNC"
                                                                        id="NEWS_FACEBOOK_PAGE_SYNC"
                                                                        name="radio"
                                                                        onChange={(e) => handlePostNewsValue(e)}
                                                                        className="d-flex flex-column iconButton"
                                                                    >
                                                                        <Image src="https://assets.libevents.net/img/public/icon_FACEBOOK_PAGE.png" style={{width: 60, height: 60, display: 'flex'}} />
                                                                        <div className="flex-grow-1" style={{marginTop: '20px'}}>Publications sur "Page" Facebook</div>
                                                                    </ToggleButton>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-4 p-2" key="3">
                                                                    <ToggleButton
                                                                        type="radio"
                                                                        variant="light"
                                                                        value="INSTAGRAM_SYNC"
                                                                        id="NEWS_INSTAGRAM_SYNC"
                                                                        name="radio"
                                                                        onChange={(e) => handlePostNewsValue(e)}
                                                                        className="d-flex flex-column iconButton"
                                                                    >
                                                                        <Image src="https://assets.libevents.net/img/public/icon_INSTAGRAM.png" style={{width: 60, height: 60, display: 'flex'}} />
                                                                        <div className="flex-grow-1" style={{marginTop: '20px'}}>Publications sur Instagram</div>
                                                                    </ToggleButton>
                                                                </div>
                                                            </ButtonGroup>
                                                        </div>
                                                        <hr className="border-bottom-custom" />
                                                        </>
                                                    }
                                                    <Card.Text id='cover'>Vos publications</Card.Text>
                                                    {(event.posts.EVENT && event.posts.EVENT.length > 0) || (event.posts.NEWS && event.posts.NEWS.length > 0) ? 
                                                        <>
                                                            <Row>
                                                            {event.posts.EVENT && event.posts.EVENT.map((post: IPost) =>
                                                               <Col key={post.id} xs={12} lg={6} className="p-3">
                                                                    <Card className="position-relative postCard">
                                                                        <div className="div-button-network">
                                                                            {post.status === "POSTED" &&
                                                                                <Button onClick={() => handlePostLink(post.link)} className="d-flex justify-content-center align-items-center p-1" variant="outline-secondary" size="sm">
                                                                                    <Link size={20} />
                                                                                </Button>
                                                                            }
                                                                            {post.status === "FAILED" &&
                                                                                <Button onClick={() => handlePostFailed(post.network ? post.network.id : 0)} className="d-flex justify-content-center align-items-center p-1" variant="warning" size="sm">
                                                                                <Triangle size={20} />
                                                                                </Button>
                                                                            }
                                                                            {post.status === "SCHEDULED" &&
                                                                                <Button onClick={() => handlePostRemove(post.id)} className="d-flex justify-content-center align-items-center p-1" variant="danger" size="sm">
                                                                                    <Trash size={20} />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        <Card.Body className="d-flex align-items-center">
                                                                            {post.network &&
                                                                                <img style={{ width: '40px', height: '40px', borderRadius: '6px' }} src={post.network.icon}></img>
                                                                            }
                                                                            <div>
                                                                                <PostStatusBadge status={post.status} /> <small>{post.status === "SCHEDULED" ? formatDatetime(post.scheduled_at) : ''}</small>  <br />
                                                                                <Card.Text className="ms-3" style={{display: 'block'}}>{(post.network ? (post.network.name ? post.network.name+(post.network.login ? ": "+post.network.login : '') : '') : '')}</Card.Text>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            )}
                                                            {event.posts.NEWS && event.posts.NEWS.map((post: IPost) =>
                                                                <Col key={post.id} xs={12} lg={6} className="p-3">
                                                                    <Card className="position-relative postCard">
                                                                        <div className="div-button-network">
                                                                            {post.status === "FAILED" &&
                                                                                <Button onClick={() => handlePostFailed(post.network ? post.network.id : 0)} className="d-flex justify-content-center align-items-center p-1" variant="warning" size="sm">
                                                                                <Triangle size={20} />
                                                                                </Button>
                                                                            }
                                                                            {post.status === "SCHEDULED" &&
                                                                                <Button onClick={() => handlePostRemove(post.id)} className="d-flex justify-content-center align-items-center p-1" variant="danger" size="sm">
                                                                                    <Trash size={20} />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        <Card.Body className="d-flex align-items-center">
                                                                            {post.network &&
                                                                                <img style={{ width: '40px', height: '40px', borderRadius: '6px' }} src={post.network.icon}></img>
                                                                            }
                                                                            <div>
                                                                                <PostStatusBadge status={post.status} /> <small>{post.status === "SCHEDULED" ? formatDatetime(post.scheduled_at) : ''}</small>  <br />
                                                                                <Card.Text className="ms-3" style={{display: 'block'}}>{(post.network ? (post.network.name ? post.network.name+(post.network.login ? ": "+post.network.login : '') : '') : '')}</Card.Text>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            )}
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Col>
                                                                <p className="mt-3"><i><small>Vous n'avez pas partagé cet événement sur vos réseaux.</small></i></p>
                                                            </Col>
                                                        </>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                                                                                     
                                        {/* -----------------------------  LIEN DE RESERVATION  ----------------------------- */}

                                        <Col ref={ref} className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-3">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Lien de réservation</div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Card.Text>Donnez le lien direct de votre fiche Lib-Events sur vos réseaux et sur votre site :</Card.Text>
                                                    <pre className='baseline-pre'>{event.baseline_text}</pre>
                                                    <div className='d-flex gap-2 ms-2'>
                                                        <Button variant='secondary' onClick={(e) => addToClipboard(event.baseline_text, e)}><Clipboard /> Copier le texte</Button>
                                                        <Button variant='secondary' onClick={(e) => addToClipboard(event.baseline_html, e)}><Clipboard /> Copier le HTML</Button>
                                                        <Button variant='secondary' onClick={(e) => addToClipboard(event.baseline_link_text, e)}><Clipboard /> Copier le lien seul</Button>
                                                    </div>
                                                    <Card.Text className="mt-3">Pensez également à transmettre ce lien à vos community manager et partenaires !</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        {/* -----------------------------  PUBLICATIONS MANUELLES  ----------------------------- */}

                                        {event.status !== 'FINISHED' && event.status !== 'CANCELED' && 
                                        <>
                                            <Col className='d-flex gap-2 flex-column col-12'>
                                                <Card className='blocCard'>
                                                    <Card.Header className="d-flex align-items-center mb-2">
                                                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Publications manuelles</div>
                                                            </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Card.Text id='cover'>Parce que notre réussite est votre réussite, voici des astuces pour gagner en visibilité sur vos différents réseaux :</Card.Text>
                                                        <div className="d-flex justify-content-around w-100 my-2">
                                                            <ButtonGroup className="w-100 row row-inputs-event">
                                                                {props.postTypesAvailables && props.postTypesAvailables.NEWS.length > 0 ? <>
                                                                    {props.postTypesAvailables.NEWS.map((postType: IPostType, index: Key) =>                                                                
                                                                        <div className="col-12 col-sm-6 col-md-4 p-2" key={index}>
                                                                            <ToggleButton
                                                                                type="radio"
                                                                                variant="light"
                                                                                value={postType.id}
                                                                                id={'NEWS_'+postType.id}
                                                                                name="radio"
                                                                                onChange={(e) => handlePostNewsValue(e)}
                                                                                className="d-flex flex-column iconButton"
                                                                            >
                                                                                <Image src={postType.icon} style={{width: 60, height: 60, display: 'flex'}} />
                                                                                <div className="flex-grow-1" style={{marginTop: '10px'}}>{postType.name}</div>
                                                                            </ToggleButton>
                                                                        </div>
                                                                    )}
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                            </ButtonGroup>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </>
                                        }

                                    </Row>
                                </Tab>

                            </Tabs>
                        </Col>
                    </Row> 

                    <Modal
                        show={showModalPublishCancel}
                        onHide={() => { setShowModalPublishCancel(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Annuler</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <Card.Text>Êtes vous sûr de vouloir annuler la publication ?<br />Votre événement repassera en BROUILLON.</Card.Text>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalPublishCancel(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handlePublishCancel}>Valider</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>

                    {event.status !== 'FINISHED' && event.status !== 'CANCELED' && <PostEventCreateAutoModalContainer
                        eventId={eventId}
                        postType={postEventValue}
                        show={showModalPostEventCreateAuto}
                        networksActive={props.networksActive}
                        handleHide={handleHideModalPostEventCreateAuto}
                    />}

                    {event.status !== 'FINISHED' && event.status !== 'CANCELED' && <PostEventCreateManualModalContainer
                        eventId={eventId}
                        show={showModalPostEventCreateManual}
                        handleHide={handleHideModalPostEventCreateManual}
                    />}

                    {event.status !== 'FINISHED' && event.status !== 'CANCELED' && <PostNewsCreateModalContainer
                        eventId={eventId}
                        postType={postNewsValue}
                        baseline={event.baseline_text}
                        baselineWithoutLink={event.baseline_without_link_text}
                        baselineInstagram={event.baseline_instagram}
                        imgCover={event.img_cover}
                        imgFlyer={event.img_flyer}
                        show={showModalPostNewsCreate}
                        networksActive={props.networksActive}
                        handleHide={handleHideModalPostNewsCreate}
                    />}

                    <Overlay
                        show={showPopOver}
                        target={target}
                        placement="bottom"
                        container={ref}
                        containerPadding={20}
                    >
                        <Popover id="popover-contained">
                            <Popover.Body>
                                <ClipboardCheck /> Copié !
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default SpaResumeComponent;