import { Outlet } from "react-router-dom"
import PoolContainer from "../containers/PoolContainer"

const PoolPage = () => {
    return <>
        <PoolContainer />
        <Outlet />
    </>
}

export default PoolPage