import { Outlet } from "react-router-dom";
import NetworksContainer from "../containers/NetworksContainer";

const NetworksPage = () => {
    return <>
        <NetworksContainer />
        <Outlet />
    </>
}

export default NetworksPage