import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IResponseStatus } from "../reducers";

function* dashboardResume(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.dashboardResume, apikey);
        yield put({
            type: response.status === "fail" ? Constants.DASHBOARD_RESUME_FAIL : Constants.DASHBOARD_RESUME_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({ type: Constants.DASHBOARD_RESUME_FAIL, e });
    }
}

export const dashboardSagas = [
    takeLatest(Constants.DASHBOARD_RESUME, dashboardResume)
];