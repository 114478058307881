import { IPrestationModelRequest } from '../reducers';
import * as Constants from '../constants'
import { ISpaInfos, ISpaImage, IPrestationRequest, IManagePrestationRequest, IManageInfosRequest } from '../reducers';

export function get(id: number, subtype: string, category: string, resolve: any, reject: any) {
    return {
        type: Constants.SPA_GET_EVENT,
        id,
        subtype,
        category,
        resolve,
        reject,
    };
}

export function listModel(subtype: string, category: string) {
    return {
        type: Constants.SPA_LIST_MODEL_EVENT,
        subtype,
        category
    };
}

export function listFinished(subtype: string, category: string) {
    return {
        type: Constants.SPA_LIST_FINISHED_EVENT,
        subtype,
        category
    };
}

export function listFuture(subtype: string, category: string) {
    return {
        type: Constants.SPA_LIST_FUTURE_EVENT,
        subtype,
        category
    };
}

export function createEmpty(subtype: string, category: string, resolve: any, reject: any) {
    return {
        type: Constants.SPA_CREATE_EMPTY_EVENT,
        subtype,
        category,
        resolve,
        reject,
    };
}

export function createModel(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_CREATE_MODEL_EVENT,
        id,
        resolve,
        reject,
    };
}

export function publishCheck(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_PUBLISH_CHECK_EVENT,
        id,
        resolve,
        reject,
    };
}

export function publish(id: number, scheduled_at:string, resolve: any, reject: any) {
    return {
        type: Constants.SPA_PUBLISH_EVENT,
        id,
        scheduled_at,
        resolve,
        reject,
    };
}

export function publishCancel(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_PUBLISH_CANCEL_EVENT,
        id,
        resolve,
        reject,
    };
}

export function ticketsDownload(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_TICKETS_DOWNLOAD,
        id,
        resolve,
        reject,
    };
}

export function remove(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_REMOVE_EVENT,
        id,
        resolve,
        reject,
    };
}

export function updateInfos(infos: ISpaInfos, resolve: any, reject: any) {
    return {
        type: Constants.SPA_UPDATE_INFOS_EVENT,
        infos,
        resolve,
        reject,
    };
}

export function updateCover(infos: ISpaImage, resolve: any, reject: any) {
    return {
        type: Constants.SPA_UPDATE_COVER_EVENT,
        infos,
        resolve,
        reject,
    };
}

export function updateFlyer(infos: ISpaImage, resolve: any, reject: any) {
    return {
        type: Constants.SPA_UPDATE_FLYER_EVENT,
        infos,
        resolve,
        reject,
    };
}

export function saveTmpImage(infos: ISpaImage, resolve: any, reject: any) {
    return {
        type: Constants.SPA_SAVE_TMP_IMAGE,
        infos,
        resolve,
        reject,
    };
}

export function manageInfos(infos: IManageInfosRequest, resolve: any, reject: any) {
    return {
        type: Constants.SPA_MANAGE_INFOS_EVENT,
        infos,
        resolve,
        reject,
    };
}

export function createEmptyPrestation(event_id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_CREATE_EMPTY_PRESTATION,
        event_id,
        resolve,
        reject,
    };
}

export function getPrestation(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_GET_PRESTATION,
        id,
        resolve,
        reject,
    };
}

export function listModelPrestation(subtype: string, category: string, resolve: any, reject: any) {
    return {
        type: Constants.SPA_LIST_MODEL_PRESTATION,
        subtype,
        category,
        resolve,
        reject,
    };
}

export function listScenarioPrestation(resolve: any, reject: any) {
    return {
        type: Constants.SPA_LIST_SCENARIO_PRESTATION,
        resolve,
        reject,
    };
}

export function createModelPrestation(infos: IPrestationModelRequest, resolve: any, reject: any) {
    return {
        type: Constants.SPA_CREATE_MODEL_PRESTATION,
        infos,
        resolve,
        reject
    };
}

export function createScenarioPrestation(infos: IPrestationModelRequest, resolve: any, reject: any) {
    return {
        type: Constants.SPA_CREATE_SCENARIO_PRESTATION,
        infos,
        resolve,
        reject
    };
}

export function removePrestation(id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_REMOVE_PRESTATION,
        id,
        resolve,
        reject,
    };
}

export function updatePrestation(prestationInfos: IPrestationRequest, resolve: any, reject: any) {
    return {
        type: Constants.SPA_UPDATE_PRESTATION,
        prestationInfos,
        resolve,
        reject,
    };
}

export function managePrestation(infos: IManagePrestationRequest, resolve: any, reject: any) {
    return {
        type: Constants.SPA_MANAGE_PRESTATION,
        infos,
        resolve,
        reject
    };
}

export function resumeDownload(event_id: number, resolve: any, reject: any) {
    return {
        type: Constants.SPA_RESUME_DOWNLOAD,
        event_id,
        resolve,
        reject
    };
}
