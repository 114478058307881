import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { IUserPassword } from '../reducers';
import { changePassword } from '../actions';
import ChangePasswordComponent from '../components/ChangePasswordComponent';


const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changePassword: (passwordData: IUserPassword) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(changePassword(passwordData, resolve, reject))
            })
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxChangePassword = ConnectedProps<typeof connector>

export default connector(ChangePasswordComponent)