import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import ScannerComponent from "../components/ScannerComponent";
import * as Actions from '../actions'
import * as Reducers from "../reducers";

const mapStateToProps = (state: RootState) => {
    const codeInfos: Reducers.ICodeInfos = state.scannersReducer.codeInfos;
    const devices: Reducers.IDevice[] = state.scannersReducer.devices;

    return {
        codeInfos,
        devices
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCode: () => {
            dispatch(Actions.getCode());
        },
        getDevices: () => {
            dispatch(Actions.getDevices());
        },
        removeDevice: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.removeDevice(id, resolve, reject));
            });
        },
        changeNotifications: (infos: Reducers.IChangeNotifRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.changeNotifications(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxScanners = ConnectedProps<typeof connector>;

export default connector(ScannerComponent);