import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import TransatPage from "../pages/TransatPage";
import TransatNewContainer from "../containers/TransatNewContainer";
import TransatNewModelsContainer from "../containers/TransatNewModelsContainer";
import TransatCreateContainer from "../containers/TransatCreateContainer";
import TransatEditContainer from '../containers/TransatEditContainer';
import TransatResumeContainer from "../containers/TransatResumeContainer";

export const TransatRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/transat',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <TransatPage /> },
                { path: '/transat/new', element: <TransatNewContainer /> },
                { path: '/transat/models', element: <TransatNewModelsContainer /> },
                {
                    path: '/transat/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/transat/create/:id',
                            element: <TransatCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/transat/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/transat/resume/:id',
                            element: <TransatResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/transat/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/transat/edit/:id',
                            element: <TransatEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}