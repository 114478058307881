import React from 'react'
import {Card} from 'react-bootstrap'

interface GoodTemplateCardProps {
    modelId: number
    selectedId: number
    name: string
    imgCover: string
    onClick: React.Dispatch<React.SetStateAction<number>>
}

const GoodTemplateCard = (props: GoodTemplateCardProps) => {

    const isSelected = (props.selectedId === props.modelId)

    return (
        <Card 
            onClick={() => props.onClick(props.modelId)}
            style={{textDecoration: "none", color: "inherit", cursor: "pointer", margin: 10}}
            className={`position-relative templateCard ${(isSelected ? 'selected' : '')}`} 
        >
            <div className="cardDisplay">
                <Card.Img className="rounded-top eventCardImg" src={props.imgCover} alt="Couverture" />
            </div>
            <Card.Body>
                <Card.Title style={(props.name ? {} : {fontStyle: "italic"})}>{(props.name ? props.name : 'Nom')}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default GoodTemplateCard