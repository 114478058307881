import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { PropsFromRedux } from '../containers/PostEventCreateManualModalContainer';

interface PostEventCreateManualModalComponentProps {
    eventId: number
    show: boolean
    handleHide: (refresh: boolean) => void
}

const PostEventCreateManualModalComponent = (props: PostEventCreateManualModalComponentProps & PropsFromRedux) => {

    const [postLink, setPostLink] = useState('')

    const handleClose = () => {
        props.handleHide(false)
    }

    const savePostManual = () => {
        props.createManualPost({ id: props.eventId, link: postLink })
        .then((status: string) => {
            toast.success("Publication enregistré.")
                        
            props.handleHide(true)
        })
        .catch((error: any) => toast.error(error.message.fr))
    }

    return (
        <>
            <Modal show={props.show} size='lg' backdrop="static" className="">
                <Modal.Header>
                    <Modal.Title>Ajouter le lien</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div>Ajouter le lien permettra à vos clients d'accéder à la publication depuis la fiche de votre événement dans l'application Lib-Events.</div>
                        <div>De plus, ce lien permet à notre équipe de découvrir vos réseaux favoris et de les ajouter à nos réseaux en publication automatique.</div>
                      
                        <Form.Group className=" mt-3" controlId="formURL">
                            <Form.Label className='mt-3' label="Lien de l'événement (URL)">Lien de l'événement (URL)
                            </Form.Label>
                            <Form.Control type="text" value={postLink} onChange={(e) => setPostLink(e.target.value)} placeholder="https://..." />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer className='justify-content-between' style={{borderTop: 'none'}}>
                    <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={handleClose}>Retour</Button>
                    <Button variant='primary' className='btn-modal-device ms-1' onClick={savePostManual}>Valider</Button>
                </Modal.Footer>
                
            </Modal>
        </>
    )
}

export default PostEventCreateManualModalComponent