import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import ForgotPasswordComponent from '../components/ForgotPasswordComponent';
import { forgotPassword, recoverPassword } from '../actions';

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forgotPassword: (email: string) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(forgotPassword(email, resolve, reject))
            })
        },
        recoverPassword: (code: string, email: string, password: string) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(recoverPassword(code, email, password, resolve, reject))
            })
        }
    }
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export type PropsFromReduxForgotPassword = ConnectedProps<typeof connector>

export default connector(ForgotPasswordComponent)