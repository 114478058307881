import React, { useState, useEffect } from 'react'
import { Badge } from 'react-bootstrap'

const PostStatusBadge = (props: { status: "SCHEDULED" | "IN_PROGRESS" | "FAILED" | "POSTED" }) => {

    const [color, setColor] = useState('')
    const [content, setContent] = useState('')

    useEffect(() => {
        switch (props.status) {
            case 'SCHEDULED':
                setColor('info')
                setContent('PROGRAMMÉ')
                break
            case 'IN_PROGRESS':
                setColor('warning')
                setContent('EN COURS')
                break
            case 'FAILED':
                setColor('danger')
                setContent('ECHOUÉ')
                break
            case 'POSTED':
                setColor('success')
                setContent('PUBLIÉ')
                break
        }
    }, [props.status])

    return (
        <Badge className="py-1 px-2 ms-3 text-white status-tag" bg={color}>
            {content}
        </Badge>
    )
}

export default PostStatusBadge