import { ChangeEvent, FormEvent, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import FooterComponent from '../../../common/components/FooterComponent';
import { PropsFromReduxLogin } from '../containers/LoginContainer';
import Logo from '../../../assets/RectBlanc.png'
import toast from 'react-hot-toast';


const LoginComponent = (props: PropsFromReduxLogin) => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        setValidated(true);

        props.logIn({ email, password })
        .catch(error => toast.error(error.message.fr))
    };

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value);
    };

    const handlePasswordInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.target.value);
    };

    return (
        <div className='w-100'>
            <Container className='d-flex align-content-center justify-content-center flex-wrap '>
                <Row className='py-4 align-items-center justify-content-center bg-white-up' style={{zIndex:50}}>
                    <Col className='d-flex flex-wrap align-items-center justify-content-center' >
                        <img src={Logo} width={'70%'} height={'100%'} alt="" />
                        <h3 className='text-center mt-3 mb-5'>Accès Organisateur</h3>
                        <Form className='register-form' noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-4 w-75 " controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="E-mail" onChange={handleEmailInputChange} required />
                            </Form.Group>
                            <Form.Group className="mb-2 w-75" controlId="password">
                                <Form.Label>Mot de passe &nbsp; </Form.Label>
                                (&nbsp;
                                <Link to="/forgot-password" className="formlink" style={{ fontSize: '14px' }}>
                                    Oublié ?&nbsp;
                                </Link>
                                )
                                <Form.Control type={(showPassword ? "text" : "password")} placeholder="Mot de passe" onChange={handlePasswordInputChange} required />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Form.Group>
                            <div className='text-center'>
                                <Button className="mt-4 mb-5 btn-register" variant="primary" type="submit">
                                    Connexion
                                </Button>
                            </div>
                            <Row>
                                <Col className="text text-center mt-2">
                                    <p className=''>Vous n'avez pas encore de compte ?
                                        <Link to='/register' className="formlink fw-bold"> Inscrivez-vous</Link>
                                    </p>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <FooterComponent />
        </div>
    )
}

export default LoginComponent