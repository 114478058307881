import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import RestaurantConfigureComponent from "../components/RestaurantConfigureComponent";
import * as Actions from '../actions'
import { IConfiguration } from "../reducers";

const mapStateToProps = (state: RootState) => {
    return {
        listConfigurations: state.restaurantReducer.listConfigurations
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        listConfiguration: () => {
            dispatch(Actions.listConfiguration());
        },
        createConfiguration: (value: IConfiguration) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.createConfiguration(value, resolve, reject));
            })
        },
        removeConfiguration: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.removeConfiguration(id, resolve, reject));
            })
        },
        updateConfiguration: (value: IConfiguration) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateConfiguration(value, resolve, reject));
            })
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxRestaurantConfigure = ConnectedProps<typeof connector>;

export default connector(RestaurantConfigureComponent);