import {Card, Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import EventStatusBadge from "./EventStatusBadge";
import { formatDatetime } from "../../utils/formattage";
import { IEventLight } from "../reducers";
import { getEventInternPath } from "../helpers/getEventInternPath";


const EventCard = (props: IEventLight) => {
    
    const navigate = useNavigate();

    let uri = getEventInternPath(props.subtype, props.category);

    let link = 'create';
    if(props.status !== 'DRAFT') {
        link = 'resume'
    }
    
    return (
        <Card 
            onClick={() => navigate("/"+uri+"/"+link+"/"+props.id)} 
            style={{textDecoration: "none", color: "inherit", cursor: "pointer", margin: 10}}
            className="position-relative eventCard" 
        >
            <div className="cardDisplay">
                <Card.Img className="rounded-top eventCardImg" src={props.img_flyer} alt="événement" />
                <EventStatusBadge status={props.status} />
            </div>
            <Card.Body>
                <Col className="justify-content-start d-flex h-50">
                    <div className="w-100">
                        <Card.Title className="fw-bolder"  style={(props.name ? {} : {fontStyle: "italic"})}>{(props.name ? props.name : 'Nom')}</Card.Title>
                        <Card.Text style={(props.started ? {} : {fontStyle: "italic"})}>{(props.started ? formatDatetime(props.started) : 'Date')}</Card.Text>
                    </div>
                </Col>
            </Card.Body>
        </Card>
    )
};

export default EventCard;