import {all} from 'redux-saga/effects'
import {authSagas} from '../../modules/auth/saga'
import {dashboardSagas} from '../../modules/dashboard/saga'
import {partySagas} from '../../modules/party/saga'
import {poolSagas} from '../../modules/pool/saga'
import {transatSagas} from '../../modules/transat/saga'
import {spaSagas} from '../../modules/spa/saga'
import {restaurantSagas} from '../../modules/restaurant/saga'
import {hostelSagas} from '../../modules/hostel/saga'
import {packSagas} from '../../modules/pack/saga'
import {businessRecordSagas} from '../../modules/business-record/saga'
import {scannerSagas} from './../../modules/scanners/saga'
import {networksSagas} from './../../modules/networks/saga'
import {bankInfoSagas} from '../../modules/bank-info/saga'
import {postsSagas} from '../../modules/posts/saga'

export function* rootSaga () {
    yield all([
        ...authSagas,
        ...dashboardSagas,

        ...partySagas,
        ...poolSagas,
        ...transatSagas,
        ...spaSagas,
        ...restaurantSagas,
        ...hostelSagas,
        ...packSagas,
        
        ...businessRecordSagas,
        ...scannerSagas,
        ...networksSagas,
        ...bankInfoSagas,

        ...postsSagas
    ])
}
