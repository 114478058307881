import {Outlet, RouteObject} from "react-router-dom";
import * as ROUTES from './bankInfo'
import withPermissions from "../../../routes/withPermissions";
import BankInfoPage from "../pages/BankInfoPage";

export const BankInfoRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: ROUTES.BANK_INFO,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <BankInfoPage />}
            ]
        }
    ]
}