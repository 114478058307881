import { SyntheticEvent, useEffect, useState } from 'react'
import { Badge, Button, Col, FloatingLabel, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { X } from 'react-bootstrap-icons'
import { IConfiguration } from '../reducers'
import RemoveConfigurationModal from './common/RemoveConfigurationModal';
import { toast } from 'react-hot-toast';

interface EditConfigurationModalComponentProps {
    show: boolean
    configuration: IConfiguration | undefined
    closeModal: () => void
    createConfiguration: (value: IConfiguration) => Promise<string>
    updateConfiguration: (value: IConfiguration) => Promise<string>
    removeConfiguration: (id: number) => Promise<string>
}

const EditConfigurationModalComponent = (props: EditConfigurationModalComponentProps) => {
    
    const [showModalRemove, setShowModalRemove] = useState(false);

    const [id, setId] = useState<number | undefined>();
    const [days, setDays] = useState<string[]>(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'])
    const [hourChoice, setHourChoice] = useState('')
    const [hourChoices, setHourChoices] = useState<string[]>([])
    const [maxPeopleTable, setMaxPeopleTable] = useState<string>("8")
    const [maxPeopleService, setMaxPeopleService] = useState<string>("100")
    const [daysBefore, setDaysBefore] = useState<string>("7")
    const [closeReservation, setCloseReservation] = useState("ON_TIME")
    const [eventStartDate, setEventStartDate] = useState("");
    const [eventEndDate, setEventEndDate] = useState("");

    useEffect(() => {
        if (props.configuration !== undefined) {
            setId(props.configuration.id)
            setDays(props.configuration.days)
            setHourChoices(props.configuration.choices)
            setMaxPeopleTable(props.configuration.quantity_per_table.toString())
            setCloseReservation(props.configuration.close_reservation)
            setMaxPeopleService(props.configuration.quantity.toString())
            setDaysBefore(props.configuration.days_before.toString())
            setEventStartDate(props.configuration.start_at)
            setEventEndDate(props.configuration.end_at)
        } else {
            setId(undefined)
            setDays(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'])
            setHourChoice('')
            setHourChoices([])
            setMaxPeopleTable("8")
            setMaxPeopleService("100")
            setDaysBefore("7")
            setEventStartDate("")
            setEventEndDate("")
        }
    }, [props.configuration])


    const arrayRemove = (arr: string[], value: string) => {
        return arr.filter((val) => val !== value)
    }
    const updateDays = (e: any) => {
        let order = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
        const day = e.currentTarget.value

        if (days.includes(day)) setDays(arrayRemove(days, day))
        else {
            let map = new Set([...days, day])
            setDays(order.filter(o => map.has(o)))
        }
    }

    const handleAddChoice = (e: SyntheticEvent) => {
        if (!hourChoice) return
        setHourChoices([...hourChoices, hourChoice])
        setHourChoice('')
    }

    const handleRemovePrestaChoice = (index: number) => {
        const choices = hourChoices
        choices.splice(index, 1)
        setHourChoices([...choices])
    }

    const disableValidate = (): boolean => {
        if (days.length !== 0 && hourChoices.length !== 0 && maxPeopleTable !== undefined && maxPeopleService !== undefined && daysBefore !== undefined)
            return false
        return true
    }

    const handleValidate = () => {
        props.configuration === undefined ? (
            props.createConfiguration({
                days: days,
                choices: hourChoices,
                quantity_per_table: (maxPeopleTable ? Number(maxPeopleTable) : 0),
                quantity: (maxPeopleService ? Number(maxPeopleService) : 0),
                days_before: (daysBefore ? Number(daysBefore) : 0),
                close_reservation: closeReservation,
                start_at: eventStartDate,
                end_at: eventEndDate,
            })
            .then((status: string) => {
                props.closeModal()
            })
            .catch(error => { toast.error(error.message.fr) })
        ) : (
            props.updateConfiguration({
                id: id,
                days: days,
                choices: hourChoices,
                quantity_per_table: (maxPeopleTable ? Number(maxPeopleTable) : 0),
                quantity: (maxPeopleService ? Number(maxPeopleService) : 0),
                days_before: (daysBefore ? Number(daysBefore) : 0),
                close_reservation: closeReservation,
                start_at: eventStartDate,
                end_at: eventEndDate,
            })
            .then((status: string) => {
                props.closeModal()
            })
            .catch(error => { toast.error(error.message.fr) })
        )
    }

    return (
        <Modal show={props.show} onHide={props.closeModal} backdrop="static" size='lg'>
            <RemoveConfigurationModal 
                configurationId={(props.configuration && props.configuration.id ? props.configuration.id : undefined)}
                closeModal={props.closeModal}
                showModalRemove={showModalRemove}
                setShowModalRemove={setShowModalRemove}
                removeConfiguration={props.removeConfiguration}
            />
            <Modal.Header>
                <Modal.Title className='w-100'>Configurer un service</Modal.Title>
                <Button variant='outline-secondary' onClick={props.closeModal}>Fermer</Button>
            </Modal.Header>
            <Modal.Body className="py-4">
                <div className='mb-2'>
                    <div className=''>Jours</div>
                    <div className='small text-muted'>Jours où le service est disponible</div>
                    <InputGroup>
                        <Form.Check inline checked={(days.includes("MONDAY"))} type="switch" id="days-monday" value="MONDAY" label="Lundi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("TUESDAY"))} type="switch" id="days-tuesday" value="TUESDAY" label="Mardi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("WEDNESDAY"))} type="switch" id="days-wednesday" value="WEDNESDAY" label="Mercredi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("THURSDAY"))} type="switch" id="days-thursday" value="THURSDAY" label="Jeudi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("FRIDAY"))} type="switch" id="days-friday" value="FRIDAY" label="Vendredi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("SATURDAY"))} type="switch" id="days-saturday" value="SATURDAY" label="Samedi" onClick={e => updateDays(e)} />
                        <Form.Check inline checked={(days.includes("SUNDAY"))} type="switch" id="days-sunday" value="SUNDAY" label="Dimanche" onClick={e => updateDays(e)} />
                    </InputGroup>
                </div>
                <div className=''>
                    <div className=''>Heures d'arrivée</div>
                    <div className='small text-muted'>Une ou plusieurs heures d'arrivées différentes par jour</div>
                    <Row>
                        <Col>
                            <InputGroup className="my-3">
                                <Form.Control type="time" step="600" value={hourChoice} onChange={(e) => setHourChoice(e.target.value)} placeholder="Choix" />
                                <InputGroup.Text id="prestaChoice" onClick={handleAddChoice} style={{cursor: "pointer"}}>Ajouter</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <div className='d-flex gap-2 flex-wrap my-3
                            '>
                                {
                                    hourChoices.map((choice, i) => (
                                        <Badge key={i} bg='light' style={{ color: '#454545', fontSize: '1.2rem' }} className='py-2'>{choice} <X onClick={() => handleRemovePrestaChoice(i)} /> </Badge>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className='mb-2'>
                    <Col>
                        <FloatingLabel className='mt-1' controlId='maxPeopleTable' label='Nombre de personnes max. par table'>
                            <Form.Control type='number' value={maxPeopleTable} onChange={(e) => setMaxPeopleTable(e.target.value)} min={0} placeholder='Nombre de personnes max. par table' />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel className='mt-1' controlId='maxPeopleService' label='Nombre de personnes max. par service'>
                            <Form.Control type='number' value={maxPeopleService} onChange={(e) => setMaxPeopleService(e.target.value)} min={0} placeholder='Nombre de personnes max. par service' />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <FloatingLabel className='mt-3' controlId='daysBefore' label='Début de la réservation (en jours)'>
                            <Form.Control type='number' value={daysBefore} onChange={(e) => setDaysBefore(e.target.value)} min={0} placeholder='Début de la réservation (en jours)' />
                        </FloatingLabel>
                        <Form.Text className="text-muted">Nombre de jours précédent le service à partir duquel le client peut réserver</Form.Text>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <div className=''>Clotûre des réservations</div>
                        <Form.Select value={closeReservation} onChange={(e) => setCloseReservation(e.target.value)}>
                            <option value='ON_TIME'>A l'heure</option>
                            <option value='BEFORE_15_MIN'>15 minutes avant</option>
                            <option value='BEFORE_30_MIN'>30 minutes avant</option>
                            <option value='BEFORE_1_H'>1 heure avant</option>
                            <option value='BEFORE_2_H'>2 heures avant</option>
                            <option value='BEFORE_4_H'>4 heures avant</option>
                        </Form.Select>
                        <Form.Text className="text-muted">Délai avant l'heure d'arrivée à partir duquel les clients ne pourront plus réserver</Form.Text>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Body className='border-top'>
                <Form.Group className='mb-3 d-flex w-100 gap-2' controlId='formEventDates'>
                    <div className='w-100'>
                        <Form.Label className='mb-1'>Date de début</Form.Label>
                        <div className='small text-muted mb-1'>Facultatif</div>
                        <Form.Control type='date' value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
                    </div>
                    <div className='w-100'>
                        <Form.Label className='mb-1'>Date de fin</Form.Label>
                        <div className='small text-muted mb-1'>Facultatif</div>
                        <Form.Control type='date' value={eventEndDate} onChange={(e) => setEventEndDate(e.target.value)} />
                    </div>
                </Form.Group>
            </Modal.Body>
            {props.configuration !== undefined ?            
                <Modal.Footer className="d-flex justify-content-center w-100">
                    <Col className="text-start">
                        <Button className="fw-bolder" variant='outline-danger' type="button" onClick={() => setShowModalRemove(true)}>Supprimer</Button>
                    </Col>
                    <Col className="text-end">
                        <Button className="fw-bolder" variant='primary' type="submit" disabled={disableValidate()} onClick={() => handleValidate()}>Sauvegarder</Button>
                    </Col>
                </Modal.Footer>
                :
                <Modal.Footer >
                    <Button className="fw-bolder" variant='primary' type="submit" disabled={disableValidate()} onClick={() => handleValidate()}>Ajouter</Button>
                </Modal.Footer>
            }
        </Modal >
    )
}

export default EditConfigurationModalComponent