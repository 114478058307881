import { produce } from "immer";
import * as Constants from "../constants";
import { AnyAction } from "@reduxjs/toolkit";

export interface INetworksState {
    networks: INetwork[] | null
    networksActive: INetwork[] | null
    postTypesAvailables: IPostTypes[] | null
    networksReferences: INetworkReference[] | null
    nouslibData: any | null
}

export interface INetwork {
    id: number,
    status: string,
    type: string,
    type_icon: string,
    login: string,
    error?: string
    page?: string,
    nouslib_name?: string,
    nouslib_country?: string,
    nouslib_region?: string,
    nouslib_department?: string,
}

export interface INetworkReference {
    id: string
    name: string
    icon: string
}

export interface INetworkCredentials {
    id?: number
    type?: 'WYYLDE_PAGE' | 'FACEBOOK_PROFILE' | 'FACEBOOK_PAGE' | 'NOUSLIB'
    login: string
    password: string
    nouslib_country: string
    nouslib_region: string
    nouslib_department: string
    nouslib_name: string
    url_page: string
}

export interface INetworkType {
    type: string
}

export interface IFacebookConfirm {
    id: number,
    page_id: string
}

export interface IInstagramConfirm {
    id: number,
    page_id: string
}

export interface IFacebookPage {
    id: number,
    name: string
}

export interface IInstagramPage {
    id: number,
    name: string
}

export interface IPostType {
    id: string
    name: string
    icon: string
}

export interface IPostTypes {
    EVENT: IPostType[]
    NEWS: IPostType[]
}

export interface IResponseStatus {
    data: {
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    };
    status: "fail" | "success";
}

const initState = {
    networks: null,
    networksActive: null,
    postTypesAvailables: null,
    networksReferences: null,
    nouslibData: null
} as INetworksState;

const networksReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case Constants.GET_NETWORKS_LIST_SUCCESS:
            draft.networks = action.payload.data.networks;
            break;
        case Constants.GET_NETWORKS_LIST_ACTIVE_SUCCESS:
            draft.networksActive = action.payload.data.networks;
            draft.postTypesAvailables = action.payload.data.posts;
            break;
        case Constants.GET_NETWORKS_REFERENCES_SUCCESS:
            draft.networksReferences = action.payload.data.networks;
            draft.nouslibData = action.payload.data.NOUSLIB;
            break;
        default:
            return draft;
    }
});

export default networksReducer;
