import { useState, useEffect, ChangeEvent } from "react";
import { Button, Card, Col, Form, Row, Modal, InputGroup, ToggleButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ISpa, IManageInfosRequest } from "../reducers";
import { PropsFromRedux } from "../containers/SpaEditContainer";
import { convertDatetimeLocalToUtc, formatDateEdit } from "../../../utils/formattage";
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import '../../../common/lib/trumbowyg/plugins/colors/ui/trumbowyg.colors.css';
import '../../../common/lib/trumbowyg/plugins/colors/trumbowyg.colors.js';
import moment from "moment";
import history from "../../../routes/history";

const SpaEditComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const eventId = parseInt(location.pathname.split("/")[3]);

    const [event, setEvent] = useState<ISpa | undefined>()

    const [eventDescription, setEventDescription] = useState('');
    
    const [valueCheckboxDiffuse, setValueCheckboxDiffuse] = useState(false);

    /*const [eventFlyerBase64, setEventFlyerBase64] = useState('');
    const [eventFlyerTmp, setEventFlyerTmp] = useState('');
    const [eventFlyerChanged, setEventFlyerChanged] = useState(false);
    const [eventFlyerFirstLoaded, setEventFlyerFirstLoaded] = useState(false);*/

    const [showModalPublish, setShowModalPublish] = useState(false);
    //const [showFlyerImageCustomize, setShowFlyerImageCustomize] = useState(false);
    
    //const [publishDate, setPublishDate] = useState('')
    //const [publishChoice, setPublishChoice] = useState<'NOW' | 'PLANNED'>('NOW')

    useEffect(() => {
        fetchEvent()
    }, [])
    
    const fetchEvent = () => {
        props.get(eventId, 'UNIQUE', 'SPA')
        .then(ev => {
            if(ev.status !== 'PUBLISHED') {
                navigate("/spa/resume/"+eventId)
                return
            }

            setEvent(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/spa")
        });
    };

    useEffect(() => {
        if (event) {
            setEventDescription(event.description ? event.description : '')

            /*if(event.img_flyer) {
                setEventFlyerTmp(event.img_flyer);
            } */           
        }
    }, [event]);
            
    //const handleCloseFlyerImageCustomize = () => setShowFlyerImageCustomize(false);
    //const handleShowFlyerImageCustomize = () => setShowFlyerImageCustomize(true);

    const handleDescription = (e: any) => {
        /* @ts-ignore */
        var content = $(e.target).trumbowyg('html');
        setEventDescription(content);
    };
         
    /* @ts-ignore */
    /*const removeFlyer = async () => {
        setEventFlyerChanged(true)
        setEventFlyerBase64('')
        setEventFlyerTmp('')
    }*/
    
    /* @ts-ignore */
    /*const transformFlyer = async (data) => {
        console.log('transformFlyer')
        console.log(data)
        
        const base64 = await blobToBase64(data.input.file);
        setEventFlyerBase64(base64)
        saveTmpImage(base64)
    }*/

    /*const saveTmpImage = async (base64: string) => {

        props.saveTmpImage({ id: eventId, base64: base64 })
        .then(url => {
            setEventFlyerTmp(url);
            console.log('saveTmpImage url: '+url)
        })
        .catch(error => {
            console.error(error.message.fr)
        })

    }  */  
    
    /*const saveFlyerCustomized = async (base64: string) => {
        if(base64) {            
            setEventFlyerBase64(base64)
            saveTmpImage(base64)
        }
        setShowFlyerImageCustomize(false)
    }*/

    /*const handleModalPublish = () => {        
        setPublishDate(moment.utc().local().add('1', 'hour').format('YYYY-MM-DD HH:mm').replace(' ', 'T'));
        setShowModalPublish(true);        
    }*/

    /*const handlePublishChoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublishChoice(e.currentTarget.value as 'NOW' | 'PLANNED')
    }*/

    const handlePublish = () => {

        let request: IManageInfosRequest = {
            id: eventId,
            description: eventDescription,
            diffuse: false
        };

        if(valueCheckboxDiffuse) {
            request.diffuse = true;
        }

        /*if(eventFlyerChanged) {
            request.base64 = eventFlyerBase64
        }*/
        
        /*if(publishChoice === 'PLANNED') {
            request.notification_at = convertDatetimeLocalToUtc(publishDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
        }*/

        props.manageInfos(request)
        .then((status) => {
            toast.success("Modification en cours. Pensez à envoyer une notification du changement depuis l'onglet Promotion réseaux !", {duration: 5000})
            handlePrevious()
        })
        .catch(error => toast.error(error.message.fr))
    }
    
    const handleCheckboxDiffuse = () => {
        setValueCheckboxDiffuse(!valueCheckboxDiffuse);
    };
    
    const handlePrevious = () => {
        history.back()
    };
    
    return (
        <>
            {event ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`eventTitle m-0 align-self-center h-50`}>Événement</Card.Title>
                                    <div className="d-flex gap-2 mt-2 mt-md-0 mb-2 mb-md-0">
                                        <Button onClick={() => handlePrevious()} variant="outline-secondary">
                                            Retour
                                        </Button>
                                        {/*<Button onClick={handleModalPublish} variant='success'>Sauvegarder</Button>*/}
                                        <Button onClick={handlePublish} variant='success'>Sauvegarder</Button>
                                    </div>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>                                
                                
                    {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Informations</div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formEventName">
                                        <Form.Label>Description</Form.Label>
                                        <Trumbowyg
                                            id='react-trumbowyg'
                                            buttons={
                                                [
                                                    ['formatting'],
                                                    ['foreColor', 'backColor'],
                                                    ['strong', 'em', 'underline', 'del'],
                                                    ['insertImage'],
                                                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                                                    ['unorderedList', 'orderedList'],
                                                    ['horizontalRule'],
                                                    ['link'],
                                                    ['viewHTML'],
                                                ]
                                            }
                                            data={event.description}
                                            placeholder='Décrit ton événement en quelques lignes..'
                                            onChange={(e: any) => handleDescription(e)} 
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 w-75 " controlId="radio-diffuse">
                                        <Form.Check type={'checkbox'}
                                            label="Mettre à jour automatiquement la description des fiches Evénements sur mes réseaux configurés"
                                            id={"radio-diffuse"}
                                            role="button"
                                            checked={valueCheckboxDiffuse} onChange={handleCheckboxDiffuse} className="position-relative" />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        

                        {/* -----------------------------  FLYER  ----------------------------- 
                    
                        <Col className='d-flex gap-2 flex-column col-12 col-md-6 offset-md-3'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Flyer</div>
                                        <div className="align-items-right">
                                            <Button className="topButton" variant="outline-secondary" disabled={eventFlyerTmp === ''} onClick={() => handleShowFlyerImageCustomize()}>
                                                Customiser
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text id='flyer'>Le flyer sera affichée au format portrait pour présenter votre événement.</Card.Text>
                                    <div className='w-100 overflow-hidden align-self-center'>
                                    <Slim ratio="2:3"
                                        initialImage={event && event.img_flyer ? event.img_flyer : ''}
                                        minSize={ { width:200, height:300 } }
                                        didRemove={ removeFlyer }
                                        didTransform={ transformFlyer }
                                        serviceFormat="file"
                                        style={ { aspectRatio: '2/1' } }
                                        label="Charger une image"
                                        buttonCancelLabel= "Annuler"
                                        buttonCancelTitle= "Annuler"
                                        buttonConfirmLabel= "Valider"
                                        buttonConfirmTitle= "Valider"
                                        buttonRotateTitle= "Rotation"
                                        buttonEditTitle= "Modifier"
                                        buttonRemoveTitle= "Supprimer"
                                        buttonUploadTitle= "Sauvegarder"
                                        statusUploadSuccess="">
                                        <input type="file" name="flyer"/>
                                    </Slim>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <CoverImageEditorModalTUI
                            show={showFlyerImageCustomize}
                            handleHide={handleCloseFlyerImageCustomize}
                            image={eventFlyerTmp}
                            updatedImage={(base64: string) => saveFlyerCustomized(base64) }
                        />*/}

                    </Row >
                    
                    {/*<Modal
                        size="lg"
                        show={showModalPublish}
                        onHide={() => { setShowModalPublish(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Publier</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <div>Vous pouvez programmer la notification de modification de votre événement.</div>
                            <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Date de notification</Form.Label>
                            
                            <InputGroup className="mb-3">
                                <ToggleButton type='radio' variant="outline-primary"
                                    value='NOW' id='NOW' name='radio'
                                    onChange={(e) => handlePublishChoiceChange(e)}
                                    checked={publishChoice === 'NOW'}
                                >
                                    Immédiatement
                                </ToggleButton>
                                <ToggleButton type='radio' variant="outline-primary"
                                    value='PLANNED' id='PLANNED' name='radio'
                                    onChange={(e) => handlePublishChoiceChange(e)}
                                    checked={publishChoice === 'PLANNED'}
                                >
                                    Plus tard
                                </ToggleButton>
                                {publishChoice === 'PLANNED' && <Form.Control className='ms-3' type='datetime-local' value={publishDate} onChange={(e) => setPublishDate(e.target.value)} />}
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalPublish(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handlePublish}>Valider</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>*/}
                    
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default SpaEditComponent;