import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import PoolPage from "../pages/PoolPage";
import PoolNewContainer from "../containers/PoolNewContainer";
import PoolNewModelsContainer from "../containers/PoolNewModelsContainer";
import PoolCreateContainer from "../containers/PoolCreateContainer";
import PoolEditContainer from '../containers/PoolEditContainer';
import PoolResumeContainer from "../containers/PoolResumeContainer";

export const PoolRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/pool',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <PoolPage /> },
                { path: '/pool/new', element: <PoolNewContainer /> },
                { path: '/pool/models', element: <PoolNewModelsContainer /> },
                {
                    path: '/pool/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pool/create/:id',
                            element: <PoolCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/pool/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pool/resume/:id',
                            element: <PoolResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/pool/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pool/edit/:id',
                            element: <PoolEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}