import * as Constants from "../constants";
import { IBankInfo, postBankInfo } from "../reducers";

export function getBankInfo(resolve: (bankInfo: IBankInfo) => void, reject: any) {
    return {
        type: Constants.GET_BANK_INFO, resolve, reject
    }
}

export function updateBankInfo(value: postBankInfo, resolve: (status: string) => void, reject: any) {
    return {
        type: Constants.UPDATE_BANK_INFO, value, resolve, reject
    }
}
