import {Outlet} from "react-router-dom"
import ChangePasswordContainer from "../containers/ChangePasswordContainer"

const ChangePasswordPage = () => {
    return <>
        <ChangePasswordContainer />
        <Outlet />
    </>
}

export default ChangePasswordPage