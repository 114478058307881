import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { PropsFromReduxBankInfo } from '../containers/BankInfoContainer';
import { Alert } from 'react-bootstrap'
import toast from "react-hot-toast";
import { formatDate } from '../../../utils/formattage';
import { IBankInfo } from '../reducers';

const BankInfoComponent = (props: PropsFromReduxBankInfo) => {

    const [retrocessionRate, setRetrocessionRate] = useState(0.1)
    const [society, setSociety] = useState("")
    const [siren, setSiren] = useState("")
    const [iban, setIban] = useState("")
    const [bic, setBic] = useState("")
    const [ibanGood, setIbanGood] = useState("")
    const [bicGood, setBicGood] = useState("")
    const [checkStatus, setCheckStatus] = useState("")
    const [checkAt, setCheckAt] = useState("")
    
    useEffect(() => {
        props.getBankInfo().then((bankInfo: IBankInfo) => {

            if(bankInfo) {                
                setRetrocessionRate(bankInfo.retrocession_rate)
                setSociety(bankInfo.society_name)
                setSiren(bankInfo.society_siren)
                setIban(bankInfo.society_iban)
                setBic(bankInfo.society_bic)
                setIbanGood(bankInfo.society_iban_good)
                setBicGood(bankInfo.society_bic_good)
                setCheckStatus(bankInfo.check_status)
                setCheckAt(bankInfo.check_at)
            }

        }).catch((error: any) => {
            if(error.message.fr) {
                toast.error(error.message.fr)
            }
        })

    }, [])
    
    const handleValidate = () => {
        props.updateBankInfo({
            society_name: society,
            society_siren: siren,
            society_iban: iban,
            society_bic: bic,
            society_iban_good: ibanGood,
            society_bic_good: bicGood
        }).then(() => {
            toast.success("Données mise à jour. La modification sera effective dès validation par notre équipe.")
            props.getBankInfo().then((bankInfo: IBankInfo) => {

                if(bankInfo) {                    
                    setRetrocessionRate(bankInfo.retrocession_rate)
                    setSociety(bankInfo.society_name)
                    setSiren(bankInfo.society_siren)
                    setIban(bankInfo.society_iban)
                    setBic(bankInfo.society_bic)
                    setIbanGood(bankInfo.society_iban_good)
                    setBicGood(bankInfo.society_bic_good)
                    setCheckStatus(bankInfo.check_status)
                    setCheckAt(bankInfo.check_at)
                }
    
            })
        }).catch((error: any) => {
            toast.error(error.message.fr)
        })
    }

    return (
        <>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Informations</div>
                                <div className="align-items-right d-none d-md-block">
                                    <Button className="topButton" variant="primary" onClick={handleValidate}>
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Votre taux de rétrocession est de : <b>{(Math.round(retrocessionRate*10000)/100)} %</b><br /><i>Ce taux sera appliqué sur les encaissements réalisés par vos clients (hors annulation et remboursement).</i></Card.Text>
                            <Card.Text>Afin de pouvoir vous transférer votre CA et effectuer des virements SEPA vers votre compte bancaire professionel, nous avons besoin de vos informations administratives et bancaires.</Card.Text>
                            <Card.Text>Ces informations sont obligatoires et seront validées manuellement par un membre de notre équipe.</Card.Text>
                            
                            {checkStatus &&
                                <>
                                    {checkStatus == 'IN_PROGRESS' ?
                                        <div className='mt-5'>
                                            <Alert variant='warning'>Données en cours de validation.</Alert>
                                        </div>
                                        :
                                        <div className='mt-5'>
                                            <Alert variant='success'>Données validées le {formatDate(checkAt)}.</Alert>
                                        </div>
                                    }
                                </>
                            }

                            <Form.Group className='mt-5 mb-3 d-flex w-100' controlId='formEventDates'>
                                <div className='w-100'>
                                    <Form.Label>Nom de la société ou association</Form.Label>
                                    <Form.Control type="text" value={society} onChange={(e) => setSociety(e.target.value)} placeholder="Rock Club" />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>SIREN (société) ou RNA (association)</Form.Label>
                                <Form.Control type="text" value={siren} onChange={(e) => setSiren(e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim())} placeholder="XXX XXX XXX" />
                            </Form.Group>
                            <hr />
                            <Card.Text>Pour reverser les gains des événements (soirées, pool, transat, SPA et PACK) et restaurants</Card.Text>
                            
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>IBAN</Form.Label>
                                <Form.Control type="text" value={iban} onChange={(e) => setIban(e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim())} placeholder="XXXX XXXX XXXX XXXX" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>BIC</Form.Label>
                                <Form.Control type="text" value={bic} onChange={(e) => setBic(e.target.value)} placeholder="XXX XXX XXX XXX" />
                            </Form.Group>
                            <hr />
                            <Card.Text>Pour reverser les gains de l'hébergement</Card.Text>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>IBAN</Form.Label>
                                <Form.Control type="text" value={ibanGood} onChange={(e) => setIbanGood(e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim())} placeholder="XXXX XXXX XXXX XXXX" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEventName">
                                <Form.Label>BIC</Form.Label>
                                <Form.Control type="text" value={bicGood} onChange={(e) => setBicGood(e.target.value)} placeholder="XXX XXX XXX XXX" />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="d-flex d-md-none align-items-center ">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder"></div>
                                <div className="align-items-right">
                                    <Button className="topButton" variant="primary" onClick={() => handleValidate()}>
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Card.Title>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default BankInfoComponent