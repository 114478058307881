import * as Constants from '../constants'
import {IChangeNotifRequest} from '../reducers';

export function getCode () {
    return {
        type: Constants.GET_CODE,
    };
}

export function getDevices () {
    return {
        type: Constants.GET_DEVICES,
    };
}

export function removeDevice (id: number, resolve: any, reject: any) {
    return {
        type: Constants.REMOVE_DEVICE,
        id,
        resolve,
        reject
    };
}

export function changeNotifications (infos: IChangeNotifRequest, resolve: any, reject: any) {
    return {
        type: Constants.CHANGE_NOTIFICATIONS,
        infos,
        resolve,
        reject
    };
}