import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import * as Actions from "../actions";
import {IGood, IGoodAvailabilitiesRequest, IGoodAvailabilityRequest } from "../reducers";
import HostelResumeComponent from "../components/HostelResumeComponent";

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get: (id: number) => {
            return new Promise((resolve: (event: IGood) => void, reject: any) => {
                dispatch(Actions.get(id,  resolve, reject));
            });
        },
        availabilitiesCreate: (infos: IGoodAvailabilitiesRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.availabilitiesCreate(infos, resolve, reject));
            });
        },
        availabilityUpdate: (infos: IGoodAvailabilityRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.availabilityUpdate(infos, resolve, reject));
            });
        },

    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HostelResumeComponent);