import { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, Modal, InputGroup, ToggleButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IGood, IManageInfosRequest } from "../reducers";
import { PropsFromRedux } from "../containers/HostelEditContainer";
import { convertDatetimeLocalToUtc, formatDateEdit } from "../../../utils/formattage";
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import '../../../common/lib/trumbowyg/plugins/colors/ui/trumbowyg.colors.css';
import '../../../common/lib/trumbowyg/plugins/colors/trumbowyg.colors.js';
import moment from "moment";
import history from "../../../routes/history";

const HostelEditComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const goodId = parseInt(location.pathname.split("/")[3]);

    const [good, setGood] = useState<IGood | undefined>()

    const [goodDescription, setGoodDescription] = useState('');
    
    useEffect(() => {
        fetchGood()
    }, [])
    
    const fetchGood = () => {
        props.get(goodId)
        .then(ev => {
            if(ev.status !== 'ACTIVATED') {
                navigate("/hostel/resume/"+goodId)
                return
            }

            setGood(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/hostel")
        });
    };

    useEffect(() => {
        if (good) {
            setGoodDescription(good.description ? good.description : '')     
        }
    }, [good]);
    
    const handleDescription = (e: any) => {
        /* @ts-ignore */
        var content = $(e.target).trumbowyg('html');
        setGoodDescription(content);
    };

    const handlePublish = () => {

        let request: IManageInfosRequest = {
            id: goodId,
            description: goodDescription
        };

        props.manageInfos(request)
        .then((status) => {
            toast.success("Modification en cours.", {duration: 5000})
            handlePrevious()
        })
        .catch(error => toast.error(error.message.fr))
    }
        
    const handlePrevious = () => {
        history.back()
    };
    
    return (
        <>
            {good ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`eventTitle m-0 align-self-center h-50`}>Chambre</Card.Title>
                                    <div className="d-flex gap-2 mt-2 mt-md-0 mb-2 mb-md-0">
                                        <Button onClick={() => handlePrevious()} variant="outline-secondary">
                                            Retour
                                        </Button>
                                        {/*<Button onClick={handleModalPublish} variant='success'>Sauvegarder</Button>*/}
                                        <Button onClick={handlePublish} variant='success'>Sauvegarder</Button>
                                    </div>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>                                
                                
                    {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Informations</div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formGoodName">
                                        <Form.Label>Description</Form.Label>
                                        <Trumbowyg
                                            id='react-trumbowyg'
                                            buttons={
                                                [
                                                    ['formatting'],
                                                    ['foreColor', 'backColor'],
                                                    ['strong', 'em', 'underline', 'del'],
                                                    ['insertImage'],
                                                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                                                    ['unorderedList', 'orderedList'],
                                                    ['horizontalRule'],
                                                    ['link'],
                                                    ['viewHTML'],
                                                ]
                                            }
                                            data={good.description}
                                            placeholder='Décrit ton événement en quelques lignes..'
                                            onChange={(e: any) => handleDescription(e)} 
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        
                    </Row >
                                        
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default HostelEditComponent;