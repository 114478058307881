import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import { IPostNewsRequest } from "../reducers";
import PostNewsCreateModalComponent from "../components/PostNewsCreateModalComponent";

const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createPostNews: (infos: IPostNewsRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.createPostNews(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PostNewsCreateModalComponent);