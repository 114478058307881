import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface Props {
    title: string
    content: string
    icon?: JSX.Element
    color?: string
    link?: string
}

const TopCard = (props: Props) => {
    return (
        <div className="col-6 col-md-3 mb-3 px-2 d-flex">
            <Card className='blocCard'>
                {(!!props.icon) &&
                    <Link to={(props.link ? props.link : '#')}>
                        <div style={{ backgroundColor: props.color }} className="blocCardIcon">
                            {props.icon}
                        </div>
                    </Link>
                }
                <Card.Body>
                    <Card.Title className='small text-muted fw-bolder'>{props.title}</Card.Title>
                    <Card.Text className='position-relative lead'>{props.content}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default TopCard