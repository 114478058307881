import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import NetworksComponent from '../components/NetworksComponent';
import { IFacebookConfirm, INetwork, INetworkCredentials, INetworkReference, INetworkType } from '../reducers';
import { createNetwork, deleteNetwork, getNetworksReferences, getNetworksList, updateNetwork, facebookLoginUrl, facebookPageList, facebookLoginConfirm, instagramLoginUrl, instagramPageList, instagramLoginConfirm } from '../actions';


const mapStateToProps = (state: RootState) => {
    const networks: INetwork[] = state.networksReducer.networks
    const networksReferences: INetworkReference[] = state.networksReducer.networksReferences
    const nousLibData: any = state.networksReducer.nouslibData
    return {
        auth: state.authReducer,
        networks,
        networksReferences,
        nousLibData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getNetworksList: () => {
            dispatch(getNetworksList())
        },
        getNetworksReferences: () => {
            dispatch(getNetworksReferences())
        },
        createNetwork: (data: INetworkCredentials) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(createNetwork(data, resolve, reject));
            });
        },
        updateNetwork: (data: INetworkCredentials) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(updateNetwork(data, resolve, reject))
            });
        },
        deleteNetwork: (id: number) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(deleteNetwork(id, resolve, reject))
            });
        },
        facebookLoginUrl: (data: INetworkType) => {
            return new Promise((resolve: (response: any) => void, reject: any) => {
                dispatch(facebookLoginUrl(data, resolve, reject));
            });
        },
        facebookPageList: (id: number) => {
            return new Promise((resolve: (response: any) => void, reject: any) => {
                dispatch(facebookPageList(id, resolve, reject));
            });
        },
        facebookLoginConfirm: (data: IFacebookConfirm) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(facebookLoginConfirm(data, resolve, reject));
            });
        },
        instagramLoginUrl: (data: INetworkType) => {
            return new Promise((resolve: (response: any) => void, reject: any) => {
                dispatch(instagramLoginUrl(data, resolve, reject));
            });
        },    
        instagramPageList: (id: number) => {
            return new Promise((resolve: (response: any) => void, reject: any) => {
                dispatch(instagramPageList(id, resolve, reject));
            });
        },
        instagramLoginConfirm: (data: IFacebookConfirm) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(instagramLoginConfirm(data, resolve, reject));
            });
        },    
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxNetworks = ConnectedProps<typeof connector>

export default connector(NetworksComponent)