import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import PoolComponent from "../components/PoolComponent";
import { IPool } from "../reducers";

const mapStateToProps = (state: RootState) => {
    return {
        eventsFuture: state.poolReducer.eventsFuture,
        eventsFinished: state.poolReducer.eventsFinished,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        listFinished: (subtype: string, category: string) => {
            dispatch(Actions.listFinished(subtype, category));
        },
        listFuture: (subtype: string, category: string) => {
            dispatch(Actions.listFuture(subtype, category));
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PoolComponent);