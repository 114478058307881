import { Button, Card } from 'react-bootstrap'
import { formatDatetime } from '../../../../utils/formattage'
import { GearFill } from 'react-bootstrap-icons'
import { ITicket } from '../../reducers'

interface TicketCardProps {
    ticket: ITicket,
    status: string | undefined,
    startModalInterInfo: (t: ITicket) => void
}

const TicketCard = (props: TicketCardProps) => {
    return (
        <>
            {props.ticket &&
                <div>
                    <Card className={ props.ticket.scanned_at ? 'mt-2 ticketCard-disabled border' : 'mt-2 ticketCard ticketCard border'}>
                        <Card.Header className='d-flex'>
                            <Card.Text className='d-flex w-100 fw-bolder justify-content-start align-items-center mb-0'>
                                Table n° {props.ticket.intern_info ? props.ticket.intern_info : '--'}
                                {props.status && props.status === 'PUBLISHED' &&
                                    <Button onClick={() => props.startModalInterInfo(props.ticket)} variant='outline-secondary' style={{ fontSize: 17, padding: 5, paddingRight: 10, paddingLeft: 10 }} className='ms-3'><GearFill /></Button>
                                }
                            </Card.Text>
                            <Card.Text className='d-flex w-100 fw-bolder justify-content-end align-items-center' > {props.ticket.quantity} couvert{props.ticket.quantity > 1 ? 's' : ''}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text style={{marginBottom: 5, fontWeight: "bold"}}>{props.ticket.user_name}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>{props.ticket.user_requests}</Card.Text>
                            <Card.Text style={{marginBottom: 5}}>N° {props.ticket.code}</Card.Text>
                            {
                                props.ticket.scanned_at && 
                                <Card.Text style={{marginBottom: 5, fontWeight: "bold"}}>Scanné le {formatDatetime(props.ticket.scanned_at)}</Card.Text>
                            }
                        </Card.Body>
                    </Card>
                </div>
            }
        </>
    )
}

export default TicketCard