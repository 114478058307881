import { useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { CalendarEvent, CashCoin, QrCodeScan, Bank } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { PropsFromReduxDashboard } from "../containers/DashboardContainer";
import { formatPrice } from "../../../utils/formattage";
import TopCard from "./common/TopCard";
import EventCard from "../../../common/components/EventCard";
import ServiceCard from "../../restaurant/components/common/ServiceCard";
import { IService } from "../../restaurant/reducers";
import { getEventInternPath } from "../../../common/helpers/getEventInternPath";

const DashboardComponent = (props: PropsFromReduxDashboard) => {

    useEffect(() => {
        props.dashboardResume();
    }, []);

    return (
        <>
            {props.resume && props.resume.event_ephemeral_next_count !== undefined && (
                <>
                    <Row>
                        <TopCard title="A venir (Soirée, Pool party, Transat, SPA, Hébergement et Pack)" content={props.resume.event_ephemeral_next_count.toString()} icon={<CalendarEvent />} color="#ff13a7" link="/" />
                        {/*  <TopCard title="Evénements à venir" content={props.resume.event_ephemeral_next_count.toString()} icon={<CalendarEvent />} color="#ff13a7" link="/XXX" /> */}
                        <TopCard title="CA Dernier événement" content={formatPrice(props.resume.event_ephemeral_last_ca_ttc)} icon={<CashCoin />} color="#ff13a7" link={"/"+getEventInternPath(props.resume.event_ephemeral_last_subtype, props.resume.event_ephemeral_last_category)+"/resume/"+props.resume.event_ephemeral_last_id} />
                        <TopCard title="Services de restauration à venir" content={(props.resume.event_eat_next ? props.resume.event_eat_next.length.toString() : '0')} icon={<CalendarEvent />} color="#ff13a7" link="/restaurant" />
                        <TopCard title="Scanners actifs" content={props.resume.devices_active_count.toString()} icon={<QrCodeScan />} color="#ff13a7" link="/scanners" />
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12} md={6}>
                            <Card className="blocCard h-100">
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Prochain événement<span className="d-none d-md-block"><small>(Soirée, Pool party, Transat, SPA, Hébergement et Pack)</small></span></div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {props.resume.event_ephemeral_next ?
                                        <div className="d-flex justify-content-center">
                                            <EventCard
                                                id={props.resume.event_ephemeral_next.id}
                                                status={props.resume.event_ephemeral_next.status}
                                                name={props.resume.event_ephemeral_next.name}
                                                subtype={props.resume.event_ephemeral_next.subtype}
                                                category={props.resume.event_ephemeral_next.category}
                                                img_flyer={props.resume.event_ephemeral_next.img_flyer}
                                                started={props.resume.event_ephemeral_next.started}
                                            />
                                        </div>
                                        :
                                        <p className="mt-3">Pas de prochain</p>
                                    }
                                </Card.Body>
                                <Card.Footer className="bg-transparent border-0">
                                    {/* 
                                    <Link to="/XXX">
                                        <Button className="my-2 w-100 fw-bolder" variant="link">
                                            Voir toutes les soirées
                                        </Button>
                                    </Link>
                                    */}
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className="blocCard h-100">
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Prochain services <span className="d-none d-md-block"><small>(Restaurant)</small></span></div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {props.resume.event_eat_next && props.resume.event_eat_next.length ? (
                                        <>
                                            {props.resume.event_eat_next.map((service: IService, index: number) => {
                                                return <ServiceCard 
                                                    key={index} 
                                                    occurence_id={service.occurence_id} 
                                                    started={service.started} 
                                                    choice={service.choice} 
                                                    quantity={service.quantity} 
                                                    quantity_available={service.quantity_available} 
                                                />
                                            })}
                                        </>
                                    ) : (
                                        <p className="mt-3">Pas de prochain</p>
                                    )}
                                </Card.Body>
                                <Card.Footer className="bg-transparent border-0">
                                    <Link to="/restaurant">
                                        <Button className="my-2 w-100 fw-bolder" variant="link">
                                            Voir tous les services
                                        </Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default DashboardComponent