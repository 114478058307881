import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { RootState } from '../../../store';
import * as Constants from '../constants';
import { IResponseStatus } from '../reducers';
import * as services from '../services';

function* listFutureService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.listFutureService, apikey);
        yield put({ type: response.status === "fail" ? Constants.LIST_FUTURE_SERVICE_FAILED : Constants.LIST_FUTURE_SERVICE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.LIST_FUTURE_SERVICE_FAILED, e });
    }
}

function* listFinishedService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.listFinishedService, apikey);
        yield put({ type: response.status === "fail" ? Constants.LIST_FINISHED_SERVICE_FAILED : Constants.LIST_FINISHED_SERVICE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.LIST_FINISHED_SERVICE_FAILED, e });
    }
}

function* listConfiguration(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.listConfiguration, apikey);
        yield put({ type: response.status === "fail" ? Constants.LIST_CONFIGURATION_FAILED : Constants.LIST_CONFIGURATION_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: Constants.LIST_CONFIGURATION_FAILED, e });
    }
}

function* createConfiguration(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.createConfiguration, apikey, action.value);
        yield put({ type: (response.status === "fail" ? Constants.CREATE_CONFIGURATION_FAILED : Constants.CREATE_CONFIGURATION_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.CREATE_CONFIGURATION_FAILED, e });
    }
}

function* removeConfiguration(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.removeConfiguration, apikey, action.id);
        yield put({ type: (response.status === "fail" ? Constants.REMOVE_CONFIGURATION_FAILED : Constants.REMOVE_CONFIGURATION_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.REMOVE_CONFIGURATION_FAILED, e });
    }
}

function* updateConfiguration(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.updateConfiguration, apikey, action.value);
        yield put({ type: (response.status === "fail" ? Constants.UPDATE_CONFIGURATION_FAILED : Constants.UPDATE_CONFIGURATION_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.UPDATE_CONFIGURATION_FAILED, e });
    }
}

function* getService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.getService, apikey, action.occurence_id);
        yield put({ type: response.status === "fail" ? Constants.GET_SERVICE_FAILED : Constants.GET_SERVICE_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.GET_SERVICE_FAILED, e });
    }
}

function* removeService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.removeService, apikey, action.occurence_id);
        yield put({ type: (response.status === "fail" ? Constants.REMOVE_SERVICE_FAILED : Constants.REMOVE_SERVICE_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.REMOVE_SERVICE_FAILED, e });
    }
}

function* manageService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.manageService, apikey, action.occurence_id, action.quantity);
        yield put({ type: (response.status === "fail" ? Constants.MANAGE_SERVICE_FAILED : Constants.MANAGE_SERVICE_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.MANAGE_SERVICE_FAILED, e });
    }
}


function* manageTableService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.manageTableService, apikey, action.ticket_id, action.info);
        yield put({ type: (response.status === "fail" ? Constants.MANAGE_TABLE_SERVICE_FAILED : Constants.MANAGE_TABLE_SERVICE_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.MANAGE_TABLE_SERVICE_FAILED, e });
    }
}


function* listTransfer(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.listTransfer, apikey);
        yield put({ type: (response.status === "fail" ? Constants.LIST_TRANSFER_FAILED : Constants.LIST_TRANSFER_SUCCESS), payload: response })
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.LIST_TRANSFER_FAILED, e });
    }
}

function* downloadResume(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.downloadResume, apikey, action.id);
        yield put({ type: response.status === "fail" ? Constants.DOWNLOAD_EAT_RESUME_FAILED : Constants.DOWNLOAD_EAT_RESUME_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.DOWNLOAD_EAT_RESUME_FAILED, e });
    }
}

function* downloadService(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(services.downloadService, apikey, action.occurence_id);
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.DOWNLOAD_SERVICE_FAILED, e });
    }
}

export const restaurantSagas = [
    takeLatest(Constants.LIST_FUTURE_SERVICE, listFutureService),
    takeLatest(Constants.LIST_FINISHED_SERVICE, listFinishedService),
    takeLatest(Constants.LIST_CONFIGURATION, listConfiguration),
    takeLatest(Constants.CREATE_CONFIGURATION, createConfiguration),
    takeLatest(Constants.REMOVE_CONFIGURATION, removeConfiguration),
    takeLatest(Constants.UPDATE_CONFIGURATION, updateConfiguration),
    takeLatest(Constants.GET_SERVICE, getService),
    takeLatest(Constants.REMOVE_SERVICE, removeService),
    takeLatest(Constants.MANAGE_SERVICE, manageService),
    takeLatest(Constants.MANAGE_TABLE_SERVICE, manageTableService),
    takeLatest(Constants.LIST_TRANSFER, listTransfer),
    takeLatest(Constants.DOWNLOAD_EAT_RESUME, downloadResume),
    takeLatest(Constants.DOWNLOAD_SERVICE, downloadService),
]