import { Outlet } from "react-router-dom"
import SpaContainer from "../containers/SpaContainer"

const SpaPage = () => {
    return <>
        <SpaContainer />
        <Outlet />
    </>
}

export default SpaPage