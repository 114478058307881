import moment from "moment"

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

export const formatDatetime = (date: string, withYear?: boolean | false) => {
    if (!date) return ''
    const d = new Date(moment.utc(date.replace(' ', 'T'), 'YYYY-MM-DD HH:mm:ss').local().toDate())
    return `${days[d.getDay()]} ${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}${(withYear ? '/'+d.getFullYear() : '')} à ${('0' + d.getHours().toString()).slice(-2)}h${('0' + d.getMinutes().toString()).slice(-2)}`
}

export const formatDate = (date: string) => {
    if (!date) return ''
    const d = new Date(moment.utc(date.replace(' ', 'T'), 'YYYY-MM-DD HH:mm:ss').local().toDate())
    return `${days[d.getDay()]} ${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`
}

export const formatHours = (date: string) => {
    if (!date) return ''
    const d = new Date(moment.utc(date.replace(' ', 'T'), 'YYYY-MM-DD HH:mm:ss').local().toDate())
    return `${('0' + d.getHours().toString()).slice(-2)}h${('0' + d.getMinutes().toString()).slice(-2)}`
}

export const formatPrice = (price: number) => {
    if (price === undefined) return ''
    if (price) return `${price.toFixed(2).replace('.', ',')} €`
    else return '0 €'
}

export const formatDateEdit = (date: string, format: string) => { // yyyy-mm-dd hh:mm:00
    if (!date) return ''
    const d = moment.utc(date, format).local().format('YYYY-MM-DD HH:mm').replace(' ', 'T')
    return d
}

export const convertDatetimeLocalToUtc = (date: string, format: string) => {
    if (!date) return ''

    const d = moment(date, format).utc().format('YYYY-MM-DD HH:mm:ss')
    return d;
}
