import { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, InputGroup, ToggleButton, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IGood } from "../reducers";
import { Trash, } from "react-bootstrap-icons";
import { PropsFromRedux } from "../containers/HostelCreateContainer";
import Slim, { blobToBase64 } from '../../../common/lib/slim/slim.react';
import Trumbowyg from 'react-trumbowyg'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import '../../../common/lib/trumbowyg/plugins/colors/ui/trumbowyg.colors.css';
import '../../../common/lib/trumbowyg/plugins/colors/trumbowyg.colors.js';

const HostelCreateComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const goodId = parseInt(location.pathname.split("/")[3]);

    const [good, setGood] = useState<IGood | undefined>();
    const [goodName, setGoodName] = useState('');
    const [goodDescription, setGoodDescription] = useState('');
    
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalPublish, setShowModalPublish] = useState(false);
    
    useEffect(() => {
        fetchGood()
    }, [])
        
    const fetchGood = () => {
        props.get(goodId)
        .then(ev => {
            if(ev.status !== 'DRAFT') {
                navigate("/hostel/resume/"+goodId)
                return
            }

            setGood(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/hostel")
        });
    };

    useEffect(() => {
        if (good) {

            setGoodName(good.name ? good.name : '')
            setGoodDescription(good.description ? good.description : '')
            
        }
    }, [good]);

    const handleDelete = () => {
        props.remove(goodId)
        .then(() => {
            setShowModalDelete(false);
            toast.success('Chambre supprimée.');
            navigate("/hostel");
        })
        .catch(error => { toast.error(error.message.fr) })
    };

    const handleDescription = (e: any) => {
        /* @ts-ignore */
        var content = $(e.target).trumbowyg('html');
        setGoodDescription(content);
    };

    const saveInfos = () => {
        
        props.updateInfos({ id: goodId, name: goodName, description: goodDescription })
        .then((status) => {
            toast.success("Informations enregistrées.");
        })
        .catch(error => toast.error(error.message.fr))
    };
    
    /* @ts-ignore */
    const loadCover = (file, image, meta) => {

        let showToast = true
        if(good && good.img_cover) {
            if(good.img_cover.endsWith(file.name)) {
                showToast = false
            }
        }

        if(showToast) {
            toast.success("Vous pouvez cropper votre image à la bonne dimension.", {duration: 5000})
        }

        return true;
    }
    
    /* @ts-ignore */
    const saveCover = async (blob, progress, success, failure, slim) => {
        if(blob[0] !== undefined) {
            const base64 = await blobToBase64(blob[0]);

            props.updateCover({ id: goodId, base64: base64 })
            .then(status => {
                toast.success("Image de couverture enregistrée.")
                success(); //Fin du process slim
            })
            .catch(error => {
                toast.error(error.message.fr)
                success(); //Fin du process slim
            })

            return;
        }
        else {
            toast.error("Une erreur est survenue, merci de réessayer.")
            success(); //Fin du process slim
        }
    }

    const handlePublishCheck = () => {
        let toastId = toast.loading('Vérification de la complétude de l\'événement..')

        setTimeout(() => {
            
            props.updateInfos({ id: goodId, name: goodName, description: goodDescription })
            .then((status) => {

                //Si OK: Check
                props.publishCheck(goodId)
                .then((status) => {
                    toast.dismiss(toastId)
                    
                    setShowModalPublish(true);
                })
                .catch(error => {
                    toast.dismiss(toastId)

                    let msgError = error.message.fr;
                    if(error.complement !== undefined && error.complement.fr !== undefined) {
                        msgError += '. '+error.complement.fr+'.'
                    }
                    toast.error(msgError, {duration: 6000})
                });
            })            
            .catch(error => {
                toast.dismiss(toastId)
                toast.error(error.message.fr); 
            });

        }, 1000);
    }

    const handlePublish = () => {

        props.publish(goodId)
        .then((status) => {
            window.location.reload();
        })
        .catch((error) => toast.error(error.message.fr))
    }
    
    return (
        <>
            {good ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`goodTitle m-0 align-self-center h-50`}>Chambre</Card.Title>
                                    <div className="d-flex gap-2 mt-2 mt-md-0 mb-2 mb-md-0">
                                        <Button onClick={() => setShowModalDelete(true)} variant="danger">
                                            <Trash />
                                        </Button>
                                        <Button onClick={handlePublishCheck} variant='success'>Publier</Button>
                                    </div>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>                                
                    
                    <Row className='mt-2 g-2 flex-wrap'>

                        {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Informations</div>
                                        <div className="align-items-right d-none d-md-block">
                                            <Button className="topButton" variant="primary" onClick={() => saveInfos()}>
                                                Sauvegarder
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formGoodName">
                                        <Row className='w-100 row-inputs-good'>
                                            <Col xs={12} md={12} className='pe-md-2'>
                                                <Form.Label>Nom</Form.Label>
                                                <Form.Control type="text" value={goodName} onChange={(e) => setGoodName(e.target.value)} placeholder="Nom" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formGoodName">
                                        <Form.Label>Description</Form.Label>
                                        <Trumbowyg
                                            id='react-trumbowyg'
                                            buttons={
                                                [
                                                    ['formatting'],
                                                    ['foreColor', 'backColor'],
                                                    ['strong', 'em', 'underline', 'del'],
                                                    ['insertImage'],
                                                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                                                    ['unorderedList', 'orderedList'],
                                                    ['horizontalRule'],
                                                    ['link'],
                                                    ['viewHTML'],
                                                ]
                                            }
                                            data={good.description}
                                            placeholder='Décrit ton événement en quelques lignes..'
                                            onChange={(e: any) => handleDescription(e)} 
                                        />
                                    </Form.Group>
                                </Card.Body>
                                <Card.Footer className="d-flex d-md-none align-items-center ">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder"></div>
                                        <div className="align-items-right">
                                            <Button className="topButton" variant="primary" onClick={() => saveInfos()}>
                                                Sauvegarder
                                            </Button>
                                        </div>
                                    </Card.Title>
                                </Card.Footer>
                            </Card>
                        </Col>
                        
                        {/* -----------------------------  IMAGE DE COUVERTURE  ----------------------------- */ }

                        <Col className='d-flex gap-2 flex-column col-12'>
                            <Card className='blocCard'>
                                <Card.Header className="d-flex align-items-center mb-2">
                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                        <div className="w-100 fw-bolder">Image de couverture</div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text id='cover'>L'image de couverture sera affichée au format paysage en en-tête de la fiche de votre chambre.</Card.Text>
                                    <div className='w-100 overflow-hidden'>
                                    <Slim ratio="2:1"
                                        initialImage={good && good.img_cover ? good.img_cover : ''}
                                        minSize={ { width:380, height:190 } }
                                        push={true}
                                        service={ saveCover }
                                        //didInit={ slimInit }
                                        didLoad={ loadCover }
                                        serviceFormat="file"
                                        style={ { aspectRatio: '2/1' } }
                                        label="Charger une image"
                                        buttonCancelLabel= "Annuler"
                                        buttonCancelTitle= "Annuler"
                                        buttonConfirmLabel= "Valider"
                                        buttonConfirmTitle= "Valider"
                                        buttonRotateTitle= "Rotation"
                                        buttonEditTitle= "Modifier"
                                        buttonRemoveTitle= "Supprimer"
                                        statusUploadSuccess="">
                                        <input type="file" name="cover"/>
                                    </Slim>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        
                    </Row >
                    
                    <Modal
                        size="lg"
                        show={showModalPublish}
                        onHide={() => { setShowModalPublish(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Publier</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <div>Vous allez publier votre chambre sur Lib-Events.<br />Vous pourrez ensuite ajouter des disponibilités, ce qui la rendra accessible à la réservation.</div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalPublish(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handlePublish}>Publier</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>
                    
                    <Modal
                        show={showModalDelete}
                        onHide={() => { setShowModalDelete(false) }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className='w-100'>Supprimer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-4">
                            <Card.Text>Êtes-vous sûr de vouloir supprimer ce brouillon ?<br />Cette action est irréversible.</Card.Text>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center w-100">
                            <Col className="text-start">
                                <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={() => setShowModalDelete(false)}>Annuler</Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant='primary' className='btn-modal-device me-1' onClick={handleDelete}>Valider</Button>
                            </Col>
                        </Modal.Footer>
                    </Modal>
                    
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default HostelCreateComponent;