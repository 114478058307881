
import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IInfosPost, IResponseStatus } from "../reducers";

function* createInfoPost(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createInfoPost, apikey, action.event_id);
        yield put({
            type: response.status === "fail" ? Constants.CREATE_INFO_POST_EVENT_FAIL : Constants.CREATE_INFO_POST_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data as IInfosPost);
    } catch (e) {
        yield put({ type: Constants.CREATE_INFO_POST_EVENT_FAIL, e });
    }
}

function* createManualPost(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createManualPost, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.CREATE_MANUAL_POST_EVENT_FAIL : Constants.CREATE_MANUAL_POST_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.CREATE_MANUAL_POST_EVENT_FAIL, e });
    }
}

function* createAutoPost(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createAutoPost, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.CREATE_AUTO_POST_EVENT_FAIL : Constants.CREATE_AUTO_POST_EVENT_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.CREATE_AUTO_POST_EVENT_FAIL, e });
    }
}

function* createPostNews(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.createPostNews, apikey, action.infos);
        yield put({
            type: response.status === "fail" ? Constants.CREATE_POST_NEWS_FAIL : Constants.CREATE_POST_NEWS_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.CREATE_POST_NEWS_FAIL, e });
    }
}

function* removePost(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.removePost, apikey, action.post_id);
        yield put({
            type: response.status === "fail" ? Constants.REMOVE_POST_FAIL : Constants.REMOVE_POST_SUCCESS,
            payload: response,
        });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.REMOVE_POST_FAIL, e });
    }
}

export const postsSagas = [
    takeLatest(Constants.CREATE_INFO_POST_EVENT, createInfoPost),
    takeLatest(Constants.CREATE_MANUAL_POST_EVENT, createManualPost),
    takeLatest(Constants.CREATE_AUTO_POST_EVENT, createAutoPost),
    takeLatest(Constants.CREATE_POST_NEWS, createPostNews),
    takeLatest(Constants.REMOVE_POST, removePost),
];
