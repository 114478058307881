import { RootState } from "../../../store";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "../actions";
import HostelNewComponent from "../components/HostelNewComponent";

const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createEmpty: () => {
            return new Promise((resolve: (id: number) => void, reject: any) => {
                dispatch(Actions.createEmpty(resolve, reject));
            });
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HostelNewComponent);