import { Outlet } from "react-router-dom";
import DashboardContainer from "../containers/DashboardContainer";

const DashboardPage = () => {
    return <>
        <DashboardContainer />
        <Outlet />
    </>
}

export default DashboardPage