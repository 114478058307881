import { API_URL } from "../constants";
import { IFacebookConfirm, IInstagramConfirm, INetworkCredentials, INetworkType } from "../reducers";

export const getNetworksList = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const getNetworksListActive = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/list/active`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const getNetworksReferences = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL}/references`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createNetwork = (apiKey: string, data: INetworkCredentials) => {
    const ENDPOINT: RequestInfo = `${API_URL}/create`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateNetwork = (apiKey: string, data: INetworkCredentials) => {
    const ENDPOINT: RequestInfo = `${API_URL}/update`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const deleteNetwork = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const facebookLoginUrl = (apiKey: string, data: INetworkType) => {
    const ENDPOINT: RequestInfo = `${API_URL}/facebook/login/url`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const facebookPageList = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/facebook/page/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const facebookLoginConfirm = (apiKey: string, data: IFacebookConfirm) => {
    const ENDPOINT: RequestInfo = `${API_URL}/facebook/login/confirm`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const instagramLoginUrl = (apiKey: string, data: INetworkType) => {
    const ENDPOINT: RequestInfo = `${API_URL}/instagram/login/url`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const instagramPageList = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL}/instagram/page/list`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const instagramLoginConfirm = (apiKey: string, data: IInstagramConfirm) => {
    const ENDPOINT: RequestInfo = `${API_URL}/instagram/login/confirm`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(data),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};