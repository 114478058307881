import { useState, useEffect } from 'react'
import { Button, Form, Modal, Col } from 'react-bootstrap'
import { IGoodAvailability, IGoodAvailabilityRequest } from '../../reducers'
import { toast } from 'react-hot-toast';

interface EditAvailabilityComponentProps {
    show: boolean
    handleHide: () => void
    handleSave: () => void
    availability: IGoodAvailability | undefined
    availabilityUpdate: (infos: IGoodAvailabilityRequest) => Promise<string>
}

const EditAvailabilityComponent = (props: EditAvailabilityComponentProps) => {

    useEffect(() => {
        setAvailQty(props.availability && props.availability.quantity_available ? props.availability.quantity_available : 0)
        setAvailPrice(props.availability && props.availability.price_public ? props.availability.price_public : 0)
    }, [props.availability])

    const [availQty, setAvailQty] = useState<number>(props.availability && props.availability.quantity_available ? props.availability.quantity_available : 0)
    const [availPrice, setAvailPrice] = useState<number>(props.availability && props.availability.price_public ? props.availability.price_public : 0)

    const handleSaveAvail = () => {
        if(props.availability) {
            let infos: IGoodAvailabilityRequest = { 
                id: props.availability.id,
                quantity: availQty,
                price_public: availPrice,
            }
            props.availabilityUpdate(infos)
            .then(status => {
                toast.success('Disponibilité mise à jour')
                props.handleSave()
            })
            .catch(err => { toast.error(err.message.fr) })
        }
    }
    return (
        <Modal show={props.show} onHide={props.handleHide} backdrop="static" size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Disponibilité</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>Vous pouvez modifier la quantité restante à la baisse ou à la hausse.</div>
                <div>Les billets en cours d'achat (panier client de 15 minutes non expiré) ne sont pas repris dans cette liste.</div>
                <div className='w-100 mt-2' >
                    <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Nouvelle quantité</Form.Label>
                    <Form.Control type="number" value={availQty} onChange={(e) => setAvailQty(Number(e.target.value))} placeholder="Quantité" />
                </div>
                <div className='w-100 mt-2' >
                    <Form.Label style={{marginTop: 10, fontWeight: 'bold'}}>Prix public TTC en €</Form.Label>
                    <Form.Control type="number" value={availPrice} onChange={(e) => setAvailPrice(Number(e.target.value))} placeholder="Prix" />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center w-100">
                <Col className="text-start">
                    <Button className="fw-bolder" variant='outline-secondary' onClick={props.handleHide}>Retour</Button>
                </Col>
                <Col className="text-end">
                    <Button className="fw-bolder" variant='primary' onClick={handleSaveAvail}>Enregistrer</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}

export default EditAvailabilityComponent