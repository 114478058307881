import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import BusinessRecordComponent from "../components/BusinessRecordComponent";
import * as Actions from '../actions'
import { ICoverRequest, IInfosRequest, IOrganizerInfos } from '../reducers';

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getInfos: () => {
            return new Promise((resolve: (organizerInfos: IOrganizerInfos) => void, reject: any) => {
                dispatch(Actions.getInfos(resolve, reject));
            });
        },
        updateInfos: (infos: IInfosRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateInfos(infos, resolve, reject));
            });
        },
        updateCover: (infos: ICoverRequest) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateCover(infos, resolve, reject));
            });
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxBusinessRecord = ConnectedProps<typeof connector>;

export default connector(BusinessRecordComponent);