import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { formatDate } from '../../../utils/formattage'
import { IConfiguration } from '../reducers'

interface ConfigurationComponentProps {
    configuration: IConfiguration
    onEdit: (c: IConfiguration) => void
}

const ConfigurationComponent = (props: ConfigurationComponentProps) => {

    const getWeekDays = (locale: string, length: 'short' | 'long') => {
        const baseDate = new Date(Date.UTC(2022, 4, 2))
        const weekDays = []
        for (let i = 0; i < 7; i++) {
            weekDays.push(baseDate.toLocaleDateString(locale, { weekday: length }));
            baseDate.setDate(baseDate.getDate() + 1);
        }
        return weekDays;
    }

    const displayDays = () => {
        return days.map((day, i) => {

            let display = weekDaysToLocale[weekDaysinEn.indexOf(day[0].toUpperCase() + day.substring(1).toLowerCase())]
            display += (i === days.length - 1 ? '' : ', ')

            return display
        })
    }

    const weekDaysinEn = getWeekDays('en-US', 'long')
    const weekDaysToLocale = getWeekDays('fr-FR', 'short')

    const { days, choices, quantity_per_table, quantity, days_before, close_reservation_text, start_at, end_at } = props.configuration

    return (
        <Card className='flex-grow-1 serviceCard' >
            <Card.Body style={{ position: 'relative' }} className='mob-padding'>
                <div className='small'><strong>Jours : </strong>{displayDays()}</div>
                <div className='small'><strong>Heures d'arrivée : </strong>{choices.map((choice, i) => (i === choices.length - 1 ? choice : choice + ', '))}</div>
                <div className='small'><strong>Personnes par table (max.) : </strong>{quantity_per_table}</div>
                <div className='small'><strong>Couverts par service : </strong>{quantity}</div>
                <div className='small'><strong>Début de la réservation : </strong>{days_before}J avant</div>
                <div className='small'><strong>Clôture de réservation : </strong>{close_reservation_text}</div>
                { start_at && 
                    <div className='small'><strong>Valable à partir de : </strong>{formatDate(start_at+' 12:00:00')}</div>
                }
                { end_at && 
                    <div className='small'><strong>Valable jusqu'au : </strong>{formatDate(end_at+' 12:00:00')}</div>
                }
                <Button onClick={() => props.onEdit(props.configuration)} variant='outline-primary' size='sm' style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>Modifier</Button>
            </Card.Body>
        </Card>
    )
}

export default ConfigurationComponent