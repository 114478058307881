import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import PackPage from "../pages/PackPage";
import PackNewContainer from "../containers/PackNewContainer";
import PackNewModelsContainer from "../containers/PackNewModelsContainer";
import PackCreateContainer from "../containers/PackCreateContainer";
import PackEditContainer from '../containers/PackEditContainer';
import PackResumeContainer from "../containers/PackResumeContainer";

export const PackRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/pack',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <PackPage /> },
                { path: '/pack/new', element: <PackNewContainer /> },
                { path: '/pack/models', element: <PackNewModelsContainer /> },
                {
                    path: '/pack/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pack/create/:id',
                            element: <PackCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/pack/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pack/resume/:id',
                            element: <PackResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/pack/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/pack/edit/:id',
                            element: <PackEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}