
import {Card} from 'react-bootstrap'

interface PrestationTemplateCardProps {
    templateId: number
    title: string
    description: string
    cover?: string
    hasCover?: boolean
    price: number
    type: string
    selectedId: number
    onClick: (id: number) => void
}

const TemplateCard = (props: PrestationTemplateCardProps) => {

    const isSelected = (props.selectedId === props.templateId)

    return (
        <div className="col-12 col-lg-6 col-xl-6 p-2">
            <Card className={`templateCard ${(isSelected ? 'selected' : '')}`} onClick={() => props.onClick(props.templateId)} data-template={props.templateId}>
                {!!props && <Card.Img src={props.cover} />}
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>{props.description}</Card.Text> 
                    {props.price !== 0 && <Card.Text className='text-muted'>{props.price}€</Card.Text>}
                </Card.Body>
            </Card>
        </div>
    )
}

export default TemplateCard