import {CORE_URL} from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/organizers";

// ------------------------------  ORGANIZERS  ------------------------------

export const GET_INFOS = "GET_INFOS";
export const GET_INFOS_SUCCESS = "GET_INFOS_SUCCESS";
export const GET_INFOS_FAIL = "GET_INFOS_FAIL";

export const UPDATE_COVER = "UPDATE_COVER";
export const UPDATE_COVER_SUCCESS = "UPDATE_COVER_SUCCESS";
export const UPDATE_COVER_FAIL = "UPDATE_COVER_FAIL";

export const UPDATE_INFOS = "UPDATE_INFOS";
export const UPDATE_INFOS_SUCCESS = "UPDATE_INFOS_SUCCESS";
export const UPDATE_INFOS_FAIL = "UPDATE_INFOS_FAIL";