import {Outlet} from "react-router-dom"
import SupportContainer from "../containers/SupportContainer"

const SupportPage = () => {
    return <>
        <SupportContainer />
        <Outlet />
    </>
}

export default SupportPage