import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_EVENTS = CORE_URL + "/events";
export const API_URL_PRESTATIONS = CORE_URL + "/prestations";

// ------------------------------  EVENT  ------------------------------

export const PARTY_GET_EVENT = "PARTY_GET_EVENT";
export const PARTY_GET_EVENT_SUCCESS = "PARTY_GET_EVENT_SUCCESS";
export const PARTY_GET_EVENT_FAIL = "PARTY_GET_EVENT_FAIL";

export const PARTY_LIST_FINISHED_EVENT = "PARTY_LIST_FINISHED_EVENT";
export const PARTY_LIST_FINISHED_EVENT_SUCCESS = "PARTY_LIST_FINISHED_EVENT_SUCCESS";
export const PARTY_LIST_FINISHED_EVENT_FAIL = "PARTY_LIST_FINISHED_EVENT_FAIL";

export const PARTY_LIST_FUTURE_EVENT = "PARTY_LIST_FUTURE_EVENT";
export const PARTY_LIST_FUTURE_EVENT_SUCCESS = "PARTY_LIST_FUTURE_EVENT_SUCCESS";
export const PARTY_LIST_FUTURE_EVENT_FAIL = "PARTY_LIST_FUTURE_EVENT_FAIL";

export const PARTY_CREATE_EMPTY_EVENT = "PARTY_CREATE_EMPTY_EVENT";
export const PARTY_CREATE_EMPTY_EVENT_SUCCESS = "PARTY_CREATE_EMPTY_EVENT_SUCCESS";
export const PARTY_CREATE_EMPTY_EVENT_FAIL = "PARTY_CREATE_EMPTY_EVENT_FAIL";

export const PARTY_CREATE_MODEL_EVENT = "PARTY_CREATE_MODEL_EVENT";
export const PARTY_CREATE_MODEL_EVENT_SUCCESS = "PARTY_CREATE_MODEL_EVENT_SUCCESS";
export const PARTY_CREATE_MODEL_EVENT_FAIL = "PARTY_CREATE_MODEL_EVENT_FAIL";

export const PARTY_UPDATE_INFOS_EVENT = "PARTY_UPDATE_INFOS_EVENT";
export const PARTY_UPDATE_INFOS_EVENT_SUCCESS = "PARTY_UPDATE_INFOS_EVENT_SUCCESS";
export const PARTY_UPDATE_INFOS_EVENT_FAIL = "PARTY_UPDATE_INFOS_EVENT_FAIL";

export const PARTY_UPDATE_COVER_EVENT = "PARTY_UPDATE_COVER_EVENT";
export const PARTY_UPDATE_COVER_EVENT_SUCCESS = "PARTY_UPDATE_COVER_EVENT_SUCCESS";
export const PARTY_UPDATE_COVER_EVENT_FAIL = "PARTY_UPDATE_COVER_EVENT_FAIL";

export const PARTY_UPDATE_FLYER_EVENT = "PARTY_UPDATE_FLYER_EVENT";
export const PARTY_UPDATE_FLYER_EVENT_SUCCESS = "PARTY_UPDATE_FLYER_EVENT_SUCCESS";
export const PARTY_UPDATE_FLYER_EVENT_FAIL = "PARTY_UPDATE_FLYER_EVENT_FAIL";

export const PARTY_SAVE_TMP_IMAGE = "PARTY_SAVE_TMP_IMAGE";
export const PARTY_SAVE_TMP_IMAGE_SUCCESS = "PARTY_SAVE_TMP_IMAGE_SUCCESS";
export const PARTY_SAVE_TMP_IMAGE_FAIL = "PARTY_SAVE_TMP_IMAGE_FAIL";

export const PARTY_UPDATE_ENTRANCES_EVENT = "PARTY_UPDATE_ENTRANCES_EVENT";
export const PARTY_UPDATE_ENTRANCES_EVENT_SUCCESS = "PARTY_UPDATE_ENTRANCES_EVENT_SUCCESS";
export const PARTY_UPDATE_ENTRANCES_EVENT_FAIL = "PARTY_UPDATE_ENTRANCES_EVENT_FAIL";

export const PARTY_LIST_MODEL_EVENT = "PARTY_LIST_MODEL_EVENT";
export const PARTY_LIST_MODEL_EVENT_SUCCESS = "PARTY_LIST_MODEL_EVENT_SUCCESS";
export const PARTY_LIST_MODEL_EVENT_FAIL = "PARTY_LIST_MODEL_EVENT_FAIL";

export const PARTY_PUBLISH_CHECK_EVENT = "PARTY_PUBLISH_CHECK_EVENT";
export const PARTY_PUBLISH_CHECK_EVENT_SUCCESS = "PARTY_PUBLISH_CHECK_EVENT_SUCCESS";
export const PARTY_PUBLISH_CHECK_EVENT_FAIL = "PARTY_PUBLISH_CHECK_EVENT_FAIL";

export const PARTY_PUBLISH_EVENT = "PARTY_PUBLISH_EVENT";
export const PARTY_PUBLISH_EVENT_SUCCESS = "PARTY_PUBLISH_EVENT_SUCCESS";
export const PARTY_PUBLISH_EVENT_FAIL = "PARTY_PUBLISH_EVENT_FAIL";

export const PARTY_PUBLISH_CANCEL_EVENT = "PARTY_PUBLISH_CANCEL_EVENT";
export const PARTY_PUBLISH_CANCEL_EVENT_SUCCESS = "PARTY_PUBLISH_CANCEL_EVENT_SUCCESS";
export const PARTY_PUBLISH_CANCEL_EVENT_FAIL = "PARTY_PUBLISH_CANCEL_EVENT_FAIL";

export const PARTY_TICKETS_DOWNLOAD = "PARTY_TICKETS_DOWNLOAD";
export const PARTY_TICKETS_DOWNLOAD_SUCCESS = "PARTY_TICKETS_DOWNLOAD_SUCCESS";
export const PARTY_TICKETS_DOWNLOAD_FAIL = "PARTY_TICKETS_DOWNLOAD_FAIL";

export const PARTY_REMOVE_EVENT = "PARTY_REMOVE_EVENT";
export const PARTY_REMOVE_EVENT_SUCCESS = "PARTY_REMOVE_EVENT_SUCCESS";
export const PARTY_REMOVE_EVENT_FAIL = "PARTY_REMOVE_EVENT_FAIL";

export const PARTY_MANAGE_ENTRANCES_EVENT = "PARTY_MANAGE_ENTRANCES_EVENT";
export const PARTY_MANAGE_ENTRANCES_EVENT_SUCCESS = "PARTY_MANAGE_ENTRANCES_EVENT_SUCCESS";
export const PARTY_MANAGE_ENTRANCES_EVENT_FAIL = "PARTY_MANAGE_ENTRANCES_EVENT_FAIL";

export const PARTY_RESUME_DOWNLOAD = "PARTY_RESUME_DOWNLOAD";
export const PARTY_RESUME_DOWNLOAD_SUCCESS = "PARTY_RESUME_DOWNLOAD_SUCCESS";
export const PARTY_RESUME_DOWNLOAD_FAIL = "PARTY_RESUME_DOWNLOAD_FAIL";

export const PARTY_MANAGE_INFOS_EVENT = "PARTY_MANAGE_INFOS_EVENT";
export const PARTY_MANAGE_INFOS_EVENT_SUCCESS = "PARTY_MANAGE_INFOS_EVENT_SUCCESS";
export const PARTY_MANAGE_INFOS_EVENT_FAIL = "PARTY_MANAGE_INFOS_EVENT_FAIL";

// ------------------------------  PRESTATION  ------------------------------

export const PARTY_CREATE_EMPTY_PRESTATION = "PARTY_CREATE_EMPTY_PRESTATION";
export const PARTY_CREATE_EMPTY_PRESTATION_SUCCESS = "PARTY_CREATE_EMPTY_PRESTATION_SUCCESS";
export const PARTY_CREATE_EMPTY_PRESTATION_FAIL = "PARTY_CREATE_EMPTY_PRESTATION_FAIL";

export const PARTY_GET_PRESTATION = "PARTY_GET_PRESTATION";
export const PARTY_GET_PRESTATION_SUCCESS = "PARTY_GET_PRESTATION_SUCCESS";
export const PARTY_GET_PRESTATION_FAIL = "PARTY_GET_PRESTATION_FAIL";

export const PARTY_REMOVE_PRESTATION = "PARTY_REMOVE_PRESTATION";
export const PARTY_REMOVE_PRESTATION_SUCCESS = "PARTY_REMOVE_PRESTATION_SUCCESS";
export const PARTY_REMOVE_PRESTATION_FAIL = "PARTY_REMOVE_PRESTATION_FAIL";

export const PARTY_UPDATE_PRESTATION = "PARTY_UPDATE_PRESTATION";
export const PARTY_UPDATE_PRESTATION_SUCCESS = "PARTY_UPDATE_PRESTATION_SUCCESS";
export const PARTY_UPDATE_PRESTATION_FAIL = "PARTY_UPDATE_PRESTATION_FAIL";

export const PARTY_LIST_MODEL_PRESTATION = "PARTY_LIST_MODEL_PRESTATION";
export const PARTY_LIST_MODEL_PRESTATION_SUCCESS = "PARTY_LIST_MODEL_PRESTATION_SUCCESS";
export const PARTY_LIST_MODEL_PRESTATION_FAIL = "PARTY_LIST_MODEL_PRESTATION_FAIL";

export const PARTY_LIST_SCENARIO_PRESTATION = "PARTY_LIST_SCENARIO_PRESTATION";
export const PARTY_LIST_SCENARIO_PRESTATION_SUCCESS = "PARTY_LIST_SCENARIO_PRESTATION_SUCCESS";
export const PARTY_LIST_SCENARIO_PRESTATION_FAIL = "PARTY_LIST_SCENARIO_PRESTATION_FAIL";

export const PARTY_CREATE_MODEL_PRESTATION = "PARTY_CREATE_MODEL_PRESTATION";
export const PARTY_CREATE_MODEL_PRESTATION_SUCCESS = "PARTY_CREATE_MODEL_PRESTATION_SUCCESS";
export const PARTY_CREATE_MODEL_PRESTATION_FAIL = "PARTY_CREATE_MODEL_PRESTATION_FAIL";

export const PARTY_CREATE_SCENARIO_PRESTATION = "PARTY_CREATE_SCENARIO_PRESTATION";
export const PARTY_CREATE_SCENARIO_PRESTATION_SUCCESS = "PARTY_CREATE_SCENARIO_PRESTATION_SUCCESS";
export const PARTY_CREATE_SCENARIO_PRESTATION_FAIL = "PARTY_CREATE_SCENARIO_PRESTATION_FAIL";

export const PARTY_MANAGE_PRESTATION = "PARTY_MANAGE_PRESTATION";
export const PARTY_MANAGE_PRESTATION_SUCCESS = "PARTY_MANAGE_PRESTATION_SUCCESS";
export const PARTY_MANAGE_PRESTATION_FAIL = "PARTY_MANAGE_PRESTATION_FAIL";
