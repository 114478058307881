import produce from "immer";
import { AnyAction } from "redux";
import * as constants from '../constants';

export interface IConfiguration {
    id?: number,
    days: string[],
    choices: string[],
    quantity_per_table: number,
    quantity: number,
    days_before: number,
    close_reservation: string, 
    close_reservation_text?: string,
    start_at: string,
    end_at: string,
}

export interface IService {
    id: number,
    occurence_id: number,
    status?: string,
    name: string,
    choice: string,
    quantity: number,
    quantity_available: number,
    started: string,
    ca_ttc?: number,
    tickets: ITicket[] | null,
}

export interface ITransfer {
    id: number,
    event_id: number,
    label: string,
    status: string,
    amount: number,
    executed_at: string,
    error: string,
}

export interface ITicket {
    id: number,
    code: string,
    quantity: number,
    user_name: string,
    user_requests: string,
    intern_info: string,
    scanned_at: string,
}

export interface IRestaurantState {
    listFuture: IService[] | null,
    listFinished: IService[] | null,    
    listConfigurations: IConfiguration[] | null,
    listTransfers: ITransfer[] | null,
}
export interface IResponseStatus {
    data: {
        pdf?: string;
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

const initState = {
    listFuture: null,
    listFinished: null,    
    listConfigurations: null,
    listTransfers: null,   
} as IRestaurantState

const restaurantReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case constants.LIST_FUTURE_SERVICE_SUCCESS:
            draft.listFuture = action.payload.data.services
            break;
        case constants.LIST_FINISHED_SERVICE_SUCCESS:
            draft.listFinished = action.payload.data.services
            break;
        case constants.LIST_CONFIGURATION_SUCCESS:
            draft.listConfigurations = action.payload.data.configurations
            break;
        case constants.LIST_TRANSFER_SUCCESS:
            draft.listTransfers = action.payload.data.transfers
            break;
        default:
            return draft;
    }
})

export default restaurantReducer