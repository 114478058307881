import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_EVENTS = CORE_URL + "/events";
export const API_URL_PRESTATIONS = CORE_URL + "/prestations";

// ------------------------------  EVENT  ------------------------------

export const SPA_GET_EVENT = "SPA_GET_EVENT";
export const SPA_GET_EVENT_SUCCESS = "SPA_GET_EVENT_SUCCESS";
export const SPA_GET_EVENT_FAIL = "SPA_GET_EVENT_FAIL";

export const SPA_LIST_FINISHED_EVENT = "SPA_LIST_FINISHED_EVENT";
export const SPA_LIST_FINISHED_EVENT_SUCCESS = "SPA_LIST_FINISHED_EVENT_SUCCESS";
export const SPA_LIST_FINISHED_EVENT_FAIL = "SPA_LIST_FINISHED_EVENT_FAIL";

export const SPA_LIST_FUTURE_EVENT = "SPA_LIST_FUTURE_EVENT";
export const SPA_LIST_FUTURE_EVENT_SUCCESS = "SPA_LIST_FUTURE_EVENT_SUCCESS";
export const SPA_LIST_FUTURE_EVENT_FAIL = "SPA_LIST_FUTURE_EVENT_FAIL";

export const SPA_CREATE_EMPTY_EVENT = "SPA_CREATE_EMPTY_EVENT";
export const SPA_CREATE_EMPTY_EVENT_SUCCESS = "SPA_CREATE_EMPTY_EVENT_SUCCESS";
export const SPA_CREATE_EMPTY_EVENT_FAIL = "SPA_CREATE_EMPTY_EVENT_FAIL";

export const SPA_CREATE_MODEL_EVENT = "SPA_CREATE_MODEL_EVENT";
export const SPA_CREATE_MODEL_EVENT_SUCCESS = "SPA_CREATE_MODEL_EVENT_SUCCESS";
export const SPA_CREATE_MODEL_EVENT_FAIL = "SPA_CREATE_MODEL_EVENT_FAIL";

export const SPA_UPDATE_INFOS_EVENT = "SPA_UPDATE_INFOS_EVENT";
export const SPA_UPDATE_INFOS_EVENT_SUCCESS = "SPA_UPDATE_INFOS_EVENT_SUCCESS";
export const SPA_UPDATE_INFOS_EVENT_FAIL = "SPA_UPDATE_INFOS_EVENT_FAIL";

export const SPA_UPDATE_COVER_EVENT = "SPA_UPDATE_COVER_EVENT";
export const SPA_UPDATE_COVER_EVENT_SUCCESS = "SPA_UPDATE_COVER_EVENT_SUCCESS";
export const SPA_UPDATE_COVER_EVENT_FAIL = "SPA_UPDATE_COVER_EVENT_FAIL";

export const SPA_UPDATE_FLYER_EVENT = "SPA_UPDATE_FLYER_EVENT";
export const SPA_UPDATE_FLYER_EVENT_SUCCESS = "SPA_UPDATE_FLYER_EVENT_SUCCESS";
export const SPA_UPDATE_FLYER_EVENT_FAIL = "SPA_UPDATE_FLYER_EVENT_FAIL";

export const SPA_SAVE_TMP_IMAGE = "SPA_SAVE_TMP_IMAGE";
export const SPA_SAVE_TMP_IMAGE_SUCCESS = "SPA_SAVE_TMP_IMAGE_SUCCESS";
export const SPA_SAVE_TMP_IMAGE_FAIL = "SPA_SAVE_TMP_IMAGE_FAIL";

export const SPA_LIST_MODEL_EVENT = "SPA_LIST_MODEL_EVENT";
export const SPA_LIST_MODEL_EVENT_SUCCESS = "SPA_LIST_MODEL_EVENT_SUCCESS";
export const SPA_LIST_MODEL_EVENT_FAIL = "SPA_LIST_MODEL_EVENT_FAIL";

export const SPA_PUBLISH_CHECK_EVENT = "SPA_PUBLISH_CHECK_EVENT";
export const SPA_PUBLISH_CHECK_EVENT_SUCCESS = "SPA_PUBLISH_CHECK_EVENT_SUCCESS";
export const SPA_PUBLISH_CHECK_EVENT_FAIL = "SPA_PUBLISH_CHECK_EVENT_FAIL";

export const SPA_PUBLISH_EVENT = "SPA_PUBLISH_EVENT";
export const SPA_PUBLISH_EVENT_SUCCESS = "SPA_PUBLISH_EVENT_SUCCESS";
export const SPA_PUBLISH_EVENT_FAIL = "SPA_PUBLISH_EVENT_FAIL";

export const SPA_PUBLISH_CANCEL_EVENT = "SPA_PUBLISH_CANCEL_EVENT";
export const SPA_PUBLISH_CANCEL_EVENT_SUCCESS = "SPA_PUBLISH_CANCEL_EVENT_SUCCESS";
export const SPA_PUBLISH_CANCEL_EVENT_FAIL = "SPA_PUBLISH_CANCEL_EVENT_FAIL";

export const SPA_TICKETS_DOWNLOAD = "SPA_TICKETS_DOWNLOAD";
export const SPA_TICKETS_DOWNLOAD_SUCCESS = "SPA_TICKETS_DOWNLOAD_SUCCESS";
export const SPA_TICKETS_DOWNLOAD_FAIL = "SPA_TICKETS_DOWNLOAD_FAIL";

export const SPA_REMOVE_EVENT = "SPA_REMOVE_EVENT";
export const SPA_REMOVE_EVENT_SUCCESS = "SPA_REMOVE_EVENT_SUCCESS";
export const SPA_REMOVE_EVENT_FAIL = "SPA_REMOVE_EVENT_FAIL";

export const SPA_RESUME_DOWNLOAD = "SPA_RESUME_DOWNLOAD";
export const SPA_RESUME_DOWNLOAD_SUCCESS = "SPA_RESUME_DOWNLOAD_SUCCESS";
export const SPA_RESUME_DOWNLOAD_FAIL = "SPA_RESUME_DOWNLOAD_FAIL";

export const SPA_MANAGE_INFOS_EVENT = "SPA_MANAGE_INFOS_EVENT";
export const SPA_MANAGE_INFOS_EVENT_SUCCESS = "SPA_MANAGE_INFOS_EVENT_SUCCESS";
export const SPA_MANAGE_INFOS_EVENT_FAIL = "SPA_MANAGE_INFOS_EVENT_FAIL";

// ------------------------------  PRESTATION  ------------------------------

export const SPA_CREATE_EMPTY_PRESTATION = "SPA_CREATE_EMPTY_PRESTATION";
export const SPA_CREATE_EMPTY_PRESTATION_SUCCESS = "SPA_CREATE_EMPTY_PRESTATION_SUCCESS";
export const SPA_CREATE_EMPTY_PRESTATION_FAIL = "SPA_CREATE_EMPTY_PRESTATION_FAIL";

export const SPA_GET_PRESTATION = "SPA_GET_PRESTATION";
export const SPA_GET_PRESTATION_SUCCESS = "SPA_GET_PRESTATION_SUCCESS";
export const SPA_GET_PRESTATION_FAIL = "SPA_GET_PRESTATION_FAIL";

export const SPA_REMOVE_PRESTATION = "SPA_REMOVE_PRESTATION";
export const SPA_REMOVE_PRESTATION_SUCCESS = "SPA_REMOVE_PRESTATION_SUCCESS";
export const SPA_REMOVE_PRESTATION_FAIL = "SPA_REMOVE_PRESTATION_FAIL";

export const SPA_UPDATE_PRESTATION = "SPA_UPDATE_PRESTATION";
export const SPA_UPDATE_PRESTATION_SUCCESS = "SPA_UPDATE_PRESTATION_SUCCESS";
export const SPA_UPDATE_PRESTATION_FAIL = "SPA_UPDATE_PRESTATION_FAIL";

export const SPA_LIST_MODEL_PRESTATION = "SPA_LIST_MODEL_PRESTATION";
export const SPA_LIST_MODEL_PRESTATION_SUCCESS = "SPA_LIST_MODEL_PRESTATION_SUCCESS";
export const SPA_LIST_MODEL_PRESTATION_FAIL = "SPA_LIST_MODEL_PRESTATION_FAIL";

export const SPA_LIST_SCENARIO_PRESTATION = "SPA_LIST_SCENARIO_PRESTATION";
export const SPA_LIST_SCENARIO_PRESTATION_SUCCESS = "SPA_LIST_SCENARIO_PRESTATION_SUCCESS";
export const SPA_LIST_SCENARIO_PRESTATION_FAIL = "SPA_LIST_SCENARIO_PRESTATION_FAIL";

export const SPA_CREATE_MODEL_PRESTATION = "SPA_CREATE_MODEL_PRESTATION";
export const SPA_CREATE_MODEL_PRESTATION_SUCCESS = "SPA_CREATE_MODEL_PRESTATION_SUCCESS";
export const SPA_CREATE_MODEL_PRESTATION_FAIL = "SPA_CREATE_MODEL_PRESTATION_FAIL";

export const SPA_CREATE_SCENARIO_PRESTATION = "SPA_CREATE_SCENARIO_PRESTATION";
export const SPA_CREATE_SCENARIO_PRESTATION_SUCCESS = "SPA_CREATE_SCENARIO_PRESTATION_SUCCESS";
export const SPA_CREATE_SCENARIO_PRESTATION_FAIL = "SPA_CREATE_SCENARIO_PRESTATION_FAIL";

export const SPA_MANAGE_PRESTATION = "SPA_MANAGE_PRESTATION";
export const SPA_MANAGE_PRESTATION_SUCCESS = "SPA_MANAGE_PRESTATION_SUCCESS";
export const SPA_MANAGE_PRESTATION_FAIL = "SPA_MANAGE_PRESTATION_FAIL";
