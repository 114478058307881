import * as Constants from '../constants'
import {ICoverRequest, IInfosRequest, IOrganizerInfos} from '../reducers';


export function getInfos(resolve: (organizerInfos: IOrganizerInfos) => void, reject: any) {
    return {
        type: Constants.GET_INFOS, resolve, reject
    };
}

export function updateInfos (infos: IInfosRequest, resolve: any, reject: any) {
    return {
        type: Constants.UPDATE_INFOS, infos, resolve, reject
    };
}

export function updateCover (infos: ICoverRequest, resolve: any, reject: any) {
    return {
        type: Constants.UPDATE_COVER, infos, resolve, reject
    };
}