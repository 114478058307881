import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Row, Col, Form, Image, InputGroup, Modal, Overlay, Ratio, ToggleButton, Popover, Card } from 'react-bootstrap'
import { Clipboard, ClipboardCheck, Download, Plus } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import { PropsFromRedux } from '../containers/PostNewsCreateModalContainer';
import { IPostNewsRequest } from '../reducers';
import moment from 'moment';
import { convertDatetimeLocalToUtc } from '../../../utils/formattage';
import { INetwork } from '../../networks/reducers';

interface PostNewsCreateModalComponentProps {
    eventId: number
    show: boolean
    postType: string
    baseline: string
    baselineWithoutLink: string
    baselineInstagram: string
    imgCover: string
    imgFlyer: string
    networksActive: INetwork[]
    handleHide: (refresh: boolean) => void
}

const PostNewsCreateModalComponent = (props: PostNewsCreateModalComponentProps & PropsFromRedux) => {

    const navigate = useNavigate()

    const [publishAuto, setPublishAuto] = useState(true)

    const [networkChoice, setNetworkChoice] = useState('')
    const [publishChoice, setPublishChoice] = useState<'NOW' | 'PLANNED'>('NOW')
    const [publishDate, setPublishDate] = useState('')
    const [networks, setNetworks] = useState<INetwork[]>([])

    const [text, setText] = useState('')
    
    const [showPopOver, setShowPopOver] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if(props.postType === 'LIB_EVENTS_NOTIFS' || props.postType === 'WYYLDE_PAGE' || props.postType === 'FACEBOOK_PAGE_SYNC' || props.postType === 'INSTAGRAM_SYNC') {
            setPublishAuto(true)
        }
        else {
            setPublishAuto(false)
        }

        setPublishDate(moment.utc().local().add('1', 'hour').format('YYYY-MM-DD HH:mm').replace(' ', 'T'));

        var n = [] as INetwork[];
        if(props.postType !== 'LIB_EVENTS_NOTIFS' && props.networksActive && props.networksActive.length) {
            props.networksActive.map(network => {
                if(network.type == props.postType) {
                    n.push(network);
                }
            })
        }
        setNetworks(n);
        
        if(props.postType === 'LIB_EVENTS_NOTIFS') {
            setText('');
        }
        else if(props.postType === 'FACEBOOK_PAGE_SYNC') {
            setText(props.baselineWithoutLink);
        }
        else if(props.postType === 'INSTAGRAM_SYNC') {
            setText(props.baselineInstagram);
        }
        else {
            setText(props.baseline);
        }

    }, [props.postType])

    useEffect(() => {
        if (!!showPopOver) {
            setTimeout(() => {
                setShowPopOver(false)
            }, 1000)
        }
    }, [showPopOver])
    
    const handleClose = () => {
        props.handleHide(false)
    }

    const handlePublishChoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublishChoice(e.currentTarget.value as 'NOW' | 'PLANNED')
    }

    const savePostAuto = () => {
        let request: IPostNewsRequest = {
            id: props.eventId,
            type: props.postType,
            network_id: parseInt(networkChoice),
            delay: publishChoice,
            text: text
        }

        if(publishChoice === "PLANNED") {
            request.planned_at = convertDatetimeLocalToUtc(publishDate.replace("T", " ")+ ":00", 'YYYY-MM-DD HH:mm:ss');
        }
        
        props.createPostNews(request)
        .then((status: string) => {
            toast.success("Publication enregistré.")
                
            props.handleHide(true)
        })
        .catch((error: any) => toast.error(error.message.fr))
    }

    const addToClipboard = (content: string, event: any) => {
        navigator.clipboard.writeText(content)
        .then(function () {
            setShowPopOver(true);
            setTarget(event.target);
        }, 
        function (err) {
            console.error(err)
        });
    }

    const downloadCover = () => {
        if(props !== undefined && props.imgCover) {
            var filenameArr = props.imgCover.split('/');
            var filename = filenameArr[filenameArr.length - 1];

            var element = document.createElement("a");
            element.href = props.imgCover 
            element.download = filename;
            element.target = '_blank';
            element.click();
        }
    }

    const downloadFlyer = () => {
        if(props !== undefined && props.imgFlyer) {
            var filenameArr = props.imgFlyer.split('/');
            var filename = filenameArr[filenameArr.length - 1];

            var element = document.createElement("a");
            element.href = props.imgFlyer 
            element.download = filename;
            element.target = '_blank';
            element.click();
        }
    }

    return (
        <>
            <Modal show={props.show} size='lg' backdrop="static" className="">
                <Modal.Header>
                    <Modal.Title>Publier {(publishAuto ? 'en automatique' : 'manuellement')}</Modal.Title>
                </Modal.Header>

                {publishAuto ? <>
                    <Modal.Body>
                        <Form.Group controlId="exampleForm.ControlTextarea1" style={{marginBottom: 30}}>
                            <Form.Label>Texte à publier</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                placeholder="Texte à publier"
                            />
                            {props.postType === 'FACEBOOK_PAGE_SYNC' && 
                                <Form.Text>Le lien sera automatiquement rajouté lors de la publication, il n'est pas nécessaire de le saisir dans le texte.</Form.Text>
                            }
                        </Form.Group>
                        {props.postType !== 'LIB_EVENTS_NOTIFS' && <>
                            <InputGroup className="my-3">
                                <InputGroup.Text id="prestaChoice">Réseau :</InputGroup.Text>
                                <Form.Select value={networkChoice} onChange={(e) => setNetworkChoice(e.target.value)}>
                                    <option value=''>Choisissez un réseau...</option>
                                    {networks && networks.length > 0 && <>
                                        {networks.map(network => {
                                            if (network.status === "CONNECTED" && !network.error) return <option key={network.id} value={network.id}>{network.type}: {network.login}</option>
                                            else return <></>
                                        })}
                                    </>
                                    }
                                </Form.Select>
                                <Button style={{ cursor: 'pointer' }} variant='secondary' onClick={() => navigate('/networks')} ><Plus className='d-block d-md-none' /> <span className='d-none d-md-block'>Ajouter un réseau</span></Button>
                            </InputGroup>
                        </>
                        }
                        <InputGroup className="my-3">
                            <InputGroup.Text id="prestaChoice">Publier</InputGroup.Text>
                            <ToggleButton type='radio' variant="outline-secondary"
                                value='NOW' id='NOW' name='radio'
                                onChange={(e) => handlePublishChoiceChange(e)}
                                checked={publishChoice === 'NOW'}
                            >
                                Immédiatement
                            </ToggleButton>
                            <ToggleButton type='radio' variant="outline-secondary"
                                value='PLANNED' id='PLANNED' name='radio'
                                onChange={(e) => handlePublishChoiceChange(e)}
                                checked={publishChoice === 'PLANNED'}
                            >
                                Plus tard
                            </ToggleButton>
                            {publishChoice === 'PLANNED' && <Form.Control className='ms-3' type='datetime-local' value={publishDate} onChange={(e) => setPublishDate(e.target.value)} />}
                        </InputGroup>
                        <Form.Text>Immédiatement: si votre événement n'est pas encore publié, la publication sur les réseaux aura lieu une fois l'événement publié.</Form.Text>
                        
                    </Modal.Body>

                    <Modal.Footer className='justify-content-between'>
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={handleClose}>Retour</Button>
                        <Button variant='primary' className='btn-modal-device ms-1' onClick={savePostAuto}>Valider</Button>
                    </Modal.Footer>
                    </>
                :
                <>
                    <Modal.Body>
                        <div ref={ref} className="my-3 mt-1">
                            <div>Connectez vous sur votre compte et publiez votre publication à l'aider des informations de votre événement (à copier-coller).</div>
                            <InputGroup className="my-3">
                                <Form.Control as="textarea" rows={4} value={text} disabled />
                                <div className='d-flex flex-column gap-2 ms-2'>
                                    <Button variant='secondary' onClick={(e) => addToClipboard(text, e)}><Clipboard /> Copier le texte</Button>
                                </div>
                            </InputGroup>
                            <Row>
                                {props && props.imgCover &&
                                    <Col style={{ position: 'relative' }}>
                                        <Card.Text className="mb-1">Couverture</Card.Text>
                                        <Ratio aspectRatio={13 / 28}>
                                            <Image src={(props !== undefined ? props.imgCover : '')} style={{ minHeight: '100%', minWidth: '100%', objectFit: 'cover' }} />
                                        </Ratio>
                                        <Button as='a' variant='light' className='dlImageButton' onClick={downloadCover}><Download /></Button>
                                    </Col>
                                }
                                {props && props.imgFlyer &&
                                    <Col style={{ position: 'relative' }}>
                                        <Card.Text className="mb-1">Flyer</Card.Text>
                                        <Ratio aspectRatio={13 / 28}>
                                            <Image src={(props !== undefined ? props.imgFlyer : '')} style={{ minHeight: '100%', minWidth: '100%', objectFit: 'cover' }} />
                                        </Ratio>
                                        <Button as='a' variant='light' className='dlImageButton' onClick={downloadFlyer}><Download /></Button>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className='justify-content-between'>
                        <Button variant='outline-secondary' className='btn-modal-device ms-1' onClick={handleClose}>Retour</Button>
                    </Modal.Footer>
                </>
                }
            </Modal>
            <Overlay
                show={showPopOver}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Body>
                        <ClipboardCheck /> Copié !
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    )
}

export default PostNewsCreateModalComponent