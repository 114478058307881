import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { PropsFromReduxSupport } from '../containers/SupportContainer';
import toast from "react-hot-toast";

const SupportComponent = (props: PropsFromReduxSupport) => {

    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    const handleValidate = () => {
        props.support({
            subject: subject,
            message: message
        }).then(() => {
            toast.success("Message envoyé. Nous traiterons votre demande au plus vite !")
            setSubject('')
            setMessage('')
        }).catch((error: any) => {
            toast.error(error.message.fr)
        })
    }

    return (
        <>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Demande</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Vous rencontrez des difficultés ? Vous avez des demandes a nous soummettre ? C'est par ici !</Card.Text>
                            <Card.Text>Dès réception, notre équipe prendra en charge votre demande pour vous répondre dans les meilleurs délais</Card.Text>
                            
                            <Form.Group className="mb-3" controlId="formSubject">
                                <Form.Label>Sujet</Form.Label>
                                <Form.Control type="text" placeholder="Sujet" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={6} placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}/>
                            </Form.Group>
                            <div className='d-flex justify-content-end mt-4'>
                                <Button variant='success' onClick={handleValidate}>Envoyer</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SupportComponent