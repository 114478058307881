import {Outlet, RouteObject} from "react-router-dom";
import * as ROUTES from './networks'
import withPermissions from "../../../routes/withPermissions";
import NetworksPage from "../pages/NetworksPage";

export const NetworksRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: ROUTES.NETWORKS,
            element: withPermissions(isLogged, <Outlet />),
            children: [
                {index: true, element: <NetworksPage />}
            ]
        }
    ]
}