import { Outlet } from "react-router-dom"
import TransatContainer from "../containers/TransatContainer"

const TransatPage = () => {
    return <>
        <TransatContainer />
        <Outlet />
    </>
}

export default TransatPage