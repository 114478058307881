import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import * as Actions from '../actions'
import { IBankInfo, postBankInfo } from '../reducers';
import BankInfoComponent from '../components/BankInfoComponent';

const mapStateToProps = (state: RootState) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBankInfo: () => {            
            return new Promise((resolve: (bankInfo: IBankInfo) => void, reject: any) => {
                dispatch(Actions.getBankInfo(resolve, reject));
            });
        },
        updateBankInfo: (value: postBankInfo) => {
            return new Promise((resolve: (status: string) => void, reject: any) => {
                dispatch(Actions.updateBankInfo(value, resolve, reject));
            })
        },

    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxBankInfo = ConnectedProps<typeof connector>

export default connector(BankInfoComponent)