import {Card, Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import { IGoodLight } from "../../reducers";
import GoodStatusBadge from "./GoodStatusBadge";


const GoodCard = (props: IGoodLight) => {
    
    const navigate = useNavigate();

    let link = 'create';
    if(props.status !== 'DRAFT') {
        link = 'resume'
    }
    
    return (
        <Card 
            onClick={() => navigate("/hostel/"+link+"/"+props.id)} 
            style={{textDecoration: "none", color: "inherit", cursor: "pointer", margin: 10}}
            className="position-relative eventCard" 
        >
            <div className="cardDisplay">
                <Card.Img className="rounded-top eventCardImg" src={props.img_cover} alt="événement" />
                <GoodStatusBadge status={props.status} />
            </div>
            <Card.Body>
                <Col className="justify-content-start d-flex h-50">
                    <div className="w-100">
                        <Card.Title className="fw-bolder"  style={(props.name ? {} : {fontStyle: "italic"})}>{(props.name ? props.name : 'Nom')}</Card.Title>
                    </div>
                </Col>
            </Card.Body>
        </Card>
    )
};

export default GoodCard;