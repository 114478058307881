import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_EVENTS = CORE_URL + "/events";

// ------------------------------  POST  ------------------------------

export const CREATE_INFO_POST_EVENT = "CREATE_INFO_POST_EVENT";
export const CREATE_INFO_POST_EVENT_SUCCESS = "CREATE_INFO_POST_EVENT_SUCCESS";
export const CREATE_INFO_POST_EVENT_FAIL = "CREATE_INFO_POST_EVENT_FAIL";

export const CREATE_MANUAL_POST_EVENT = "CREATE_MANUAL_POST_EVENT";
export const CREATE_MANUAL_POST_EVENT_SUCCESS = "CREATE_MANUAL_POST_EVENT_SUCCESS";
export const CREATE_MANUAL_POST_EVENT_FAIL = "CREATE_MANUAL_POST_EVENT_FAIL";

export const CREATE_AUTO_POST_EVENT = "CREATE_AUTO_POST_EVENT";
export const CREATE_AUTO_POST_EVENT_SUCCESS = "CREATE_AUTO_POST_EVENT_SUCCESS";
export const CREATE_AUTO_POST_EVENT_FAIL = "CREATE_AUTO_POST_EVENT_FAIL";

export const CREATE_POST_NEWS = "CREATE_POST_NEWS";
export const CREATE_POST_NEWS_SUCCESS = "CREATE_POST_NEWS_SUCCESS";
export const CREATE_POST_NEWS_FAIL = "CREATE_POST_NEWS_FAIL";

export const REMOVE_POST = "REMOVE_POST";
export const REMOVE_POST_SUCCESS = "REMOVE_POST_SUCCESS";
export const REMOVE_POST_FAIL = "REMOVE_POST_FAIL";
