import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { takeLatest, put, call, select } from "redux-saga/effects";
import * as Constants from "../constants";
import * as Services from "../services";
import { IResponseStatus } from "../reducers";

function* getBankInfo(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.getBankInfoServices, apikey);
        yield put({type: response.status === "fail" ? Constants.GET_BANK_INFO_FAILED : Constants.GET_BANK_INFO_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.data);
    } catch (e) {
        yield put({ type: Constants.GET_BANK_INFO_FAILED, e });
    }
}

function* updateBankInfo(action: AnyAction) {
    const getApiKey = (state: RootState) => state.authReducer.user.api_key;

    try {
        const apikey: string = yield select(getApiKey);
        const response: IResponseStatus = yield call(Services.updateBankInfoServices, apikey, action.value);
        yield put({type: response.status === "fail" ? Constants.GET_BANK_INFO_FAILED : Constants.GET_BANK_INFO_SUCCESS, payload: response });
        response.status === "fail" ? action.reject(response.data) : action.resolve(response.status);
    } catch (e) {
        yield put({ type: Constants.UPDATE_BANK_INFO_FAILED, e });
    }
}

export const bankInfoSagas = [
    takeLatest(Constants.GET_BANK_INFO, getBankInfo),
    takeLatest(Constants.UPDATE_BANK_INFO, updateBankInfo),
];