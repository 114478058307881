import { useState, useEffect, useRef, Key } from "react";
import { Button, Card, Col, Form, Image, Row, Tabs, Tab, Overlay, Popover, } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IGood, IGoodAvailability, IGoodTicket } from "../reducers";
import { ClipboardCheck } from "react-bootstrap-icons";
import { PropsFromRedux } from "../containers/HostelResumeContainer";
import parse from "html-react-parser";
import history from "../../../routes/history";
import queryString from 'query-string';
import GoodStatusBadge from "./common/GoodStatusBadge";
import EditAvailabilityComponent from "./common/EditAvailabilityComponent";
import AvailabilityCard from "./common/AvailabilityCard";
import AddAvailabilityComponent from "./common/AddAvailabilityComponent";
import GoodTicketCard from "./common/GoodTicketCard";

const HostelResumeComponent = (props: PropsFromRedux) => {

    const location = useLocation();
    const navigate = useNavigate();

    const goodId = parseInt(location.pathname.split("/")[3]);

    const queryParams = queryString.parse(location.search);
    const [tabsKey, setTabsKey] = useState((queryParams && queryParams.tab ? queryParams.tab.toString() : 'resume'));

    const [good, setGood] = useState<IGood | undefined>()
    
    const [showAvailabilityAdd, setShowAvailabilityAdd] = useState(false);
    
    const [availabilityEdit, setAvailabilityEdit] = useState<IGoodAvailability>();
    const [showAvailabilityEdit, setShowAvailabilityEdit] = useState(false);

    useEffect(() => {
        fetchGood()
    }, [])
    
    const fetchGood = () => {
        props.get(goodId)
        .then(ev => {
            if(ev.status === 'DRAFT') {
                navigate("/hostel/create/"+goodId)
                return
            }

            setGood(ev)
        })
        .catch(error => {
            toast.error(error.message.fr)
            navigate("/hostel")
        });
    };

    const handleTabsKey = (key: string) => {
        setTabsKey(key)
        history.push('/hostel/resume/'+goodId+'?tab='+key)
    }
                                
    const handleShowAvailabilityAdd = () => {        
        setShowAvailabilityAdd(true)
    }

    const handleHideAvailabilityAdd = () => setShowAvailabilityAdd(false)
    
    const handleSaveAvailabilityAdd = () => {
        setShowAvailabilityAdd(false);
        fetchGood();
    };

    const handleShowAvailabilityEdit = (avail: IGoodAvailability) => {
        
        setAvailabilityEdit(avail)
        setShowAvailabilityEdit(true)
    }

    const handleHideAvailabilityEdit = () => setShowAvailabilityEdit(false)

    const handleSaveAvailabilityEdit = () => {
        setShowAvailabilityEdit(false);
        fetchGood();
    };
    
    return (
        <>
            {good ? (
                <>
                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className="col-sm-12">
                            <Card>
                                <Card.Header className="d-md-flex py-3 py-md-2 justify-content-between">
                                    <Card.Title className={`eventTitle m-0 align-self-center h-50`}>
                                        {good.name} 
                                        <GoodStatusBadge status={good.status} position="INLINE" />
                                    </Card.Title>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row> 

                    <Row className='mt-2 g-2 flex-wrap'>
                        <Col className='d-flex gap-2 flex-column col-12'>
                    
                            <Tabs activeKey={tabsKey} onSelect={(k) => handleTabsKey(k ? k : 'resume')} id="event-tab" className="nav-fill nav-custom">

                                {/* -----------------------------  Mes réservations  ----------------------------- */}
                                <Tab eventKey="resume" title="Mes réservations">
                                    <Row className='g-2 flex-wrap'>
                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Réservations</div>
                                                        <div className="align-items-right">
                                                            {/*
                                                                <Button className="topButton" onClick={() => handleShowAvailabilityAdd()} variant="primary">
                                                                    Virements 
                                                                </Button>
                                                            */}
                                                        </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        {good.tickets && good.tickets.length ? (<>                                                                  
                                                            {good.tickets.map((ticket: IGoodTicket, index: Key) => {
                                                                return (
                                                                    <GoodTicketCard
                                                                        key={index}
                                                                        ticket={ticket}
                                                                    />
                                                                );
                                                            })}
                                                            </>)
                                                            :
                                                            (<>
                                                                <p className="mt-2">Aucune réservation en cours</p>
                                                            </>)
                                                        }
                                                    </Row>
                                                </Card.Body>
                                            </Card >
                                            <AddAvailabilityComponent
                                                show={showAvailabilityAdd} 
                                                handleHide={handleHideAvailabilityAdd}
                                                handleSave={handleSaveAvailabilityAdd}
                                                
                                                goodId={good.id}

                                                availabilitiesCreate={props.availabilitiesCreate}
                                            />
                                            <EditAvailabilityComponent
                                                show={showAvailabilityEdit} 
                                                handleHide={handleHideAvailabilityEdit}
                                                handleSave={handleSaveAvailabilityEdit}
                                                
                                                availability={availabilityEdit}

                                                availabilityUpdate={props.availabilityUpdate}
                                            />
                                        </Col >
                                    </Row>
                                </Tab>
                                
                                {/* -----------------------------  Disponibilités  ----------------------------- */}
                                <Tab eventKey="availabilities" title="Disponibilités">
                                    <Row className='g-2 flex-wrap'>
                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Disponibilités</div>
                                                        <div className="align-items-right">
                                                            {good.status === 'ACTIVATED' &&
                                                                <Button className="topButton" onClick={() => handleShowAvailabilityAdd()} variant="primary">
                                                                    Ajouter 
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {good.availabilities && good.availabilities.length ? (<>
                                                            <div className="d-flex justify-content-start flex-wrap mb-4">                                                                    
                                                                {good.availabilities.map((avail: IGoodAvailability, index: Key) => {
                                                                    return (
                                                                        <AvailabilityCard
                                                                            key={index}
                                                                            availability={avail}

                                                                            showAvailabilityEdit={handleShowAvailabilityEdit}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        </>)
                                                        :
                                                        (<>
                                                            <p className="mt-2">Aucune disponibilité ajouté</p>
                                                        </>)
                                                    }
                                                </Card.Body>
                                            </Card >
                                            <AddAvailabilityComponent
                                                show={showAvailabilityAdd} 
                                                handleHide={handleHideAvailabilityAdd}
                                                handleSave={handleSaveAvailabilityAdd}
                                                
                                                goodId={good.id}

                                                availabilitiesCreate={props.availabilitiesCreate}
                                            />
                                            <EditAvailabilityComponent
                                                show={showAvailabilityEdit} 
                                                handleHide={handleHideAvailabilityEdit}
                                                handleSave={handleSaveAvailabilityEdit}
                                                
                                                availability={availabilityEdit}

                                                availabilityUpdate={props.availabilityUpdate}
                                            />
                                        </Col >
                                    </Row>
                                </Tab>

                                {/* -----------------------------  Contenu  ----------------------------- */}
                                <Tab eventKey="informations" title="Contenu">
                                    <Row className='g-2 flex-wrap'>
                                    
                                        {/* -----------------------------  INFORMATIONS  ----------------------------- */}

                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Informations</div>
                                                        <div className="align-items-right">
                                                            {good.status === 'ACTIVATED' &&
                                                                <Button className="topButton" onClick={() => navigate("/hostel/edit/"+goodId)} variant="primary">
                                                                    Modifier 
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3" controlId="formGoodName">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Nom de l'événement</Form.Label>
                                                        <Card.Text>{good.name}</Card.Text>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formGoodPlace">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Adresse</Form.Label>
                                                        <Card.Text>{good.place}</Card.Text>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formGoodName">
                                                        <Form.Label style={{fontWeight: 'bold'}}>Description</Form.Label>
                                                        <Card.Text className="textGoodDescription">{parse(good.description)}</Card.Text>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        
                                        {/* -----------------------------  IMAGE DE COUVERTURE  ----------------------------- */ }

                                        <Col className='d-flex gap-2 flex-column col-12'>
                                            <Card className='blocCard'>
                                                <Card.Header className="d-flex align-items-center mb-2">
                                                    <Card.Title className="d-flex w-100 align-items-center pt-2">
                                                        <div className="w-100 fw-bolder">Image de couverture</div>
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {good.img_cover ? (<>
                                                            <div className="align-items-center d-flex justify-content-center overflow-hidden">
                                                                <Image
                                                                    src={good.img_cover}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                        </>)
                                                        :
                                                        (<>
                                                            <p className="mt-2">Aucune image ajouté</p>
                                                        </>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        
                                    </Row>
                                </Tab>

                            </Tabs>
                        </Col>
                    </Row>
                </>
            ) : (
                <></>
            )}

        </>
    );
};

export default HostelResumeComponent;