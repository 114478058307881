import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import LoginComponent from '../components/LoginComponent';
import { logIn } from '../actions';
import { IUserCredential } from '../reducers';


const mapStateToProps = (state: RootState) => {
    return {
        auth: state.authReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        logIn: (credentials: IUserCredential) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(logIn(credentials, resolve, reject))
            })
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxLogin = ConnectedProps<typeof connector>

export default connector(LoginComponent)