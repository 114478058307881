
import { connect, ConnectedProps } from 'react-redux';
import { logOut } from '../../modules/auth/actions';
import { RootState } from '../../store';
import TopMenuComponent from '../components/TopMenuComponent';

const mapStateToProps = (state: RootState) => {
    return {
        isLogged: state.authReducer.isLogged
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        logOut: () => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(logOut(resolve, reject))
            })
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export type PropsFromReduxTopMenu = ConnectedProps<typeof connector>


export default connector(TopMenuComponent)