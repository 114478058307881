import {Outlet} from "react-router-dom"
import BusinessRecordContainer from "../containers/BusinessRecordContainer"

const BusinessRecordPage = () => {
    return <>
        <BusinessRecordContainer />
        <Outlet />
    </>
}

export default BusinessRecordPage