
import { Key, useEffect } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ServiceCard from './common/ServiceCard'
import { PropsFromReduxRestaurant } from '../containers/RestaurantContainer'
import { IService } from '../reducers'

const RestaurantComponent = (props: PropsFromReduxRestaurant) => {

    useEffect(() => {
        props.listFutureService();
        props.listFinishedService();
    }, []); 

    return (
        <Row className='mt-2 g-2'>
            <Col className=''>
                <Card className='blocCard'>
                    <Card.Header className="d-flex align-items-center mb-2">
                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                            <div className="w-100 fw-bolder">
                                <span className="d-none d-md-inline-block">Services à venir</span>
                                <span className="d-inline-block d-md-none">A venir</span>
                            </div>
                            <div className="align-items-right">
                                <Link to='/restaurant/configure'>
                                    <Button className="topButton" variant="primary">
                                    Configuration
                                    </Button>
                                </Link>
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {props.listFuture && props.listFuture.length > 0 ?
                            <>
                                {props.listFuture.map((service: IService, index: Key) => {
                                    return <ServiceCard 
                                        key={index} 
                                        occurence_id={service.occurence_id} 
                                        started={service.started} 
                                        choice={service.choice} 
                                        quantity={service.quantity} 
                                        quantity_available={service.quantity_available} 
                                    />
                                })}
                            </>
                            :
                            <p className="mt-2">Pas de service en cours</p>
                        }
                    </Card.Body>
                </Card>
            </Col>
            <Col className=''>
                <Card className='blocCard'>
                    <Card.Header className="d-flex align-items-center mb-2">
                        <Card.Title className="d-flex w-100 align-items-center pt-2">
                            <div className="w-100 fw-bolder">
                                <span className="d-none d-md-inline-block">Services terminés</span>
                                <span className="d-inline-block d-md-none">Terminés</span>
                            </div>
                            <div className="align-items-right">
                                <Link to='/restaurant/transfers'>
                                    <Button className="topButton" variant="primary">
                                        Virements
                                    </Button>
                                </Link>
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {props.listFinished && props.listFinished.length > 0 ?
                            <>
                                {props.listFinished.map((service: IService, index: Key) => {
                                    return <ServiceCard 
                                        key={index} 
                                        occurence_id={service.occurence_id} 
                                        started={service.started} 
                                        choice={service.choice} 
                                        quantity={service.quantity} 
                                        quantity_available={service.quantity_available} 
                                    />
                                })}
                            </>
                            :
                            <p className="mt-2">Pas de service terminé</p>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default RestaurantComponent