import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { PropsFromReduxChangePassword } from '../containers/ChangePasswordContainer';
import toast from 'react-hot-toast';

const ChangePasswordComponent = (props: PropsFromReduxChangePassword) => {

    const [validated, setValidated] = useState(false);

    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [checkPassword, setCheckPassword] = useState<string>('')

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCheckPassword, setShowCheckPassword] = useState(false);

    useEffect(() => {
        if (currentPassword !== '' && newPassword !== '' && checkPassword !== '') setValidated(true)
        else setValidated(false)

    }, [currentPassword, newPassword, checkPassword])

    const handleValidate = () => {
        props.changePassword({
            old_password: currentPassword,
            new_password: newPassword,
            check_password: checkPassword
        })
        .then(() => {
            toast.success('Mot de passe mis à jour')
            setCurrentPassword('')
            setNewPassword('')
            setCheckPassword('')
        })
        .catch(error => toast.error(error.message.fr))
    };

    return (
        <>
            <Row className='mt-2 g-2 flex-wrap'>
                <Col className='d-flex gap-2 flex-column col-12'>
                    <Card className='blocCard'>
                        <Card.Header className="d-flex align-items-center mb-2">
                            <Card.Title className="d-flex w-100 align-items-center pt-2">
                                <div className="w-100 fw-bolder">Mot de passe</div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Par sécurité, saisissez votre ancien mot de passe afin d'en définir un nouveau.</Card.Text>
                            <Card.Text>Si vous ne vous souvenez plus de votre mot de passe et souhaitez le changer, déconnectez-vous et utilisez la procédure de mot de passe oublié.</Card.Text>
                            
                            <Form.Group className="mb-3" controlId="formCurrentPassword">
                                <Form.Label>Mot de passe actuel</Form.Label>
                                <Form.Control type={(showCurrentPassword ? "text" : "password")} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="*********" />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    {showCurrentPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formNewPassword">
                                <Form.Label>Nouveau mot de passe</Form.Label>
                                <Form.Control type={(showNewPassword ? "text" : "password")} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="*********" />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowNewPassword(!showNewPassword)}>
                                    {showNewPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formCheckPassword">
                                <Form.Label>Confirmation du mot de passe</Form.Label>
                                <Form.Control type={(showCheckPassword ? "text" : "password")} value={checkPassword} onChange={(e) => setCheckPassword(e.target.value)} placeholder="*********" />
                                <Button variant='link' data-delay-hide='1000' className='ps-0' style={{ fontSize: '14px' }}
                                    onClick={() => setShowCheckPassword(!showCheckPassword)}>
                                    {showCheckPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Form.Group>
                            <div className='d-flex justify-content-end mt-4'>
                                <Button disabled={!validated} variant='success' onClick={handleValidate}>Envoyer</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ChangePasswordComponent