import { AnyAction } from "redux";
import { produce } from "immer";
import * as Constants from "../constants";
import { IEventLight } from "../../../common/reducers";

export interface ITransatState {
    eventsModel: IEventLight[] | null;
    eventsFuture: IEventLight[] | null;
    eventsFinished: ITransatModel[] | null;
}

export interface ITransatModel {
    id: number;
    status: "DRAFT" | "SCHEDULED" | "PUBLISHED" | "FINISHED";
    name: string;
    img_flyer: string;
    started: string;
}

export interface ITransat {
    id: number;
    user_id: number;
    status: "DRAFT" | "SCHEDULED" | "PUBLISHED" | "FINISHED" | "CANCELED";
    subtype: string;
    category: string;
    name: string;
    img_cover: string;
    img_flyer: string;
    description: string;
    started: string;
    ended: string;
    place: string;
    prestations: IPrestation[];
    posts: {
        EVENT: [],
        NEWS: []
    };
    resume: {
        entrances_sold: number,
        prestations_sold: number,
        ca_ttc: number
    };
    transfer: {
        id: number,
        status: string,
        amount: number,
        error: string,
        executed_at: string
    };
    scheduled_at: string;
    baseline_text: string;
    baseline_html: string;
    baseline_without_link_text: string;
    baseline_without_link_html: string;
    baseline_link_text: string;
    baseline_link_html: string;
    baseline_instagram: string;
}

export interface IPrestationModel {
    id: number,
    name: string,
    description?: string,
    price_public?: number,
    img_cover: string,
    have_cover: boolean,
    type: "QUANTITY" | "CHOICES"
}

export interface IPrestation {
    id: number;
    event_id: number;
    name: string;
    description: string;
    price_public: number;
    img_cover: string;
    type: "QUANTITY" | "CHOICES";
    quantity_available: number;
    choices_available: string[];
    have_cover: boolean;
}

export interface ITransatInfos {
    id: number;
    name: string;
    started: string;
    ended: string;
    description: string;
}

export interface ITransatImage {
    id: number;
    base64: string;
}

export interface IManageInfosRequest {
    id: number,
    description: string,
    base64?: string,
    notification_at?: string,
    diffuse?: boolean
}

export interface IManagePrestationRequest {
    id: number;
    quantity?: number;
    choices?: string[]
}

export interface IPrestationRequest {
    id: number,
    name: string,
    description: string,
    price_public: number,
    type: "QUANTITY" | "CHOICES",
    quantity?: number,
    choices?: string[],
    base64?: string
}

export interface IPrestationModelRequest {
    event_id: number,
    prestation_id: number
}

export interface IResponseStatus {
    data: {
        id?: number;
        pdf?: string;
        url?: string;
        prestations?: IPrestationModel[];
        scenarios?: IPrestationModel[];
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

const initState = {
    eventsModel: null,
    eventsFuture: null,
    eventsFinished: null
} as ITransatState

const transatReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case Constants.TRANSAT_LIST_MODEL_EVENT_SUCCESS:
            draft.eventsModel = action.payload.data.events;
            break;
        case Constants.TRANSAT_LIST_FUTURE_EVENT_SUCCESS:
            draft.eventsFuture = action.payload.data.events;
            break;
        case Constants.TRANSAT_LIST_FINISHED_EVENT_SUCCESS:
            draft.eventsFinished = action.payload.data.events;
            break;
        default:
            return draft;
    }
})

export default transatReducer