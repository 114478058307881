import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const FooterComponent = () => {

    let year = new Date().getFullYear();

    return (
        <div className="footer d-flex text-center" >
            <Container >
                <Row >
                    <Col className="my-1" >
                        Tous droits réservés © {year} Lib-Events
                    </Col>
                </Row>
            </Container>
        </div >

    )
}

export default FooterComponent