import { CORE_URL } from "../../../common/helpers/Env";

export const API_URL_EVENTS = CORE_URL + "/events";
export const API_URL_PRESTATIONS = CORE_URL + "/prestations";

// ------------------------------  EVENT  ------------------------------

export const PACK_GET_EVENT = "PACK_GET_EVENT";
export const PACK_GET_EVENT_SUCCESS = "PACK_GET_EVENT_SUCCESS";
export const PACK_GET_EVENT_FAIL = "PACK_GET_EVENT_FAIL";

export const PACK_LIST_FINISHED_EVENT = "PACK_LIST_FINISHED_EVENT";
export const PACK_LIST_FINISHED_EVENT_SUCCESS = "PACK_LIST_FINISHED_EVENT_SUCCESS";
export const PACK_LIST_FINISHED_EVENT_FAIL = "PACK_LIST_FINISHED_EVENT_FAIL";

export const PACK_LIST_FUTURE_EVENT = "PACK_LIST_FUTURE_EVENT";
export const PACK_LIST_FUTURE_EVENT_SUCCESS = "PACK_LIST_FUTURE_EVENT_SUCCESS";
export const PACK_LIST_FUTURE_EVENT_FAIL = "PACK_LIST_FUTURE_EVENT_FAIL";

export const PACK_CREATE_EMPTY_EVENT = "PACK_CREATE_EMPTY_EVENT";
export const PACK_CREATE_EMPTY_EVENT_SUCCESS = "PACK_CREATE_EMPTY_EVENT_SUCCESS";
export const PACK_CREATE_EMPTY_EVENT_FAIL = "PACK_CREATE_EMPTY_EVENT_FAIL";

export const PACK_CREATE_MODEL_EVENT = "PACK_CREATE_MODEL_EVENT";
export const PACK_CREATE_MODEL_EVENT_SUCCESS = "PACK_CREATE_MODEL_EVENT_SUCCESS";
export const PACK_CREATE_MODEL_EVENT_FAIL = "PACK_CREATE_MODEL_EVENT_FAIL";

export const PACK_UPDATE_INFOS_EVENT = "PACK_UPDATE_INFOS_EVENT";
export const PACK_UPDATE_INFOS_EVENT_SUCCESS = "PACK_UPDATE_INFOS_EVENT_SUCCESS";
export const PACK_UPDATE_INFOS_EVENT_FAIL = "PACK_UPDATE_INFOS_EVENT_FAIL";

export const PACK_UPDATE_COVER_EVENT = "PACK_UPDATE_COVER_EVENT";
export const PACK_UPDATE_COVER_EVENT_SUCCESS = "PACK_UPDATE_COVER_EVENT_SUCCESS";
export const PACK_UPDATE_COVER_EVENT_FAIL = "PACK_UPDATE_COVER_EVENT_FAIL";

export const PACK_UPDATE_FLYER_EVENT = "PACK_UPDATE_FLYER_EVENT";
export const PACK_UPDATE_FLYER_EVENT_SUCCESS = "PACK_UPDATE_FLYER_EVENT_SUCCESS";
export const PACK_UPDATE_FLYER_EVENT_FAIL = "PACK_UPDATE_FLYER_EVENT_FAIL";

export const PACK_SAVE_TMP_IMAGE = "PACK_SAVE_TMP_IMAGE";
export const PACK_SAVE_TMP_IMAGE_SUCCESS = "PACK_SAVE_TMP_IMAGE_SUCCESS";
export const PACK_SAVE_TMP_IMAGE_FAIL = "PACK_SAVE_TMP_IMAGE_FAIL";

export const PACK_LIST_MODEL_EVENT = "PACK_LIST_MODEL_EVENT";
export const PACK_LIST_MODEL_EVENT_SUCCESS = "PACK_LIST_MODEL_EVENT_SUCCESS";
export const PACK_LIST_MODEL_EVENT_FAIL = "PACK_LIST_MODEL_EVENT_FAIL";

export const PACK_PUBLISH_CHECK_EVENT = "PACK_PUBLISH_CHECK_EVENT";
export const PACK_PUBLISH_CHECK_EVENT_SUCCESS = "PACK_PUBLISH_CHECK_EVENT_SUCCESS";
export const PACK_PUBLISH_CHECK_EVENT_FAIL = "PACK_PUBLISH_CHECK_EVENT_FAIL";

export const PACK_PUBLISH_EVENT = "PACK_PUBLISH_EVENT";
export const PACK_PUBLISH_EVENT_SUCCESS = "PACK_PUBLISH_EVENT_SUCCESS";
export const PACK_PUBLISH_EVENT_FAIL = "PACK_PUBLISH_EVENT_FAIL";

export const PACK_PUBLISH_CANCEL_EVENT = "PACK_PUBLISH_CANCEL_EVENT";
export const PACK_PUBLISH_CANCEL_EVENT_SUCCESS = "PACK_PUBLISH_CANCEL_EVENT_SUCCESS";
export const PACK_PUBLISH_CANCEL_EVENT_FAIL = "PACK_PUBLISH_CANCEL_EVENT_FAIL";

export const PACK_TICKETS_DOWNLOAD = "PACK_TICKETS_DOWNLOAD";
export const PACK_TICKETS_DOWNLOAD_SUCCESS = "PACK_TICKETS_DOWNLOAD_SUCCESS";
export const PACK_TICKETS_DOWNLOAD_FAIL = "PACK_TICKETS_DOWNLOAD_FAIL";

export const PACK_REMOVE_EVENT = "PACK_REMOVE_EVENT";
export const PACK_REMOVE_EVENT_SUCCESS = "PACK_REMOVE_EVENT_SUCCESS";
export const PACK_REMOVE_EVENT_FAIL = "PACK_REMOVE_EVENT_FAIL";

export const PACK_RESUME_DOWNLOAD = "PACK_RESUME_DOWNLOAD";
export const PACK_RESUME_DOWNLOAD_SUCCESS = "PACK_RESUME_DOWNLOAD_SUCCESS";
export const PACK_RESUME_DOWNLOAD_FAIL = "PACK_RESUME_DOWNLOAD_FAIL";

export const PACK_MANAGE_INFOS_EVENT = "PACK_MANAGE_INFOS_EVENT";
export const PACK_MANAGE_INFOS_EVENT_SUCCESS = "PACK_MANAGE_INFOS_EVENT_SUCCESS";
export const PACK_MANAGE_INFOS_EVENT_FAIL = "PACK_MANAGE_INFOS_EVENT_FAIL";

// ------------------------------  PRESTATION  ------------------------------

export const PACK_CREATE_EMPTY_PRESTATION = "PACK_CREATE_EMPTY_PRESTATION";
export const PACK_CREATE_EMPTY_PRESTATION_SUCCESS = "PACK_CREATE_EMPTY_PRESTATION_SUCCESS";
export const PACK_CREATE_EMPTY_PRESTATION_FAIL = "PACK_CREATE_EMPTY_PRESTATION_FAIL";

export const PACK_GET_PRESTATION = "PACK_GET_PRESTATION";
export const PACK_GET_PRESTATION_SUCCESS = "PACK_GET_PRESTATION_SUCCESS";
export const PACK_GET_PRESTATION_FAIL = "PACK_GET_PRESTATION_FAIL";

export const PACK_REMOVE_PRESTATION = "PACK_REMOVE_PRESTATION";
export const PACK_REMOVE_PRESTATION_SUCCESS = "PACK_REMOVE_PRESTATION_SUCCESS";
export const PACK_REMOVE_PRESTATION_FAIL = "PACK_REMOVE_PRESTATION_FAIL";

export const PACK_UPDATE_PRESTATION = "PACK_UPDATE_PRESTATION";
export const PACK_UPDATE_PRESTATION_SUCCESS = "PACK_UPDATE_PRESTATION_SUCCESS";
export const PACK_UPDATE_PRESTATION_FAIL = "PACK_UPDATE_PRESTATION_FAIL";

export const PACK_LIST_MODEL_PRESTATION = "PACK_LIST_MODEL_PRESTATION";
export const PACK_LIST_MODEL_PRESTATION_SUCCESS = "PACK_LIST_MODEL_PRESTATION_SUCCESS";
export const PACK_LIST_MODEL_PRESTATION_FAIL = "PACK_LIST_MODEL_PRESTATION_FAIL";

export const PACK_LIST_SCENARIO_PRESTATION = "PACK_LIST_SCENARIO_PRESTATION";
export const PACK_LIST_SCENARIO_PRESTATION_SUCCESS = "PACK_LIST_SCENARIO_PRESTATION_SUCCESS";
export const PACK_LIST_SCENARIO_PRESTATION_FAIL = "PACK_LIST_SCENARIO_PRESTATION_FAIL";

export const PACK_CREATE_MODEL_PRESTATION = "PACK_CREATE_MODEL_PRESTATION";
export const PACK_CREATE_MODEL_PRESTATION_SUCCESS = "PACK_CREATE_MODEL_PRESTATION_SUCCESS";
export const PACK_CREATE_MODEL_PRESTATION_FAIL = "PACK_CREATE_MODEL_PRESTATION_FAIL";

export const PACK_CREATE_SCENARIO_PRESTATION = "PACK_CREATE_SCENARIO_PRESTATION";
export const PACK_CREATE_SCENARIO_PRESTATION_SUCCESS = "PACK_CREATE_SCENARIO_PRESTATION_SUCCESS";
export const PACK_CREATE_SCENARIO_PRESTATION_FAIL = "PACK_CREATE_SCENARIO_PRESTATION_FAIL";

export const PACK_MANAGE_PRESTATION = "PACK_MANAGE_PRESTATION";
export const PACK_MANAGE_PRESTATION_SUCCESS = "PACK_MANAGE_PRESTATION_SUCCESS";
export const PACK_MANAGE_PRESTATION_FAIL = "PACK_MANAGE_PRESTATION_FAIL";
