import { produce } from "immer";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, CHANGE_PASSWORD_SUCCESS, REGISTER_SUCCESS, RECOVER_PASSWORD_SUCCESS, LOGOUT_FAILED } from "../constants";
import { AnyAction } from "@reduxjs/toolkit";

export interface IAuthState {
    isLogged: Boolean
    user?: IUserState
}

export interface IUserCredential {
    name?: string
    email: string
    password: string
}

export interface IUserPassword {
    old_password: string
    new_password: string
    check_password: string
}

export interface ISupport {
    subject: string
    message: string
}

export interface IResponseStatus {
    data: {
        code?: number;
        label?: string;
        message?: {
            fr: string,
            en: string
        }
    },
    status: "fail" | "success";
}

export interface IUserState {
    email: string,
    type: string,
    api_key?: string,
    name?: string
}

const initState = {
    isLogged: false,
    user: {
        api_key: '',
        type: '' ,
        email: ''
    } as IUserState
} as IAuthState;

const authReducer = produce((draft = initState, action: AnyAction) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            draft.user = action.payload.data
            draft.isLogged = true
            break;
        case REGISTER_SUCCESS:
            draft.user = action.payload.data
            draft.isLogged = true
            break;
        case LOGOUT_SUCCESS:
        case LOGOUT_FAILED:
            draft.user = {
                api_key: '',
                type: '' ,
                email: '',
                name: ''
            } as IUserState
            draft.isLogged = false
            break;
        case CHANGE_PASSWORD_SUCCESS:
            draft.user = action.payload.data
            break;
        case RECOVER_PASSWORD_SUCCESS:
            draft.user = action.payload.data
            draft.isLogged = true
            break;
        default:
            return draft;
    }
})

export default authReducer;