import { useState, useEffect } from 'react'
import { Badge } from 'react-bootstrap'

const EventStatusBadge = (props: { status: "DRAFT" | "PUBLISHED" | "SCHEDULED" | "FINISHED" | "CANCELED", position?: string}) => {

    const [color, setColor] = useState('')
    const [content, setContent] = useState('')

    useEffect(() => {
        switch (props.status) {
            case 'DRAFT':
                setColor('danger')
                setContent('BROUILLON')
                break
            case 'SCHEDULED':
                setColor('warning')
                setContent('BIENTÔT PUBLIÉ')
                break
            case 'PUBLISHED':
                setColor('success')
                setContent('PUBLIÉ')
                break
            case 'FINISHED':
                setColor('secondary')
                setContent('TERMINÉ')
                break
        }
    }, [props.status])

    return (
        <>
            {props.position && props.position === 'INLINE' ? 
                (<>   
                    <Badge className="rounded-pill m-2 statusPill" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, fontSize: 14}} bg={color}>
                        {content}
                    </Badge>
                </>
                ) 
                :
                (<>
                    <Badge className="position-absolute rounded-pill m-2 statusPill" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, fontSize: 14}} bg={color}>
                        {content}
                    </Badge>
                </>)
            }
        </>
    )
}

export default EventStatusBadge