import React from "react";
import {Card, Badge, Col, Button, Row} from "react-bootstrap";
import {Pencil, Gear} from "react-bootstrap-icons";

interface PrestationCardProps {
    id: number;
    title: string;
    content: string;
    cover?: string;
    price: number;
    type: string;
    quantityAvailable?: number;
    choices?: string[];
    eventStatus: 'DRAFT' | 'SCHEDULED' | 'PUBLISHED' | 'FINISHED' | 'CANCELED';
    showPrestationEdit: (id: number) => void ;
    showPrestationManage: (id: number, quantity?: number) => void ;
}

const PrestationCard = (props: PrestationCardProps) => {
    return (
        <div className="col-12 col-lg-6 p-3 mt-2">
            <Card className="position-relative prestaCard">
                <div className="cardDisplay">
                    {props.cover && <Card.Img className="rounded-top" src={props.cover} alt="" />}
                    {props.eventStatus === 'DRAFT' && 
                        <Button onClick={() => props.showPrestationEdit(props.id)} className="position-absolute px-1 pt-0 pb-2" variant="outline-secondary" size="sm">
                            <Pencil size={18} />
                        </Button>
                    }
                    {(props.eventStatus === 'SCHEDULED' || props.eventStatus === 'PUBLISHED') && 
                        <Button onClick={() => {props.showPrestationManage(props.id, props.quantityAvailable)}} className="position-absolute px-1 pt-0 pb-2" variant="outline-secondary" size="sm">
                            <Gear size={18} />
                        </Button>
                    }
                </div>
                <Card.Body>
                    <Row>
                        <Col className="justify-content-start d-flex h-50">
                            <div className="w-100">
                                <Card.Title className="fw-bolder">{props.title}</Card.Title>
                                <Card.Text className="my-2">{props.content}</Card.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col className="d-flex align-items-end">
                            <div className="h5">{props.price ? props.price.toFixed(2) : ""}€</div>
                        </Col>
                        <Col>
                            {!!props.choices && (
                                <div className="justify-content-start d-flex flex-wrap gap-2">
                                    {props.choices.map((choice, index) => (
                                        <Badge bg="light" key={index} className="border" style={{color: "#454545", fontSize: "1rem"}}>
                                            {choice}
                                        </Badge>
                                    ))}
                                </div>
                            )}
                            {!!props.quantityAvailable && <Card.Text className="text-muted text-small">Quantité : {props.quantityAvailable}</Card.Text>}
                            <></>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default PrestationCard;