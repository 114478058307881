import React from 'react'
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap'
import Utensils from '../../assets/Utensils'
import Logo from '../../assets/RectBlanc.png'
import {FORGOT_PASSWORD, LOGIN, REGISTER} from '../../modules/auth/routes/auth'
import {PropsFromReduxNavbar} from '../containers/NavbarContainer'
import {NavLink, useLocation} from 'react-router-dom'
import {Bank2, BrightnessHigh, Building, CalendarEvent, DiamondFill, Droplet, DropletFill, HouseDoorFill, MoonStarsFill, PieChartFill, QrCodeScan, ShareFill, ThermometerHigh, Water} from 'react-bootstrap-icons'
import { CORE_URL } from '../helpers/Env'

const NavbarComponent = (props: PropsFromReduxNavbar) => {

    const usermail = (props ? props.email : '')
    const username = (props ? props.name : '')

    const noShow = [LOGIN, REGISTER, FORGOT_PASSWORD]
    const location = useLocation()
    if (noShow.includes(location.pathname)) return null

    let mode = undefined;
    if (CORE_URL.includes('demo-core.libevents')) {
        mode = 'DEMO'
    }
    else if (CORE_URL.includes('libevents_core')) {
        mode = 'LOCAL'
    }

    return (
        <Navbar collapseOnSelect sticky="top" className='queries d-none d-md-block'>
            <Container fluid >
                <Navbar.Toggle className='justify-content-end text-decoration-none' aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className='sidebar d-flex align-content-start justify-content-center pt-4 d-lg-block d-none'>
                        <div className='sidebar-header pb-2 mb-1 w-75'>
                            <img src={Logo} alt="" width={'100%'} height={'100%'} />
                        </div>
                        <Nav className='my-2 mx-3 flex-column justify-content-center align-items-start pt-2'>
                            <NavLink to="/" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><PieChartFill className="me-3" /><p className="m-0 p-0">Tableau de bord</p></NavLink>
                            <hr className='my-1' style={{width: '100%', opacity: 0.1}} />
                            <NavLink to="/party" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><MoonStarsFill className="me-3" /><p className="m-0 p-0">Soirées</p></NavLink>
                            <NavLink to="/pool" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><DropletFill className="me-3" /><p className="m-0 p-0">Pool party</p></NavLink>
                            <NavLink to="/transat" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><BrightnessHigh className="me-3" /><p className="m-0 p-0">Transat</p></NavLink>
                            <NavLink to="/spa" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><ThermometerHigh className="me-3" /><p className="m-0 p-0">SPA</p></NavLink>
                            <NavLink to="/restaurant" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><i className=" me-3"><Utensils /></i><p className="m-0 p-0">Restaurant</p></NavLink>
                            <NavLink to="/hostel" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><HouseDoorFill className="me-3" /><p className="m-0 p-0">Hébergement</p></NavLink>
                            <NavLink to="/pack" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><DiamondFill className="me-3" /><p className="m-0 p-0">Pack</p></NavLink>
                            <hr className='my-1' style={{width: '100%', opacity: 0.1}} />
                            <NavLink to="/business-record" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><Building className="me-3" /><p className="m-0 p-0">Fiche établissement</p></NavLink>
                            <NavLink to="/scanners" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><QrCodeScan className="me-3" /><p className="m-0 p-0">Scanners </p></NavLink>
                            <NavLink to="/networks" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><ShareFill className="me-3" /><p className="m-0 p-0">Réseaux</p></NavLink>
                            <NavLink to="/bank-info" style={{textDecoration: 'none'}} className='mb-1 d-flex nav-link fw-bolder align-items-center'><Bank2 className="me-3" /><p className="m-0 p-0">Données bancaires</p></NavLink>
                        </Nav>
                        <div className="nav-foot text-center " >
                            {mode !== undefined &&
                                <Col style={{ alignItems: 'center', width: '100%', paddingBottom: 5, color: 'red', fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>
                                    MODE {mode}
                                </Col>
                            }
                            <Row style={{flexDirection: 'column'}}>
                                {username && 
                                    <Col style={{marginTop: 5, paddingLeft: 15, paddingRight: 15}}>
                                        Compte organisateur:<br/><span className="fw-bold">{username}</span>
                                    </Col>
                                }
                                {usermail && 
                                    <Col style={{marginTop: 5, paddingLeft: 15, paddingRight: 15}}>
                                        Connecté en tant que:<br/><span className="fw-bold">{usermail}</span>
                                    </Col>
                                }
                            </Row>
                        </div >
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    )
}

export default NavbarComponent