import { API_URL_EVENTS, API_URL_PRESTATIONS } from "../constants";
import { IPartyImage, IPartyEntrances, IPartyInfos, IManageEntranceRequest, IManageInfosRequest, IManagePrestationRequest, IPrestationRequest } from "../reducers";
import { IPrestationModelRequest } from "../reducers";

export const get = (apiKey: string, id: number, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/get`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id, subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listFinished = (apiKey: string, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/list/finished`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listFuture = (apiKey: string, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/list/future`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createEmpty = (apiKey: string, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/create/empty`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createModel = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/create/model`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const publishCheck = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/publish/check`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const publish = (apiKey: string, id: number, scheduled_at:string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/publish`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id, scheduled_at }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const publishCancel = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/publish/cancel`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const ticketsDownload = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/tickets/download`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const remove = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateInfos = (apiKey: string, infos: IPartyInfos) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/update/infos`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateCover = (apiKey: string, infos: IPartyImage) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/update/cover`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateFlyer = (apiKey: string, infos: IPartyImage) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/update/flyer`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const saveTmpImage = (apiKey: string, infos: IPartyImage) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/tmp/image`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updateEntrances = (apiKey: string, infos: IPartyEntrances) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/update/entrances`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listModel = (apiKey: string, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/list/models`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const manageInfos = (apiKey: string, infos: IManageInfosRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/manage/infos`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const manageEntrances = (apiKey: string, infos: IManageEntranceRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/manage/entrances`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ ...infos }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const resumeDownload = (apiKey: string, event_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_EVENTS}/resume/download`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id: event_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createEmptyPrestation = (apiKey: string, event_id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/create/empty`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ event_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const getPrestation = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/get`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listModelPrestation = (apiKey: string, subtype: string, category: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/list/models`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ subtype, category }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const listScenarioPrestation = (apiKey: string) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/list/scenarios`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createModelPrestation = (apiKey: string, infos: IPrestationModelRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/create/model`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(infos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const createScenarioPrestation = (apiKey: string, infos: IPrestationModelRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/create/scenario`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ scenario_id: infos.prestation_id, event_id: infos.event_id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const removePrestation = (apiKey: string, id: number) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/remove`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ id }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const updatePrestation = (apiKey: string, prestationInfos: IPrestationRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/update`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify(prestationInfos),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};

export const managePrestation = (apiKey: string, infos: IManagePrestationRequest) => {
    const ENDPOINT: RequestInfo = `${API_URL_PRESTATIONS}/manage`;

    const parameters: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: apiKey,
        },
        body: JSON.stringify({ ...infos }),
        mode: "cors",
    };

    return fetch(ENDPOINT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((e) => {
            console.error(e);
        });
};
