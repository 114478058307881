import { Outlet, RouteObject } from "react-router-dom";
import withPermissions from "../../../routes/withPermissions";
import SpaPage from "../pages/SpaPage";
import SpaNewContainer from "../containers/SpaNewContainer";
import SpaNewModelsContainer from "../containers/SpaNewModelsContainer";
import SpaCreateContainer from "../containers/SpaCreateContainer";
import SpaEditContainer from '../containers/SpaEditContainer';
import SpaResumeContainer from "../containers/SpaResumeContainer";

export const SpaRoutes = (isLogged: boolean): RouteObject[] => {
    return [
        {
            path: '/spa',
            element: withPermissions(isLogged, <Outlet />),
            children: [
                { index: true, element: <SpaPage /> },
                { path: '/spa/new', element: <SpaNewContainer /> },
                { path: '/spa/models', element: <SpaNewModelsContainer /> },
                {
                    path: '/spa/create',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/spa/create/:id',
                            element: <SpaCreateContainer />,
                        },
                    ]
                },
                {
                    path: '/spa/resume',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/spa/resume/:id',
                            element: <SpaResumeContainer />,
                        },
                    ]
                },
                {
                    path: '/spa/edit',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/spa/edit/:id',
                            element: <SpaEditContainer />
                        }
                    ]
                },
            ]
        }
    ]
}