import {CORE_URL} from "../../../common/helpers/Env";

export const API_URL = CORE_URL + "/devices";

export const GET_CODE = "GET_CODE";
export const GET_CODE_SUCCESS = "GET_CODE_SUCCESS";
export const GET_CODE_FAIL = "GET_CODE_FAIL";

export const GET_DEVICES = "GET_DEVICES";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL";

export const REMOVE_DEVICE = "REMOVE_DEVICE";
export const REMOVE_DEVICE_SUCCESS = "REMOVE_DEVICE_SUCCESS";
export const REMOVE_DEVICE_FAIL = "REMOVE_DEVICE_FAIL";

export const CHANGE_NOTIFICATIONS = "CHANGE_NOTIFICATIONS";
export const CHANGE_NOTIFICATIONS_SUCCESS = "CHANGE_NOTIFICATIONS_SUCCESS";
export const CHANGE_NOTIFICATIONS_FAIL = "CHANGE_NOTIFICATIONS_FAIL";